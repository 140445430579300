import _ from 'lodash';
import { UploadedFile } from '../UploadFiles/UploadedFileList.types';
import {
  DocumentGridProps,
  DOCUMENTS_GRID_COLUMNS,
  SpreadSheetDocument,
} from './DocumentsGrid/DocumentsGrid.types';
import { DocumentResponse } from '../../store/files/documents/documents.list.types';
import { DocumentMetadataFields } from '../UploadFiles/MetadataForm/MetadataForm.types';

const createEmptyObjectFromArray = (properties: string[]) =>
  properties.reduce((acc: Record<string, string>, value: string) => {
    acc[value] = '';
    return acc;
  }, {});

export const mapToSpreadSheetDocuments = (
  docs: UploadedFile[] | DocumentResponse[]
): DocumentGridProps['docs'] => {
  const gridColumnsNames = Object.values(DOCUMENTS_GRID_COLUMNS);
  const emptyTemplateObject = createEmptyObjectFromArray(gridColumnsNames);
  const documentFields = [
    ...gridColumnsNames,
    DocumentMetadataFields.ParentDocuments,
    DocumentMetadataFields.VersionKey,
  ];

  return docs?.map((doc) => ({
    ...emptyTemplateObject,
    ...(_.pick(doc, documentFields) as SpreadSheetDocument),
  }));
};
