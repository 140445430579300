import React from 'react';
import './DisplayItemName.scss';
import { DisplayItemProps } from './DisplayItemName.types';
import { useAppDispatch } from '../../../store/hooks';
import { ItemNameText } from '../ItemNameText/ItemNameText';

export const DisplayItemName = ({ item, action }: DisplayItemProps) => {
  const dispatch = useAppDispatch();
  const clickableClass = action ? 'item-name--clickable' : '';
  const handleClick = action ? () => dispatch(action) : undefined;
  const name = 'FileName' in item ? item.FileName : item.ClauseName;
  return (
    <span className={`item-name ${clickableClass}`} onClick={handleClick}>
      <ItemNameText fileName={name} strong />
    </span>
  );
};
