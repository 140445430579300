import React from 'react';
import { Comment } from './Comment/Comment';
import { CommentListProps } from './CommentList.types';
import { CircularProgress } from '@mui/material';

import '../Comments.scss';

export const CommentList: React.FC<CommentListProps> = ({
  handleDelete,
  comments,
  isCurrentUserCommentAuthor,
  isDeletingComment,
  deletingComment,
  isAddingComment,
  isCommentsFetching,
}) => {
  return (
    <div className={`comments-list comments-list--container${!comments.length ? '__empty' : ''}`}>
      {comments.length ? (
        comments.map((comment) => (
          <Comment
            key={comment.CommentId}
            content={comment.Content}
            name={comment.UserEmail}
            createdAt={comment.CreatedAt}
            showDelete={isCurrentUserCommentAuthor(comment.UserEmail)}
            handleDelete={() => handleDelete(comment.CommentId)}
            loading={isDeletingComment && comment.CommentId === deletingComment}
            deleteBlocked={
              (isDeletingComment && comment.CommentId !== deletingComment) || isAddingComment
            }
          />
        ))
      ) : !isCommentsFetching ? (
        'No comments'
      ) : (
        <CircularProgress size={50} />
      )}
    </div>
  );
};
