import { useGetClausesListQuery } from './clauses.list.service';
import { ClausesListParams } from './clauses.list.types';
import { clauseSelector } from './clauses.selectors';
import { bookmarksSelectors } from '../bookmarks/bookmars.selectors';
import { updateClausesForBookmarkListQueryParams } from '../bookmarks/bookmarks.slice';
import {
  openClauseViewModal,
  updateDraftListQueryParams,
  updateListQueryParams,
  updatePendingListQueryParams,
  updateReportedListQueryParams,
  updateUploadedListQueryParams,
} from './clauses.slice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { GetClausesListWithParamsBaseHookParams } from './clauses.hooks.types';
import {
  preparePaginationForGrid,
  prepareQueryParams,
  prepareQueryParamsWithFirstPage,
  prepareSortForGrid,
} from '../documentsAndClauses/list.hooks.helpers';
import { PENDING_LIST_REFRESH_INTERVAL } from '../../../config/config';
import { AccessMode } from '../documentsAndClauses/list.types';
import { GridSortModel } from '@mui/x-data-grid/models/gridSortModel';
import { DocumentStatus } from '../documents/documents.list.types';
import { BOOLEAN_VALUES } from '../../../components/UploadFiles/MetadataForm/MetadataFormHelpers';
import { useParams } from 'react-router-dom';

// TODO similar to useGetDocumentsListWithParamsBase
function useGetClausesListWithParamsBase({
  defaultParams,
  queryOptions,
  selector,
  action,
}: GetClausesListWithParamsBaseHookParams) {
  const dispatch = useAppDispatch();

  const params = useAppSelector(selector);
  const { PageNumber, PageSize, SortBy, SortOrder, ...filters } = params;

  const query = useGetClausesListQuery(
    { ...params, ...defaultParams },
    {
      refetchOnMountOrArgChange: true,
      ...queryOptions,
    }
  );
  const rowCount = query.data?.TotalClauseCount ?? 0;

  const { page, pageSize } = preparePaginationForGrid(PageNumber, PageSize);
  const sort = prepareSortForGrid(SortBy, SortOrder);

  const setPage = (pageNumber: number) =>
    dispatch(action(prepareQueryParams<ClausesListParams>(filters, pageNumber, pageSize, sort)));
  const setPageSize = (size: number) =>
    dispatch(action(prepareQueryParamsWithFirstPage<ClausesListParams>(filters, size, sort)));
  const setFilters = (newFilters: ClausesListParams) =>
    dispatch(
      action(prepareQueryParamsWithFirstPage<ClausesListParams>(newFilters, pageSize, sort))
    );
  const setSort = (model: GridSortModel) =>
    dispatch(action(prepareQueryParamsWithFirstPage<ClausesListParams>(filters, pageSize, model)));

  return {
    ...query,
    defaultParams,
    rowCount,
    page,
    pageSize,
    setPage,
    setPageSize,
    filters,
    setFilters,
    sort,
    setSort,
  };
}

export function useGetClausesListWithParams() {
  return useGetClausesListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.READY],
      AccessMode: AccessMode.READ,
      Reported: BOOLEAN_VALUES.FALSE,
      IsDraft: BOOLEAN_VALUES.FALSE,
    },
    selector: clauseSelector.selectListQueryParams,

    action: updateListQueryParams,
  });
}

export function useGetPendingClausesListWithParams() {
  return useGetClausesListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.PROCESSING, DocumentStatus.FAILED, DocumentStatus.DUPLICATE],
      AccessMode: AccessMode.WRITE,
      Queries: [],
      IsDraft: BOOLEAN_VALUES.FALSE,
    },
    queryOptions: {
      pollingInterval: PENDING_LIST_REFRESH_INTERVAL,
    },
    selector: clauseSelector.selectPendingListQueryParams,
    action: updatePendingListQueryParams,
  });
}

export function useGetPendingDraftClausesListWithParams() {
  return useGetClausesListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.PROCESSING, DocumentStatus.FAILED, DocumentStatus.DUPLICATE],
      AccessMode: AccessMode.WRITE,
      Queries: [],
      IsDraft: BOOLEAN_VALUES.TRUE,
    },
    queryOptions: {
      pollingInterval: PENDING_LIST_REFRESH_INTERVAL,
    },
    selector: clauseSelector.selectPendingListQueryParams,
    action: updatePendingListQueryParams,
  });
}

export function useGetUploadedClausesListWithParams() {
  return useGetClausesListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.READY],
      AccessMode: AccessMode.WRITE,
      Reported: BOOLEAN_VALUES.FALSE,
      IsDraft: BOOLEAN_VALUES.FALSE,
    },
    selector: clauseSelector.selectUploadedListQueryParams,
    action: updateUploadedListQueryParams,
  });
}

export function useGetReportedClausesListWithParams() {
  return useGetClausesListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.READY],
      // TODO global users access issue fix - to be refined
      AccessMode: AccessMode.READ,
      Reported: BOOLEAN_VALUES.TRUE,
      IsDraft: BOOLEAN_VALUES.FALSE,
    },
    selector: clauseSelector.selectReportedListQueryParams,
    action: updateReportedListQueryParams,
  });
}

export function useGetDraftClausesListWithParams() {
  return useGetClausesListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.READY],
      AccessMode: AccessMode.READ,
      Reported: BOOLEAN_VALUES.FALSE,
      IsDraft: BOOLEAN_VALUES.TRUE,
    },
    selector: clauseSelector.selectDraftListQueryParams,
    action: updateDraftListQueryParams,
  });
}

export function useGetClausesForBookmarkListWithParams(BookmarkId: string) {
  return useGetClausesListWithParamsBase({
    defaultParams: {
      Status: [DocumentStatus.READY],
      AccessMode: AccessMode.READ,
      Reported: BOOLEAN_VALUES.FALSE,
      IsDraft: BOOLEAN_VALUES.FALSE,
      BookmarkId,
    },
    selector: bookmarksSelectors.selectClausesForBookmarkListQueryParams,
    action: updateClausesForBookmarkListQueryParams,
  });
}

export const useFetchAndDispatchClause = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { data: clauses } = useGetClausesListQuery(
    {
      ClauseId: [id ?? ''],
      Status: [DocumentStatus.READY],
    },
    { skip: !id }
  );
  if (id) {
    const clause = clauses?.Clauses?.[0];
    dispatch(openClauseViewModal({ clause: clause! }));
  }
};
