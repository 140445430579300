import React from 'react';
import InfoPage from '../StaticComponents/InfoPage/InfoPage';
import { SERVICE_EMAIL } from '../../config/config';

const YOU_DO_NOT_HAVE_ACCESS = 'You do not have permission to access GWR';
const YOU_DO_NOT_HAVE_ACCESS_WRITE =
  'You have insufficient access rights to edit the content in GWR';

const NoAccess = ({ write }: { write?: boolean }) =>
  write ? (
    <InfoPage header={YOU_DO_NOT_HAVE_ACCESS_WRITE}>
      <div>In order to obtain Editors rights please reach us by sending request to</div>
      <a href={`mailto:${SERVICE_EMAIL}`} target='_blank' rel='noreferrer'>
        {SERVICE_EMAIL}
      </a>
      <br />
      <div>Please let us know which ERGO company you are working in.</div>
    </InfoPage>
  ) : (
    <InfoPage header={YOU_DO_NOT_HAVE_ACCESS}>
      <div>To get easy and secure access please reach us by sending request to</div>
      <a href={`mailto:${SERVICE_EMAIL}`} target='_blank' rel='noreferrer'>
        {SERVICE_EMAIL}
      </a>
      <div>Please let us know which ERGO Company you are working in.</div>
      <br />
      <div>
        We need to confirm your role-based authentication first & then you can easily log-in and
        browse content in GWR.
      </div>
    </InfoPage>
  );

export default NoAccess;
