import {
  Document,
  DocumentMetadataUpdateRequest,
  DocumentMetadataUpdateResponse,
  DocumentMetadataUpdateResponseError,
} from './documents.list.types';
import {
  DocumentUpdateDescriptions,
  DocumentUpdateStatuses,
} from '../../../components/SpreadSheet/DocumentsGrid/DocumentsGrid.types';
import { ErrorMessages } from '../../../services/errors.service.types';
import { getDocumentIds } from '../../../components/SpreadSheet/DocumentsGrid/AchDocumentsGrid.helpers';
import { ClauseMetadataUpdateResponseError } from '../clauses/clauses.list.types';

export const mapStatusToIds = (documentIds: Document['DocumentId'][], status: string) =>
  documentIds.reduce(
    (pv, id) => ({
      ...pv,
      [id]: status,
    }),
    {}
  ) || {};

export const mapAchErrors = ({
  error,
  errorMessage,
}: DocumentMetadataUpdateResponseError | ClauseMetadataUpdateResponseError): string =>
  (error || errorMessage || '').indexOf('duplicate') > -1
    ? ErrorMessages.MetadataUpdateDuplicate
    : ErrorMessages.MetadataUpdateError;

export const mapSuccessResponseToUpdateStatuses = (
  { Added, Failed, Errors }: DocumentMetadataUpdateResponse,
  documents: DocumentMetadataUpdateRequest
): DocumentUpdateStatuses => ({
  ...mapStatusToIds(getDocumentIds(documents), ErrorMessages.MetadataUpdateError),
  ...mapStatusToIds(Added?.length ? Added : [], DocumentUpdateDescriptions.SuccessfullyUpdated),
  ...(Failed?.reduce(
    (pv, id, i) => ({
      ...pv,
      [id]: mapAchErrors(Errors?.[i] || {}),
    }),
    {}
  ) || {}),
});

export const isAchResponseWithErrors = ({ Failed, Errors }: DocumentMetadataUpdateResponse) =>
  (Failed && Failed?.length > 0) || (Errors && Errors?.length > 0);

export const mapAchErrorResponseToErrorMessage = (response: DocumentMetadataUpdateResponse) => {
  return response?.Errors?.map(mapAchErrors).join('; ') || '';
};
