import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ReportedFieldsProps } from './ReportedField.types';
import Popper from '@mui/material/Popper';
import { useState } from 'react';
import { IconButton, Paper } from '@mui/material';
import { CheckboxWithLabel } from '../../StaticComponents/CheckboxWithLabel';
import { formatUiDateTimeFromString } from '../../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { ReportedFieldsDetails } from './ReportedField.helper';

import common from '../../../assets/common.module.scss';
import './ReportedField.scss';

export const ReportedField = ({
  item,
  dictionaries,
  isReportedChecked = false,
  setIsReportedChecked,
  editMode = false,
}: ReportedFieldsProps): JSX.Element => {
  const [popperOpen, setPopperOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const {
    Reported,
    ReportedAt = '',
    ReportedBy = '',
    ReportedReason = '',
    ReportedComment = '',
  } = item ?? {};
  const handlePopperOpen = (event: React.MouseEvent<HTMLElement>) => {
    setPopperOpen(true);
    setAnchorEl(event.currentTarget);
  };

  const handlePopperClose = () => {
    setPopperOpen(false);
  };

  if (!Reported) return <></>;
  return (
    <div className='reported-field'>
      <div className='reported-field-preview'>
        <div className='reported-field-title'>
          <WarningAmberIcon />
          <span>Reported {ReportedAt && `on ${formatUiDateTimeFromString(ReportedAt)}`}</span>
        </div>
        {!editMode && (
          <div>
            <IconButton size='small' onClick={handlePopperOpen} disableFocusRipple>
              <InfoOutlinedIcon fontSize='inherit' />
            </IconButton>

            <Popper
              open={popperOpen}
              anchorEl={anchorEl}
              placement='bottom-start'
              sx={{ zIndex: common.zIndexMuiModal }}
            >
              <ClickAwayListener onClickAway={handlePopperClose}>
                <Paper sx={{ p: '24px 16px', minWidth: 200 }}>
                  <ReportedFieldsDetails
                    dictionaries={dictionaries}
                    reportedBy={ReportedBy}
                    reportedReason={ReportedReason}
                    reportedComment={ReportedComment}
                  />
                </Paper>
              </ClickAwayListener>
            </Popper>
          </div>
        )}
      </div>

      {editMode && (
        <div className='reported-field-edit'>
          <ReportedFieldsDetails
            dictionaries={dictionaries}
            reportedBy={ReportedBy}
            reportedReason={ReportedReason}
            reportedComment={ReportedComment}
          />
          <CheckboxWithLabel
            checked={isReportedChecked}
            onChange={(_, checked) => setIsReportedChecked && setIsReportedChecked(checked)}
            label='Cancel report'
          />
        </div>
      )}
    </div>
  );
};
