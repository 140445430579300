import React from 'react';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { DocumentContextMenuButtonProps } from './DocumentContextMenuButton.types';
import { useAppSelector } from '../../../store/hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import './DocumentContextMenuButton.scss';

export const DocumentContextMenuButton = ({
  dataId,
  clauseMode,
  disabled,
  onClick,
}: DocumentContextMenuButtonProps) => {
  const docsToCompare = useAppSelector(
    clauseMode ? uiSelectors.selectClauseToCompare : uiSelectors.selectDocsToCompare
  );
  const compareNr = dataId ? docsToCompare.map(({ dataId }) => dataId).indexOf(dataId) + 1 : 0;

  return (
    <>
      {compareNr ? (
        <div className='compare-badge'>
          <DifferenceOutlinedIcon />
          <span>{compareNr}</span>
        </div>
      ) : null}
      <IconButton disabled={disabled} onClick={onClick}>
        <MoreVertIcon />
      </IconButton>
    </>
  );
};
