import React from 'react';
import {
  SEARCH_BY_PROVIDED_PARENT_DOCUMENTS_ID,
  useRelatedDocumentsValues,
} from '../../../UploadFiles/MetadataForm/useParentDocumentsValues.hook';
import { NOT_SELECTED } from '../../../UploadFiles/MetadataForm/MetadataFormHelpers';

import { useMinimizeItemForVersion } from '../../../StaticComponents/Modals/MinimizeItem/useMinimizeItem';
import { ParentDocumentsProps } from './ParentDocumentsMetadata.types';

export const ParentDocumentsMetadata: React.FC<ParentDocumentsProps> = ({ document }) => {
  const parentDocumentsValues = useRelatedDocumentsValues(
    document,
    SEARCH_BY_PROVIDED_PARENT_DOCUMENTS_ID
  );

  const { handleMinimize, checkIfItemMinimized, checkIfMinimizeDisabled, handleClose } =
    useMinimizeItemForVersion({});

  const toggleMinimize = (DocumentId: string, FileName: string) => {
    if (checkIfMinimizeDisabled(DocumentId)) return;
    if (checkIfItemMinimized(DocumentId)) {
      handleClose(DocumentId);
    } else {
      handleMinimize(DocumentId, FileName);
    }
  };

  return (
    <>
      {parentDocumentsValues?.length
        ? parentDocumentsValues.map((doc) => (
            <div
              onClick={() => toggleMinimize(doc.value, doc.label)}
              key={doc.value}
              className={`document-parents ${
                !checkIfItemMinimized(doc.value) && checkIfMinimizeDisabled(doc.value)
                  ? 'document-parents__blocked'
                  : ''
              }`}
            >
              {doc.label}
            </div>
          ))
        : NOT_SELECTED}
    </>
  );
};
