import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DocumentContextMenuItemProps } from './DocumentContextMenu.types';
import { useAppDispatch } from '../../../store/hooks';
import { openMetadataEditModal } from '../../../store/files/documents/documents.slice';
import {
  isClauseResponse,
  isDocumentResponse,
} from '../../../store/files/documentsAndClauses/list.helpers';
import { openClauseMetadataEditModal } from '../../../store/files/clauses/clauses.slice';

export const DocumentContextMenuEdit = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const dispatch = useAppDispatch();

  const handleMenuItemClick = () => {
    if (isDocumentResponse(document)) {
      dispatch(openMetadataEditModal({ document }));
    } else if (isClauseResponse(document)) {
      const clause = document;
      dispatch(openClauseMetadataEditModal({ clause }));
    }
    onClose();
  };
  return (
    <MenuItem disabled={disabled} onClick={handleMenuItemClick}>
      <EditOutlinedIcon />
      Edit
    </MenuItem>
  );
};
