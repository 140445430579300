import {
  CreateReportDTO,
  ExportReportRequest,
  GenerateReportRequest,
  GenerateReportType,
  ObjectType as ObjectTypeEnum,
  ReportFilters,
  ReportSearchFilters,
} from './reports.types';
import { omitBy, pickBy } from 'lodash';
import { mapToClauseFilters, mapToDocumentFilters } from '../documentsAndClauses/list.helpers';
import { DocumentsListParams } from '../documents/documents.list.types';
import { ClausesListParams } from '../clauses/clauses.list.types';
import { formatDate } from '../../../components/SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { API_DATE_FORMAT } from '../../../config/config';
import { DateRange } from '@mui/x-date-pickers-pro';

export const mapReportsRequest = ({ Filters, Fields, ObjectType }: ExportReportRequest) => {
  const cleanedFilters = pickBy(Filters, (v) => !!v && !!v.length);
  return {
    Filters: cleanedFilters,
    Fields,
    ObjectType,
  };
};

export const mapToCreateReportDto = ({
  Filters,
  Fields,
  ObjectType,
}: ExportReportRequest): CreateReportDTO => {
  const cleanedFilters = omitBy(Filters, (v) => Array.isArray(v) && !v?.length) as ReportFilters;
  const reportIncludeFilters =
    ObjectType === ObjectTypeEnum.CLAUSE
      ? (mapToClauseFilters(cleanedFilters as ClausesListParams) as ReportSearchFilters)
      : (mapToDocumentFilters(cleanedFilters as DocumentsListParams) as ReportSearchFilters);

  return {
    ExcludeFilters: undefined,
    IncludeFilters: { ...reportIncludeFilters, ObjectType },
    AccessMode: cleanedFilters?.AccessMode,
    BookmarkId: cleanedFilters?.BookmarkId,
    Fields: Fields,
    ObjectType,
    Queries: Filters?.Queries,
  };
};

export const mapToGenerateReportRequest = (
  ReportType: GenerateReportType,
  [DateFrom, DateTo]: DateRange<Date>
): GenerateReportRequest => ({
  ReportType,
  DateFrom: DateFrom ? formatDate(DateFrom, API_DATE_FORMAT) : undefined,
  DateTo: DateTo ? formatDate(DateTo, API_DATE_FORMAT) : undefined,
});
