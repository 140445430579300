import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import CircularProgress from '@mui/material/CircularProgress';
import LoadingButton from '@mui/lab/LoadingButton';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import './StatusProgress.scss';
import { StatusProgressProps } from './StatusProgress.types';

export const StatusProgress = ({
  filesExist,
  numberOfFilesUploading,
  numberOfUploadingDocs,
  isLoading,
  dataChangedCount,
  errorMessage,
  saved,
  onSave,
  missingFields,
  isSnackbarEnabled = true,
}: StatusProgressProps) => {
  return !filesExist ? (
    <></>
  ) : (
    <Snackbar
      className='upload-progress'
      open={isSnackbarEnabled}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      message={
        <>
          {numberOfFilesUploading ? (
            <>
              <CircularProgress className='uploading' size={20} />
              <strong>Files uploading</strong>
              <small>
                ({numberOfFilesUploading}/{numberOfUploadingDocs})
              </small>
            </>
          ) : isLoading ? (
            <>
              <CircularProgress className='uploading' size={20} />
              <strong>Saving metadata</strong>
            </>
          ) : errorMessage ? (
            <>
              <ErrorOutlineOutlinedIcon className='error' />
              <strong>{errorMessage}</strong>
            </>
          ) : missingFields ? (
            <>
              <WarningAmberOutlinedIcon className='warning' />
              <strong>Missing fields</strong>
            </>
          ) : dataChangedCount ? (
            <>
              <WarningAmberOutlinedIcon className='warning' />
              <strong>Unsaved changes found</strong>
            </>
          ) : saved ? (
            <>
              <DoneOutlinedIcon className='done' />
              <strong>{saved}</strong>
            </>
          ) : filesExist ? (
            <>
              <DoneOutlinedIcon className='done' />
              <strong>No unsaved changes</strong>
            </>
          ) : (
            <></>
          )}

          <LoadingButton
            loading={isLoading}
            variant='contained'
            size='medium'
            className='submit-button'
            disabled={!dataChangedCount || missingFields}
            onClick={onSave}
          >
            Save
          </LoadingButton>
        </>
      }
    />
  );
};
