export const plural = (number: number) => (number === 1 ? '' : 's');

export const SavedWithErrors = ({ success, errors }: { success: number; errors: number }) => {
  return (
    <>
      {success} file{plural(success)} saved{' '}
      {errors ? (
        <span className='error'>
          ({errors} error{plural(errors)})
        </span>
      ) : (
        ''
      )}
    </>
  );
};
