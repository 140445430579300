import {
  GridCellParams,
  GridColDef,
  GridColumnHeaderParams,
  GridEditInputCell,
  GridEditInputCellProps,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridValueGetterParams,
  GridValueSetterParams,
  useGridApiContext,
} from '@mui/x-data-grid-pro';
import { UploadedFileStatus } from '../../UploadFiles/UploadedFileList.types';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CachedIcon from '@mui/icons-material/Cached';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CoronavirusOutlineOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import './DocumentsGridColumn.scss';
import {
  DictionariesResponse,
  MetadataDictionary,
} from '../../../store/files/upload/list.service.types';
import {
  formatUiDate,
  getMetadataValuesByColumnName,
  getOrParseDate,
  sortComparator,
  validateExpireAtField,
  validateValidFromField,
  validateValidToField,
  valueFormatter,
} from './DocumentsGrid.helpers';
import { DOCUMENTS_GRID_COLUMNS, DocumentUpdateStatuses } from './DocumentsGrid.types';
import React from 'react';
import { Switch, Tooltip } from '@mui/material';
import { DocumentUpdateStatus } from './DocumentUpdateStatus';
import {
  BOOLEAN_COLUMN_TYPE_CONFIG,
  DOCUMENT_NAME_COLUMN_TYPE_CONFIG,
} from '../../Documents/DocumentsListGrid.helpers';
import {
  DATE_FIELD_CONFIG,
  DOCUMENT_LABELS,
  DOCUMENT_METADATA_TOOLTIPS,
  MAX_INSURED_INDUSTRY_LENGTH,
  MAX_PRODUCT_TYPE_LENGTH,
  MIN_INSURED_INDUSTRY_LENGTH,
  MIN_PRODUCT_TYPE_LENGTH,
  TOOLTIP_DELAY,
} from '../../../config/config';
import { EditAutocomplete } from './EditAutocomplete';
import { FileNameInput } from '../../StaticComponents/FileNameInput/FileNameInput';
import { formatApiDate, formatBoolean } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { DeleteIconBlinking } from '../../UploadFiles/UploadedFileDelete/DeleteIconBlinking';
import { GridEditDateCell } from './GridEditDateCell';
import { mapErrorResponseToErrorMessageDetails } from '../../../store/files/documents/documents.update.error.helpers';

const renderDocumentOrUpdateStatus =
  (statuses: DocumentUpdateStatuses) => (params: GridRenderCellParams) => {
    const documentId = params.id.toString();
    if (statuses[documentId]) {
      return <DocumentUpdateStatus statuses={statuses} documentId={documentId} />;
    }
    return renderStatus(params);
  };

const renderStatus = (params: GridRenderCellParams) => {
  let uploadingIcon = <></>;
  let error = params.row.Errors;
  let errorDescription = error && mapErrorResponseToErrorMessageDetails(error);

  switch (params.value) {
    case UploadedFileStatus.MISSING_DATA:
      uploadingIcon = <WarningAmberOutlinedIcon className='warning' />;
      break;
    case UploadedFileStatus.UPLOADING:
    case UploadedFileStatus.SCANNING:
      uploadingIcon = <CachedIcon className='uploading' />;
      break;

    case UploadedFileStatus.UPLOAD_COMPLETE:
      uploadingIcon = <DoneOutlinedIcon className='done' />;
      break;
    case UploadedFileStatus.INFECTED:
      uploadingIcon = <CoronavirusOutlineOutlinedIcon className='error' />;
      break;
    case UploadedFileStatus.UPLOAD_FAILED:
    case UploadedFileStatus.ERROR:
      uploadingIcon = <ErrorOutlineOutlinedIcon className='error' />;
      break;
    case UploadedFileStatus.DELETING:
      uploadingIcon = <DeleteIconBlinking />;
      break;
  }

  return (
    <Tooltip title={errorDescription || params.value}>
      <div className='uploading-status-cell'>{uploadingIcon}</div>
    </Tooltip>
  );
};

const renderEditAutocomplete =
  (dictionaries: DictionariesResponse, field: string) => (params: GridRenderCellParams) => {
    return <EditAutocomplete {...params} dictionaries={dictionaries} field={field} />;
  };

const getConfigForSingleSelect = (dictionaries: DictionariesResponse, field: string) => ({
  flex: 1,
  valueOptions: getMetadataValuesByColumnName(dictionaries, field),
  valueFormatter: valueFormatter(dictionaries, field),
  sortComparator: sortComparator,
});

const getConfigForValidation = (validate: boolean, dictionary: MetadataDictionary) => ({
  preProcessEditCellProps: ({ props: { value } }: GridPreProcessEditCellProps) => {
    const error = validate && dictionary.required && !value;
    return { value, error };
  },
});

const getConfigForMinMaxLengthValidation = (
  validate: boolean,
  min: number,
  max: number,
  dictionary: MetadataDictionary
) => ({
  preProcessEditCellProps: ({ props: { value } }: GridPreProcessEditCellProps) => {
    const error =
      (validate && dictionary.required && !value) || value?.length < min || value?.length > max;
    return { value, error };
  },
});

const withTooltip = (params: GridRenderCellParams) => {
  const finalValue = params.formattedValue || params.value;
  return finalValue ? (
    <Tooltip title={finalValue} enterNextDelay={TOOLTIP_DELAY} placement='top-start'>
      <span>{finalValue}</span>
    </Tooltip>
  ) : null;
};

export const headerWithTooltip = ({
  colDef: { headerName, description },
}: GridColumnHeaderParams) => {
  return headerName ? (
    <Tooltip
      title={<div className='form-notice-tooltip'>{description}</div>}
      placement='top'
      enterNextDelay={TOOLTIP_DELAY}
    >
      <div className='MuiDataGrid-columnHeaderTitle spreadsheet-column-header'>{headerName}</div>
    </Tooltip>
  ) : null;
};

const CellSwitch: React.FC<GridRenderCellParams> = ({ id, field, value }) => {
  const apiRef = useGridApiContext();

  const handleOnClick = async ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    apiRef.current.startCellEditMode({ id, field, deleteValue: true });
    await apiRef.current.setEditCellValue({ id, field, value: checked, debounceMs: 100 });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return <Switch checked={!!value} onChange={handleOnClick} />;
};

const lobSetter = ({ row, value }: GridValueSetterParams) => {
  return row.Lob === value ? row : { ...row, Lob: value, Lop: '' };
};

const GRID_DATE_FIELD_CONFIG: Partial<GridColDef> = {
  valueFormatter: ({ value }) => formatUiDate(value),
  valueGetter: ({ value }: GridValueGetterParams) => getOrParseDate(value) || null,
  renderEditCell: (params) => <GridEditDateCell {...DATE_FIELD_CONFIG} {...params} />,
};

export const SPREADSHEET_COLUMNS_DEFINITION = (
  validate: boolean,
  statuses: DocumentUpdateStatuses,
  dictionaries: DictionariesResponse,
  disabledColumns: DOCUMENTS_GRID_COLUMNS[],
  rowsSelected: boolean
) => {
  const tmpColumnsDef: GridColDef[] = [
    {
      headerName: DOCUMENT_LABELS.FILE_NAME,
      field: DOCUMENTS_GRID_COLUMNS.FILE_NAME,
      description: DOCUMENT_METADATA_TOOLTIPS.FILE_NAME,
      type: 'string',
      editable: !disabledColumns.includes(DOCUMENTS_GRID_COLUMNS.FILE_NAME),
      width: 200,
      disableColumnMenu: true,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      renderEditCell: (params: GridEditInputCellProps) => (
        <GridEditInputCell {...params} inputComponent={FileNameInput} />
      ),
      preProcessEditCellProps: ({ props: { value } }: GridPreProcessEditCellProps) => {
        return { value, error: !value };
      },
    },
    {
      field: DOCUMENTS_GRID_COLUMNS.STATUS,
      editable: false,
      headerName: '',
      minWidth: 35,
      width: 35,
      resizable: false,
      sortable: false,
      disableColumnMenu: true,
      renderCell: renderDocumentOrUpdateStatus(statuses),
    },
    {
      headerName: DOCUMENT_LABELS.COMPANY_NAME,
      field: DOCUMENTS_GRID_COLUMNS.COMPANY_NAME,
      description: DOCUMENT_METADATA_TOOLTIPS.COMPANY_NAME,
      type: 'singleSelect',
      editable: !disabledColumns.includes(DOCUMENTS_GRID_COLUMNS.COMPANY_NAME),
      disableColumnMenu: true,
      minWidth: 100,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      ...getConfigForSingleSelect(dictionaries, DOCUMENTS_GRID_COLUMNS.COMPANY_NAME),
    },
    {
      headerName: DOCUMENT_LABELS.COUNTRY,
      field: DOCUMENTS_GRID_COLUMNS.COUNTRY,
      description: DOCUMENT_METADATA_TOOLTIPS.COUNTRY,
      type: 'singleSelect',
      disableColumnMenu: true,
      editable: false,
      minWidth: 100,
      maxWidth: 100,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      renderEditCell: renderEditAutocomplete(dictionaries, DOCUMENTS_GRID_COLUMNS.COUNTRY),
      ...getConfigForSingleSelect(dictionaries, DOCUMENTS_GRID_COLUMNS.COUNTRY),
    },
    {
      headerName: DOCUMENT_LABELS.BUSINESS_LINE,
      field: DOCUMENTS_GRID_COLUMNS.BUSINESS_LINE,
      description: DOCUMENT_METADATA_TOOLTIPS.BUSINESS_TYPE,
      type: 'singleSelect',
      editable: true,
      disableColumnMenu: !rowsSelected,
      minWidth: 120,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      renderEditCell: renderEditAutocomplete(dictionaries, DOCUMENTS_GRID_COLUMNS.BUSINESS_LINE),
      ...getConfigForSingleSelect(dictionaries, DOCUMENTS_GRID_COLUMNS.BUSINESS_LINE),
      valueSetter: lobSetter,
    },
    {
      headerName: DOCUMENT_LABELS.PRODUCT_LINE,
      field: DOCUMENTS_GRID_COLUMNS.PRODUCT_LINE,
      description: DOCUMENT_METADATA_TOOLTIPS.PRODUCT_LINE,
      type: 'singleSelect',
      editable: true,
      disableColumnMenu: !rowsSelected,
      minWidth: 100,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      renderEditCell: renderEditAutocomplete(dictionaries, DOCUMENTS_GRID_COLUMNS.PRODUCT_LINE),
      ...getConfigForSingleSelect(dictionaries, DOCUMENTS_GRID_COLUMNS.PRODUCT_LINE),
      cellClassName: (params: GridCellParams<string>) =>
        validate && !params.value ? 'document-grid-cell-error' : '',
    },
    {
      headerName: DOCUMENT_LABELS.PRODUCT_TYPE,
      field: DOCUMENTS_GRID_COLUMNS.PRODUCT_TYPE,
      description: DOCUMENT_METADATA_TOOLTIPS.PRODUCT_TYPE,
      type: 'string',
      editable: true,
      minWidth: 100,
      disableColumnMenu: true,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      renderEditCell: (params) => (
        <GridEditInputCell
          inputProps={{
            minLength: MIN_PRODUCT_TYPE_LENGTH,
            maxLength: MAX_PRODUCT_TYPE_LENGTH,
          }}
          {...params}
        />
      ),
      ...getConfigForMinMaxLengthValidation(
        validate,
        MIN_PRODUCT_TYPE_LENGTH,
        MAX_PRODUCT_TYPE_LENGTH,
        dictionaries.ProductType
      ),
    },
    {
      headerName: DOCUMENT_LABELS.INSURED_INDUSTRY,
      field: DOCUMENTS_GRID_COLUMNS.INSURER_INDUSTRY,
      description: DOCUMENT_METADATA_TOOLTIPS.INSURED_INDUSTRY,
      type: 'string',
      editable: true,
      minWidth: 120,
      disableColumnMenu: true,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      renderEditCell: (params) => (
        <GridEditInputCell
          inputProps={{
            minLength: MIN_INSURED_INDUSTRY_LENGTH,
            maxLength: MAX_INSURED_INDUSTRY_LENGTH,
          }}
          {...params}
        />
      ),
      ...getConfigForMinMaxLengthValidation(
        validate,
        MIN_INSURED_INDUSTRY_LENGTH,
        MAX_INSURED_INDUSTRY_LENGTH,
        dictionaries.InsurerIndustry
      ),
    },
    {
      headerName: DOCUMENT_LABELS.BUSINESS_TYPE,
      field: DOCUMENTS_GRID_COLUMNS.BUSINESS_TYPE,
      description: DOCUMENT_METADATA_TOOLTIPS.BUSINESS_TYPE,
      type: 'singleSelect',
      editable: true,
      disableColumnMenu: !rowsSelected,
      minWidth: 100,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      renderEditCell: renderEditAutocomplete(dictionaries, DOCUMENTS_GRID_COLUMNS.BUSINESS_TYPE),
      ...getConfigForSingleSelect(dictionaries, DOCUMENTS_GRID_COLUMNS.BUSINESS_TYPE),
    },
    {
      headerName: DOCUMENT_LABELS.DOCUMENT_TYPE,
      field: DOCUMENTS_GRID_COLUMNS.DOCUMENT_TYPE,
      description: DOCUMENT_METADATA_TOOLTIPS.DOCUMENT_TYPE,
      type: 'singleSelect',
      editable: true,
      disableColumnMenu: !rowsSelected,
      minWidth: 100,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      renderEditCell: renderEditAutocomplete(dictionaries, DOCUMENTS_GRID_COLUMNS.DOCUMENT_TYPE),
      ...getConfigForSingleSelect(dictionaries, DOCUMENTS_GRID_COLUMNS.DOCUMENT_TYPE),
    },
    {
      headerName: DOCUMENT_LABELS.CLASSIFICATION,
      field: DOCUMENTS_GRID_COLUMNS.CLASSIFICATION,
      description: DOCUMENT_METADATA_TOOLTIPS.CLASSIFICATION,
      type: 'singleSelect',
      editable: true,
      disableColumnMenu: !rowsSelected,
      minWidth: 100,
      maxWidth: 100,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      renderEditCell: renderEditAutocomplete(dictionaries, DOCUMENTS_GRID_COLUMNS.CLASSIFICATION),
      ...getConfigForSingleSelect(dictionaries, DOCUMENTS_GRID_COLUMNS.CLASSIFICATION),
    },
    {
      headerName: DOCUMENT_LABELS.LANGUAGE,
      field: DOCUMENTS_GRID_COLUMNS.LANGUAGE,
      description: DOCUMENT_METADATA_TOOLTIPS.LANGUAGE,
      type: 'singleSelect',
      editable: !disabledColumns.includes(DOCUMENTS_GRID_COLUMNS.LANGUAGE),
      disableColumnMenu: disabledColumns.includes(DOCUMENTS_GRID_COLUMNS.LANGUAGE) || !rowsSelected,
      minWidth: 80,
      maxWidth: 100,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      renderEditCell: renderEditAutocomplete(dictionaries, DOCUMENTS_GRID_COLUMNS.LANGUAGE),
      ...getConfigForSingleSelect(dictionaries, DOCUMENTS_GRID_COLUMNS.LANGUAGE),
    },
    {
      headerName: DOCUMENT_LABELS.VALID_FROM,
      field: DOCUMENTS_GRID_COLUMNS.VALID_FROM,
      description: DOCUMENT_METADATA_TOOLTIPS.VALID_FROM,
      type: 'date',
      editable: true,
      width: 130,
      align: 'center',
      disableColumnMenu: true,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      valueSetter: ({ row, value }: GridValueSetterParams) => ({
        ...row,
        [DOCUMENTS_GRID_COLUMNS.VALID_FROM]: formatApiDate(value),
      }),
      preProcessEditCellProps: ({ props: { value }, row }: GridPreProcessEditCellProps) => {
        const error = validateValidFromField(
          validate,
          dictionaries.ValidFrom.required,
          row[DOCUMENTS_GRID_COLUMNS.VALID_TO],
          value
        );
        return { value, error };
      },
      ...GRID_DATE_FIELD_CONFIG,
    },
    {
      headerName: DOCUMENT_LABELS.VALID_TO,
      field: DOCUMENTS_GRID_COLUMNS.VALID_TO,
      description: DOCUMENT_METADATA_TOOLTIPS.VALID_TO,
      type: 'date',
      editable: true,
      width: 130,
      align: 'center',
      disableColumnMenu: true,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      valueSetter: ({ row, value }: GridValueSetterParams) => ({
        ...row,
        [DOCUMENTS_GRID_COLUMNS.VALID_TO]: formatApiDate(value),
      }),
      preProcessEditCellProps: ({ props: { value }, row }: GridPreProcessEditCellProps) => {
        const error = validateValidToField(
          validate,
          dictionaries.ValidTo.required,
          row[DOCUMENTS_GRID_COLUMNS.VALID_FROM],
          value
        );
        return { value, error };
      },
      ...GRID_DATE_FIELD_CONFIG,
    },
    {
      headerName: DOCUMENT_LABELS.EXPIRE_AT,
      field: DOCUMENTS_GRID_COLUMNS.EXPIRE_AT,
      description: DOCUMENT_METADATA_TOOLTIPS.EXPIRE_AT,
      type: 'date',
      editable: true,
      width: 130,
      align: 'center',
      disableColumnMenu: true,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      valueSetter: ({ row, value }: GridValueSetterParams) => ({
        ...row,
        [DOCUMENTS_GRID_COLUMNS.EXPIRE_AT]: formatApiDate(value),
      }),
      preProcessEditCellProps: ({ props: { value } }: GridPreProcessEditCellProps) => {
        const error = validateExpireAtField(validate, dictionaries.ExpireAt.required, value);
        return { value, error };
      },
      ...GRID_DATE_FIELD_CONFIG,
    },
    {
      headerName: DOCUMENT_LABELS.VERSION,
      field: DOCUMENTS_GRID_COLUMNS.VERSION,
      description: DOCUMENT_METADATA_TOOLTIPS.VERSION,
      type: 'string',
      editable: true,
      minWidth: 70,
      maxWidth: 70,
      disableColumnMenu: true,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      ...getConfigForValidation(validate, dictionaries.Version),
    },
    {
      headerName: DOCUMENT_LABELS.LATEST_VERSION,
      field: DOCUMENTS_GRID_COLUMNS.LATEST_VERSION,
      description: DOCUMENT_METADATA_TOOLTIPS.LATEST_VERSION,
      editable: true,
      minWidth: 100,
      maxWidth: 100,
      disableColumnMenu: true,
      valueSetter: ({ row, value }: GridValueSetterParams) => ({
        ...row,
        [DOCUMENTS_GRID_COLUMNS.LATEST_VERSION]: formatBoolean(value),
      }),
      renderHeader: headerWithTooltip,
      renderCell: (params) => <CellSwitch {...params} />,
      renderEditCell: (params) => <CellSwitch {...params} />,
      ...BOOLEAN_COLUMN_TYPE_CONFIG,
    },
    {
      headerName: DOCUMENT_LABELS.ACTIVE,
      field: DOCUMENTS_GRID_COLUMNS.ACTIVE,
      description: DOCUMENT_METADATA_TOOLTIPS.ACTIVE,
      editable: true,
      width: 70,
      disableColumnMenu: true,
      valueSetter: ({ row, value }: GridValueSetterParams) => ({
        ...row,
        [DOCUMENTS_GRID_COLUMNS.ACTIVE]: formatBoolean(value),
      }),
      renderHeader: headerWithTooltip,
      renderCell: (params) => <CellSwitch {...params} />,
      renderEditCell: (params) => <CellSwitch {...params} />,
      ...BOOLEAN_COLUMN_TYPE_CONFIG,
    },
    {
      headerName: DOCUMENT_LABELS.DOCUMENT_NAME,
      field: DOCUMENTS_GRID_COLUMNS.DOCUMENT_NAME,
      description: DOCUMENT_METADATA_TOOLTIPS.DOCUMENT_NAME,
      type: 'string',
      editable: false,
      minWidth: 120,
      disableColumnMenu: true,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
      ...DOCUMENT_NAME_COLUMN_TYPE_CONFIG,
    },
    {
      headerName: DOCUMENT_LABELS.DOCUMENT_ID,
      field: DOCUMENTS_GRID_COLUMNS.DOCUMENT_ID,
      description: DOCUMENT_METADATA_TOOLTIPS.DOCUMENT_ID,
      type: 'string',
      editable: false,
      width: 200,
      disableColumnMenu: true,
      renderHeader: headerWithTooltip,
      renderCell: withTooltip,
    },
  ];

  return tmpColumnsDef;
};
