import React, { useEffect, useState } from 'react';
import { DocumentGrid } from './DocumentsGrid';
import { DocumentGridProps, DocumentGridWrapperProps } from './DocumentsGrid.types';
import { StatusProgress } from '../StatusProgress';
import { mapToSpreadSheetDocuments } from '../SpreadSheet.helpers';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';
import { useUpdateMetadataMutation } from '../../../store/files/upload/list.service';
import { UPDATE_METADATA_CACHE_KEY } from '../../../store/files/upload/list.service.types';
import { useEditingDisabled } from '../../UploadFiles/EditingDisabled.hook';
import { showModal } from '../../../store/ui/ui.slice';
import { ERROR_TITLES } from '../../../store/ui/ui.error.types';
import { handleApiError } from '../../../store/error.helpers';
import { useStatusProgress } from '../StatusProgress.hook';
import { ALL_CHANGES_SAVED } from '../StatusProgress.types';
import { mapToUploadedFileRequestDto } from './PreLandingDocumentGrid.helpers';
import { PRE_LANDING_PAGE_SIZE, UPLOAD_FILES_BLOCKED_COLUMNS } from '../../../config/config';
import { removeSelectedDocs, updateSelection } from '../../../store/files/upload/upload.slice';
import { useDispatchUnsavedSpreadsheetChanges } from './DispatchUnsavedSpreadsheetChanges.hook';
import { useGetUploadedFiles } from '../../UploadFiles/useGetUploadedFiles';

export const PreLandingDocumentGrid: React.FC<DocumentGridWrapperProps> = ({
  search,
  isSnackbarEnabled,
}) => {
  const selected = useAppSelector(uploadSelectors.selectedDocs);
  const localDocs = useAppSelector(uploadSelectors.selectDocsList);
  const numberOfFilesUploading = useAppSelector(uploadSelectors.selectFilesUploading)?.length;
  const [updateMetadata, { isLoading }] = useUpdateMetadataMutation({
    fixedCacheKey: UPDATE_METADATA_CACHE_KEY,
  });
  const dispatch = useAppDispatch();
  const { docs } = useGetUploadedFiles();

  const [documents, setDocuments] = useState<DocumentGridProps['docs']>([]);
  const isEditingDisabled = useEditingDisabled();

  const [isParsing, setIsParsing] = useState<boolean>(true);
  const [dataChanged, setDataChanged] = useState<DocumentGridProps['docs']>([]);
  const { saved, errorMessage, setSaved, setErrorMessage } = useStatusProgress(dataChanged.length);

  useDispatchUnsavedSpreadsheetChanges(dataChanged);

  useEffect(() => {
    setDocuments(mapToSpreadSheetDocuments(docs));
    setIsParsing(false);
  }, [docs]);

  const handleSave = () => {
    updateMetadata(mapToUploadedFileRequestDto(dataChanged))
      .unwrap()
      .then(() => {
        setDataChanged([]);
        setSaved(ALL_CHANGES_SAVED);
        dispatch(removeSelectedDocs());
        setErrorMessage('');
      })
      .catch((response) => {
        dispatch(
          showModal({
            title: ERROR_TITLES.ERROR_WHILE_SAVING_DATA,
            reason: handleApiError(response),
          })
        );
        setErrorMessage(ERROR_TITLES.ERROR_WHILE_SAVING_DATA);
      });
  };

  return (
    <>
      <DocumentGrid
        className='grid-footer'
        disabledColumns={UPLOAD_FILES_BLOCKED_COLUMNS.SPREADSHEET}
        search={search}
        setDataChanged={setDataChanged}
        docs={documents}
        isLoading={isParsing}
        isEditingDisabled={isEditingDisabled}
        pageSize={PRE_LANDING_PAGE_SIZE}
        selectionModel={selected}
        onSelectionModelChange={(selectionModel) => {
          dispatch(updateSelection({ selected: selectionModel as string[] }));
        }}
      />
      <StatusProgress
        filesExist={!!documents?.length}
        numberOfFilesUploading={numberOfFilesUploading}
        numberOfUploadingDocs={localDocs?.length}
        isLoading={isLoading}
        dataChangedCount={dataChanged.length}
        errorMessage={errorMessage}
        saved={saved}
        onSave={handleSave}
        isSnackbarEnabled={isSnackbarEnabled}
      />
    </>
  );
};
