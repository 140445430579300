import React from 'react';
import Modal from '@mui/material/Modal';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import '../../UploadFiles/BulkMetadataModal/BulkMetadataModal.scss';
import { ButtonWithIcon } from '../../StaticComponents/ButtonWithIcon';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { DocumentMetadata } from '../../UploadFiles/MetadataForm/MetadataForm.types';
import { MetadataForm } from '../../UploadFiles/MetadataForm/MetadataForm';
import { useLoadingWithTimeout } from '../../../store/files/documents/documents.hooks';
import { useUpdateDocumentsMetadataMutation } from '../../../store/files/documents/documents.list.service';
import { cleanupEmpty } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { useAppDispatch } from '../../../store/hooks';
import { useConfirmationModal } from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.hook';
import { BulkEditConfirmModal } from '../../StaticComponents/Modals/ConfirmationModal/BulkEditConfirmModal';
import { showModal, updateStatuses } from '../../../store/ui/ui.slice';
import { MY_FILES_BLOCKED_COLUMNS } from '../../../config/config';
import { mapErrorResponseToUpdateStatuses } from '../../../store/files/documents/documents.update.error.helpers';
import { UploadedDocumentsBulkMetadataModalProps } from './UploadedDocumentsBulkMetadataModal.types';
import {
  isAchResponseWithErrors,
  mapAchErrorResponseToErrorMessage,
  mapSuccessResponseToUpdateStatuses,
} from '../../../store/files/documents/documents.update.success.helpers';
import { handleApiError } from '../../../store/error.helpers';
import { useDispatchSelectDocs } from '../../SpreadSheet/DocumentsGrid/AchDocumentsGrid.hooks';

const BULK_EDIT_ERROR_MODAL_TITLE = 'ERROR - Bulk metadata edit';
const BULK_EDIT_SUCCESS_MODAL_TITLE = 'Bulk metadata edit';
const BULK_EDIT_SUCCESS_MODAL_MESSAGE = 'Metadata changed successfully';

export const UploadedDocumentsBulkMetadataModal: React.FC<
  UploadedDocumentsBulkMetadataModalProps
> = ({ useGetDataListWithParams, selectedDocsIds, disabled, bulkEditOpen, setBulkEditOpen }) => {
  const { data: documents } = useGetDataListWithParams();
  const [update] = useUpdateDocumentsMetadataMutation();
  const { open: openModal, handleOpen, handleClose: handleCloseModal } = useConfirmationModal();
  const dispatch = useAppDispatch();

  const { isLoading, startLoading, stopLoading, loadWithTimeout } = useLoadingWithTimeout();

  const handleClose = () => {
    setBulkEditOpen(false);
  };

  const dispatchSelectDocs = useDispatchSelectDocs();

  const handleSubmit = (data: DocumentMetadata) => {
    const cleanedData = cleanupEmpty(data);

    const onlySelectedDocuments = documents?.Documents?.filter(({ DocumentId }) =>
      selectedDocsIds.includes(DocumentId)
    );

    if (!onlySelectedDocuments) {
      return;
    }

    const changedDocuments = onlySelectedDocuments?.map(({ DocumentId }) => {
      return {
        DocumentId,
        ...cleanedData,
      };
    });
    startLoading();
    update(changedDocuments)
      .unwrap()
      .then((response) => {
        dispatch(updateStatuses(mapSuccessResponseToUpdateStatuses(response, changedDocuments)));
        if (isAchResponseWithErrors(response)) {
          stopLoading();
          setBulkEditOpen(false);
          dispatch(
            showModal({
              title: BULK_EDIT_ERROR_MODAL_TITLE,
              reason: mapAchErrorResponseToErrorMessage(response),
            })
          );
        } else {
          loadWithTimeout(() => {
            setBulkEditOpen(false);
            dispatch(
              showModal({
                title: BULK_EDIT_SUCCESS_MODAL_TITLE,
                reason: BULK_EDIT_SUCCESS_MODAL_MESSAGE,
              })
            );
            dispatchSelectDocs([], false);
          });
        }
      })
      .catch((response) => {
        stopLoading();
        setBulkEditOpen(false);
        dispatch(updateStatuses(mapErrorResponseToUpdateStatuses(response, changedDocuments)));
        dispatch(
          showModal({
            title: BULK_EDIT_ERROR_MODAL_TITLE,
            reason: handleApiError(response),
          })
        );
      });
  };

  return (
    <>
      <BulkEditConfirmModal
        open={openModal}
        onClose={handleCloseModal()}
        onConfirm={() => setBulkEditOpen(true)}
      />

      <ButtonWithIcon
        startIcon={<NoteAltOutlinedIcon fontSize='large' />}
        onClick={handleOpen}
        disabled={disabled || !selectedDocsIds.length}
      >
        Bulk metadata edit
      </ButtonWithIcon>

      <Modal open={bulkEditOpen}>
        <div className='bulk-metadata-modal'>
          <ModalClose onClose={handleClose} />

          <div className='bulk-metadata-modal-header'>
            <div className='bulk-metadata-modal-title'>Bulk metadata edit</div>
            <div>Enter the default values to fill the entire columns.</div>
          </div>

          <MetadataForm
            onSubmit={handleSubmit}
            isLoading={isLoading}
            disabledColumns={MY_FILES_BLOCKED_COLUMNS.FORM}
            bulkMetadataEdit
          />
        </div>
      </Modal>
    </>
  );
};
