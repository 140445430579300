import { FileTypeIcon } from '../../../StaticComponents/FileTypeIcon';
import { mapUploadedFileType } from '../../../../store/files/upload/upload.helpers';
import { getDictionaryLabelByValue } from '../../../../store/files/upload/list.helpers';
import { DictionariesResponse } from '../../../../store/files/upload/list.service.types';
import { ItemNameText } from '../../../DocumentsAndClauses/ItemNameText/ItemNameText';
import { GridColDef } from '@mui/x-data-grid-pro';
import DoneIcon from '@mui/icons-material/Done';
import { BOOLEAN_VALUES } from '../../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { formatUiDateFromString } from '../../../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { Compare } from '../Compare';
import { MINIMIZED_DATA } from '../../../../store/ui/ui.types';
import React from 'react';
import { TabItemColumnConfig } from './DocumentTabItems.types';
import { DraftLabel } from '../../../StaticComponents/DraftLabel';

export const getColumnsVersions = (
  dictionaries: DictionariesResponse,
  config: TabItemColumnConfig = {}
): GridColDef[] => {
  const columns: GridColDef[] = [
    {
      field: 'Details',
      flex: 8,
      sortable: false,
      renderCell: ({
        row: { FileName, Entity, Version, ValidTo, ValidFrom, Lob, Lop, IsDraft },
      }) => {
        return (
          <div className='tab-items-details-column'>
            <FileTypeIcon fileType={mapUploadedFileType(FileName)} />
            <div className='tab-items-details-column__text'>
              <div className='tab-items-details-column__header'>
                <ItemNameText fileName={FileName} strong />
                <DraftLabel draft={IsDraft} />
              </div>
              <ItemNameText
                fileName={getDictionaryLabelByValue(dictionaries?.Entity, Entity) ?? ''}
              />
              {config.showLOB && (
                <ItemNameText fileName={getDictionaryLabelByValue(dictionaries?.Lob, Lob) ?? ''} />
              )}
              {config.showLOP && (
                <ItemNameText fileName={getDictionaryLabelByValue(dictionaries?.Lop, Lop) ?? ''} />
              )}
              {config.showVersion && <span>{`version ${Version}`}</span>}
              <span>{`valid from ${ValidFrom === '' ? '-' : formatUiDateFromString(ValidFrom)} to ${
                ValidTo === '' ? '-' : formatUiDateFromString(ValidTo)
              }`}</span>
            </div>
          </div>
        );
      },
    },
  ];

  if (config.enableCompareButton || config.showBoolFlags) {
    columns.push({
      field: 'VersionCheck',
      flex: 2.8,
      sortable: false,
      renderCell: ({ row }) => {
        return (
          <div className='tab-items-check-column'>
            <div className='tab-items-check-column-compare'>
              {config.enableCompareButton && (
                <Compare
                  documentId={row.DocumentId}
                  name={row.FileName}
                  dataType={MINIMIZED_DATA.MINIMIZED_DOCS}
                />
              )}
            </div>
            <div>
              {config.showBoolFlags && row.LatestVersion === BOOLEAN_VALUES.TRUE && (
                <div className='tab-items-check-column-item'>
                  <DoneIcon /> <span>latest version</span>
                </div>
              )}
              {config.showBoolFlags && row.Active === BOOLEAN_VALUES.TRUE && (
                <div className='tab-items-check-column-item'>
                  <DoneIcon /> <span>active</span>
                </div>
              )}
            </div>
          </div>
        );
      },
    });
  }

  return columns;
};
