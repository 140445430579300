import React from 'react';
import './UploadButton.scss';
import { Button } from '@mui/material';
import { UploadButtonProps } from './UploadButton.types';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';

export const UploadButton: React.FC<UploadButtonProps> = ({
  adjustStyleForDropdown,
  draftModeSelected,
  onClick,
}) => {
  return (
    <Button
      className={
        'upload-files ' +
        (adjustStyleForDropdown ? 'draft-mode ' : '') +
        (draftModeSelected ? 'draft-mode-selected' : '')
      }
      variant='contained'
      startIcon={<FileUploadOutlinedIcon fontSize='large' />}
      onClick={onClick}
    >
      {draftModeSelected ? 'Upload drafts' : 'Upload files'}
    </Button>
  );
};
