import React from 'react';
import { getFlagByCountryName } from '../../../store/files/upload/list.helpers';
import { DOCUMENT_LABELS } from '../../../config/config';
import { Chip } from '@mui/material';
import { ClauseItemPreviewProps } from './ClauseItemPreview.types';
import { useSelector } from 'react-redux';
import { dictionariesSelectors } from '../../../store/files/upload/list.selectors';

import './ClauseItemPreview.scss';

export const ClauseItemPreview: React.FC<ClauseItemPreviewProps> = ({ metadata }) => {
  const allCompanies = useSelector(dictionariesSelectors.selectAllCompanies);
  const allGermanCompanies = useSelector(dictionariesSelectors.selectAllGermanCompanies);
  const allLobs = useSelector(dictionariesSelectors.selectAllLobs);
  const allCountries = useSelector(dictionariesSelectors.selectAllCountries);

  const isSubsetSelected = (
    selectedValues: string[] | undefined,
    subsetValues: string[] | undefined
  ) => {
    if (!selectedValues?.length || !subsetValues?.length) {
      return false;
    }
    return subsetValues.every((val) => selectedValues.includes(val));
  };

  return (
    <div className='clause-item-preview'>
      {metadata.map(({ dictValues, label, values }, index) => {
        if (dictValues === undefined || dictValues.length === 0 || label === DOCUMENT_LABELS.TAG)
          return null;

        let chipContent;

        switch (label) {
          case DOCUMENT_LABELS.COUNTRY:
            if (isSubsetSelected(values, allCountries)) {
              chipContent = <Chip key='all-lobs' label='All Countries 🌍' />;
            } else {
              chipContent = dictValues.map((item, index) => (
                <Chip
                  key={`country-${index}`}
                  label={`${item} ${getFlagByCountryName(values ? values[index] : '')}`}
                />
              ));
            }
            break;

          case DOCUMENT_LABELS.BUSINESS_LINE:
            if (isSubsetSelected(values, allLobs)) {
              chipContent = <Chip key='all-lobs' label='All Lines of Business' />;
            } else {
              chipContent = dictValues.map((item, i) => <Chip key={`lob-${i}`} label={item} />);
            }
            break;

          case DOCUMENT_LABELS.COMPANY_NAME:
            let companyChips = [];
            const isAllCompanies = isSubsetSelected(values, allCompanies);
            const isAllGermanCompanies = isSubsetSelected(values, allGermanCompanies);

            if (isAllCompanies) {
              companyChips.push(<Chip key='all-companies' label='All Companies' />);
            }

            if (!isAllCompanies && isAllGermanCompanies) {
              companyChips.push(<Chip key='all-german-companies' label='All German Companies' />);
            }

            if (!isAllCompanies) {
              values?.forEach((companyValue, index) => {
                if (!(isAllGermanCompanies && allGermanCompanies.includes(companyValue))) {
                  companyChips.push(<Chip key={`company-${index}`} label={dictValues[index]} />);
                }
              });
            }
            chipContent = companyChips;
            break;

          default:
            chipContent = dictValues.map((item, index) => (
              <Chip key={`other-${index}`} label={item} />
            ));
            break;
        }
        return (
          <span key={index} className={index % 2 !== 0 ? 'pair' : ''}>
            {chipContent}
          </span>
        );
      })}
    </div>
  );
};
