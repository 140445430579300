import React from 'react';
import './Settings.scss';
import { Paper } from '@mui/material';
import Box from '@mui/material/Box';
import SavedSearches from './SavedSearches/SavedSearches';
import PersonIcon from '@mui/icons-material/PersonOutline';
import NotificationIcon from '@mui/icons-material/NotificationsNone';
import SearchIcon from '@mui/icons-material/Search';
import { Navigate, NavLink, Route, Routes } from 'react-router-dom';
import { RoutePath } from '../../services/route.service';
import { useReadAccess } from '../../contexts/UserAccess';
import { BulkDeleteModal } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal';

import { useBulkDeleteSavedSearches } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal.hooks';

export const Settings = () => {
  useReadAccess();

  return (
    <div className='settings'>
      <Paper elevation={0} className='settings-header'>
        <span className='settings-title'>Settings</span>
        <Routes>
          <Route
            path={RoutePath.SETTINGS_SAVED_SEARCHES}
            element={<BulkDeleteModal handler={useBulkDeleteSavedSearches} />}
          />
        </Routes>
      </Paper>

      <Box className='settings-container'>
        <Paper className='settings-container-menu'>
          <Box
            className='settings-container-menu-item disabled-link'
            component={NavLink}
            to={RoutePath.SETTINGS_ACCOUNT}
          >
            <PersonIcon />
            Account
          </Box>
          <Box
            className='settings-container-menu-item disabled-link'
            component={NavLink}
            to={RoutePath.SETTINGS_NOTIFICATIONS}
          >
            <NotificationIcon />
            Notification
          </Box>
          <Box
            className='settings-container-menu-item'
            component={NavLink}
            to={RoutePath.SETTINGS_SAVED_SEARCHES}
          >
            <SearchIcon />
            Saved searches
          </Box>
        </Paper>
        <Paper className='settings-container-content'>
          <Routes>
            <Route index element={<Navigate to={RoutePath.SETTINGS_SAVED_SEARCHES} replace />} />
            {/*<Route path={RoutePath.SETTINGS_ACCOUNT} element={<Account />} />*/}
            {/*<Route path={RoutePath.SETTINGS_NOTIFICATIONS} element={<Notifications />} />*/}
            <Route path={RoutePath.SETTINGS_SAVED_SEARCHES} element={<SavedSearches />} />
            <Route path='*' element={<Navigate to={RoutePath.NOT_FOUND} replace />} />
          </Routes>
        </Paper>
      </Box>
    </div>
  );
};
