import { Backdrop, Button } from '@mui/material';
import './CompareButton.scss';
import React from 'react';
import { TEST_ID } from '../../config/test-fields-ids.config';
import CircularProgress from '@mui/material/CircularProgress';
import { useCompareDocs } from './CompareButton.hooks';
import { CompareButtonProps, CounterProps } from './CompareButton.types';
import { resetCompareSettings } from '../../store/ui/ui.slice';
import { useAppDispatch } from '../../store/hooks';
import { MINIMIZED_DATA } from '../../store/ui/ui.types';

const Counter: React.FC<CounterProps> = ({ actual, limit }) => {
  return (
    <div className={`compare-button__counter ${actual ? 'active' : 'disabled'}`}>
      <div>
        {actual}/{limit}
      </div>
    </div>
  );
};

export const CounterInCompare = ({ numberInCompare }: { numberInCompare: number }) => (
  <div className='compare-button__counter active'>
    <div>{numberInCompare}</div>
  </div>
);

export const CompareButton: React.FC<CompareButtonProps> = ({ dataType, itemsToCompare }) => {
  const { setFetchDocsToCompare, isFetching, disabled, limit } = useCompareDocs(
    dataType,
    itemsToCompare
  );
  const dispatch = useAppDispatch();

  const handleCompare = () => {
    dispatch(resetCompareSettings({ documentMode: dataType === MINIMIZED_DATA.MINIMIZED_DOCS }));
    setFetchDocsToCompare(true);
  };

  return (
    <>
      <Button
        className='compare-button'
        startIcon={<Counter actual={itemsToCompare.length} limit={limit} />}
        data-test-id={TEST_ID.DOC_BAR.COMPARE}
        onClick={handleCompare}
        disabled={disabled}
      >
        <div className='compare-button__text'>Compare</div>
      </Button>

      <Backdrop
        className='document-bar-list__backdrop'
        open={isFetching}
        transitionDuration={{ exit: 0 }}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};
