import { ReactNode, useEffect, useState } from 'react';

export function useStatusProgress(dataChangedCount?: number) {
  const [saved, setSaved] = useState<ReactNode>('');
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (dataChangedCount) {
      setSaved('');
      setErrorMessage('');
    }
  }, [dataChangedCount]);

  return { saved, errorMessage, setSaved, setErrorMessage };
}
