import { GridColDef } from '@mui/x-data-grid-pro';
import React from 'react';
import { UPLOADED_FILE_TYPE } from '../../UploadFiles/UploadedFileDelete/UploadedFileDeleteButton.types';
import { UploadedFileDeleteButton } from '../../UploadFiles/UploadedFileDelete/UploadedFileDeleteButton';
import IconButton from '@mui/material/IconButton';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Tooltip } from '@mui/material';
import { AdminPanelMetadata } from '../../../store/adminPanel/adminPanel.types';
import { Tag } from '../../../store/files/tags/tags.types';

export const ManageTagsListColumns = (
  metadata: AdminPanelMetadata,
  handleEdit: (row: Tag) => void
): GridColDef[] => [
  {
    field: 'Name',
    flex: 2,
    headerName: 'Tag',
  },
  {
    field: 'Synonyms',
    flex: 6,
    headerName: 'Synonyms',
    sortable: false,
    renderCell({ value }) {
      const synonyms = value.join(', ');
      return (
        <Tooltip title={synonyms}>
          <div className='manage-tag-list-synonyms-column'>{synonyms}</div>
        </Tooltip>
      );
    },
  },
  {
    field: 'Language',
    flex: 2,
    headerName: 'Language',
    renderCell: ({ value }) => metadata.TagsLanguages.find(({ value: v }) => value === v)?.label,
  },
  {
    field: 'action',
    flex: 1,
    headerName: '',
    sortable: false,
    resizable: false,
    renderCell({ row }) {
      return (
        <>
          <IconButton onClick={() => handleEdit(row)}>
            <EditOutlinedIcon fontSize='small' />
          </IconButton>

          <UploadedFileDeleteButton FileId={row.Name} type={UPLOADED_FILE_TYPE.TAG} />
        </>
      );
    },
  },
];
