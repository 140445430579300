import RemoveIcon from '@mui/icons-material/Remove';

import './MinimizeItem.scss';
import '../ModalClose.scss';
import { TEST_ID } from '../../../../config/test-fields-ids.config';

import { useMinimizeItem } from './useMinimizeItem';
import { MinimizeItemProps } from './MinimizeItem.types';
import { Clause } from '../../../../store/files/clauses/clauses.list.types';
import { Document } from '../../../../store/files/documents/documents.list.types';

export const MinimizeItem = <T extends Document | Clause>({
  item,
  onMinimize,
  setMinimize,
}: MinimizeItemProps<T>) => {
  const { isMinimizeDisabled, handleMinimize } = useMinimizeItem({ item, onMinimize, setMinimize });

  return (
    <RemoveIcon
      id='minimize-doc'
      className={`modal-close modal-minimize
        ${isMinimizeDisabled ? 'modal-minimize--disable ' : ' '}
      `}
      onClick={handleMinimize()}
      data-test-id={TEST_ID.DOC_VIEW.MINIMIZE_DOC}
    />
  );
};
