import { Tabs } from '../StaticComponents/Tabs/Tabs';
import { SpreadSheetView } from '../SpreadSheet/SpreadSheetView';
import React, { useState } from 'react';
import './UploadedDocuments.scss';
import { DOCUMENTS_EDIT_SOURCE } from '../SpreadSheet/SpreadSheetView.types';
import { UploadedDocumentsBulkMetadataModal } from '../Documents/BulkMetadataUpdate/UploadedDocumentsBulkMetadataModal';
import { NoItemsFound } from '../StaticComponents/NoItemsFound/NoItemsFound';
import { Paper } from '@mui/material';
import { DocumentsSearch } from '../Documents/DocumentsSearch/DocumentsSearch';
import { DocumentsList } from '../Documents/DocumentsList';
import { PendingItemsListGrid } from './PendingItemsListGrid/PendingItemsListGrid';
import { useAppSelector } from '../../store/hooks';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import { ConfirmationModal } from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal';
import {
  LEAVE_PAGE_MODAL_MESSAGE_UNSAVED_DATA,
  LEAVE_PAGE_MODAL_SUBMIT_BUTTON_LABEL,
  LEAVE_PAGE_MODAL_TITLE,
} from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import { useCustomPageLeavePrompt } from '../StaticComponents/Modals/ConfirmationModal/CustomPageLeavePrompt.hook';
import { BulkDeleteModal } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal';
import { TAB_NAMES } from '../../config/config';
import { RoutePath } from '../../services/route.service';
import { SearchButton } from './SearchButton';
import { useSearchButtonHook } from './SearchButton.helpers';
import { useBulkDeleteACHDocs } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal.hooks';
import { COLUMNS_DEFINITION } from './PendingItemsListGrid/PendingItemsListGrid.helpers';
import { DocumentsCount } from '../Documents/DocumentsCount';
import { UPLOADED_DOCS_CONFIG, UploadedDocumentsConfig } from './UploadedDocuments.helpers';

export const UploadedDocuments = ({
  config: {
    basePath,
    disableBulkDelete,
    disableSpreadsheet,
    exportButtonEnabled,
    hideBulk,
    menuOptions,
    title,
    useAccess,
    useGetDataListWithParams,
    useGetPendingDocumentsListWithParams,
  } = UPLOADED_DOCS_CONFIG,
}: {
  config?: UploadedDocumentsConfig;
}) => {
  useAccess();
  const { data: docs, isLoading, isFetching, filters, setFilters } = useGetDataListWithParams();
  const searchButtonProps = useSearchButtonHook();
  const { showSearch, isPendingTab } = searchButtonProps;
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const toggleFullscreen = () => setFullscreen(!fullscreen);
  const [bulkEditOpen, setBulkEditOpen] = useState<boolean>(false);

  const unsavedSpreadsheetData = useAppSelector(uiSelectors.selectUnsavedChanges);
  const { setConfirmedNavigationState, open, handleClose } =
    useCustomPageLeavePrompt(unsavedSpreadsheetData);

  const selectedDocsIds = useAppSelector(uiSelectors.selectSelectedItem);

  return (
    <div className='my-files'>
      <Paper elevation={0} className={'my-files-header ' + (fullscreen ? 'fullscreen' : '')}>
        <span className='my-files-title'>{title}</span>
        <div>
          <SearchButton fullscreen={fullscreen} {...searchButtonProps} />
          <BulkDeleteModal handler={useBulkDeleteACHDocs} disabled={disableBulkDelete} />
          {hideBulk ? null : (
            <UploadedDocumentsBulkMetadataModal
              useGetDataListWithParams={useGetDataListWithParams}
              selectedDocsIds={selectedDocsIds}
              disabled={isPendingTab}
              bulkEditOpen={bulkEditOpen}
              setBulkEditOpen={setBulkEditOpen}
            />
          )}
        </div>
      </Paper>

      {showSearch && (
        <DocumentsSearch filters={filters} onApply={setFilters} accessMode={AccessMode.WRITE} />
      )}
      <DocumentsCount useGetDataListWithParams={useGetDataListWithParams} disabled={isPendingTab} />
      <Tabs
        base={basePath}
        components={[
          {
            label: TAB_NAMES.LIST_VIEW,
            url: RoutePath.UPLOADED_DOCUMENTS_LIST_VIEW,
            component: (
              <DocumentsList
                menuOptions={menuOptions}
                useGetDataListWithParams={useGetDataListWithParams}
                exportButtonEnabled={exportButtonEnabled}
              />
            ),
          },
          {
            label: TAB_NAMES.SPREADSHEET_VIEW,
            url: RoutePath.UPLOADED_DOCUMENTS_SPREADSHEET_VIEW,
            component:
              docs?.Documents?.length || isLoading || isFetching ? (
                <SpreadSheetView
                  useGetDataListWithParams={useGetDataListWithParams}
                  editMode={DOCUMENTS_EDIT_SOURCE.DOCUMENTS}
                  fullscreen={fullscreen}
                  toggleFullscreen={toggleFullscreen}
                  isSnackbarEnabled={!bulkEditOpen}
                />
              ) : (
                <Paper elevation={0} className='uploaded-file-list'>
                  <NoItemsFound />
                </Paper>
              ),
            disabled: disableSpreadsheet,
          },
          {
            label: TAB_NAMES.PENDING,
            url: RoutePath.UPLOADED_DOCUMENTS_PENDING,
            component: (
              <Paper elevation={0} className='documents-list'>
                <PendingItemsListGrid
                  dataType='Documents'
                  rowIdKey='DocumentId'
                  columnsDefinition={COLUMNS_DEFINITION('document')}
                  dataHook={useGetPendingDocumentsListWithParams!}
                />
              </Paper>
            ),
            disabled: !useGetPendingDocumentsListWithParams,
          },
        ]}
      />
      <ConfirmationModal
        open={open}
        onClose={handleClose()}
        onConfirm={setConfirmedNavigationState}
        title={LEAVE_PAGE_MODAL_TITLE}
        message={LEAVE_PAGE_MODAL_MESSAGE_UNSAVED_DATA}
        successButtonLabel={LEAVE_PAGE_MODAL_SUBMIT_BUTTON_LABEL}
      />
    </div>
  );
};
