import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import { ScrollSync } from 'react-scroll-sync';

interface ScrollSyncContextType {
  isScrollSyncEnabled: boolean;
  setIsScrollSyncEnabled: Dispatch<SetStateAction<boolean>>;
}

const defaultContextValue: ScrollSyncContextType = {
  isScrollSyncEnabled: false,
  setIsScrollSyncEnabled: () => {},
};

const ScrollSyncContext = createContext<ScrollSyncContextType>(defaultContextValue);

export const useScrollSync = () => useContext(ScrollSyncContext);

export const ScrollSyncProvider = ({ children }: { children: ReactNode }) => {
  const [isScrollSyncEnabled, setIsScrollSyncEnabled] = useState(false);

  return (
    <ScrollSyncContext.Provider value={{ isScrollSyncEnabled, setIsScrollSyncEnabled }}>
      <ScrollSync enabled={isScrollSyncEnabled} proportional={false}>
        {children}
      </ScrollSync>
    </ScrollSyncContext.Provider>
  );
};
