import { useAppDispatch, useAppSelector } from '../hooks';
import { uiSelectors } from './ui.selectors';
import {
  MAX_NUMBER_OF_CLAUSES_TO_COMPARE,
  MAX_NUMBER_OF_CLAUSES_TO_MINIMIZE,
  MAX_NUMBER_OF_DOCS_TO_COMPARE,
  MAX_NUMBER_OF_MINIMIZE_DATA,
} from '../../config/config';
import {
  addClauseToCompare,
  addDocToCompare,
  removeClauseFromCompare,
  removeDocFromCompare,
} from './ui.actions';
import { MINIMIZED_DATA } from './ui.types';

export const useCompareDocumentsInView = (
  dataId: string,
  name: string,
  dataType: MINIMIZED_DATA
) => {
  const dispatch = useAppDispatch();

  const docsToCompare = useAppSelector(uiSelectors.selectDocsToCompare);
  const minimizedDocs = useAppSelector(uiSelectors.selectMinimizedDocs);
  const clauseToCompare = useAppSelector(uiSelectors.selectClauseToCompare);
  const minimizedClauses = useAppSelector(uiSelectors.selectMinimizedClauses);

  const isDocInCompare =
    dataType === MINIMIZED_DATA.MINIMIZED_DOCS
      ? docsToCompare.some((docToCompare) => docToCompare.dataId === dataId)
      : clauseToCompare.some((clause) => clause.dataId === dataId);

  const addOrRemoveDoc = () => {
    if (!isDocInCompare) {
      if (dataType === MINIMIZED_DATA.MINIMIZED_DOCS) {
        dispatch(addDocToCompare({ dataId, name }));
      } else {
        dispatch(addClauseToCompare({ dataId, name }));
      }
    } else {
      if (dataType === MINIMIZED_DATA.MINIMIZED_DOCS) {
        dispatch(removeDocFromCompare(dataId));
      } else {
        dispatch(removeClauseFromCompare(dataId));
      }
    }
  };

  const isDisableAddToCompare =
    dataType === MINIMIZED_DATA.MINIMIZED_DOCS
      ? docsToCompare?.length >= MAX_NUMBER_OF_DOCS_TO_COMPARE ||
        (minimizedDocs?.length >= MAX_NUMBER_OF_MINIMIZE_DATA &&
          !minimizedDocs.some((minimizedDoc) => minimizedDoc.dataId === dataId))
      : clauseToCompare?.length >= MAX_NUMBER_OF_CLAUSES_TO_COMPARE ||
        (minimizedClauses?.length >= MAX_NUMBER_OF_CLAUSES_TO_MINIMIZE &&
          !minimizedClauses.some((minimizedClause) => minimizedClause.dataId === dataId));

  return {
    addOrRemoveDoc,
    isDocInCompare,
    isDisableAddToCompare,
  };
};
