import { BrowserRouter, Route, Routes } from 'react-router-dom';
import React from 'react';
import { Login } from '../Login/Login';
import { RoutePath } from '../../services/route.service';
import { UserRouter } from './User.router';

export const AppRouter = () => (
  <BrowserRouter>
    <Routes>
      <Route path={RoutePath.LOGIN} element={<Login />}></Route>
      <Route path={RoutePath.LOGOUT} element={<Login />}></Route>
      <Route path='*' element={<UserRouter />}></Route>
    </Routes>
  </BrowserRouter>
);
