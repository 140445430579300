import React, { useState, useEffect } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const DeleteIconBlinking = () => {
  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowIcon(!showIcon);
    }, 500); // 1 second interval

    return () => clearInterval(interval);
  }, [showIcon]);

  return <>{showIcon && <DeleteOutlineOutlinedIcon className='warning' />}</>;
};
