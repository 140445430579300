import { Box, Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import React from 'react';
import './Tabs.scss';
import { TabsProps } from './Tabs.types';
import { Link, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { getRoutePath, RoutePath } from '../../../services/route.service';

export const Tabs: React.FC<TabsProps> = ({ base, components }) => {
  const { pathname } = useLocation();
  const tabs = components.filter(({ disabled }) => !disabled);

  const firstChildUrl = tabs[0].url;
  const value = pathname === base ? getRoutePath(base, firstChildUrl) : pathname;

  return (
    <div className='documents-tabs'>
      <TabContext value={value}>
        <Box sx={{ width: '100%' }}>
          <TabList>
            {tabs.map(({ label, url }) => (
              <Tab
                label={label}
                value={getRoutePath(base, url)}
                key={label}
                component={Link}
                to={url}
              />
            ))}
          </TabList>
        </Box>

        <div className='tab-panel'>
          <Routes>
            <Route index element={<Navigate to={firstChildUrl} replace />} />

            {tabs?.map(({ url, component }, index) => (
              <Route key={index} path={url} element={component} />
            ))}

            <Route path='*' element={<Navigate to={RoutePath.NOT_FOUND} replace />} />
          </Routes>
        </div>
      </TabContext>
    </div>
  );
};
