import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { ModalCloseProps } from './ModalClose.types';
import './ModalClose.scss';
import { TEST_ID } from '../../../config/test-fields-ids.config';

export const ModalClose: React.FC<ModalCloseProps> = ({ onClose }) => {
  return (
    <CloseIcon
      className='modal-close'
      onClick={onClose}
      data-test-id={TEST_ID.DOC_VIEW.CLOSE_DOC}
    />
  );
};
