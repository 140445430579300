import _ from 'lodash';
import { useEffect, RefObject } from 'react';

export const useCursorHandler = (inputRef: RefObject<HTMLInputElement>) => {
  useEffect(() => {
    const inputElement = inputRef.current;

    if (inputElement) {
      const handleMouseUp = () => {
        const selectionStart = inputElement.selectionStart;
        if (selectionStart === null) {
          return;
        }

        const { value } = inputElement;

        // Early exit for the special cases at the start or end of the text
        if (selectionStart === 0 || selectionStart === value.length) {
          inputElement.setSelectionRange(selectionStart, selectionStart);
          return;
        }

        // Identifying all breakpoints (spaces and newlines)
        const breakIndices = _.flatMap(value, (char, index) =>
          char === ' ' || char === '\n' ? index : []
        );

        // Finding the nearest breakpoints before and after the selectionStart
        const nearestBreakBefore = _.last(_.filter(breakIndices, (x) => x < selectionStart));
        const nearestBreakAfter = _.first(_.filter(breakIndices, (x) => x >= selectionStart));

        // Prevent cursor movement if it is already at a valid position
        if (nearestBreakBefore !== undefined && selectionStart === nearestBreakBefore + 1) {
          return;
        }

        // Calculating distances to the nearest breaks
        const distanceToBefore =
          nearestBreakBefore !== undefined
            ? selectionStart - (nearestBreakBefore + 1)
            : Number.MAX_SAFE_INTEGER;
        const distanceToAfter =
          nearestBreakAfter !== undefined
            ? nearestBreakAfter - selectionStart
            : Number.MAX_SAFE_INTEGER;

        // Conditionally move the cursor to the closest valid breakpoint
        if (nearestBreakBefore !== undefined && distanceToBefore < distanceToAfter) {
          inputElement.setSelectionRange(nearestBreakBefore + 1, nearestBreakBefore + 1);
        } else if (nearestBreakAfter !== undefined) {
          inputElement.setSelectionRange(nearestBreakAfter, nearestBreakAfter);
        }
      };

      inputElement.addEventListener('mouseup', handleMouseUp);
      return () => {
        inputElement.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [inputRef]); // Reacts to changes in inputRef
};

// Hook for preventing typing and deletion of alphanumeric characters
export const useInputRestrictions = (inputRef: RefObject<HTMLInputElement>) => {
  useEffect(() => {
    const inputElement = inputRef.current;

    if (inputElement) {
      const handleKeyDown = (event: KeyboardEvent) => {
        const { key, target } = event;
        const input = target as HTMLInputElement;
        const { selectionStart, selectionEnd } = input;

        // Allow control keys to function
        const allowedKeys = [
          'ArrowLeft',
          'ArrowRight',
          'ArrowUp',
          'ArrowDown',
          'Backspace',
          'Delete',
          'Tab',
          'Enter',
          'Escape',
          'Home',
          'End',
          'PageUp',
          'PageDown',
        ];

        // Prevent typing alphanumeric characters unless it is a control key
        if (/[\p{L}0-9]/u.test(key) && !allowedKeys.includes(key)) {
          event.preventDefault();
          return;
        }

        // Special handling for backspace and delete keys
        if (
          (key === 'Backspace' || key === 'Delete') &&
          selectionStart !== null &&
          selectionEnd !== null
        ) {
          // Check if the selection is a single character and it's alphanumeric
          if (selectionStart === selectionEnd) {
            const charToDelete =
              key === 'Backspace'
                ? input.value.charAt(selectionStart - 1)
                : input.value.charAt(selectionStart);
            if (/[\p{L}0-9]/u.test(charToDelete)) {
              event.preventDefault();
              return;
            }
          } else {
            event.preventDefault();
            return;
          }
        }
      };

      inputElement.addEventListener('keydown', handleKeyDown);

      return () => {
        inputElement.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [inputRef]); // Ensures effect updates if inputRef changes
};
