import React from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { ButtonWithIcon } from '../../../StaticComponents/ButtonWithIcon';
import { AddNewVersionButtonProps } from './AddNewVersionButton.types';
import { openModal } from '../../../../store/files/upload/upload.slice';
import { ADD_PREVIOUS_VERSION_MODE } from '../DocumentViewModal.types';
import { useAppDispatch } from '../../../../store/hooks';

const AddNewVersionButton: React.FC<AddNewVersionButtonProps> = ({ document }) => {
  const dispatch = useAppDispatch();
  const handleClick = async () => {
    dispatch(openModal({ id: ADD_PREVIOUS_VERSION_MODE, previousVersion: document }));
  };

  return (
    <ButtonWithIcon
      startIcon={<FileUploadOutlinedIcon fontSize='small' />}
      className='upload-files small create-new-version-button'
      onClick={handleClick}
    >
      Add new version
    </ButtonWithIcon>
  );
};

export default AddNewVersionButton;
