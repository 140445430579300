export interface NoItemsFoundProps {
  subtitle?: string;
  icon?:
    | 'default'
    | 'searchIcon'
    | 'clauseIcon'
    | 'folderIconBookmarks'
    | 'folderIconReports'
    | 'usersIcon'
    | 'tagsIcon';
  handleClick?: () => void;
}

export const REQUEST_ACCESS_OR_CHANGE_SEARCH_MESSAGE = 'Request access or change search criteria.';
