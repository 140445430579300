import {
  ClauseDictionariesResponse,
  PROVIDER,
} from '../../../store/files/upload/list.service.types';
import { ClauseResponse } from '../../../store/files/clauses/clauses.list.types';
import { LanguageToggleSwitchTypes } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import { CLAUSE_SOURCE_ERGO_AUTOMATED } from '../../../store/files/upload/list.helpers';
import { hasMinimumWords } from '../../UploadClause/UploadClause.helpers';
import { MIN_CLAUSE_WORD_COUNT_MSG } from '../../../config/config';

enum ADJUST_CLAUSE_TOOLTIPS {
  REPORTED = 'You cannot adjust a clause with the status "Reported".',
}

const hasWriteAccessToEntity = (
  entities: string[],
  classification: string,
  writeAccessDictionaries: ClauseDictionariesResponse
) => {
  const filtered = writeAccessDictionaries.Entities.values.filter(
    ({ value, related }) =>
      entities.includes(value) && related?.Classification?.includes(classification)
  );
  return filtered.length > 0;
};

const checkGlobalUser = (provider: string, globalUser: boolean) => {
  return provider === PROVIDER.GPC ? globalUser : true;
};

export const isButtonVisible = (
  clause: ClauseResponse,
  language: LanguageToggleSwitchTypes,
  writeAccessDictionaries: ClauseDictionariesResponse | undefined,
  globalUser: boolean
) => {
  return (
    language !== LanguageToggleSwitchTypes.English &&
    clause.ClauseSources.includes(CLAUSE_SOURCE_ERGO_AUTOMATED) &&
    writeAccessDictionaries &&
    hasWriteAccessToEntity(clause.Entities, clause.Classification, writeAccessDictionaries) &&
    checkGlobalUser(clause.Provider, globalUser)
  );
};

export const getTooltipMessage = (clause: ClauseResponse, selectedText: string) => {
  if (clause.Reported) return ADJUST_CLAUSE_TOOLTIPS.REPORTED;
  if (!hasMinimumWords(selectedText)) return MIN_CLAUSE_WORD_COUNT_MSG;
  return '';
};
