import React, { useEffect, useRef, useState } from 'react';
import { useDocumentDownload } from '../../StaticComponents/FileViewer/useDocumentDownload';
import SpecialCharacterTextField from './SpecialCharactersTextField';
import { FileLoader } from '../../StaticComponents/FileViewer/FileLoader';
import { UseFormSetValue } from 'react-hook-form';
import { ClauseMetadata, ClauseMetadataFields } from '../../UploadClause/UploadClause.types';
import './SpecialCharacterTextEditor.scss';

interface SpecialCharacterTextEditorProps {
  url: string | undefined;
  loading: boolean;
  error: boolean;
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  setValue: UseFormSetValue<ClauseMetadata>;
}

const SpecialCharacterTextEditor: React.FC<SpecialCharacterTextEditorProps> = ({
  url,
  loading,
  error,
  value,
  onChange,
  setValue,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { documentData, isDownloading, isError } = useDocumentDownload(loading, error, url);
  const [text, setText] = useState(value);
  const isInitialized = useRef(false);

  useEffect(() => {
    if (!isInitialized.current && documentData) {
      setText(documentData);
      setValue(ClauseMetadataFields.Content, documentData, {
        shouldValidate: true,
        shouldDirty: false,
      });
      isInitialized.current = true;
    }
  }, [documentData, setValue, text]);

  useEffect(() => {
    if (value !== text) {
      setText(value);
    }
  }, [value, text]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    onChange(event);
  };
  if (isDownloading || loading || isError) {
    return <FileLoader loading={isDownloading} error={isError} />;
  }

  return (
    <div className='edit-clause-text-field '>
      <SpecialCharacterTextField
        inputRef={inputRef}
        value={text}
        onChange={handleChange}
        label=''
        fullWidth
        multiline
        rows={10}
      />
    </div>
  );
};

export default SpecialCharacterTextEditor;
