import React, { useCallback, useEffect } from 'react';
import _, { cloneDeep, isEmpty } from 'lodash';
import {
  filterBusinessTypesValues,
  filterCountriesValues,
  filterEntityValuesBasedOnFields,
  filterLobsValues,
  filterLopsValues,
} from './DocumentsSearch.helpers';
import { ClauseSearchFieldsProps } from './SearchFilters.types';
import {
  ClauseDictionariesResponse,
  MetadataDictionaryValue,
} from '../../../store/files/upload/list.service.types';
import { ClausesListParams } from '../../../store/files/clauses/clauses.list.types';

export const NARROW_CLAUSE_FIELDS = ['Entities', 'Countries', 'Lops', 'Lobs', 'BusinessTypes'];

interface UseNarrowDownClauseFilters {
  filtersApplied: ClauseSearchFieldsProps['filtersApplied'];
  lastSelected?: string;
  entitiesValues?: MetadataDictionaryValue[];
  isDropDownOpen: boolean;
  dictionaries?: ClauseDictionariesResponse;
  setCountriesValues: React.Dispatch<MetadataDictionaryValue[] | undefined>;
  setLobsValues: React.Dispatch<MetadataDictionaryValue[] | undefined>;
  setLopsValues: React.Dispatch<MetadataDictionaryValue[] | undefined>;
  setBusinessTypes: React.Dispatch<MetadataDictionaryValue[] | undefined>;
  setEntitiesValues: React.Dispatch<MetadataDictionaryValue[] | undefined>;
}

export const isItTheOnlyFilterSelected = (
  field: keyof ClausesListParams,
  filtersApplied: ClauseSearchFieldsProps['filtersApplied']
) => {
  const filtersSelected = _.omitBy(_.pick(filtersApplied, NARROW_CLAUSE_FIELDS), isEmpty);
  return Object.keys(filtersSelected)?.length === 1 && filtersSelected?.[field]?.length;
};

export const getEntitiesFilters = (
  filtersApplied: ClauseSearchFieldsProps['filtersApplied'],
  entityValues?: MetadataDictionaryValue[]
) => {
  if (filtersApplied.Entities?.length) return filtersApplied.Entities;
  else return entityValues?.map(({ value }) => value);
};

export const useNarrowDownClauseFilters = ({
  filtersApplied,
  lastSelected,
  entitiesValues,
  isDropDownOpen,
  dictionaries,
  setCountriesValues,
  setLobsValues,
  setLopsValues,
  setBusinessTypes,
  setEntitiesValues,
}: UseNarrowDownClauseFilters) => {
  const isThereOnlyOneFilterSelected =
    Object.keys(_.omitBy(_.pick(filtersApplied, NARROW_CLAUSE_FIELDS), isEmpty))?.length === 1;

  const isNotLastSelected = useCallback(
    (field: keyof ClausesListParams) => {
      return lastSelected !== field;
    },
    [lastSelected]
  );

  const getFiltersValues = useCallback(
    (
      field: keyof ClausesListParams,
      filtersSelected: Pick<ClausesListParams, 'Entities'> & Pick<ClausesListParams, 'Lobs'>,
      setLobDicts: boolean = false
    ) => {
      const filtersSelectedTmp = cloneDeep(filtersSelected);
      if (isItTheOnlyFilterSelected(field, filtersApplied)) {
        filtersSelectedTmp['Entities'] = dictionaries?.Entities.values.map(({ value }) => value);
        if (setLobDicts)
          filtersSelectedTmp['Lobs'] = dictionaries?.Lobs.values.map(({ value }) => value);
      } else {
        filtersSelectedTmp['Entities'] = getEntitiesFilters(filtersApplied, entitiesValues);
      }
      return filtersSelectedTmp;
    },
    [dictionaries, entitiesValues, filtersApplied]
  );

  useEffect(() => {
    if (!isDropDownOpen || isThereOnlyOneFilterSelected) {
      let filtersSelected: Partial<ClausesListParams> = _.pick(
        filtersApplied,
        NARROW_CLAUSE_FIELDS
      );
      filtersSelected['Entities'] = getEntitiesFilters(filtersApplied, entitiesValues);
      let newLobs = undefined;
      if (isNotLastSelected('Lobs')) {
        filtersSelected = getFiltersValues('Lobs', filtersSelected);
        newLobs = filterLobsValues(dictionaries, filtersSelected);
        setLobsValues(newLobs);
      }
      if (isNotLastSelected('Lops')) {
        let filtersSelectedForLop = cloneDeep(filtersSelected);
        filtersSelectedForLop['Lobs'] = filtersApplied.Lobs?.length
          ? filtersApplied.Lobs
          : newLobs?.map(({ value }) => value);
        filtersSelectedForLop = getFiltersValues('Lops', filtersSelectedForLop, true);
        const lops = filterLopsValues(dictionaries, filtersSelectedForLop);
        setLopsValues(lops);
      }
      if (isNotLastSelected('BusinessTypes')) {
        filtersSelected = getFiltersValues('BusinessTypes', filtersSelected);
        setBusinessTypes(filterBusinessTypesValues(dictionaries, filtersSelected));
      }
      if (isNotLastSelected('Countries')) {
        filtersSelected = getFiltersValues('Countries', filtersSelected);
        setCountriesValues(filterCountriesValues(dictionaries, filtersSelected));
      }
    }
  }, [
    dictionaries,
    entitiesValues,
    filtersApplied,
    getFiltersValues,
    isDropDownOpen,
    isNotLastSelected,
    isThereOnlyOneFilterSelected,
    setBusinessTypes,
    setCountriesValues,
    setLobsValues,
    setLopsValues,
  ]);

  useEffect(() => {
    if (!isDropDownOpen && lastSelected !== 'Entities') {
      const filtersSelected = _.pick(filtersApplied, NARROW_CLAUSE_FIELDS);
      if (Object.keys(filtersSelected).length) {
        const entities = filterEntityValuesBasedOnFields(filtersSelected, dictionaries?.Entities);
        setEntitiesValues(entities);
      }
    }
  }, [dictionaries, filtersApplied, isDropDownOpen, lastSelected, setEntitiesValues]);
};
