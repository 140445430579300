import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import {
  DOCUMENT_FILE_GET_ENDPOINT,
  DOCUMENT_FILE_REPORT_DELETE_ENDPOINT,
  DOCUMENT_FILE_REPORT_ENDPOINT,
  DOCUMENTS_DELETE_ENDPOINT,
  DOCUMENTS_LIST_ENDPOINT,
  DOCUMENTS_UPDATE_ENDPOINT,
  DOCUMENT_SEARCH_SIMILAR_ENDPOINT,
} from '../../../services/api.service.config';
import {
  DocumentFileResponse,
  DocumentMetadataUpdateRequest,
  DocumentMetadataUpdateResponse,
  DOCUMENTS_LIST_API_ENDPOINTS,
  DOCUMENTS_LIST_API_TAGS,
  DocumentsListParams,
  DocumentsListResponse,
  FILE_REQUEST_CACHE_EXPIRATION,
  ReportRequest,
} from './documents.list.types';
import { mapDocumentsListParamsToGetDocumentDTO } from '../documentsAndClauses/list.helpers';
import { appendAuthorizationHeader } from '../../../components/Auth/auth.helpers';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { GetDocumentDTO, isGetDocumentDTO } from '../documentsAndClauses/list.dto.types';
import { TAB_ITEMS_PAGE_SIZE } from '../../../config/config';

const getDocumentFileTag = (id: string) => [{ type: DOCUMENTS_LIST_API_TAGS.DOCUMENT_FILE, id }];

export const documentsListApi = createApi({
  reducerPath: 'documentsListApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '',
    prepareHeaders: appendAuthorizationHeader,
  }),
  tagTypes: [DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST, DOCUMENTS_LIST_API_TAGS.DOCUMENT_FILE],
  endpoints: (builder) => ({
    [DOCUMENTS_LIST_API_ENDPOINTS.GET_DOCUMENTS_LIST]: builder.query<
      DocumentsListResponse,
      DocumentsListParams | GetDocumentDTO
    >({
      query: (values) => ({
        url: DOCUMENTS_LIST_ENDPOINT,
        body: isGetDocumentDTO(values) ? values : mapDocumentsListParamsToGetDocumentDTO(values),
        method: 'POST',
      }),
      providesTags: [DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST],
    }),
    [DOCUMENTS_LIST_API_ENDPOINTS.DELETE_DOCUMENT]: builder.mutation<void, string>({
      query: (id) => ({
        url: DOCUMENTS_DELETE_ENDPOINT.replace('{id}', id),
        method: 'DELETE',
      }),
    }),
    [DOCUMENTS_LIST_API_ENDPOINTS.UPDATE_DOCUMENTS_METADATA]: builder.mutation<
      DocumentMetadataUpdateResponse,
      DocumentMetadataUpdateRequest
    >({
      query: (body) => ({
        url: DOCUMENTS_UPDATE_ENDPOINT,
        method: 'PUT',
        body,
      }),
    }),
    [DOCUMENTS_LIST_API_ENDPOINTS.GET_DOCUMENT_FILE]: builder.query<DocumentFileResponse, string>({
      query: (id) =>
        DOCUMENT_FILE_GET_ENDPOINT.replace('{id}', id) +
        `?ExpiresIn=${FILE_REQUEST_CACHE_EXPIRATION}`,
      keepUnusedDataFor: FILE_REQUEST_CACHE_EXPIRATION,
      providesTags: (result, error, id) => getDocumentFileTag(id),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        await timeoutPromise(FILE_REQUEST_CACHE_EXPIRATION * 1000);
        dispatch(documentsListApi.util.invalidateTags(getDocumentFileTag(id)));
      },
    }),
    [DOCUMENTS_LIST_API_ENDPOINTS.REPORT_DOCUMENT]: builder.mutation<void, ReportRequest>({
      query: (body) => ({
        url: DOCUMENT_FILE_REPORT_ENDPOINT,
        method: 'POST',
        body,
      }),
    }),
    [DOCUMENTS_LIST_API_ENDPOINTS.DELETE_DOCUMENT_REPORT]: builder.mutation<void, string>({
      query: (id) => ({
        url: DOCUMENT_FILE_REPORT_DELETE_ENDPOINT.replace('{id}', id),
        method: 'DELETE',
      }),
    }),
    [DOCUMENTS_LIST_API_ENDPOINTS.GET_SIMILAR]: builder.query<DocumentsListResponse, string>({
      query: (id) => ({
        url:
          DOCUMENT_SEARCH_SIMILAR_ENDPOINT.replace('{id}', id) +
          `?PageSize=${TAB_ITEMS_PAGE_SIZE.toString()}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetDocumentsListQuery,
  useGetDocumentFileQuery,
  useDeleteDocumentMutation,
  useUpdateDocumentsMetadataMutation,
  useReportDocumentMutation,
  useDeleteDocumentReportMutation,
  useGetSimilarQuery,
} = documentsListApi;
