import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import common from '../../../assets/common.module.scss';
import { InfoButtonProps } from './InfoButton.types';

export const InfoButton = ({ title, description }: InfoButtonProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <IconButton aria-describedby={id} size='small' onClick={handleClick}>
        <InfoOutlinedIcon fontSize='inherit' />
      </IconButton>

      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        elevation={1}
      >
        <Box sx={{ p: '16px 16px 25px', maxWidth: 280 }}>
          <Typography
            sx={{
              fontSize: 14,
              fontWeight: 'bold',
              lineHeight: '24px',
              color: common.darkGrayTextColor,
              marginBottom: '5px',
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: 12,
              lineHeight: '16px',
              color: common.midGray,
            }}
          >
            {description}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};
