import React, { useCallback, useEffect } from 'react';
import { Divider } from '@mui/material';
import { useSnackbar } from 'notistack';
import './EditSearch.scss';
import { EditSearchProps } from './EditSearch.types';
import { MarsModal } from '../../../StaticComponents/Modals/MarsModal';
import { useUpdateSavedSearchMutation } from '../../../../store/user/savedsearch.service';
import { SavedSearch, SavedSearchTypeValues } from '../../../../store/user/savedsearch.types';
import { DocumentsListParams } from '../../../../store/files/documents/documents.list.types';
import { handleApiError } from '../../../../store/error.helpers';
import { useForm } from 'react-hook-form';
import { SearchFilters } from '../../../Documents/DocumentsSearch/SearchFilters';
import { SearchFiltersControl } from '../../../Documents/DocumentsSearch/SearchFilters.types';
import { SaveSearchInput } from '../../../Documents/SaveSearch';
import { ApiError } from '../../../UploadFiles/MetadataForm/ApiError';
import { ErrorMessages } from '../../../../services/errors.service.types';
import { Messages } from '../../../../services/messages.service.types';
import { FILTERS_PREFIX } from '../SavedSearches.types';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { savedSearchSelectors } from '../../../../store/user/savedsearch.selectors';
import { closeSavedSearchEditModal } from '../../../../store/user/savedsearch.slice';
import { SetValueType } from '../../../Documents/DocumentsSearch/DocumentsSearch.types';
import { TEST_ID } from '../../../../config/test-fields-ids.config';
import { useApiError } from '../../../../store/ui/ui.helpers';
import { SequentialSearchFields } from '../../../Documents/DocumentsSearch/SequentialSearchFields/SequentialSearchFields';
import { useSequentialSearch } from '../../../Documents/DocumentsSearch/SequentialSearchFields/SequentialSearchFields.hook';
import { SequentialSearchOptions } from '../../../Documents/DocumentsSearch/SequentialSearchOptions/SequentialSearchOptions';
import { SEQUENTIAL_SEARCH } from '../../../../store/files/documentsAndClauses/list.types';
import { FieldPath } from 'react-hook-form/dist/types/path';

const sequentialSearchPath: FieldPath<SavedSearch> = `Filters.${SEQUENTIAL_SEARCH}`;

const EditSearch: React.FC<EditSearchProps> = ({ dictionaries, dictionariesClauses }) => {
  const { apiError, setApiError, clearApiError } = useApiError();
  const savedSearch = useAppSelector(savedSearchSelectors.selectSavedSearchEditModal);
  const dispatch = useAppDispatch();
  const {
    control,
    getValues,
    setValue,
    watch,
    reset,
    formState: { isValid, isDirty },
  } = useForm<SavedSearch>({ mode: 'onChange' });

  const clauseMode = savedSearch?.SavedSearchType === SavedSearchTypeValues.CLAUSE;
  const dicts = clauseMode ? dictionariesClauses : dictionaries;

  const sequentialSearch = useSequentialSearch(watch(sequentialSearchPath), (values) =>
    setValue(sequentialSearchPath, values, { shouldDirty: true })
  );
  const { setSelectedSearch } = sequentialSearch;

  useEffect(() => {
    reset(savedSearch);
    setSelectedSearch(0);
  }, [reset, savedSearch, setSelectedSearch]);

  const { enqueueSnackbar } = useSnackbar();
  const [update, { isLoading }] = useUpdateSavedSearchMutation();

  const filtersApplied = watch(FILTERS_PREFIX) || {};

  const handleSubmit = () => {
    setApiError(undefined);

    update(getValues())
      .unwrap()
      .then(() => {
        enqueueSnackbar(Messages.SavedSearchUpdateSuccess);
        handleClose();
      })
      .catch((response) => {
        enqueueSnackbar(ErrorMessages.SavedSearchUpdateError, { variant: 'error' });
        setApiError(handleApiError(response, true));
      });
  };

  const handleClose = () => {
    dispatch(closeSavedSearchEditModal());
    setApiError(undefined);
  };

  const setValueWithPath = useCallback(
    (name: string, filters: DocumentsListParams) => {
      const path = `${FILTERS_PREFIX}.${name}` as unknown as keyof SavedSearch;
      setValue(path, filters);
    },
    [setValue]
  );

  return (
    <MarsModal
      className='save-search-modal edit-save-search-modal'
      data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_EDIT_MODAL}
      title='Edit search'
      buttonLabel='Save'
      open={!!savedSearch?.SavedSearchId}
      onConfirm={handleSubmit}
      onClose={handleClose}
      disabled={isLoading || !isValid || !isDirty}
      loading={isLoading}
    >
      <div className='edit-search-modal-content'>
        {/* TODO hidden as sharing of saved searches is out of scope for now */}
        {/*<Divider />*/}
        {/*<div className='edit-search-modal-content__show-to'>*/}
        {/*  <div>*/}
        {/*    <div className='edit-search-modal-content__title'>Show to everyone in my entity</div>*/}
        {/*    <div>Show this search for everyone in my entity</div>*/}
        {/*  </div>*/}
        {/*  <Switch className='edit-search-modal-content__show-to-switch' />*/}
        {/*</div>*/}
        {/*<Divider />*/}

        <div
          data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_EDIT_NAME}
          className='edit-search-modal-content__search-name'
        >
          <div className='edit-search-modal-content__title'>Search name</div>

          <SaveSearchInput
            control={control as SearchFiltersControl}
            isLoading={isLoading}
            onClearError={clearApiError}
          />
        </div>
        <Divider />

        <div className='edit-search-modal-content__filters'>
          <div className='edit-search-modal-content__title'>Filters</div>
          <div
            data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_EDIT_FILTERS}
            className='edit-search-modal-content__filters-content'
          >
            <SearchFilters
              dictionaries={dicts}
              control={control as SearchFiltersControl}
              filtersApplied={filtersApplied}
              setValue={setValueWithPath as SetValueType}
              withPathPrefix
              clauseMode={clauseMode}
            />
          </div>
        </div>

        <div className='sequential-search-fields-arrow-line'>
          <div
            data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_EDIT_QUERY}
            className='edit-search-modal-content__search-name'
          >
            <div className='edit-search-modal-content__title'>Search phrases</div>

            <SequentialSearchFields params={sequentialSearch} />
          </div>

          <SequentialSearchOptions
            params={sequentialSearch}
            control={control}
            clauseMode={clauseMode}
            prefix={FILTERS_PREFIX}
          />
        </div>

        <ApiError apiError={apiError} />
      </div>
    </MarsModal>
  );
};

export default EditSearch;
