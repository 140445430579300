import { getFiltersCount } from './DocumentsSearch/DocumentsSearch.helpers';
import { DocumentsListFiltersProps } from './Documents.types';
import { SearchMethod } from '../../store/files/documentsAndClauses/list.types';
import { OriginalLanguage } from '../../store/files/documents/documents.list.types';
import { plural } from '../SpreadSheet/SavedWithErrors';

export const generateMessage = (
  filters: DocumentsListFiltersProps['filters'],
  clauseMode = false
) => {
  const numberOfFilters = getFiltersCount(filters, clauseMode);
  return numberOfFilters ? `${numberOfFilters} filter${plural(numberOfFilters)}` : '';
};

export const messageForCounter = (count: number): string => {
  return `Showing ${count} result${plural(count)}`;
};

export const getText = (value: string) =>
  value.length > 40 ? value.substring(0, 40) + '...' : value;

export const getMethod = (value?: SearchMethod) => {
  switch (value) {
    case SearchMethod.PHRASE:
      return 'Phrase search';
    case SearchMethod.BROAD:
      return 'Broad search';
    case SearchMethod.EXACT:
      return 'Exact search';
    default:
      return '';
  }
};

export const getLang = (value?: OriginalLanguage) =>
  value === OriginalLanguage.ORIGINAL ? 'Orig. language' : 'English';

export const getExcluded = (value?: string[]) => value?.join(', ');
