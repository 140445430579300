import React, { useState, useRef, useEffect } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LoadingButton from '@mui/lab/LoadingButton';

import { formatUiDateTimeFromStringShorten } from '../../Comments.helpers';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/trash.svg';
import { CommentProps } from './Comment.types';

import './Comment.scss';

export const Comment: React.FC<CommentProps> = ({
  content,
  createdAt,
  name,
  showDelete,
  handleDelete,
  loading,
  deleteBlocked,
}) => {
  const [showMore, setShowMore] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (contentRef.current?.scrollHeight! > contentRef.current?.clientHeight!) {
      setIsOverflowing(true);
    }
  }, []);

  return (
    <div className='comment'>
      <div className='comment--header'>
        <div>
          <AccountCircleIcon fontSize='large' />
          {name}
        </div>
        <div>{formatUiDateTimeFromStringShorten(createdAt)}</div>
        {showDelete ? (
          <LoadingButton
            className={`comment--header--delete-button ${deleteBlocked ? 'disabled' : ''}`}
            variant='text'
            disabled={deleteBlocked}
            title='Delete comment'
            loading={loading}
            type='submit'
            onClick={() => handleDelete()}
          >
            {!loading ? <DeleteIcon /> : null}
          </LoadingButton>
        ) : null}
      </div>
      <div ref={contentRef} className={`comment--content${showMore ? '__expanded' : ''}`}>
        {content}
      </div>
      {isOverflowing ? (
        <button className='comment--read-more-button' onClick={() => setShowMore(!showMore)}>
          {showMore ? 'Read Less' : 'Read More'}
        </button>
      ) : null}
    </div>
  );
};
