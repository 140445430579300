export enum ERROR_MARS_CODE {
  DUPLICATED_DOCUMENT = 'E2001',
}

export enum ERROR_RESPONSE_STATUS {
  DUPLICATED_DOCUMENT = 409,
  VALIDATION_ERROR = 422,
}

export interface ClauseUploadApiErrorResponse {
  data: ApiErrors;
}

interface ApiErrors {
  errors: ApiError[];
}

interface ApiError {
  errorCode: string;
  errorDetails: ApiErrorDetails;
}

interface ApiErrorDetails {
  errorMessage: string;
  paramName: string;
}
