import React from 'react';
import { FormNoticeProps } from './MetadataFormHelpers.types';
import { CheckboxProps, FormControlLabelProps } from '@mui/material';
import { FormNotice } from './MetadataFormHelpers';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

type CustomCheckboxType = FormNoticeProps &
  Pick<CheckboxProps, 'checked' | 'onChange' | 'disabled'> &
  Pick<FormControlLabelProps, 'label'>;

export const CustomCheckbox: React.FC<CustomCheckboxType> = ({
  tooltipMessage,
  checked,
  onChange,
  disabled,
  label,
}) => {
  return (
    <div className='clause-metadata-form-custom-checkbox'>
      <FormControlLabel
        control={
          <Checkbox size='small' checked={checked} onChange={onChange} disabled={disabled} />
        }
        label={label}
      />
      {tooltipMessage ? <FormNotice tooltipMessage={tooltipMessage} /> : null}
    </div>
  );
};
