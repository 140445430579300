import TextField from '@mui/material/TextField';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { AdminPanelCreatedUser, AdminPanelUser } from '../../../store/adminPanel/adminPanel.types';
import { DocumentFilter } from '../../Documents/DocumentsSearch/DocumentFilter';
import { useGetMetadataQuery } from '../../../store/adminPanel/adminPanel.service';
import { Controller, useForm } from 'react-hook-form';
import Button from '@mui/material/Button';
import { FirstParam } from '../../Documents/DocumentsSearch/DocumentsSearch.types';
import { filterUsers, mapGroupsToDictValues } from './ManageUsersGroupsFilters.helpers';

export const ManageUsersGroupsFilters: React.FC<{
  users: AdminPanelUser[];
  setFilteredUsers: Dispatch<SetStateAction<AdminPanelUser[] | undefined>>;
}> = ({ users, setFilteredUsers }) => {
  const {
    control,
    getValues,
    reset,
    formState: { isDirty },
  } = useForm<AdminPanelCreatedUser>({
    defaultValues: {
      Active: true,
    },
    mode: 'onChange',
  });

  const { data, isLoading, isFetching } = useGetMetadataQuery();
  const isInputDisabled = isLoading || isFetching;

  const groups = useMemo(() => mapGroupsToDictValues(data?.Groups), [data]);

  return (
    <>
      <div className='admin-panel__manage-users-groups-criteria'>
        <Controller
          control={control}
          name='Entity'
          defaultValue=''
          render={({ field }) => (
            <DocumentFilter
              {...field}
              label='Company'
              values={data?.Entities}
              firstParam={FirstParam.NONE}
              disabled={isInputDisabled}
            />
          )}
        />

        <Controller
          control={control}
          name='UserGroups'
          defaultValue={[]}
          render={({ field }) => (
            <DocumentFilter
              {...field}
              disabled={isInputDisabled}
              label='Groups'
              multiple
              values={groups}
            />
          )}
        />

        <Controller
          control={control}
          name='Email'
          defaultValue=''
          render={({ field }) => <TextField {...field} placeholder='Email' size='small' />}
        />
      </div>
      <div className='admin-panel__buttons'>
        <Button
          size='small'
          onClick={() => {
            setFilteredUsers(filterUsers(users, getValues()));
          }}
        >
          Apply filters
        </Button>

        <Button
          size='small'
          onClick={() => {
            reset();
            setFilteredUsers(users);
          }}
          disabled={!isDirty}
        >
          Clear filters
        </Button>
      </div>
    </>
  );
};
