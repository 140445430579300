import { formatDate } from '../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { parseDate } from '../UploadFiles/MetadataForm/MetadataFormHelpers';

import { UI_DATE_TIME_SHORTEN_FORMAT } from '../../config/config';

import { MAX_COMMENT_LENGTH, MIN_COMMENT_LENGTH } from '../../config/config';

export const formatUiDateTimeFromStringShorten = (value: string) => {
  const date = parseDate(value);
  return formatDate(date, UI_DATE_TIME_SHORTEN_FORMAT);
};

export const AddCommentRules = {
  required: {
    value: true,
    message: `Minimum ${MIN_COMMENT_LENGTH} character is required`,
  },
  minLength: {
    value: MIN_COMMENT_LENGTH,
    message: `Minimum ${MIN_COMMENT_LENGTH} character is required`,
  },
  maxLength: {
    value: MAX_COMMENT_LENGTH,
    message: `Maximum ${MAX_COMMENT_LENGTH} characters allowed`,
  },
};
