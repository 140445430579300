export enum COMPARE_MODE {
  SHOW_ORIGINAL = 'showOriginal',
  SHOW_DIFFERENCES = 'showDifferences',
  SHOW_SIMILARITIES = 'showSimilarities',
  SHOW_REPLACEMENTS = 'showReplacements',
}

export interface CompareOptionsBarProps {
  disabled?: boolean;
  isDocumentMode?: boolean;
}
