import React from 'react';
import { InfoButton } from './InfoButton';
import { InfoButtonProps } from './InfoButton.types';

export const LabelWithInfo = ({ title, description }: InfoButtonProps) => {
  return (
    <>
      {title} <InfoButton title={title} description={description} />
    </>
  );
};
