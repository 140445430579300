import CachedIcon from '@mui/icons-material/Cached';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import React from 'react';
import { PendingStatusProps } from './PendingStatus.types';
import './PendingStatus.scss';

export const PendingStatus = ({ mode, label }: PendingStatusProps) => {
  return (
    <div className={`pending-status ${mode}`}>
      {mode === 'uploading' && <CachedIcon />}
      {mode === 'error' && <ErrorOutlineOutlinedIcon />}
      {mode === 'warning' && <WarningAmberOutlinedIcon />}
      <div className='pending-status__label'>{label}</div>
    </div>
  );
};
