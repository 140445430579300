export class PromiseRunner<T> {
  private readonly ref: () => Promise<T>;
  private current?: Promise<T>;

  constructor(ref: () => Promise<T>) {
    this.ref = ref;
  }

  public async run() {
    if (!this.current) {
      this.current = this.ref();
      this.current.finally(() => (this.current = undefined));
    }
    return this.current;
  }
}
