import React from 'react';
import './SearchFilters.scss';
import { SearchFiltersProps } from './SearchFilters.types';
import { ClauseSearchFields } from './ClauseSearchFields';
import { DocumentSearchFields } from './DocumentSearchFields';

export const SearchFilters: React.FC<SearchFiltersProps> = ({
  dictionaries,
  filtersApplied,
  control,
  setValue,
  withPathPrefix,
  children,
  clauseMode,
}) => {
  return (
    <div className='search-filters'>
      {clauseMode && dictionaries ? (
        <ClauseSearchFields
          control={control}
          dictionaries={dictionaries && 'Entities' in dictionaries ? dictionaries : undefined}
          filtersApplied={filtersApplied}
          setValue={setValue}
          withPathPrefix={withPathPrefix}
        />
      ) : (
        dictionaries && (
          <DocumentSearchFields
            control={control}
            dictionaries={dictionaries && 'Entity' in dictionaries ? dictionaries : undefined}
            filtersApplied={filtersApplied}
            setValue={setValue}
            withPathPrefix={withPathPrefix}
          />
        )
      )}

      {children}
    </div>
  );
};
