export interface UploadButtonProps {
  adjustStyleForDropdown?: boolean;
  draftModeSelected?: boolean;
  onClick: () => void;
}

export interface UploadButtonDraftModeProps {
  onClick: () => void;
}

export enum UPLOAD_BUTTON {
  UPLOAD_FILES = 'UploadFiles',
  UPLOAD_DRAFTS = 'UploadDrafts',
}

export interface DropDownButtonProps {
  draftModeSelected: boolean;
  onChange: (value: string) => void;
}
