import { Box, Paper } from '@mui/material';
import React, { useState } from 'react';
import { SearchDocumentGrid } from './CustomToolbar/SearchDocumentGrid';
import { FullScreenDocumentGrid } from './CustomToolbar/FullScreenDocumentGrid';
import './SpreadSheetView.scss';
import { DOCUMENTS_EDIT_SOURCE, SpreadSheetViewProps } from './SpreadSheetView.types';
import { PreLandingDocumentGrid } from './DocumentsGrid/PreLandingDocumentGrid';
import { AchDocumentsGrid } from './DocumentsGrid/AchDocumentsGrid';

export const SpreadSheetView: React.FC<SpreadSheetViewProps> = ({
  useGetDataListWithParams,
  editMode = true,
  fullscreen,
  toggleFullscreen,
  isSnackbarEnabled,
}) => {
  const [search, setSearch] = useState<string>('');
  const setSearchText = (value: string) => setSearch(value);

  return (
    <Paper elevation={0} className={'spreadsheet-view ' + (fullscreen ? 'fullscreen' : '')}>
      <Box className='top-grid-bar'>
        <SearchDocumentGrid value={search} setSearchText={setSearchText} />
        <FullScreenDocumentGrid fullscreen={fullscreen} toggleFullscreen={toggleFullscreen} />
      </Box>
      {editMode === DOCUMENTS_EDIT_SOURCE.PRE_LANDING ? (
        <PreLandingDocumentGrid search={search} isSnackbarEnabled={isSnackbarEnabled} />
      ) : editMode === DOCUMENTS_EDIT_SOURCE.DOCUMENTS && useGetDataListWithParams ? (
        <AchDocumentsGrid
          useGetDataListWithParams={useGetDataListWithParams}
          search={search}
          isSnackbarEnabled={isSnackbarEnabled}
        />
      ) : (
        <></>
      )}
    </Paper>
  );
};
