import { SUBMIT_UPLOAD_MODAL_SUBMIT_BUTTON_LABEL } from './ConfirmationModal.messages';
import { ConfirmationModal } from './ConfirmationModal';
import React from 'react';
import { SubmitConfirmModalProps } from './ConfirmationModal.types';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

export const SubmitConfirmModal: React.FC<SubmitConfirmModalProps> = ({
  open,
  onConfirm,
  onClose,
  message,
  title,
}) => {
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      message={message}
      title={title}
      successButtonIcon={<TaskAltIcon fontSize='large' />}
      successButtonLabel={SUBMIT_UPLOAD_MODAL_SUBMIT_BUTTON_LABEL}
    />
  );
};
