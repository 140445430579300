import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { MINIMIZED_DATA, MinimizedData } from '../../store/ui/ui.types';
import { useGetDocumentsListQuery } from '../../store/files/documents/documents.list.service';
import {
  MAX_NUMBER_OF_CLAUSES_TO_COMPARE,
  MAX_NUMBER_OF_DOCS_TO_COMPARE,
  MIN_NUMBER_OF_ITEMS_TO_COMPARE,
} from '../../config/config';
import { openCompareModal, showModal } from '../../store/ui/ui.slice';
import {
  DocumentResponse,
  DocumentsListParams,
} from '../../store/files/documents/documents.list.types';
import { closeMinimizedClause, closeMinimizedDoc } from '../../store/ui/ui.actions';
import { ErrorMessages } from '../../services/errors.service.types';
import { useGetClausesListQuery } from '../../store/files/clauses/clauses.list.service';
import { ClauseResponse } from '../../store/files/clauses/clauses.list.types';
import { useIsDocumentResponseData } from '../../store/files/documentsAndClauses/list.hooks.helpers';
import { isDocumentListResponse } from '../../store/files/documentsAndClauses/list.helpers';

const getNotExistingDocs = (
  compareDocsIds: string[],
  data?: (DocumentResponse | ClauseResponse)[]
) => {
  const existingDataIds = data?.map((item) => useIsDocumentResponseData(item).itemId);
  return compareDocsIds.filter((compareDocId) => !existingDataIds?.includes(compareDocId));
};

const config = {
  [MINIMIZED_DATA.MINIMIZED_DOCS]: {
    limit: MAX_NUMBER_OF_DOCS_TO_COMPARE,
    closeMinimized: closeMinimizedDoc,
    errors: {
      single: {
        title: ErrorMessages.DocumentNotExistsCompareTitle,
        reason: ErrorMessages.DocumentNotExistsCompare,
      },
      multiple: {
        title: ErrorMessages.DocumentsNotExistCompareTitle,
        reason: ErrorMessages.DocumentsNotExistCompare,
      },
    },
  },
  [MINIMIZED_DATA.MINIMIZED_CLAUSES]: {
    limit: MAX_NUMBER_OF_CLAUSES_TO_COMPARE,
    closeMinimized: closeMinimizedClause,
    errors: {
      single: {
        title: ErrorMessages.ClauseNotExistsCompareTitle,
        reason: ErrorMessages.ClauseNotExistsCompare,
      },
      multiple: {
        title: ErrorMessages.ClausesNotExistCompareTitle,
        reason: ErrorMessages.ClausesNotExistCompare,
      },
    },
  },
};

export const useCompareDocs = (dataType: MINIMIZED_DATA, itemsToCompare: MinimizedData[]) => {
  const dispatch = useAppDispatch();
  const [fetchDocsToCompare, setFetchDocsToCompare] = useState(false);

  const compareDocsIds = itemsToCompare.map(({ dataId }) => dataId);

  const queryParameters: DocumentsListParams = { DocumentId: compareDocsIds };

  const {
    data: documents,
    isSuccess,
    isFetching,
  } = useGetDocumentsListQuery(queryParameters, {
    skip: !fetchDocsToCompare || dataType !== MINIMIZED_DATA.MINIMIZED_DOCS,
  });

  const {
    data: clauses,
    isSuccess: isSuccessClauses,
    isFetching: isFetchingClauses,
  } = useGetClausesListQuery(
    { ClauseId: compareDocsIds },
    {
      skip: !fetchDocsToCompare || dataType !== MINIMIZED_DATA.MINIMIZED_CLAUSES,
    }
  );

  const [mergedData, mergedIsSuccess, mergedIsFetching] = useMemo(
    () =>
      dataType === MINIMIZED_DATA.MINIMIZED_DOCS
        ? [documents, isSuccess, isFetching]
        : [clauses, isSuccessClauses, isFetchingClauses],
    [clauses, dataType, documents, isFetching, isFetchingClauses, isSuccess, isSuccessClauses]
  );

  const { limit, closeMinimized, errors } = config[dataType];

  useEffect(() => {
    const dataToCompare: DocumentResponse[] | ClauseResponse[] = isDocumentListResponse(mergedData)
      ? mergedData.Documents.reduce<DocumentResponse[]>((previousValue, currentValue) => {
          previousValue[compareDocsIds.indexOf(currentValue.DocumentId)] = currentValue;
          return previousValue;
        }, [])
      : mergedData?.Clauses
      ? mergedData.Clauses.reduce<ClauseResponse[]>((previousValue, currentValue) => {
          previousValue[compareDocsIds.indexOf(currentValue.ClauseId)] = currentValue;
          return previousValue;
        }, [])
      : [];
    const notExistingItems = getNotExistingDocs(compareDocsIds, dataToCompare);
    const dataSuccessfullyFetched = mergedIsSuccess && !mergedIsFetching && fetchDocsToCompare;

    if (dataSuccessfullyFetched) {
      if (notExistingItems.length === 0) {
        dispatch(openCompareModal({ documents: dataToCompare }));
      } else {
        dispatch(showModal(notExistingItems.length === 1 ? errors.single : errors.multiple));
        dispatch(closeMinimized(notExistingItems));
      }
      setFetchDocsToCompare(false);
    }
  }, [
    mergedIsSuccess,
    mergedIsFetching,
    mergedData,
    dispatch,
    fetchDocsToCompare,
    compareDocsIds,
    dataType,
    errors.single,
    errors.multiple,
    closeMinimized,
  ]);

  const disabled =
    itemsToCompare.length < MIN_NUMBER_OF_ITEMS_TO_COMPARE || itemsToCompare.length > limit;

  return { setFetchDocsToCompare, isFetching: mergedIsFetching, disabled, limit };
};
