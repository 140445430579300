import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { DocumentContextMenuItemProps } from './DocumentContextMenu.types';
import { useAppDispatch } from '../../../store/hooks';
import { clearSelectedText } from '../../../store/files/documents/documents.slice';
import { isClauseResponse } from '../../../store/files/documentsAndClauses/list.helpers';
import {
  closeClauseViewModal,
  openClauseMetadataEditModal,
} from '../../../store/files/clauses/clauses.slice';

export const DocumentContextMenuClauseViewEdit = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const dispatch = useAppDispatch();

  const clause = isClauseResponse(document) ? document : undefined;

  const handleMenuItemClick = () => {
    if (clause) {
      dispatch(openClauseMetadataEditModal({ clause }));
      dispatch(clearSelectedText());
      dispatch(closeClauseViewModal());
    }
    onClose();
  };

  return (
    <MenuItem disabled={disabled || !clause} onClick={handleMenuItemClick}>
      <EditOutlinedIcon />
      Edit
    </MenuItem>
  );
};
