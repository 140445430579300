import { Paper } from '@mui/material';
import React, { useState } from 'react';
import { ManageTagsList } from './ManageTagsList';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { ButtonWithIcon } from '../../StaticComponents/ButtonWithIcon';
import { EditTagModal } from './EditTagModal';
import { Tag } from '../../../store/files/tags/tags.types';

export const ManageTags = () => {
  const [selected, setSelected] = useState<Tag>();

  return (
    <>
      <Paper elevation={0} sx={{ minWidth: 600, borderRadius: 4, marginBottom: '30px' }}>
        <div className='admin-panel__manage-users-groups'>
          <div className='admin-panel__manage-users-groups-filters'>
            <br />
            <br />
          </div>

          <div className='manage-users-groups-bulk-operations'>
            <ButtonWithIcon
              startIcon={<ControlPointIcon fontSize='large' />}
              onClick={() => {
                setSelected({} as Tag);
              }}
            >
              Add tag
            </ButtonWithIcon>
          </div>
        </div>
      </Paper>

      <ManageTagsList setSelected={setSelected} />

      <EditTagModal onClose={() => setSelected(undefined)} tag={selected} />
    </>
  );
};
