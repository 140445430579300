import { FieldValues, UseFormReturn } from 'react-hook-form';
import { ClauseMetadata, ClauseMetadataFields } from '../../UploadClause/UploadClause.types';
import {
  ClauseInheritedMetadataResponse,
  ClauseResponse,
} from '../../../store/files/clauses/clauses.list.types';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';

export type FormMethods<FormData extends FieldValues> = Pick<
  UseFormReturn<FormData>,
  'control' | 'watch' | 'trigger' | 'setValue' | 'getFieldState' | 'getValues'
>;

export interface ClauseMetadataFormProps {
  formMethods: FormMethods<ClauseMetadata>;
  disableFromMetadataEdit?: boolean;
  apiErrorMessage?: string | undefined;
  inputNameError?: string | undefined;
  clause?: ClauseResponse;
  baseDocumentForClauseCreation?: DocumentResponse;
  onUpdateEntities?: (nonEditableEntities: MetadataDictionaryValue[]) => void;
  inheritedMetadata?: ClauseInheritedMetadataResponse;
  inheritedMetadataIsLoading?: boolean;
  disableTags?: boolean;
  adjustedClause?: ClauseResponse;
  draft?: boolean;
}

export type MetadataFieldName =
  | ClauseMetadataFields.Entities
  | ClauseMetadataFields.Countries
  | ClauseMetadataFields.Lobs
  | ClauseMetadataFields.Lops
  | ClauseMetadataFields.BusinessTypes;

export const selectableGroups = ['Selectable', 'Non Selectable'];
export const SELECT_ALL = 'Select/Unselect All';
export const SELECT_ALL_GERMAN_COMPANIES = 'Select all German companies';

export interface MenuItemMetadataDictionaryValue extends MetadataDictionaryValue {
  group?: string;
  isNonEditable?: boolean;
  label2?: string;
}
