import React from 'react';
import { getDocumentStatusByValue } from '../../store/files/documents/documents.list.helpers';
import './StatusLabel.scss';
import { StatusLabelProps } from './StatusLabel.types';

export const StatusLabel = ({ status }: StatusLabelProps) => {
  const statusKey = getDocumentStatusByValue(status);
  const className = `label-${statusKey.toLowerCase()} label-status`;
  return (
    <div className={className}>
      <span>{statusKey}</span>
    </div>
  );
};
