import { useEffect } from 'react';
import { updateStatuses } from '../../store/ui/ui.slice';
import { useAppDispatch } from '../../store/hooks';

export const useClearSelectedAndErrorDocs = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    return () => {
      dispatch(updateStatuses({}));
    };
  }, [dispatch]);
};
