import React, { useState } from 'react';
import './Bookmarks.scss';
import BookmarksList from './BookmarksList';
import BookmarksHeader from './BookmarksHeader';
import BookmarksSearch from './BookmarksSearch';
import { BookmarkCreate } from './BookmarksCreate';
import { useReadAccess } from '../../contexts/UserAccess';

export const Bookmarks = () => {
  useReadAccess();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const [showSearchbar, setShowSearchbar] = useState(false);
  const handleToggleSearchbar = () => setShowSearchbar(!showSearchbar);
  const [searchQuery, setSearchQuery] = useState('');

  return (
    <div className='bookmarks'>
      <BookmarksHeader
        handleOpen={handleOpen}
        handleToggleSearchbar={handleToggleSearchbar}
        showSearchbar={showSearchbar}
      />
      <BookmarksSearch
        openSearch={showSearchbar}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
      />
      <BookmarksList handleOpen={handleOpen} searchQuery={searchQuery} />
      <BookmarkCreate open={open} handleClose={() => setOpen(false)} />
    </div>
  );
};
