import React, { ReactNode } from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useConfirmationModal } from '../Modals/ConfirmationModal/ConfirmationModal.hook';
import { DeleteConfirmModal } from '../Modals/ConfirmationModal/DeleteConfirmModal';
import { DELETE_MODAL_SUBMIT_BUTTON_LABEL } from '../Modals/ConfirmationModal/ConfirmationModal.messages';

interface GridContextMenuDeleteProps {
  title: string;
  description: string;
  onClose: () => void;
  handleDeleteConfirm: () => void;
  icon: ReactNode;
  label?: string;
  disabled?: boolean;
  successButtonLabel?: string;
}

export const GridContextMenuDelete: React.FC<GridContextMenuDeleteProps> = ({
  onClose,
  title,
  description,
  handleDeleteConfirm,
  icon,
  label = DELETE_MODAL_SUBMIT_BUTTON_LABEL,
  disabled,
  successButtonLabel,
}) => {
  const { open, handleOpen, handleClose } = useConfirmationModal();
  const className = label === DELETE_MODAL_SUBMIT_BUTTON_LABEL ? 'documents-list-menu-delete' : '';

  return (
    <>
      <MenuItem className={className} onClick={handleOpen} disabled={disabled}>
        {icon}
        {label}
      </MenuItem>

      <DeleteConfirmModal
        open={open}
        onClose={handleClose(onClose)}
        onConfirm={handleDeleteConfirm}
        title={title}
        message={description}
        successButtonLabel={successButtonLabel}
      />
    </>
  );
};
