import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { DocumentContextMenuItemProps } from './DocumentContextMenu.types';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Backdrop, CircularProgress } from '@mui/material';
import common from '../../../assets/common.module.scss';
import { useDocumentDownload } from './DocumentContextMenuDownload.hooks';
import { DOWNLOAD_LABEL } from '../../../config/config';

export const DocumentContextMenuDownload = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const { handleClick, inProgress } = useDocumentDownload(document, onClose);

  return (
    <>
      <MenuItem disabled={disabled} onClick={handleClick}>
        <FileDownloadOutlinedIcon />
        {DOWNLOAD_LABEL}
      </MenuItem>
      <Backdrop sx={{ color: common.white }} open={inProgress}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};
