import { useEffect, useState } from 'react';
import {
  LanguageToggleSwitchHookReturn,
  LanguageToggleSwitchTypes,
} from './LanguageToggleSwitch.types';
import { OriginalLanguage } from '../../../store/files/documents/documents.list.types';

export const useLanguageToggleSwitch = (
  documentId?: string,
  pdf?: boolean,
  keyword?: string,
  original?: OriginalLanguage
): LanguageToggleSwitchHookReturn => {
  const [language, setLanguage] = useState<LanguageToggleSwitchTypes>(
    LanguageToggleSwitchTypes.PDF
  );

  useEffect(() => {
    if (keyword && original === OriginalLanguage.ORIGINAL) {
      setLanguage(LanguageToggleSwitchTypes.Original);
    } else if (keyword && original !== OriginalLanguage.ORIGINAL) {
      setLanguage(LanguageToggleSwitchTypes.English);
    } else if (documentId && pdf) {
      setLanguage(LanguageToggleSwitchTypes.PDF);
    } else if (documentId && !pdf) {
      setLanguage(LanguageToggleSwitchTypes.Original);
    }
  }, [documentId, keyword, original, pdf]);

  return [language, setLanguage, pdf];
};
