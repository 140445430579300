import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ExportReportRequest,
  ExportReportResponse,
  Report,
  GetReportResponse,
  GenerateReportRequest,
} from './reports.types';
import {
  REPORTS_LIST_ENDPOINT,
  REPORT_ENDPOINT,
  REPORT_ID_ENDPOINT,
  GENERATE_REPORT_ENDPOINT,
} from '../../../services/api.service.config';
import { appendAuthorizationHeader } from '../../../components/Auth/auth.helpers';
import { mapReportsRequest, mapToCreateReportDto } from './reports.helpers';
import { UpdateNameProps } from '../../../components/SharedComponents/ContextMenu/ContextMenuEditName.types';

export enum REPORTS_API_TAGS {
  LIST = 'ReportsList',
}

export const reportsApi = createApi({
  reducerPath: 'reportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: REPORTS_LIST_ENDPOINT,
    prepareHeaders: appendAuthorizationHeader,
  }),
  tagTypes: [REPORTS_API_TAGS.LIST],
  endpoints: (builder) => ({
    getReports: builder.query<Report[], void>({
      query: () => '',
      providesTags: [REPORTS_API_TAGS.LIST],
      keepUnusedDataFor: 43200,
    }),
    getReport: builder.query<GetReportResponse, string>({
      query: (id) => ({
        url: REPORT_ID_ENDPOINT.replace('{id}', id),
        method: 'GET',
      }),
    }),
    exportReport: builder.mutation<ExportReportResponse, ExportReportRequest>({
      query: (body) => {
        return {
          url: REPORT_ENDPOINT,
          method: 'POST',
          body: mapToCreateReportDto(mapReportsRequest(body)),
        };
      },
      transformResponse: (response: ExportReportResponse) => response,
    }),
    deleteReport: builder.mutation<void, string>({
      query: (id) => ({
        url: REPORT_ID_ENDPOINT.replace('{id}', id),
        method: 'DELETE',
      }),
      invalidatesTags: [REPORTS_API_TAGS.LIST],
    }),
    updateReport: builder.mutation<void, UpdateNameProps>({
      query: ({ id, name }) => {
        return {
          url: REPORT_ID_ENDPOINT.replace('{id}', id),
          method: 'PATCH',
          body: {
            ReportName: name,
          },
        };
      },
      invalidatesTags: (res, err) => (err ? [] : [REPORTS_API_TAGS.LIST]),
    }),
    generateReport: builder.mutation<void, GenerateReportRequest>({
      query: (body) => {
        return {
          url: GENERATE_REPORT_ENDPOINT,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: (res, err) => (err ? [] : [REPORTS_API_TAGS.LIST]),
    }),
  }),
});

export const {
  useGetReportsQuery,
  useGetReportQuery,
  useExportReportMutation,
  useDeleteReportMutation,
  useUpdateReportMutation,
  useGenerateReportMutation,
} = reportsApi;
