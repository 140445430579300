import Grid from '@mui/material/Grid';
import {
  formatUiDateFromString,
  formatUiDateTimeFromString,
  getDocumentName,
} from '../../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { DOCUMENT_LABELS, DOCUMENT_TYPES } from '../../../config/config';
import { BOOLEAN_VALUES } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { getDictionaryLabelByValue } from '../../../store/files/upload/list.helpers';
import { ElementCheckedIcon, renderItemOrListOfItems } from './MetadataPanel.helper';
import { MetadataPanelProps } from './MetadataPanel.types';
import { ReportedField } from '../../SharedComponents/ReportedField/ReportedField';
import { ParentDocumentsMetadata } from './ParentDocumentsMetadata/ParentDocumentsMetadata';
import './MetadataPanel.scss';

export const MetadataPanel = ({ dictionaries, document }: MetadataPanelProps) => (
  <div className='metadata-view'>
    <div className='metadata-view-block'>
      <ReportedField item={document} dictionaries={dictionaries} />
      <Grid container rowSpacing={2}>
        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.COMPANY_NAME}:</div>
        </Grid>
        <Grid item xs={8}>
          {getDictionaryLabelByValue(dictionaries?.Entity, document?.Entity)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.COUNTRY}:</div>
        </Grid>
        <Grid item xs={8}>
          {renderItemOrListOfItems(dictionaries?.Country, document?.Country, true)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.BUSINESS_LINE}:</div>
        </Grid>
        <Grid item xs={8}>
          {getDictionaryLabelByValue(dictionaries?.Lob, document?.Lob)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.PRODUCT_LINE}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{getDictionaryLabelByValue(dictionaries?.Lop, document?.Lop)}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.PRODUCT_TYPE}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{document?.ProductType}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.INSURED_INDUSTRY}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{document?.InsurerIndustry}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.BUSINESS_TYPE}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{getDictionaryLabelByValue(dictionaries?.BusinessType, document?.BusinessType)}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.DOCUMENT_TYPE}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{getDictionaryLabelByValue(dictionaries?.DocumentType, document?.DocumentType)}</div>
        </Grid>

        {document?.DocumentType !== DOCUMENT_TYPES.STANDARD_GENERAL_CONDITION ? (
          <>
            <Grid item xs={4}>
              <div className='bold'>{DOCUMENT_LABELS.PARENT_DOCUMENTS}:</div>
            </Grid>
            <Grid item xs={8}>
              <ParentDocumentsMetadata document={document} />
            </Grid>
          </>
        ) : null}

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.CLASSIFICATION}:</div>
        </Grid>
        <Grid item xs={8}>
          {getDictionaryLabelByValue(dictionaries?.Classification, document?.Classification)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.LANGUAGE}:</div>
        </Grid>
        <Grid item xs={8}>
          {getDictionaryLabelByValue(dictionaries?.Language, document?.Language)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>Validity:</div>
        </Grid>

        <Grid item xs={8}>
          <div>
            <small>from</small> {formatUiDateFromString(document?.ValidFrom)}{' '}
            {document?.ValidTo && <small>to</small>} {formatUiDateFromString(document?.ValidTo)}
          </div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.EXPIRE_AT}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{formatUiDateFromString(document?.ExpireAt)}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.LATEST_VERSION}:</div>
        </Grid>
        <Grid item xs={8} className='metadata-view-block-icon-container'>
          <ElementCheckedIcon checked={document?.LatestVersion === BOOLEAN_VALUES.TRUE} />
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.ACTIVE}:</div>
        </Grid>
        <Grid item xs={8} className='metadata-view-block-icon-container'>
          <ElementCheckedIcon checked={document?.Active === BOOLEAN_VALUES.TRUE} />
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.DOCUMENT_NAME}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{getDocumentName(document)}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.DOCUMENT_ID}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{document?.DocumentId}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.UPDATED_BY}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{document?.UpdatedBy}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.UPDATED_AT}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{formatUiDateTimeFromString(document?.UpdatedAt)}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.CREATED_BY}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{document?.CreatedBy}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.CREATED_AT}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{formatUiDateTimeFromString(document?.CreatedAt)}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.REMARKS}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{document?.Remarks}</div>
        </Grid>
      </Grid>
    </div>
  </div>
);
