import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import { GridColDef } from '@mui/x-data-grid-pro';
import { ButtonWithIcon } from '../../StaticComponents/ButtonWithIcon';
import { SavedSearchInfoButton } from './SavedSearchInfoButton';
import { SavedSearchDeleteButton } from './SavedSearchDeleteButton';
import {
  ClauseDictionariesResponse,
  DictionariesResponse,
} from '../../../store/files/upload/list.service.types';
import { SavedSearchCellParams } from './SavedSearches.types';
import { useAppDispatch } from '../../../store/hooks';
import { openSavedSearchEditModal } from '../../../store/user/savedsearch.slice';
import { SavedSearch, SavedSearchTypeValues } from '../../../store/user/savedsearch.types';
import Chip from '@mui/material/Chip';
import { TEST_ID } from '../../../config/test-fields-ids.config';

export const getColumns = (
  dictionaries?: DictionariesResponse,
  dictionariesClauses?: ClauseDictionariesResponse
): GridColDef[] => [
  {
    field: 'searchName',
    headerName: '',
    flex: 19,
    sortable: false,
    renderCell: ({ row: { Name, Filters, SavedSearchType } }: SavedSearchCellParams) => {
      return (
        <div
          data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_INFO_BUTTON}
          className='search-name-column'
        >
          <div className='search-name-column-text'>
            {Name} <Chip size='small' label={SavedSearchType} />
          </div>
          <SavedSearchInfoButton
            title={Name}
            filters={Filters}
            dictionaries={dictionaries}
            dictionariesClauses={dictionariesClauses}
            clauseMode={SavedSearchType === SavedSearchTypeValues.CLAUSE}
          />
        </div>
      );
    },
  },
  {
    field: 'edit',
    headerName: '',
    flex: 4,
    sortable: false,
    renderCell: function ButtonCell({ row }: SavedSearchCellParams) {
      const dispatch = useAppDispatch();

      return (
        <ButtonWithIcon
          startIcon={
            <NoteAltOutlinedIcon
              data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_EDIT}
              fontSize='large'
            />
          }
          onClick={() => dispatch(openSavedSearchEditModal(row))}
        >
          Edit search
        </ButtonWithIcon>
      );
    },
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    sortable: false,
    renderCell: function ButtonCell({ row: { SavedSearchId } }: SavedSearchCellParams) {
      return <SavedSearchDeleteButton id={SavedSearchId} />;
    },
  },
];

export const filterSavedSearches = (data?: SavedSearch[], phrase?: string) =>
  data ? (phrase ? data.filter(({ Name }) => Name?.includes(phrase)) : data) : [];

export const getSavedSearchTypes = (
  hasDocumentReadAccess: boolean,
  hasClauseReadAccess: boolean
) => {
  let types: SavedSearchTypeValues[] = [];
  if (hasDocumentReadAccess) types.push(SavedSearchTypeValues.DOCUMENT);
  if (hasClauseReadAccess) types.push(SavedSearchTypeValues.CLAUSE);
  return types;
};
