import { UploadedFileRequest } from '../../store/files/upload/list.service.types';

export interface UploadedFile extends UploadedFileRequest {
  id: string;
  action?: UploadedFileAction;
  fileType?: string;
  edit?: boolean;
  key?: string | null;
  progress?: number;
  Status: UploadedFileStatus;
  ScanningResult?: string;
}

export enum UploadedFileStatus {
  MISSING_DATA = 'Missing data',
  UPLOAD_COMPLETE = 'Ready to submit',
  UPLOAD_FAILED = 'Upload failed',
  UPLOADING = 'Uploading',
  DELETING = 'Deleting',
  SCANNING = 'Sent to Malware Scanner',
  ERROR = 'Error',
  INFECTED = 'Infected',
  SUBMITTING = 'Submitting',
}

export enum UploadedFileAction {
  STOP = 'stop',
  DELETE = 'delete',
  NONE = 'None',
}
