import { forwardRef, InputHTMLAttributes } from 'react';
import { FILE_NAME_LENGTH, prepareFileName, sanitizeFileName } from './FileNameInput.helpers';
import { FILE_EXTENSIONS } from '../../../store/ui/ui.types';

export const FileNameInput = forwardRef<
  HTMLInputElement,
  InputHTMLAttributes<HTMLInputElement> & { fileExtension?: FILE_EXTENSIONS }
>(({ value, onChange, fileExtension, ...props }, ref) => {
  const extension = fileExtension || FILE_EXTENSIONS.PDF;
  return (
    <input
      {...props}
      ref={ref}
      maxLength={FILE_NAME_LENGTH(extension)}
      value={typeof value === 'string' ? prepareFileName(value, extension) : value}
      onChange={({ target, ...event }) =>
        onChange &&
        onChange({
          ...event,
          target: { ...target, value: sanitizeFileName(target.value, extension) },
        })
      }
    />
  );
});
