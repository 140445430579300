import { useCallback } from 'react';
import './DocumentsListGridScroll.scss';

const CUSTOM_SCROLLER_CLASS = 'documents-list-top-scroll-wrapper';

const appendCustomScroller = (node: Element, width: string) => {
  const customScroller = document.createElement('div');
  customScroller.className = CUSTOM_SCROLLER_CLASS;

  const inner = document.createElement('div');
  inner.style.width = width;
  customScroller.append(inner);

  node.after(customScroller);

  if (customScroller.offsetHeight - customScroller.clientHeight === 0) {
    customScroller.classList.add('hidden-scroll');
  }
};

const addScrollListeners = (grid: HTMLDivElement) => {
  const virtualScroller = grid.getElementsByClassName('MuiDataGrid-virtualScroller')[0];
  const customScroller = grid.getElementsByClassName(CUSTOM_SCROLLER_CLASS)[0];

  const handleVirtualScroller = () => {
    customScroller.scrollLeft = virtualScroller.scrollLeft;
  };
  const handleCustomScroller = () => {
    virtualScroller.scrollLeft = customScroller.scrollLeft;
  };

  virtualScroller.addEventListener('scroll', handleVirtualScroller);
  customScroller.addEventListener('scroll', handleCustomScroller);
};

const attachScrollerAndListeners = (grid: HTMLDivElement) => {
  const headers = grid.getElementsByClassName('MuiDataGrid-columnHeaders')[0];
  const content = grid.getElementsByClassName('MuiDataGrid-virtualScrollerContent')[0];

  if (content instanceof HTMLElement) {
    appendCustomScroller(headers, content.style.width);
    addScrollListeners(grid);
  }
};

export const useDocumentsListGridScroll = () => {
  return useCallback((grid: HTMLDivElement | null) => {
    if (!grid) return;

    setTimeout(() => {
      attachScrollerAndListeners(grid);
    });
  }, []);
};
