import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Bookmark } from './bookmarks.types';

const selectBookmarks = (state: RootState) => state.files.bookmarks;

const selectBookmarksModal = createSelector(
  selectBookmarks,
  (bookmark): [Bookmark | undefined, string | undefined] => {
    return [bookmark.bookmarkModal, bookmark.currentBookmarkId];
  }
);

const selectDocumentsForBookmarkListQueryParams = createSelector(
  selectBookmarks,
  (items) => items.listQueryParams.documents
);
const selectClausesForBookmarkListQueryParams = createSelector(
  selectBookmarks,
  (items) => items.listQueryParams.clauses
);

export const bookmarksSelectors = {
  selectBookmarksModal,
  selectDocumentsForBookmarkListQueryParams,
  selectClausesForBookmarkListQueryParams,
};
