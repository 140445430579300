import sanitize from 'sanitize-filename';
import { FILE_EXTENSIONS } from '../../../store/ui/ui.types';

export const FILE_NAME_LENGTH = (fileExtension: string) => 255 - fileExtension.length;

export const sanitizeFileName = (name?: string, extension = FILE_EXTENSIONS.PDF) =>
  name ? sanitize(name) + extension : '';

export const prepareFileName = (name?: string, extension = FILE_EXTENSIONS.PDF) =>
  name ? name.slice(0, extension.length * -1) : '';
