import { Bookmark } from '../../store/files/bookmarks/bookmarks.types';

export const isDocument = (item: Bookmark | undefined) =>
  item?.hasOwnProperty('DocumentId') ? true : false;

export const mapToAddItemBody = (selectedBookmark: string, item: Bookmark | undefined) => {
  return {
    BookmarkId: selectedBookmark,
    ItemsToAdd: [
      {
        ItemType: isDocument(item) ? 'document' : 'clause',
        ItemId: item?.DocumentId || item?.ClauseId,
      },
    ],
  };
};
