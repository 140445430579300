import React, { useState } from 'react';
import './ReportsTabSection.scss';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ReportSectionProps } from './ReportsTabSection.types';
import TextField from '@mui/material/TextField';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { DATE_FIELD_CONFIG, DATE_RANGE_PICKER_LABELS } from '../../config/config';
import { useGenerateReportMutation } from '../../store/files/reports/reports.service';
import LoadingButton from '@mui/lab/LoadingButton';
import { ExportConfirmationModal } from '../Documents/Export/ExportConfirmationModal/ExportConfirmationModal';
import { mapToGenerateReportRequest } from '../../store/files/reports/reports.helpers';

export const ReportsTabSection: React.FC<ReportSectionProps> = ({
  title,
  type,
  description,
  loading,
}) => {
  const [value, setValue] = useState<DateRange<Date>>([null, null]);
  const [open, setOpen] = useState(false);
  const [generate, { isLoading, isError }] = useGenerateReportMutation();
  const disabled = (value[0] && value[1] && value[0] > value[1]) || false;

  const handleGenerate = async () => {
    try {
      await generate(mapToGenerateReportRequest(type, value)).unwrap();
      setValue([null, null]);
    } finally {
      setOpen(true);
    }
  };

  return (
    <div className='report-section'>
      <div className='title'>{title}</div>
      <div className='description'>{description}</div>
      <div className='actions'>
        <div>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            localeText={{ start: DATE_RANGE_PICKER_LABELS.FROM, end: DATE_RANGE_PICKER_LABELS.TO }}
          >
            <DateRangePicker
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              {...DATE_FIELD_CONFIG}
              renderInput={(startProps: TextFieldProps, endProps: TextFieldProps) => (
                <>
                  <TextField {...startProps} name={`${type}_startDate`} size='small' />
                  <span className='splitter'> to </span>
                  <TextField {...endProps} name={`${type}_endDate`} size='small' />
                </>
              )}
            />
          </LocalizationProvider>
        </div>
        <div>
          <LoadingButton
            className='button-with-icon'
            startIcon={<FileDownloadOutlinedIcon />}
            disabled={disabled}
            onClick={handleGenerate}
            loading={isLoading || loading}
          >
            Generate Report
          </LoadingButton>
        </div>
      </div>

      <ExportConfirmationModal isError={isError} open={open} handleClose={() => setOpen(false)} />
    </div>
  );
};
