import { RootState } from '../store';
import { createSelector } from '@reduxjs/toolkit';
import { ZOOM_UNLOCKED } from '../../config/config';

const selectUIData = (state: RootState) => state.ui;

const selectErrorModal = (state: RootState) => selectUIData(state).errorModal;

const selectShowLoader = (state: RootState) => selectUIData(state).showLoader;

const selectUploadFilesPopup = (state: RootState) => selectUIData(state).uploadFilesPopup;

const selectUploadedDocuments = (state: RootState) => selectUIData(state).uploadedDocuments;

const selectSelectedItem = (state: RootState) => selectUploadedDocuments(state).selectedDocs;

const selectDocumentsSelectedRelatedClauses = (state: RootState) =>
  selectUploadedDocuments(state).documentsRelatedClausesIds || [];

const selectIsDraftDocuments = (state: RootState) =>
  selectUploadedDocuments(state).isDraftDocuments || false;

const selectUpdateStatuses = (state: RootState) => selectUploadedDocuments(state).updateStatuses;

const selectUploadedFiles = (state: RootState) => selectUIData(state).uploadedFiles;

const selectMinimizedDocs = (state: RootState) => selectUIData(state).minimizedDocs;

const selectMinimizedClauses = (state: RootState) => selectUIData(state).minimizedClauses;

const selectDocsToCompare = (state: RootState) =>
  selectUIData(state).minimizedDocs.filter(({ comparable }) => comparable);

const selectClauseToCompare = (state: RootState) =>
  selectUIData(state).minimizedClauses.filter(({ comparable }) => comparable);

const selectAuthError = (state: RootState) => selectUIData(state).authError;

const selectUnsavedChanges = (state: RootState) => selectUploadedFiles(state).unsavedChanges;

const selectModalCompare = createSelector(selectUIData, (items) => items.compareModal);

const selectCompareClauseReferences = createSelector(
  selectUIData,
  (items) => items.compareClauseReferences
);

const selectCompareDocumentReferences = createSelector(
  selectUIData,
  (items) => items.compareDocumentReferences
);
const selectCompareMode = createSelector(selectUIData, (items) => items.compareOptions.compareMode);
const selectLockLanguage = createSelector(
  selectUIData,
  (items) => items.compareOptions.isLanguageLocked
);
const selectLockedLanguage = createSelector(
  selectUIData,
  (items) => items.compareOptions.lockedLanguage
);

const selectZoomLock = createSelector(selectUIData, (items) => items.compareOptions.zoomLock);
const selectZoomLocked = createSelector(
  selectUIData,
  (items) => items.compareOptions.zoomLock > ZOOM_UNLOCKED
);

const selectReportedReasonModal = (state: RootState) => selectUIData(state).reportedReasonModal;

const selectTextSearchHighlightDetails = createSelector(
  selectUIData,
  (ui) => ui.textSearchHighlightDetails
);

const selectScrollToClauseInDoc = (state: RootState) => selectUIData(state).scrollToClauseInDoc;

const selectDraftMode = (state: RootState) => selectUIData(state).draftMode;

export const uiSelectors = {
  selectDocsToCompare,
  selectUpdateStatuses,
  selectErrorModal,
  selectMinimizedDocs,
  selectSelectedItem,
  selectShowLoader,
  selectUIData,
  selectAuthError,
  selectUnsavedChanges,
  selectUploadFilesPopup,
  selectClauseToCompare,
  selectMinimizedClauses,
  selectModalCompare,
  selectReportedReasonModal,
  selectCompareClauseReferences,
  selectCompareMode,
  selectLockLanguage,
  selectLockedLanguage,
  selectZoomLock,
  selectZoomLocked,
  selectCompareDocumentReferences,
  selectTextSearchHighlightDetails,
  selectDocumentsSelectedRelatedClauses,
  selectIsDraftDocuments,
  selectScrollToClauseInDoc,
  selectDraftMode,
};
