import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitResult } from '../../../store/files/upload/submit.service.types';
import { UploadConfirmationModal } from '../../StaticComponents/Modals/UploadConfirmationModal/UploadConfirmationModal';
import { getRoutePath, RoutePath } from '../../../services/route.service';
import { UploadSubmitClausesProps } from './UploadSubmitClauses.types';

export const UploadSubmitClauses: React.FC<UploadSubmitClausesProps> = ({
  open,
  setOpen,
  isError,
  error,
  draftMode,
}) => {
  const navigate = useNavigate();

  const onClose = () => {
    const path = draftMode
      ? getRoutePath(RoutePath.SANDBOX_CLAUSES, RoutePath.UPLOADED_CLAUSES_PENDING)
      : getRoutePath(RoutePath.UPLOADED_CLAUSES, RoutePath.UPLOADED_CLAUSES_PENDING);
    setOpen(false);
    !isError && navigate(path);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const result: SubmitResult = {
    success: isError ? 0 : 1,
    error: isError ? 1 : 0,
    errorsId: [],
    duplicates: 0,
    duplicatesId: [],
  };
  const renderDuplicateItems = [<></>];
  const renderErrorItems = [<>- {error}</>];

  return (
    <UploadConfirmationModal
      open={open}
      onClose={onClose}
      result={result}
      renderDuplicateItems={renderDuplicateItems}
      renderErrorItems={renderErrorItems}
      mode={draftMode ? 'draft_clause' : 'clause'}
    />
  );
};
