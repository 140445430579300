import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { ExportConfirmationModalProps } from './ExportConfirmationModal.types';
import { MarsModal } from '../../../StaticComponents/Modals/MarsModal';

import './ExportConfirmationModal.scss';
import { TAB_NAMES } from '../../../../config/config';

export const ExportConfirmationModal = ({
  open,
  handleClose,
  isError,
}: ExportConfirmationModalProps) => (
  <MarsModal open={open} onClose={handleClose} buttonLabel='Close'>
    <div className='export-confirmation-modal'>
      {!isError ? (
        <TaskAltIcon className='success-icon' />
      ) : (
        <ErrorOutlineOutlinedIcon className='error-icon' />
      )}
      <div>
        {!isError
          ? `Export started successfully. You can find status of your file in the "${TAB_NAMES.MY_REPORTS}"`
          : 'Something went wrong. Try again later.'}
      </div>
    </div>
  </MarsModal>
);
