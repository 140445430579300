import { Paper, PaperProps } from '@mui/material';
import { DictionariesResponse } from '../../../store/files/upload/list.service.types';
import { SpreadSheetDocument } from './DocumentsGrid.types';
import { DocumentMetadataFields } from '../../UploadFiles/MetadataForm/MetadataForm.types';
import { getValidationRules } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';

export const CustomPaper = (props: PaperProps) => {
  return <Paper {...props} sx={{ minWidth: 'max-content' }} />;
};

export const getLopOptions = (
  dictionaries: DictionariesResponse,
  lobValue: SpreadSheetDocument['Lob'],
  entityValue: SpreadSheetDocument['Entity']
) => {
  let lops: string[] = [];
  if (lobValue) {
    lops = getValidationRules(
      dictionaries.Entity,
      entityValue,
      DocumentMetadataFields.Lob,
      lobValue,
      DocumentMetadataFields.Lop
    );
  }
  if (!lops.length) {
    lops = dictionaries.Lob.values.find(({ value }) => value === lobValue)?.related?.Lop ?? [];
  }
  return lops.length ? dictionaries.Lop.values.filter(({ value }) => lops.includes(value)) : [];
};
