import { FormControlLabel, Switch } from '@mui/material';
import './SwitchWithLabel.scss';
import React from 'react';
import { ComponentWithLabelProps as SwitchWithLabelProps } from './ComponentWithLabel.types';
import { FormNotice } from '../UploadFiles/MetadataForm/MetadataFormHelpers';

export const SwitchWithLabel: React.FC<SwitchWithLabelProps> = React.forwardRef(
  ({ checked, onChange, label, labelPlacement, tooltipMessage = '', ...props }, ref) => (
    <div className='switch-with-label'>
      <FormControlLabel
        ref={ref}
        labelPlacement={labelPlacement}
        control={<Switch {...props} checked={checked} size='medium' onChange={onChange} />}
        label={label}
      />
      {tooltipMessage && <FormNotice tooltipMessage={tooltipMessage} />}
    </div>
  )
);
