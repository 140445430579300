import TaskAltIcon from '@mui/icons-material/TaskAlt';
import PendingOutlinedIcon from '@mui/icons-material/PendingOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import { ReportsStatusProps } from './ReportStatus.types';
import './ReportStatus.scss';

const ReportStatus = ({ mode, label }: ReportsStatusProps) => {
  return (
    <div className={`report-status ${mode}`}>
      {mode === 'done' && <TaskAltIcon />}
      {mode === 'pending' && <PendingOutlinedIcon />}
      {mode === 'failed' && <ErrorOutlineOutlinedIcon />}
      <div className='pending-status__label'>{label}</div>
    </div>
  );
};

export default ReportStatus;
