import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SavedSearch } from './savedsearch.types';

export interface SavedSearchState {
  editModal?: SavedSearch;
}

const initialState: SavedSearchState = {
  editModal: undefined,
};

export const savedSearchSlice = createSlice({
  name: 'savedSearch',
  initialState,
  reducers: {
    openSavedSearchEditModal: (state: SavedSearchState, action: PayloadAction<SavedSearch>) => {
      state.editModal = action.payload;
    },
    closeSavedSearchEditModal: (state: SavedSearchState) => {
      state.editModal = undefined;
    },
  },
});

export const { openSavedSearchEditModal, closeSavedSearchEditModal } = savedSearchSlice.actions;

export default savedSearchSlice.reducer;
