import { useUpdateDocumentsMetadataMutation } from '../../store/files/documents/documents.list.service';
import { DOCS_UPDATE_METADATA_CACHE_KEY } from '../../store/files/documents/documents.list.types';
import { DocumentListDataFetchingHook } from '../Documents/Documents.types';

export function useAchEditingDisabled(useGetDataListWithParams: DocumentListDataFetchingHook) {
  const { isFetching } = useGetDataListWithParams();
  const [, { isLoading }] = useUpdateDocumentsMetadataMutation({
    fixedCacheKey: DOCS_UPDATE_METADATA_CACHE_KEY,
  });

  return isFetching || isLoading;
}
