import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useAppSelector } from '../../store/hooks';
import { calculateProgress } from './UploadProgress.helper';
import { uploadSelectors } from '../../store/files/upload/upload.selectors';
import { MarsSnackbar } from '../StaticComponents/MarsSnackbar/MarsSnackbar';

export const UploadProgress = () => {
  const docs = useAppSelector(uploadSelectors.selectDocsList);
  const uploading = useAppSelector(uploadSelectors.selectFilesUploading).map(
    ({ progress }) => progress || 0
  );
  const overallProgress = calculateProgress(uploading);

  return (
    <MarsSnackbar
      open={!!overallProgress}
      message={
        <>
          <CircularProgress variant='determinate' value={overallProgress} size={16} />
          <strong>Uploading files... {overallProgress}%</strong>
          <small>
            ({docs.length - uploading.length}/{docs.length})
          </small>
        </>
      }
    />
  );
};
