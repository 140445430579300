import { GroupNameFilterLabelFunction, GroupNameFilterProps } from './GroupNameFilter.types';

const isOptionAllSelected = (value?: string | string[]) =>
  Array.isArray(value) && value.indexOf('') > -1;

export const getValueOrEmptyArray = (value: GroupNameFilterProps['value']) =>
  isOptionAllSelected(value) ? [] : value;

const getLabelByValue: (values: GroupNameFilterProps['values']) => GroupNameFilterLabelFunction =
  (values) => (value) =>
    values?.find((v) => v.GroupName === value)?.GroupName || '';

const getLabelForMultipleValues = (labelFn: GroupNameFilterLabelFunction) => (value: string[]) =>
  value.map(labelFn).join(', ');

export const prepareLabel = (
  multiple: GroupNameFilterProps['multiple'],
  values: GroupNameFilterProps['values'],
  selected: GroupNameFilterProps['value']
) => {
  const labelFn = getLabelByValue(values);
  return multiple
    ? getLabelForMultipleValues(labelFn)(selected as string[])
    : labelFn(selected as string);
};
