import {
  useCompareItemForVersion,
  useMinimizeItemForVersion,
} from '../../../StaticComponents/Modals/MinimizeItem/useMinimizeItem';
import { openNewDocumentViewModal } from '../DocumentRelatedClauses/ClauseRelatedDocuments.hooks';

export const useDocumentTabItemsHook = () => {
  const { handleMinimize, checkIfItemMinimized, checkIfMinimizeDisabled, handleClose } =
    useMinimizeItemForVersion({});
  const { handleCompare, checkIfItemCompared } = useCompareItemForVersion();

  const toggleMinimize = (DocumentId: string, FileName: string) => {
    if (checkIfItemMinimized(DocumentId)) {
      handleClose(DocumentId);
    } else {
      handleMinimize(DocumentId, FileName);
    }
  };

  const toggleCompare = (DocumentId: string, FileName: string) => {
    if (checkIfItemMinimized(DocumentId)) {
      handleClose(DocumentId);
    } else {
      handleCompare(DocumentId, FileName);
    }
  };

  return {
    toggleMinimize,
    toggleCompare,
    checkIfItemMinimized,
    checkIfItemCompared,
    checkIfMinimizeDisabled,
    handleClose,
    openNewDocumentViewModal,
  };
};
