import React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useConfirmationModal } from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.hook';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import {
  DELETE_MODAL_MESSAGE,
  DELETE_MODAL_TITLE,
  SAVED_SEARCH,
} from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import { useDeleteSavedSearchMutation } from '../../../store/user/savedsearch.service';
import { useSnackbar } from 'notistack';
import { ErrorMessages } from '../../../services/errors.service.types';
import { Messages } from '../../../services/messages.service.types';
import { DeleteConfirmModal } from '../../StaticComponents/Modals/ConfirmationModal/DeleteConfirmModal';
import { showLoader } from '../../../store/ui/ui.slice';
import { useAppDispatch } from '../../../store/hooks';

const title = DELETE_MODAL_TITLE(SAVED_SEARCH);
const message = DELETE_MODAL_MESSAGE(SAVED_SEARCH);

export const SavedSearchDeleteButton = ({ id }: { id: string }) => {
  const dispatch = useAppDispatch();
  const { open, handleOpen, handleClose } = useConfirmationModal();
  const [remove, { isLoading }] = useDeleteSavedSearchMutation();
  const { enqueueSnackbar } = useSnackbar();

  const handleDeleteConfirm = () => {
    dispatch(showLoader(true));
    remove(id)
      .unwrap()
      .then(() => {
        enqueueSnackbar(Messages.SavedSearchRemovedSuccess);
      })
      .catch(() => enqueueSnackbar(ErrorMessages.DeleteSavedSearchError, { variant: 'error' }))
      .finally(() => dispatch(showLoader(false)));
  };

  return (
    <>
      <IconButton onClick={handleOpen} disabled={isLoading}>
        <DeleteOutlineOutlinedIcon
          data-test-id={TEST_ID.UPLOAD_FILES.DELETE_UPLOADED_DOCUMENT}
          fontSize='small'
        />
      </IconButton>
      <DeleteConfirmModal
        open={open}
        onClose={handleClose()}
        onConfirm={handleDeleteConfirm}
        disabled={isLoading}
        title={title}
        message={message}
      />
    </>
  );
};
