import { hasWriteAccessToDoc } from './DocumentViewModal.helpers';
import {
  DocumentResponse,
  DocumentStatus,
} from '../../../store/files/documents/documents.list.types';
import {
  useGetClauseDictionariesQuery,
  useGetDictionariesQuery,
} from '../../../store/files/upload/list.service';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { useMemo } from 'react';
import { useGetClausesListQuery } from '../../../store/files/clauses/clauses.list.service';
import { useUserAccessContext } from '../../../contexts/UserAccess';

export enum SEMI_AUTO_CLAUSE_CREATE_TOOLTIPS {
  INSUFFICIENT_RIGHTS = 'You do not have the required rights to create a clause from this document due to its company or classification.',
  DOCUMENT_REPORTED = 'You cannot create a clause from a document with the status "Reported".',
  SELECT_TEXT = 'To create a clause from this document, select the document text.',
}

const useCanCreateDocumentItem = (
  document: DocumentResponse | undefined,
  documentDictionary?: boolean
) => {
  const queryHook = documentDictionary ? useGetDictionariesQuery : useGetClauseDictionariesQuery;

  const { data: writeAccessDictionaries } = queryHook(
    {
      AccessMode: AccessMode.WRITE,
    },
    { skip: !document }
  );

  return document && writeAccessDictionaries
    ? hasWriteAccessToDoc(document.Entity, document.Classification, writeAccessDictionaries)
    : false;
};

export const useCanCreateSemiAutoClause = (document: DocumentResponse | undefined) => {
  return useCanCreateDocumentItem(document);
};

export const useCanCreateNewDocumentVersion = (document: DocumentResponse | undefined) => {
  return useCanCreateDocumentItem(document, true);
};

export const useGetRelatedClauses = (document: DocumentResponse | undefined, skip = false) => {
  const { hasClauseReadAccess } = useUserAccessContext();

  const canGetRelatedClauses = useMemo(
    () => hasClauseReadAccess && !!document?.RelatedClauses.length,
    [hasClauseReadAccess, document?.RelatedClauses.length]
  );

  const { data: clausesData } = useGetClausesListQuery(
    { ClauseId: document?.RelatedClauses, Status: [DocumentStatus.READY] },
    { skip: !canGetRelatedClauses || skip }
  );

  return useMemo(
    () => (canGetRelatedClauses && clausesData?.Clauses.length ? clausesData.Clauses : []),
    [canGetRelatedClauses, clausesData?.Clauses]
  );
};
