import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { SavedSearch } from './savedsearch.types';

const selectSavedSearch = (state: RootState) => state.savedSearch;

const selectSavedSearchEditModal = createSelector(
  selectSavedSearch,
  (savedSearch): SavedSearch | undefined => savedSearch.editModal
);

export const savedSearchSelectors = {
  selectSavedSearchEditModal,
};
