import { ReactNode } from 'react';

export interface StatusProgressProps {
  filesExist: boolean;
  numberOfFilesUploading?: number;
  numberOfUploadingDocs?: number;
  isLoading: boolean;
  dataChangedCount: number;
  errorMessage: string;
  saved: ReactNode;
  onSave: () => void;
  missingFields?: boolean;
  isSnackbarEnabled?: boolean;
}

export const ALL_CHANGES_SAVED = 'All changes saved';
