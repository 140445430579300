import { useDispatch } from 'react-redux';
import { clearSelectedText, setSelectedText } from '../../../store/files/documents/documents.slice';

const transformText = (selectionObj: Selection | null) => {
  return selectionObj?.toString().replaceAll('-\n', '').replaceAll('\n', ' ');
};

export const useTextSelectionDispatch = () => {
  const dispatch = useDispatch();

  const handleDispatchTextSelection = (selectionObj: Selection | null) => {
    const text = transformText(selectionObj);

    if (text) {
      dispatch(setSelectedText(text));
    } else {
      dispatch(clearSelectedText());
    }
  };

  return { handleTextSelectionDispatch: handleDispatchTextSelection };
};

export const copyEventListener = (window: Window) => (event: ClipboardEvent) => {
  const selectionObj = window.getSelection();
  const transformedText = transformText(selectionObj);
  if (event.clipboardData && transformedText) {
    event.clipboardData.setData('text/plain', transformedText);
    event.preventDefault();
  }
};
