import { MinimizedData, MINIMIZED_DATA, ITEM_TYPE } from './ui.types';
import { useMemo } from 'react';
import { matchPath } from 'react-router-dom';
import { isClauseResponse, isDocumentResponse } from '../files/documentsAndClauses/list.helpers';
import { closeDocumentViewModal } from '../files/documents/documents.slice';
import { closeMinimizedClause, closeMinimizedDoc } from './ui.actions';
import { closeClauseViewModal } from '../files/clauses/clauses.slice';
import { AppDispatch } from '../store';
import { DocumentResponse } from '../files/documents/documents.list.types';
import { ClauseResponse } from '../files/clauses/clauses.list.types';
import { useState, useCallback } from 'react';

export const getMinimizedDocs = () =>
  JSON.parse(localStorage.getItem(MINIMIZED_DATA.MINIMIZED_DOCS) || '[]');
export const getMinimizedClauses = () =>
  JSON.parse(localStorage.getItem(MINIMIZED_DATA.MINIMIZED_CLAUSES) || '[]');

export const setComparableFlagToExistingOne = (
  currentMinimizedDocs: MinimizedData[],
  minimizedDocToCompare: MinimizedData,
  comparable: boolean
) => {
  return currentMinimizedDocs.map((minimizedDoc) => {
    if (minimizedDoc.dataId === minimizedDocToCompare.dataId) {
      return {
        ...minimizedDoc,
        comparable,
      };
    }
    return minimizedDoc;
  });
};

export const useIndexOfCompare = (data: MinimizedData[]) =>
  useMemo(() => (dataId: string) => data.findIndex((data) => data.dataId === dataId) + 1, [data]);

export const getFilteredPages = (allowedPages: string[], pathname: string) => {
  return allowedPages.filter((p) => matchPath(p, pathname)).length > 0;
};

export function handleCloseItemViewModal(
  dispatch: AppDispatch,
  item: DocumentResponse | ClauseResponse | undefined
): void {
  if (isDocumentResponse(item)) {
    dispatch(closeDocumentViewModal());
    if (item) {
      dispatch(closeMinimizedDoc([item.DocumentId]));
    }
  } else if (isClauseResponse(item)) {
    dispatch(closeClauseViewModal());
    if (item) {
      dispatch(closeMinimizedClause([item.ClauseId]));
    }
  }
}

export function formatItemType(itemType: ITEM_TYPE): string {
  return ` ${itemType.toLowerCase()}`;
}

export const useApiError = () => {
  const [apiError, setApiError] = useState<string>();

  const clearApiError = useCallback(() => {
    setApiError(undefined);
  }, []);

  return { apiError, setApiError, clearApiError };
};
