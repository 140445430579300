import { useState } from 'react';
import './BookmarksList.scss';
import '../Documents/Documents.scss';

import { Paper } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { NUMBER_OF_ITEMS_ALLOWED, INIT_NUMBER_OF_DOCUMENTS } from '../../config/config';

import { NoItemsFound } from '../StaticComponents/NoItemsFound/NoItemsFound';
import { useGetBookmarksQuery } from '../../store/files/bookmarks/bookmarks.service';
import { getColumns } from './BookmarksList.helpers';

import { paginationOptions } from '../StaticComponents/CustomPagination/CustomPagination';
import { BookmarksListProps } from './BookmarksList.types';

import { Box, CircularProgress } from '@mui/material';
import { useContextMenu } from '../Documents/DocumentContextMenu/DocumentContextMenu.hooks';
import { Bookmark } from '../../store/files/bookmarks/bookmarks.types';
import { BookmarksContextMenu } from './BookmarksContextMenu';

const BookmarksList = ({ handleOpen, searchQuery }: BookmarksListProps) => {
  const { data, isLoading, isFetching } = useGetBookmarksQuery();
  const { control, handleContextMenu } = useContextMenu<Bookmark>();

  const [pageSize, setPageSize] = useState<number>(INIT_NUMBER_OF_DOCUMENTS);

  const filteredBookmarks = data?.filter((bookmark) => {
    return bookmark.Name!.toLowerCase().includes(searchQuery.toLowerCase());
  });

  if (isLoading || isFetching) {
    return (
      <Box sx={{ textAlign: 'center', padding: '30px' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <div>
      <Paper className={`bookmarks-list ${filteredBookmarks?.length ? '' : 'empty'}`}>
        {filteredBookmarks?.length ? (
          <div>
            <DataGridPro
              sortModel={[{ field: 'UpdatedAt', sort: 'desc' }]}
              className='documents-list-grid bookmarks-list-grid'
              headerHeight={32}
              autoHeight={true}
              getRowId={(row) => row.BookmarkId}
              rowsPerPageOptions={NUMBER_OF_ITEMS_ALLOWED}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rows={filteredBookmarks ?? []}
              columns={getColumns(handleContextMenu)}
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              pagination
              componentsProps={{
                pagination: paginationOptions(),
              }}
            />
            <BookmarksContextMenu
              control={control}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            />
          </div>
        ) : (
          <>
            <NoItemsFound
              handleClick={handleOpen}
              icon='folderIconBookmarks'
              subtitle='Your bookmarks will be stored here.'
            />
          </>
        )}
      </Paper>
    </div>
  );
};

export default BookmarksList;
