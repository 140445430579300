import { UploadedFile, UploadedFileStatus } from './UploadedFileList.types';
import { listApi } from '../../store/files/upload/list.service';

export const showDeleteAndEditButtons = (Status: string) =>
  Status !== UploadedFileStatus.DELETING &&
  Status !== UploadedFileStatus.UPLOAD_FAILED &&
  Status !== UploadedFileStatus.SUBMITTING;

export const getDocsByStatus = (compareToStatus: UploadedFileStatus, data?: UploadedFile[]) =>
  data?.filter(({ Status }) => Status === compareToStatus) || [];

export const showStatus = (DocumentId: string, Status: UploadedFileStatus) =>
  listApi.util.updateQueryData('getList', undefined, (draftDocs) => {
    const document = draftDocs.find((post) => post.DocumentId === DocumentId);
    if (document) {
      document.Status = Status;
    }
  });
