import { DataGridPro, GridEventListener, GridSlotsComponentsProps } from '@mui/x-data-grid-pro';
import { ManageUsersGroupsColumns } from './ManageUsersGroups.helpers';
import { AdminPanelUser } from '../../../store/adminPanel/adminPanel.types';
import { NUMBER_OF_ITEMS_ALLOWED } from '../../../config/config';
import { NoItemsFound } from '../../StaticComponents/NoItemsFound/NoItemsFound';
import { Paper } from '@mui/material';
import React, { Dispatch, useState } from 'react';
import { paginationOptions } from '../../StaticComponents/CustomPagination/CustomPagination';
import { EditUsersGroupsModal } from './EditUsersGroupsModal';
import { useGetMetadataQuery } from '../../../store/adminPanel/adminPanel.service';

export const ManageUsersGroupsList: React.FC<{
  filteredUsers: AdminPanelUser[] | undefined;
  setSelectedUsers: Dispatch<string[]>;
}> = ({ filteredUsers, setSelectedUsers }) => {
  const [pageSize, setPageSize] = useState<number>(100);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<AdminPanelUser | undefined>();
  const { data: metadata } = useGetMetadataQuery();

  const componentsCustomProps: GridSlotsComponentsProps = {
    pagination: paginationOptions(),
  };

  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    setSelectedUser(params.row);
    setOpenEdit(true);
  };

  return (
    <Paper elevation={0} sx={{ minWidth: 600, borderRadius: 4, minHeight: '50vh' }}>
      {filteredUsers?.length && metadata ? (
        <div style={{ width: '100%' }}>
          <DataGridPro
            autoHeight={true}
            checkboxSelection
            disableSelectionOnClick
            className='documents-list-grid'
            columns={ManageUsersGroupsColumns(metadata)}
            componentsProps={componentsCustomProps}
            disableColumnMenu
            getRowId={(row: AdminPanelUser) => row.Email}
            onPageSizeChange={setPageSize}
            onRowClick={handleRowClick}
            onSelectionModelChange={(UsersIds) => {
              setSelectedUsers(UsersIds as string[]);
            }}
            pageSize={pageSize}
            pagination
            rows={filteredUsers ?? []}
            rowsPerPageOptions={NUMBER_OF_ITEMS_ALLOWED}
          />
        </div>
      ) : (
        <div style={{ padding: '0 0 239px 0' }}>
          <NoItemsFound icon='usersIcon' />
        </div>
      )}
      {openEdit && selectedUser && (
        <EditUsersGroupsModal
          open={openEdit}
          onClose={() => setOpenEdit(false)}
          selectedUser={selectedUser}
          metadata={metadata}
        />
      )}
    </Paper>
  );
};
