import Grid from '@mui/material/Grid';
import { SearchOptions } from '../SearchOptions';
import { AdvancedOptions } from '../AdvancedOptions';
import React from 'react';
import { SequentialSearchHookReturn } from '../SequentialSearchFields/SequentialSearchFields.hook';
import { SearchOptionsProps } from '../SearchOptions.types';
import './SequentialSearchOptions.scss';

export const SequentialSearchOptions = ({
  params: { selectedSearch, sequentialSearches },
  control,
  clauseMode,
  prefix,
}: {
  params: SequentialSearchHookReturn;
} & Pick<SearchOptionsProps, 'control' | 'clauseMode' | 'prefix'>) => {
  return (
    <>
      {sequentialSearches.map((value, index) => (
        <div
          key={index}
          className={`sequential-search-options-wrapper ${
            selectedSearch !== index ? 'sequential-search-options-wrapper--hidden' : ''
          }`}
        >
          <Grid item sx={{ width: 198 }} className='sequential-search-options'>
            <SearchOptions control={control} index={index} prefix={prefix} />
          </Grid>

          <Grid item sx={{ width: 280 }} className='sequential-search-options'>
            <AdvancedOptions
              control={control}
              clauseMode={clauseMode}
              index={index}
              prefix={prefix}
            />
          </Grid>
        </div>
      ))}
    </>
  );
};
