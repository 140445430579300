import {
  Document,
  DocumentMetadataUpdateRequest,
  DocumentResponse,
} from '../../../store/files/documents/documents.list.types';
import {
  DocumentUpdateDescriptions,
  DocumentUpdateStatuses,
  SpreadSheetDocument,
} from './DocumentsGrid.types';
import { map } from 'lodash';
import { mapStatusToIds } from '../../../store/files/documents/documents.update.success.helpers';

export const mapToDocumentMetadataUpdateRequestDto = (
  documents: SpreadSheetDocument[] | DocumentResponse[]
): DocumentMetadataUpdateRequest => {
  return documents.map(({ Status, DocumentName, ...doc }) => doc);
};

const nonErrorDescriptions = [
  DocumentUpdateDescriptions.SuccessfullyUpdated.toString(),
  DocumentUpdateDescriptions.UnsavedChanges.toString(),
];

export const getErrorRowClass = (
  statuses: DocumentUpdateStatuses,
  documentId: string,
  className: string
) =>
  statuses[documentId] && !nonErrorDescriptions.includes(statuses[documentId]) ? className : '';

export const getDocumentIds = (docs: DocumentMetadataUpdateRequest): Document['DocumentId'][] =>
  map(docs, 'DocumentId');

export const mapChangesToUpdateStatuses = (documentIds: Document['DocumentId'][]) =>
  mapStatusToIds(documentIds, DocumentUpdateDescriptions.UnsavedChanges);

export const missingFields = (dataChanged: SpreadSheetDocument[]) =>
  dataChanged.filter(({ Lop }) => !Lop).length > 0;
