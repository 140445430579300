import React from 'react';
import { TermsAndConditions } from './TermsAndConditions';
import { MarsModal } from '../MarsModal';
import { TermsAndConditionsModalProps } from './TermsAndConditionsModal.types';
import Typography from '@mui/material/Typography';

export const TermsAndConditionsModal: React.FC<TermsAndConditionsModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <MarsModal
      buttonLabel='Close'
      open={open}
      onClose={onClose}
      className='terms-and-conditions-modal'
    >
      <Typography variant='subtitle1' align='center'>
        Privacy Notice for ERGO Group <br /> Global Wording Repository
      </Typography>
      <TermsAndConditions />
    </MarsModal>
  );
};
