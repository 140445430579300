import React from 'react';
import './UploadButton.scss';
import { ButtonGroup } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { UPLOAD_BUTTON, UploadButtonDraftModeProps } from './UploadButton.types';
import { UploadButton } from './UploadButton';
import { DropDownButton } from './DropDownButton';
import { useAppDispatch } from '../../../store/hooks';
import { draftMode } from '../../../store/ui/ui.slice';
import useUploadButtonLogic from './UploadButton.hooks';

export const UploadButtonWithDraftMode: React.FC<UploadButtonDraftModeProps> = ({ onClick }) => {
  const methods = useForm();
  const dispatch = useAppDispatch();
  const { isDraftModeSelected, showDropDownButton } = useUploadButtonLogic();

  const onDropDownClick = (value: string) => {
    dispatch(draftMode(value === UPLOAD_BUTTON.UPLOAD_DRAFTS));
  };

  return (
    <FormProvider {...methods}>
      <ButtonGroup>
        <UploadButton
          onClick={onClick}
          adjustStyleForDropdown={showDropDownButton}
          draftModeSelected={isDraftModeSelected}
        />
        {showDropDownButton && (
          <DropDownButton onChange={onDropDownClick} draftModeSelected={isDraftModeSelected} />
        )}
      </ButtonGroup>
    </FormProvider>
  );
};
