import React, { useCallback, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import { showStatus } from '../UploadedFileList.helper';
import { UploadedFileStatus } from '../UploadedFileList.types';
import SubmitService from '../../../store/files/upload/submit.service';
import { SubmitUploadButtonProps } from './SubmitUploadButton.types';
import { useAppDispatch } from '../../../store/hooks';
import { useLazyGetListQuery } from '../../../store/files/upload/list.service';
import { ErrorMessages } from '../../../services/errors.service.types';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { closeModal } from '../../../store/files/upload/upload.slice';
import { formatChangedFieldsMessage, useAsyncConfirm } from '../MetadataForm/MetadataForm.hooks';
import { SubmitConfirmModal } from '../../StaticComponents/Modals/ConfirmationModal/SubmitConfirmModal';

const SubmitUploadButton: React.FC<SubmitUploadButtonProps> = ({
  disabled,
  toSubmit,
  setResultForFinalConfirmModal,
  setOpenFinalConfirmModal,
  beforeSubmit,
  onLoadingChange,
  changedFields,
}) => {
  const addNewVersionMode = !!beforeSubmit;
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const [trigger] = useLazyGetListQuery();
  const { openConfirm, open, handleConfirm, handleClose } = useAsyncConfirm();

  const onSubmitUpload = useCallback(async () => {
    setLoading(true);
    toSubmit.forEach((doc) => dispatch(showStatus(doc, UploadedFileStatus.SUBMITTING)));
    setResultForFinalConfirmModal(await SubmitService.submitDocuments(toSubmit));
    setLoading(false);
    setOpenFinalConfirmModal(true);
  }, [dispatch, setOpenFinalConfirmModal, setResultForFinalConfirmModal, toSubmit]);

  const onSubmitNewVersionUpload = useCallback(async () => {
    setLoading(true);
    onLoadingChange && onLoadingChange(true);
    if (beforeSubmit) {
      await beforeSubmit();
    }
    if (!!changedFields?.length) {
      const userConfirmed = await openConfirm();
      if (!userConfirmed) {
        setLoading(false);
        onLoadingChange && onLoadingChange(false);
        return;
      }
    }
    await timeoutPromise(1500);
    const { data: prelandingDocs } = await trigger();
    const updatedDoc = prelandingDocs?.find((doc) => doc.DocumentId === toSubmit[0]);
    if (updatedDoc?.Status !== UploadedFileStatus.UPLOAD_COMPLETE) {
      dispatch(closeModal());
      throw new Error(ErrorMessages.DocumentIsNotReadyToSubmitError);
    }
    await onSubmitUpload();
    onLoadingChange && onLoadingChange(false);
  }, [
    beforeSubmit,
    dispatch,
    changedFields,
    onLoadingChange,
    onSubmitUpload,
    openConfirm,
    toSubmit,
    trigger,
  ]);

  return (
    <>
      <LoadingButton
        variant='contained'
        size='small'
        disabled={disabled}
        loading={loading}
        onClick={addNewVersionMode ? onSubmitNewVersionUpload : onSubmitUpload}
      >
        Submit upload
      </LoadingButton>
      {changedFields && (
        <SubmitConfirmModal
          open={open}
          onClose={handleClose}
          onConfirm={handleConfirm}
          message={formatChangedFieldsMessage(changedFields)}
          title='Metadata Changes Detected'
        />
      )}
    </>
  );
};

export default SubmitUploadButton;
