import React from 'react';
import { formatUiDateFromString } from '../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { Bookmark } from '../../store/files/bookmarks/bookmarks.types';
import { RoutePath } from '../../services/route.service';
import { ReactComponent as FolderIcon } from '../../assets/icons/folder.svg';
import { ReactComponent as PeopleIcon } from '../../assets/icons/people.svg';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Link } from 'react-router-dom';
import { DocumentContextMenuButton } from '../Documents/DocumentContextMenu/DocumentContextMenuButton';
import { ContextMenuHandler } from '../Documents/DocumentContextMenu/DocumentContextMenu.types';

export const getColumns = (handleMenuButtonClick: ContextMenuHandler<Bookmark>): GridColDef[] => [
  {
    field: 'Name',
    headerName: 'FOLDER NAME',
    flex: 19,
    sortable: false,
    renderCell: ({ row: { Name, BookmarkId } }) => {
      const sharingDisabled = true;
      return (
        <div className='folder-name-column'>
          <FolderIcon />
          <Link to={`${RoutePath.BOOKMARKS}/${BookmarkId}`} className='folder-name-column__text'>
            {Name}
          </Link>
          {sharingDisabled ? null : <PeopleIcon />}
        </div>
      );
    },
  },
  {
    field: 'UpdatedAt',
    headerName: 'LAST EDIT',
    flex: 4,
    sortable: false,
    renderCell: function ButtonCell({
      row: { UpdatedAt },
    }: GridRenderCellParams<Bookmark['UpdatedAt']>) {
      return <div>{formatUiDateFromString(UpdatedAt)}</div>;
    },
  },
  {
    field: 'action',
    headerName: '',
    flex: 1,
    sortable: false,
    renderCell: ({ row }: GridRenderCellParams) => (
      <DocumentContextMenuButton
        onClick={(event: React.MouseEvent<HTMLElement>) => handleMenuButtonClick(event, row)}
      />
    ),
  },
];
