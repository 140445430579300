// @ts-nocheck

import { diff_match_patch, Diff } from 'diff-match-patch';

declare module 'diff-match-patch' {
  interface diff_match_patch {
    diff_wordMode(text1: string, text2: string): Diff[];
    diff_wordsToChars_(
      text1: string,
      text2: string
    ): { chars1: string; chars2: string; lineArray: string[] };
  }
}

const tokenize = (text: string) => {
  return text.match(/(?:[A-Za-zА-Яа-я0-9À-ž]+|[^A-Za-zА-Яа-я0-9À-ž])/g) || [];
};

diff_match_patch.prototype.diff_wordMode = function (text1: string, text2: string) {
  const { chars1, chars2, lineArray } = this.diff_wordsToChars_(text1, text2);
  const diffs = this.diff_main(chars1, chars2, false);

  // if there is any problem with a way it is matching words Diff_EditCost and diff_cleanupEfficiency could be swtiched to just:
  // this.diff_cleanupSemantic(diffs);
  this.Diff_EditCost = 5;
  this.diff_cleanupEfficiency(diffs);
  this.diff_charsToLines_(diffs, lineArray);
  return diffs;
};

diff_match_patch.prototype.diff_wordsToChars_ = function (
  text1: string,
  text2: string
): { chars1: string; chars2: string; lineArray: string[] } {
  const wordArray: string[] = [];
  const wordHash: Record<string, number> = {};
  wordArray[0] = '';

  const diff_linesToWordsMunge_ = (text: string): string => {
    let chars = '';
    let wordArrayLength = wordArray.length;
    for (let word of tokenize(text)) {
      if (wordHash.hasOwnProperty(word)) {
        chars += String.fromCharCode(wordHash[word]);
      } else {
        chars += String.fromCharCode(wordArrayLength);
        wordHash[word] = wordArrayLength;
        wordArray[wordArrayLength++] = word;
      }
    }
    return chars;
  };

  const chars1 = diff_linesToWordsMunge_(text1);
  const chars2 = diff_linesToWordsMunge_(text2);
  return { chars1, chars2, lineArray: wordArray };
};
