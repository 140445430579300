import { useBlocker } from './Blocker.hook';
import { useEffect, useRef, useState } from 'react';
import { useConfirmationModal } from './ConfirmationModal.hook';

export type Retry = ({ retry }: { retry: () => void }) => void;

export function useCustomPageLeavePrompt(shouldPrompt: boolean) {
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const { open, handleOpen, handleClose } = useConfirmationModal();

  const retryFn = useRef(() => {});
  useEffect(() => {
    if (confirmedNavigation) {
      retryFn.current();
      setConfirmedNavigation(false);
    }
  }, [confirmedNavigation]);

  const setConfirmedNavigationState = () => {
    setConfirmedNavigation(true);
  };

  const handleBlockNavigation: Retry = ({ retry }) => {
    if (shouldPrompt) {
      handleOpen();
      retryFn.current = retry;
    } else {
      retry();
    }
  };

  useBlocker(handleBlockNavigation, shouldPrompt && !confirmedNavigation);
  return { handleBlockNavigation, setConfirmedNavigationState, open, handleClose };
}
