import { MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material';
import { useEffect, useState } from 'react';
import { SearchInputField } from '../UploadFiles/MetadataForm/MetadataFormHelpers';
import { useSearchBookmarkInput } from './BookmarkSearchInput';
import { BookmarkSelectProps } from './BookmarkSelect.types';

export const BookmarkSelect = ({
  selectedBookmark,
  setSelectedBookmark,
  bookmarkNames = [],
}: BookmarkSelectProps) => {
  const handleBookmarkSelect = (event: SelectChangeEvent<string>) => {
    setSelectedBookmark(event.target.value);
  };
  const { filteredValues, onChangeSearch } = useSearchBookmarkInput(bookmarkNames || []);
  const [lastBookmarkCreated, setLastBookmarkCreated] = useState(
    bookmarkNames.length ? bookmarkNames[0].BookmarkId : ''
  );
  useEffect(() => {
    if (bookmarkNames.length) {
      setLastBookmarkCreated(bookmarkNames[0].BookmarkId);
    }
  }, [bookmarkNames]);
  return (
    <Select
      className='bookmarks-modal-select bookmarks-input'
      ref={null}
      size='small'
      value={selectedBookmark || lastBookmarkCreated}
      onChange={handleBookmarkSelect}
      input={<OutlinedInput />}
      renderValue={(selected) => {
        const selectedBookmark = bookmarkNames?.find(({ BookmarkId }) => BookmarkId === selected);
        return selectedBookmark?.Name;
      }}
    >
      {bookmarkNames?.length && (
        <SearchInputField label='bookmarks' onChangeHandle={onChangeSearch} />
      )}
      {filteredValues?.length ? (
        filteredValues.map(({ Name, BookmarkId }, index) => (
          <MenuItem key={index} value={BookmarkId}>
            {Name}
          </MenuItem>
        ))
      ) : (
        <MenuItem disabled>You have not any bookmarks yet</MenuItem>
      )}
    </Select>
  );
};
