import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { DocumentContextMenuItemProps } from './DocumentContextMenu.types';
import { useDispatch } from 'react-redux';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { openModal } from '../../../store/files/upload/upload.slice';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';
import { useCanCreateNewDocumentVersion } from '../DocumentViewModal/DocumentViewModal.hooks';
import { ADD_PREVIOUS_VERSION_MODE } from '../DocumentViewModal/DocumentViewModal.types';

export const DocumentContextMenuAddNewVersion = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const dispatch = useDispatch();
  const canCreateNewVersion = useCanCreateNewDocumentVersion(document as DocumentResponse);
  const handleAddNewVersionClick = () => {
    if (document) {
      dispatch(
        openModal({ id: ADD_PREVIOUS_VERSION_MODE, previousVersion: document as DocumentResponse })
      );
    }
    onClose();
  };
  return (
    <MenuItem disabled={disabled || !canCreateNewVersion} onClick={handleAddNewVersionClick}>
      <FileUploadOutlinedIcon />
      Add new version
    </MenuItem>
  );
};
