export enum ConfigStrings {
  CHECK_NOMENCLATURE_LINK_TEXT = 'Check clause nomenclature',
  REQUIRED_IN_CLAUSE_NOMENCLATURE = 'Required in clause nomenclature',
  TOPIC = 'Topic',
  EXAMPLE_INTRO = 'E.g., ',
  OTHER = 'Other',
  ADDITIONS_HEADER = 'Additions (choose relevant ones for that clause)',
  EXCL_WRITEBACK = 'Excl. / Writeback',
  SOURCE_OTHER_THAN_ERGO = 'Source (if other than ERGO)',
  YEAR = 'Year',
  GEOGRAPHIC_SCOPE_OF_APPLICATION = 'Geographic scope of application',
  LOB_LOP = 'LoB / LoP',
  EXAMPLE_OF_CLAUSE_NAME = 'Example of clause name',
  REQUIRED = 'Required',
  SANCTION_CLAUSE = 'Sanction Clause',
  ADDITIONAL = 'Additional',
  INCL_EXCL_WRITEBACK = 'Incl. / Excl. / Writeback',
  SANCTION_LIMITATION_EXCLUSION_CLAUSE = 'Sanction Limitation and Exclusion Clause_LMA3100',
  SOURCE = 'Source',
  SANCTION_CLAUSE_AON_BASED = 'Sanction Clause_Aon_based on LMA3100',
  SANCTION_CLAUSE_GDV_2020 = 'Sanction Clause_GDV_2020',
  SANCTION_CLAUSE_US_SANCTIONS_ONLY = 'Sanction Clause_US sanctions only',
  WAR_RISKS_INCLUSION_CLAUSE_MARINE = 'War risks_inclusion clause_marine',
}

export const ADDITION_CATEGORIES: string[] = [
  ConfigStrings.EXCL_WRITEBACK,
  ConfigStrings.SOURCE_OTHER_THAN_ERGO,
  ConfigStrings.YEAR,
  ConfigStrings.GEOGRAPHIC_SCOPE_OF_APPLICATION,
  ConfigStrings.LOB_LOP,
];
