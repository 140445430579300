import { ButtonWithIcon } from '../../StaticComponents/ButtonWithIcon';
import React, { Dispatch, useState } from 'react';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { BulkEditGroupsModal } from './BulkEditGroupsModal';
import { UsersGroupsOperation } from '../../../store/adminPanel/adminPanel.types';

export const ManageUsersGroupsBulkOperations: React.FC<{
  selectedUsers: string[];
  setSelectedUsers: Dispatch<string[]>;
}> = ({ selectedUsers, setSelectedUsers }) => {
  const [openBulkEdit, setOpenBulkEdit] = useState<UsersGroupsOperation | undefined>();
  const areUsersSelected = selectedUsers?.length > 1;

  return (
    <>
      <ButtonWithIcon
        startIcon={<ControlPointIcon fontSize='large' />}
        disabled={!areUsersSelected}
        onClick={() => {
          setOpenBulkEdit(UsersGroupsOperation.ADD);
        }}
      >
        Bulk add
      </ButtonWithIcon>
      <ButtonWithIcon
        startIcon={<DeleteOutlineOutlinedIcon fontSize='large' />}
        disabled={!areUsersSelected}
        onClick={() => {
          setOpenBulkEdit(UsersGroupsOperation.DELETE);
        }}
      >
        Bulk delete
      </ButtonWithIcon>
      {openBulkEdit && (
        <BulkEditGroupsModal
          operation={openBulkEdit}
          onClose={() => {
            setOpenBulkEdit(undefined);
          }}
          selectedUsers={selectedUsers}
          setSelectedUsers={setSelectedUsers}
        />
      )}
    </>
  );
};
