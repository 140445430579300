import {
  DOCUMENT_CONTEXT_MENU_ITEM_NAME,
  ContextMenuItem,
} from '../Documents/DocumentContextMenu/DocumentContextMenu.types';

export const MenuItemsStatusDone: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT, disabled: false },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE, disabled: false },
];

export const MenuItemsStatusFailed: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: true },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT, disabled: true },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE, disabled: false },
];

export const MenuItemsStatusPending: ContextMenuItem[] = [
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD, disabled: true },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT, disabled: true },
  { name: DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE, disabled: true },
];
