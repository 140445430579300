import { MINIMIZED_DATA } from '../../../store/ui/ui.types';
import {
  MAX_NUMBER_OF_CLAUSES_TO_COMPARE,
  MAX_NUMBER_OF_CLAUSES_TO_MINIMIZE,
  MAX_NUMBER_OF_DOCS_TO_COMPARE,
  MAX_NUMBER_OF_MINIMIZE_DATA,
} from '../../../config/config';

const msg = {
  [MINIMIZED_DATA.MINIMIZED_DOCS]: {
    type: 'documents',
    minimize: MAX_NUMBER_OF_MINIMIZE_DATA,
    compare: MAX_NUMBER_OF_DOCS_TO_COMPARE,
  },
  [MINIMIZED_DATA.MINIMIZED_CLAUSES]: {
    type: 'clauses',
    minimize: MAX_NUMBER_OF_CLAUSES_TO_MINIMIZE,
    compare: MAX_NUMBER_OF_CLAUSES_TO_COMPARE,
  },
};

export function getDisableDocumentCompareMessage(dataType: MINIMIZED_DATA): string {
  const { compare, minimize, type } = msg[dataType];
  return `Either minimize bar (${minimize} ${type}) or comparison (${compare} ${type}) limits have been reached. Go to minimize bar at the bottom and change your selection before you proceed`;
}
