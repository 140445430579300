import { SpreadSheetDocument } from './DocumentsGrid.types';
import { UploadedFileRequest } from '../../../store/files/upload/list.service.types';

export const mapToUploadedFileRequestDto = (
  documents: SpreadSheetDocument[]
): UploadedFileRequest[] => {
  return documents.map(
    ({ UpdatedAt, Status, DocumentName, ...uploadedFileDocument }) => uploadedFileDocument
  );
};
