import React from 'react';
import { RoutePath } from '../../services/route.service';
import { Link } from 'react-router-dom';
import FolderIcon from '@mui/icons-material/FolderOutlined';
import ArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeftOutlined';
import { useSearchButtonHook } from '../MyFiles/SearchButton.helpers';
import { SearchButton } from '../MyFiles/SearchButton';
import { DocumentsSearch } from '../Documents/DocumentsSearch/DocumentsSearch';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import { DocumentsCount } from '../Documents/DocumentsCount';
import { BookmarkDetailsHeaderProps, DataSearchProps } from './BookmarkDetailsHeader.types';
import { useContextMenu } from '../Documents/DocumentContextMenu/DocumentContextMenu.hooks';
import { Bookmark } from '../../store/files/bookmarks/bookmarks.types';
import { BookmarksContextMenu } from './BookmarksContextMenu';
import { DocumentContextMenuButton } from '../Documents/DocumentContextMenu/DocumentContextMenuButton';

const DataSearch: React.FC<DataSearchProps> = ({
  useGetDataForBookmarkListWithParamsFn,
  showSearch,
  isDocumentsTab,
}) => {
  const { filters, setFilters } = useGetDataForBookmarkListWithParamsFn();
  return showSearch ? (
    <DocumentsSearch
      filters={filters}
      savedSearchHidden
      onApply={setFilters}
      accessMode={AccessMode.READ}
      clauseMode={!isDocumentsTab}
    />
  ) : null;
};

export const BookmarkDetailsHeader: React.FC<BookmarkDetailsHeaderProps> = ({
  selectedBookmark,
  isDocumentsTab,
  useGetDataListWithParams,
}) => {
  const searchButtonProps = useSearchButtonHook();
  const { showSearch } = searchButtonProps;
  const { control, handleContextMenu } = useContextMenu<Bookmark>();
  return (
    <>
      <div className='bookmark-details-header'>
        <div className='bookmark-details-header__left'>
          <Link className='bookmark-details-header__link' to={RoutePath.BOOKMARKS}>
            <ArrowLeftIcon />
          </Link>
          <FolderIcon className='bookmark-details-header__folder' />

          <span className='bookmark-details-header__title'>{selectedBookmark?.Name}</span>
          <DocumentContextMenuButton
            onClick={(event: React.MouseEvent<HTMLElement>) =>
              handleContextMenu(event, selectedBookmark!)
            }
          />
        </div>
        <SearchButton {...searchButtonProps} />
      </div>

      {searchButtonProps && (
        <div className='bookmark-details-header__search'>
          <DataSearch
            useGetDataForBookmarkListWithParamsFn={useGetDataListWithParams}
            showSearch={showSearch}
            isDocumentsTab={isDocumentsTab}
          />
        </div>
      )}
      <DocumentsCount
        useGetDataListWithParams={useGetDataListWithParams}
        clauseMode={!isDocumentsTab}
        saveSearchDisabled
      />
      <BookmarksContextMenu
        control={control}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      />
    </>
  );
};
