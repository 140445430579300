import {
  ClauseDictionariesResponse,
  DictionariesResponse,
} from '../../../store/files/upload/list.service.types';
import { SequentialSearch } from '../../../store/files/documentsAndClauses/list.types';
import { notEmptySearchQuery } from '../DocumentsSearch/DocumentsSearch.helpers';
import { ClauseMetadataFields } from '../../UploadClause/UploadClause.types';

import { Dispatch } from 'redux';
import {
  clearSelectedText,
  closeDocumentViewModal,
} from '../../../store/files/documents/documents.slice';
import { setScrollToClauseInDoc } from '../../../store/ui/ui.slice';
import { handleCloseItemViewModal } from '../../../store/ui/ui.helpers';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from '../../../services/route.service';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';

export const hasWriteAccessToDoc = (
  entity: string,
  classification: string,
  writeAccessDictionaries: ClauseDictionariesResponse | DictionariesResponse
): boolean => {
  const entitiesDictionary =
    ClauseMetadataFields.Entities in writeAccessDictionaries
      ? writeAccessDictionaries.Entities
      : writeAccessDictionaries.Entity;

  if (!entitiesDictionary) {
    return false;
  }

  const isEntityIncluded = entitiesDictionary.values.some((e) => e.value === entity);
  const entityClassifications = entitiesDictionary.values.find(({ value }) => value === entity)
    ?.related?.Classification;
  const isClassificationIncluded = entityClassifications?.some(
    (entityClassification) => entityClassification === classification
  );
  return isEntityIncluded && !!isClassificationIncluded;
};

export const getLastNotEmptyQuery = (queries?: SequentialSearch[]) => {
  const notEmptyQueries = queries?.filter(notEmptySearchQuery);
  return notEmptyQueries?.[notEmptyQueries.length - 1] || {};
};

const commonCloseDocumentViewModalLogic = (
  dispatch: Dispatch,
  id: string | undefined,
  navigate: ReturnType<typeof useNavigate>
) => {
  dispatch(clearSelectedText());
  dispatch(setScrollToClauseInDoc(undefined));
  if (id) {
    navigate(RoutePath.DOCUMENTS);
  }
};

export const handleCloseDocumentViewModal = (
  dispatch: Dispatch,
  document: DocumentResponse,
  id: string | undefined,
  navigate: ReturnType<typeof useNavigate>
) => {
  handleCloseItemViewModal(dispatch, document);
  commonCloseDocumentViewModalLogic(dispatch, id, navigate);
};

export const handleCloseDocumentViewModalOnly = (
  dispatch: Dispatch,
  id: string | undefined,
  navigate: ReturnType<typeof useNavigate>
) => {
  dispatch(closeDocumentViewModal());
  commonCloseDocumentViewModalLogic(dispatch, id, navigate);
};
