import React from 'react';
import { FileTypeIconProps } from './FileTypeIcon.types';
import other from '../../assets/icons/other.svg';
import docx from '../../assets/icons/docx.svg';
import pdf from '../../assets/icons/PDF.svg';

export const FileTypeIcon: React.FC<FileTypeIconProps> = ({ fileType }) => {
  let docTypeElement = <img src={other} alt={fileType} />;
  switch (fileType) {
    case 'pdf':
      docTypeElement = <img src={pdf} alt='pdf' />;
      break;
    case 'docx':
      docTypeElement = <img src={docx} alt='docx' />;
      break;
  }
  return docTypeElement;
};
