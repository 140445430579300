import React from 'react';
import { Tooltip } from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { DocumentUpdateStatusProps } from './DocumentUpdateStatus.types';
import { DocumentUpdateDescriptions } from './DocumentsGrid.types';
import './DocumentUpdateStatus.scss';

export const DocumentUpdateStatus = ({ statuses, documentId }: DocumentUpdateStatusProps) => {
  if (!statuses[documentId]) {
    return null;
  }

  let icon;
  switch (statuses[documentId]) {
    case DocumentUpdateDescriptions.SuccessfullyUpdated:
      icon = <DoneOutlinedIcon className='done' />;
      break;
    case DocumentUpdateDescriptions.UnsavedChanges:
      icon = <WarningAmberOutlinedIcon className='warning' />;
      break;
    default:
      icon = <ErrorOutlineOutlinedIcon className='error' />;
  }

  return (
    <Tooltip title={statuses[documentId]} className='document-update-status'>
      {icon}
    </Tooltip>
  );
};
