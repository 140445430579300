import React from 'react';
import { Document } from '../../../store/files/documents/documents.list.types';
import { Clause } from '../../../store/files/clauses/clauses.list.types';

export enum DOCUMENT_CONTEXT_MENU_ITEM_NAME {
  FILES_COMPARE = 'FILES_COMPARE',
  BOOKMARK = 'Bookmark',
  COMPARE = 'Compare',
  DOWNLOAD = 'Download',
  CLAUSE_VIEW_EDIT = 'CLAUSE_VIEW_EDIT',
  EDIT = 'Edit',
  DELETE = 'Delete',
  REPORT = 'Report',
  REMOVE_FROM_BOOKMARK = 'Remove_from_bookmark',
  MOVE = 'Move',
  SHOW_RELATED = 'Show related',
  ADD_NEW_VERSION = 'Add new version',
}

export interface ContextMenuHookReturn<T> {
  control: ContextMenuHookControl<T>;
  handleContextMenu: ContextMenuHandler<T>;
}

export interface ContextMenuHookControl<T> {
  onClose: () => void;
  anchorEl: HTMLElement | null;
  selectedItem?: T;
}

export type ContextMenuHandler<T> = (event: React.MouseEvent<HTMLElement>, item: T) => void;

export interface ContextMenuItem {
  name: DOCUMENT_CONTEXT_MENU_ITEM_NAME;
  disabled: boolean;
}

export interface ContextMenuProps<T> {
  control: ContextMenuHookControl<T>;
  documentContextMenuItems: ContextMenuItem[];
}

export interface DocumentContextMenuItemProps {
  document?: Document | Clause;
  onClose: () => void;
  disabled?: boolean;
}
