import React from 'react';
import { Chip } from '@mui/material';
import { AdminPanelUser } from '../../../store/adminPanel/adminPanel.types';
import './UsersListChip.scss';

export const UsersListChip: React.FC<{ selectedUsers?: AdminPanelUser[] }> = ({
  selectedUsers,
}) => (
  <div className='users-list'>
    {selectedUsers?.map((user) => {
      const userLabel =
        user.Name && user.FamilyName ? `${user.Name} ${user.FamilyName}` : user.Email;
      return <Chip label={userLabel} key={user.Email} />;
    })}
  </div>
);
