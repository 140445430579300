import React, { useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useForm } from 'react-hook-form';
import {
  useGetClauseDictionariesQuery,
  useGetDictionariesQuery,
} from '../../../store/files/upload/list.service';
import { DocumentsListParams } from '../../../store/files/documents/documents.list.types';
import './DocumentsSearch.scss';
import { getFiltersCount } from './DocumentsSearch.helpers';
import { DocumentsSearchProps, SetValueType } from './DocumentsSearch.types';
import { SearchFilters } from './SearchFilters';
import { SearchFiltersControl } from './SearchFilters.types';
import { SavedSearchSelect } from './SaveSearchSelect/SavedSearchSelect';
import { cleanupFilters } from '../../../store/files/documents/documents.list.helpers';
import { useGetRelatedDocumentsFromLocationState } from '../useGetRelatedDocumentsFromLocationState';
import { useSequentialSearch } from './SequentialSearchFields/SequentialSearchFields.hook';
import { SequentialSearchFields } from './SequentialSearchFields/SequentialSearchFields';
import { SEQUENTIAL_SEARCH } from '../../../store/files/documentsAndClauses/list.types';
import { SequentialSearchOptions } from './SequentialSearchOptions/SequentialSearchOptions';

export const DocumentsSearch = ({
  filters,
  onApply,
  accessMode,
  clauseMode,
  savedSearchHidden = false,
}: DocumentsSearchProps) => {
  const useDictionariesQuery = clauseMode ? useGetClauseDictionariesQuery : useGetDictionariesQuery;
  const { data: dictionaries } = useDictionariesQuery({ AccessMode: accessMode });
  const { control, getValues, setValue, watch, reset } = useForm<DocumentsListParams>();
  const { fileName, relatedDocuments, clearLocationState } =
    useGetRelatedDocumentsFromLocationState();

  const filtersApplied = getValues();

  useEffect(() => reset(filters), [filters, reset]);

  const filtersCount = getFiltersCount(watch(), clauseMode);
  const appliedFiltersCount = !relatedDocuments?.length
    ? getFiltersCount(filters, clauseMode)
    : getFiltersCount(filters, clauseMode) + 1;

  const sequentialSearch = useSequentialSearch(watch(SEQUENTIAL_SEARCH), (values) =>
    setValue(SEQUENTIAL_SEARCH, values)
  );
  const { setSelectedSearch } = sequentialSearch;

  const handleApply = () => {
    onApply(getValues());
  };

  const handleClear = () => {
    setSelectedSearch(0);
    onApply(cleanupFilters(getValues(), clauseMode));
    clearLocationState();
  };

  return (
    <Paper elevation={0} className='documents-search'>
      <Grid container>
        <Grid item xs className='documents-search-main sequential-search-fields-arrow-line'>
          <div className='documents-search-search'>
            <SequentialSearchFields params={sequentialSearch} />

            {!savedSearchHidden ? (
              <SavedSearchSelect onChange={reset} clauseMode={clauseMode} />
            ) : null}
          </div>

          <SearchFilters
            dictionaries={dictionaries}
            control={control as SearchFiltersControl}
            filtersApplied={filtersApplied}
            setValue={setValue as SetValueType}
            clauseMode={clauseMode}
          >
            <>
              {relatedDocuments?.length ? (
                <div className='documents-search-parent-filter'>
                  Documents related to: {fileName}
                </div>
              ) : null}
              <div className='documents-search-buttons'>
                <Button variant='contained' onClick={handleApply}>
                  Apply parameters <span className='documents-search-counter'>{filtersCount}</span>
                </Button>

                <Button variant='text' onClick={handleClear} disabled={!appliedFiltersCount}>
                  Clear parameters
                  <span className='documents-search-counter'>{appliedFiltersCount}</span>
                </Button>
              </div>
            </>
          </SearchFilters>
        </Grid>

        <SequentialSearchOptions
          params={sequentialSearch}
          control={control}
          clauseMode={clauseMode}
        />
      </Grid>
    </Paper>
  );
};
