import { getReportedReasonLabelByValue } from '../../../store/files/upload/list.helpers';
import { ReportedFieldsDetailsProps } from './ReportedField.types';

export const ReportedFieldsDetails = ({
  dictionaries,
  reportedBy,
  reportedReason,
  reportedComment,
}: ReportedFieldsDetailsProps) => (
  <>
    <div>
      <span className='reported-fields-label'>Reporter: </span>
      <span className='reported-fields-value'>{reportedBy}</span>
    </div>
    <div>
      <span className='reported-fields-label'>Reason: </span>
      <span className='reported-fields-value'>
        {getReportedReasonLabelByValue(dictionaries?.ReportedReason!, reportedReason)}
      </span>
    </div>
    {reportedComment && (
      <div>
        <span className='reported-fields-label'>Comment: </span>
        <span className='reported-fields-value'>{reportedComment}</span>
      </div>
    )}
  </>
);
