import {
  getDictionaryLabelByValue,
  getFlagByCountryName,
} from '../../../store/files/upload/list.helpers';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material/';
import { MetadataDictionary } from '../../../store/files/upload/list.service.types';
import { ElementCheckedIconProps } from './MetadataPanel.types';

export const ElementCheckedIcon: React.FC<ElementCheckedIconProps> = ({ checked, type }) =>
  checked ? (
    <CheckIcon className={type === 'tiny' ? 'icon-checked' : ''} fontSize='small' />
  ) : (
    <CloseIcon className={type === 'tiny' ? 'icon-checked' : ''} fontSize='small' />
  );

export const renderItemOrListOfItems = (
  dictionary: MetadataDictionary,
  metadataClause: string | string[],
  isCountry?: boolean
) => {
  const isString = typeof metadataClause === 'string';
  return isString ? (
    <div>
      {isCountry && getFlagByCountryName(metadataClause)}{' '}
      {getDictionaryLabelByValue(dictionary, metadataClause)}
    </div>
  ) : (
    metadataClause.map((doc: string, i: number) => (
      <span key={i} className='space-right'>
        {isCountry && getFlagByCountryName(doc)} {getDictionaryLabelByValue(dictionary, doc)}
      </span>
    ))
  );
};
