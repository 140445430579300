import React, { useEffect, useState } from 'react';
import './DragAndDrop.scss';
import { useDropzone } from 'react-dropzone';
import { DragAndDropProps } from './DragAndDrop.types';
import { convertToMB, wasUploadRejectedDueToTotalNumber } from './DragAndDrop.helper';
import { UploadingModalError } from '../UplodingErrorModal/UploadingModalError';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  uploadFile,
  clearFailed,
  setIsFileAccepted,
  setUploadingDocId,
} from '../../../store/files/upload/upload.slice';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { showModal } from '../../../store/ui/ui.slice';

export const DragAndDrop: React.FC<DragAndDropProps> = ({
  filesTypes,
  maxFilesSize,
  maxFiles,
  isDraft,
  versionKey = undefined,
}) => {
  const [filesRejected, setFilesRejected] = useState<string[]>([]);
  const filesMimeTypes = filesTypes?.map(({ mimeType }) => mimeType);
  const filesMimeNames = filesTypes?.map(({ name }) => name);
  const maxFilesSizeMB = convertToMB(maxFilesSize);
  const dispatch = useAppDispatch();
  const failedFiles = useAppSelector(uploadSelectors.selectFailedFiles);

  const reason = `Too many files selected. ${
    maxFiles > 1 ? `Please select up to ${maxFiles} documents.` : `Please select only one document.`
  }`;

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    isDragActive,
    fileRejections,
    acceptedFiles,
  } = useDropzone({
    accept: filesMimeTypes,
    maxSize: maxFilesSize,
    multiple: true,
    maxFiles: maxFiles,
    onDrop: (accepted) => {
      accepted.forEach((file) => {
        dispatch(uploadFile({ file, isDraft, versionKey }))
          .unwrap()
          .then((result) => {
            const { documentId } = result;
            dispatch(setUploadingDocId(documentId));
          });
      });
    },
    onDropRejected: (frs) => {
      if (wasUploadRejectedDueToTotalNumber(frs)) {
        dispatch(
          showModal({
            title: 'Uploading error',
            reason: reason,
          })
        );
      } else {
        setFilesRejected(frs.map(({ file: { name } }) => name));
      }
    },
  });

  useEffect(() => {
    if (!!acceptedFiles.length) {
      dispatch(setIsFileAccepted(true));
    }
  }, [acceptedFiles, dispatch]);

  const numberOfFilesDropped = fileRejections?.length + acceptedFiles?.length;

  let styles = isFocused ? 'focus ' : '';
  styles += isDragActive || isDragAccept ? 'accept-style ' : '';
  styles += isDragReject ? 'reject-style ' : '';

  return (
    <>
      {filesRejected ? (
        <UploadingModalError
          files={filesRejected}
          numberOfFilesDropped={numberOfFilesDropped}
          message={`- file has to be ${filesMimeTypes} and max size ${maxFilesSizeMB}MB`}
          onClose={() => setFilesRejected([])}
        />
      ) : null}
      {failedFiles ? (
        <UploadingModalError
          files={failedFiles}
          numberOfFilesDropped={numberOfFilesDropped}
          onClose={() => dispatch(clearFailed())}
        />
      ) : null}
      <div className='drag-and-drop' data-test-id={TEST_ID.UPLOAD_FILES.DRAG_AND_DROP}>
        <div {...getRootProps()} className={`drag-and-drop-box ${styles}`}>
          <input {...getInputProps()} />
          <p className='dnd-msg-title'>
            Drag & Drop your {maxFiles === 1 ? 'file' : 'files'} here or <button>browse</button>
          </p>
          <p className='dnd-msg-subtitle'>
            Files supported: {filesMimeNames?.join(',')?.toUpperCase()}
            <br />
            Max file size: {maxFilesSizeMB}MB
          </p>
          <p className='dnd-msg-subtitle sensitive-data-warning'>
            You confirm that documents don't contain personal data
          </p>
        </div>
      </div>
    </>
  );
};
