import { DELETE_MODAL_SUBMIT_BUTTON_LABEL } from './ConfirmationModal.messages';
import { ConfirmationModal } from './ConfirmationModal';
import React from 'react';
import { DeleteConfirmModalProps } from './ConfirmationModal.types';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

export const DeleteConfirmModal: React.FC<DeleteConfirmModalProps> = ({
  open,
  onConfirm,
  onClose,
  disabled,
  title,
  message,
  successButtonLabel = DELETE_MODAL_SUBMIT_BUTTON_LABEL,
}) => {
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      disabled={disabled}
      title={title}
      message={message}
      successButtonIcon={<DeleteOutlineOutlinedIcon fontSize='large' />}
      successButtonLabel={successButtonLabel}
    />
  );
};
