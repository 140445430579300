import React from 'react';
import './UploadingModalError.scss';
import { MarsModal } from '../../StaticComponents/Modals/MarsModal';
import { UploadingModalErrorTypes } from './UploadingModalError.types';
import { List, ListItem, ListItemText } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export const UploadingModalError: React.FC<UploadingModalErrorTypes> = ({
  files,
  numberOfFilesDropped,
  message,
  onClose,
}) => {
  const getErrorMessage = () => {
    return message
      ? message
      : 'to upload to the system, please check them to re-upload them later.';
  };

  return (
    <MarsModal title='Uploading error' buttonLabel='Close' open={!!files?.length} onClose={onClose}>
      <div className='uploading-modal-error'>
        <p className='description'>
          <b>FAILED FILES ({files?.length})</b>
          <br />
          <b>
            {files?.length} out of {numberOfFilesDropped} files failed
          </b>{' '}
          {getErrorMessage()}
        </p>

        <List>
          {files?.map((file) => (
            <ListItem key={file} secondaryAction={<ErrorOutlineIcon className='error-icon' />}>
              <ListItemText className='list-item'>{file}</ListItemText>
            </ListItem>
          ))}
        </List>
      </div>
    </MarsModal>
  );
};
