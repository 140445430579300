import React, { useEffect } from 'react';
import { AchDocumentViewerProps } from './DocumentViewer.types';
import { useGetDocumentFileQuery } from '../../../store/files/documents/documents.list.service';
import PdfViewer from '../../StaticComponents/FileViewer/PdfViewer';
import { HtmlViewer } from '../../StaticComponents/FileViewer/HtmlViewer';
import { LanguageToggleSwitchTypes } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import { DocumentFileResponseFields } from '../../../store/files/documents/documents.list.types';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { useAppSelector } from '../../../store/hooks';
import { useSearch } from '../../StaticComponents/FileViewer/ViewerToolbar/SearchContext';

export const AchDocumentViewer = ({
  documentId = '',
  language,
  keyword,
  method,
  order,
  searchOption,
  debug,
  clauses,
}: AchDocumentViewerProps) => {
  const { data, isFetching, isError } = useGetDocumentFileQuery(documentId, {
    skip: !documentId,
  });

  const isLanguageLocked = useAppSelector(uiSelectors.selectLockLanguage);
  const lockedLanguage = useAppSelector(uiSelectors.selectLockedLanguage);

  const languageToSwitch = isLanguageLocked && order !== undefined ? lockedLanguage : language;

  const { setSearchTerm } = useSearch();
  useEffect(() => {
    const searchTermPrefill = keyword ? keyword : '';
    setSearchTerm(searchTermPrefill);
  }, [keyword, setSearchTerm]);

  switch (languageToSwitch) {
    case LanguageToggleSwitchTypes.PDF: {
      const url = data?.[DocumentFileResponseFields.OriginalPdfUrl];
      return (
        <PdfViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={keyword}
          method={method}
          searchOption={searchOption}
          clauses={clauses}
        />
      );
    }
    case LanguageToggleSwitchTypes.Original: {
      const url = data?.[DocumentFileResponseFields.OriginalHtmlUrl];
      return (
        <HtmlViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={keyword}
          method={method}
          order={order}
          language={LanguageToggleSwitchTypes.Original}
          searchOption={searchOption}
          debug={debug}
          clauses={clauses}
        />
      );
    }
    case LanguageToggleSwitchTypes.English: {
      const url = data?.[DocumentFileResponseFields.TranslatedHtmlUrl];
      return (
        <HtmlViewer
          loading={isFetching}
          error={isError}
          url={url}
          keyword={keyword}
          method={method}
          order={order}
          language={LanguageToggleSwitchTypes.English}
          searchOption={searchOption}
          debug={debug}
        />
      );
    }
  }
};
