import React, { FormEvent, useEffect } from 'react';
import { Modal } from '@mui/material';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { ButtonWithIcon } from '../../StaticComponents/ButtonWithIcon';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { useSnackbar } from 'notistack';
import { useApiError } from '../../../store/ui/ui.helpers';
import { ApiError } from '../../UploadFiles/MetadataForm/ApiError';
import { CustomTextField, requiredRule } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { TagInput } from '../../Documents/DocumentsSearch/TagInput/TagInput';
import { Tag } from '../../../store/files/tags/tags.types';
import { useGetMetadataQuery } from '../../../store/adminPanel/adminPanel.service';
import { useAddTagMutation, useUpdateTagMutation } from '../../../store/files/tags/tags.service';
import { handleApiError } from '../../../store/error.helpers';
import './EditTagModal.scss';

export const EditTagModal: React.FC<{
  onClose: () => void;
  tag?: Tag;
}> = ({ onClose, tag }) => {
  const { apiError, setApiError, clearApiError } = useApiError();
  const { enqueueSnackbar } = useSnackbar();

  const { data: metadata } = useGetMetadataQuery(undefined, { skip: !tag });
  const [create, { isLoading: isLoadingCreate }] = useAddTagMutation();
  const [update, { isLoading: isLoadingUpdate }] = useUpdateTagMutation();
  const isLoading = isLoadingCreate || isLoadingUpdate;
  const editMode = !!tag?.Name;

  const {
    control,
    formState: { isDirty, isValid },
    getValues,
    reset,
  } = useForm<Tag>({
    mode: 'onChange',
  });

  useEffect(() => {
    if (tag) {
      reset(tag);
      clearApiError();
    }
  }, [tag, reset, clearApiError]);

  const handleSubmit = async (e?: FormEvent) => {
    e?.preventDefault();
    clearApiError();

    (editMode ? update : create)(getValues())
      .unwrap()
      .then(() => {
        const message = editMode
          ? 'Tag was changed successfully.'
          : 'Tag was created successfully.';
        enqueueSnackbar(message, { variant: 'success' });
        onClose();
      })
      .catch((response) => setApiError(handleApiError(response, true)));
  };

  return (
    <Modal open={!!tag}>
      <form className='edit-users-group-form__edit-user__form edit-tag-form'>
        <div className='edit-users-groups-modal'>
          <ModalClose onClose={onClose} />

          <div className='confirmation-modal-header'>
            <div className='confirmation-modal-title'>{editMode ? 'Edit' : 'Add'} tag</div>
            <div className='confirmation-modal-message'>
              <div className='edit-users-group-form__edit-user'>
                <div className='edit-users-group-form__edit-user__filters'>
                  <Controller
                    control={control}
                    defaultValue=''
                    name='Name'
                    rules={requiredRule(true, true)}
                    render={({ field, fieldState: { error } }) => (
                      <TextField
                        {...field}
                        disabled={editMode}
                        fullWidth
                        helperText={<ErrorMessage error={error} />}
                        label='Tag'
                        size='small'
                        variant='outlined'
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    defaultValue=''
                    name='Language'
                    rules={requiredRule(true, true)}
                    render={({ field, fieldState: { error } }) => (
                      <CustomTextField
                        {...field}
                        disabled={editMode}
                        fullWidth
                        helperWarning={error}
                        label='Language'
                        select
                        values={metadata?.TagsLanguages}
                        variant='outlined'
                      />
                    )}
                  />

                  <Controller
                    control={control}
                    defaultValue={[]}
                    name='Synonyms'
                    rules={requiredRule(true, true)}
                    render={({ field: { value, onChange }, fieldState: { error } }) => (
                      <div className='edit-tag-form__tags'>
                        <TagInput
                          noSpecialCharacters
                          onChange={onChange}
                          placeholder='Type synonym here and press Enter'
                          value={value}
                        />
                        <ErrorMessage error={error} />
                      </div>
                    )}
                  />
                </div>

                <div className='edit-users-group-form__edit-user__form__error edit-tag-form__error'>
                  <ApiError apiError={apiError} />
                </div>
              </div>
            </div>
          </div>

          <div className='modal-buttons'>
            <ButtonWithIcon className='cancel-button' disabled={isLoading} onClick={onClose}>
              Cancel
            </ButtonWithIcon>

            <LoadingButton
              className='confirm-button'
              disabled={!(isDirty && isValid)}
              loading={isLoading}
              onClick={handleSubmit}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </form>
    </Modal>
  );
};
