import { ErrorResponse, ValidationErrorResponse } from '../documents/documents.list.types';
import { DocumentUpdateStatuses } from '../../../components/SpreadSheet/DocumentsGrid/DocumentsGrid.types';
import { ErrorMessages } from '../../../services/errors.service.types';
import { mapValidationErrorsToRecord } from '../documents/documents.update.error.helpers';

export const mapClauseErrorResponseToUpdateStatuses = (
  response: ErrorResponse<ValidationErrorResponse>,
  clauseId: string
): DocumentUpdateStatuses => {
  if (!response.data?.errors?.length) {
    return { [clauseId]: ErrorMessages.MetadataUpdateError };
  }

  return mapValidationErrorsToRecord(response.data.errors);
};
