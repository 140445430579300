import React, { useEffect } from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { Backdrop } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { documentsSelectors } from '../../../store/files/documents/documents.selectors';
import { closeDocumentFilesCompareModal } from '../../../store/files/documents/documents.slice';
import { AchDocumentViewer } from '../../UploadFiles/MetadataEditModal/AchDocumentViewer';
import { LanguageToggleSwitchTypes } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import { ScrollSyncProvider, useScrollSync } from '../DocumentViewModal/ScrollSyncContext';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import './DocumentFilesCompareModal.scss';

export const DocumentFilesCompareModal = () => {
  const dispatch = useAppDispatch();

  const document = useAppSelector(documentsSelectors.selectModalDocumentFilesCompare);

  const handleClose = () => {
    dispatch(closeDocumentFilesCompareModal());
  };

  return (
    <Modal
      open={!!document?.DocumentId}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 0 }}
    >
      <div className='metadata-edit-modal document-files-compare-modal'>
        <ModalClose onClose={handleClose} />
        <ScrollSyncProvider>
          <Sync />
          <Grid container>
            <Grid item xs>
              <AchDocumentViewer
                documentId={document?.DocumentId}
                language={LanguageToggleSwitchTypes.PDF}
              />
            </Grid>
            <Grid item xs>
              <AchDocumentViewer
                documentId={document?.DocumentId}
                language={LanguageToggleSwitchTypes.Original}
                debug
              />
            </Grid>
            <Grid item xs>
              <AchDocumentViewer
                documentId={document?.DocumentId}
                language={LanguageToggleSwitchTypes.English}
                debug
              />
            </Grid>
          </Grid>
        </ScrollSyncProvider>
      </div>
    </Modal>
  );
};

const Sync = () => {
  const { setIsScrollSyncEnabled } = useScrollSync();
  useEffect(() => {
    setIsScrollSyncEnabled(true);
  }, [setIsScrollSyncEnabled]);
  return null;
};
