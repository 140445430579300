import { useEffect, useState, useCallback } from 'react';

import { COMPARE_MODE } from '../../Clauses/ClauseCompareModal/CompareOptionsBar/CompareOptionsBar.types';

import { isClauseResponse } from '../../../store/files/documentsAndClauses/list.helpers';

import { uiSelectors } from '../../../store/ui/ui.selectors';
import { useAppSelector } from '../../../store/hooks';

import DiffMatchPatch, { Diff } from 'diff-match-patch';
import './diff-match-patch-extended';

const dmp = new DiffMatchPatch();

export type DiffViewObject = {
  removed?: boolean;
  added?: boolean;
  unchanged?: boolean;
  value: string;
};

const diffHtmlStringCleanup = (htmlString: string) => htmlString.replace(/&para;/g, '');

const createReferenceViewObjects = (
  differences: Diff[],
  map: Record<number, keyof DiffViewObject>,
  compareMode: COMPARE_MODE,
  statusValueMap: Record<string, number>,
  multipleComparison?: boolean
): DiffViewObject[] => {
  const viewObjects: DiffViewObject[] = [];
  differences.forEach(([status, value]) => {
    if (
      status !== statusValueMap.added &&
      compareMode !== COMPARE_MODE.SHOW_REPLACEMENTS &&
      !multipleComparison
    ) {
      viewObjects.push({ [map[status]]: true, value });
    }
  });
  return viewObjects;
};

const createComparisonViewObjects = (
  differences: Diff[],
  map: Record<number, keyof DiffViewObject>,
  statusValueMap: Record<string, number>
): DiffViewObject[] => {
  const viewObjects: DiffViewObject[] = [];
  differences.forEach(([status, value]) => {
    if (status !== statusValueMap.removed) {
      viewObjects.push({ [map[status]]: true, value });
    }
  });
  return viewObjects;
};

const handleMultipleComparisonsAndReplacements = (differences: Diff[]): string => {
  const htmlFromDiff = dmp.diff_prettyHtml(differences);
  return diffHtmlStringCleanup(htmlFromDiff);
};

const mapDifferencesToViewObjects = (
  differences: Diff[],
  compareMode: COMPARE_MODE,
  elementOrder?: number,
  multipleComparison?: boolean
): DiffViewObject[] | string => {
  const statusMap: Record<number, keyof DiffViewObject> = {
    '-1': 'removed',
    '0': 'unchanged',
    '1': 'added',
  };

  const mapForSimilarities: Record<number, keyof DiffViewObject> = {
    '-1': 'unchanged',
    '0': 'added',
    '1': 'unchanged',
  };

  const statusValueMap = {
    removed: -1,
    unchanged: 0,
    added: 1,
  } as const;

  const mapForMode =
    compareMode === COMPARE_MODE.SHOW_SIMILARITIES ? mapForSimilarities : statusMap;

  if (elementOrder === 0) {
    return createReferenceViewObjects(
      differences,
      mapForMode,
      compareMode,
      statusValueMap,
      multipleComparison
    );
  } else if (elementOrder !== 0 && compareMode === COMPARE_MODE.SHOW_REPLACEMENTS) {
    return handleMultipleComparisonsAndReplacements(differences);
  } else {
    return createComparisonViewObjects(differences, mapForMode, statusValueMap);
  }
};

export const useCompareClauseText = (elementOrder?: number) => {
  const [diff, setDiff] = useState<DiffViewObject[] | string>([]);
  const itemsInComparison =
    useAppSelector(uiSelectors.selectModalCompare)?.filter(isClauseResponse).length ?? 0;
  const multipleComparison = itemsInComparison > 2;

  const compareMode = useAppSelector(uiSelectors.selectCompareMode);

  useEffect(() => {
    if (compareMode === COMPARE_MODE.SHOW_ORIGINAL && diff?.length) {
      setDiff([]);
    }
  }, [compareMode, diff?.length]);

  const compareClauseText = useCallback(
    (text1?: string, text2?: string) => {
      if (text1 && text2 && compareMode !== COMPARE_MODE.SHOW_ORIGINAL) {
        //@ts-ignore
        const differences = dmp.diff_wordMode(text1, text2);

        const diffOutput = mapDifferencesToViewObjects(
          differences,
          compareMode,
          elementOrder,
          multipleComparison
        );

        setDiff(diffOutput);
      } else {
        setDiff([]);
      }
    },
    [compareMode, elementOrder, multipleComparison]
  );

  return { diff, compareClauseText, multipleComparison };
};
