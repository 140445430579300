import { ClauseMetadataUpdateResponse } from './clauses.list.types';
import {
  DocumentUpdateDescriptions,
  DocumentUpdateStatuses,
} from '../../../components/SpreadSheet/DocumentsGrid/DocumentsGrid.types';
import { ErrorMessages } from '../../../services/errors.service.types';
import { mapAchErrors } from '../documents/documents.update.success.helpers';

export const mapClauseSuccessResponseToUpdateStatuses = (
  response: ClauseMetadataUpdateResponse,
  clauseId: string
): DocumentUpdateStatuses => {
  let status: string;

  if (response.Succeeded && response.Succeeded.length) {
    status = DocumentUpdateDescriptions.SuccessfullyUpdated;
  } else if (response.Errors && response.Errors.length) {
    status = mapAchErrors(response.Errors[0]);
  } else {
    status = ErrorMessages.MetadataUpdateError;
  }

  return {
    [clauseId]: status,
  };
};
