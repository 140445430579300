import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { DocumentContextMenuItemProps } from './DocumentContextMenu.types';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useCompareDocumentsInView } from '../../../store/ui/ui.compare.hooks';
import { Tooltip } from '@mui/material';
import { useIsDocumentResponseData } from '../../../store/files/documentsAndClauses/list.hooks.helpers';
import { getDisableDocumentCompareMessage } from '../DocumentViewModal/Compare.helpers';
export const DocumentContextMenuCompare = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const { itemId, itemName, minimizedDataType } = useIsDocumentResponseData(document);

  const { addOrRemoveDoc, isDisableAddToCompare, isDocInCompare } = useCompareDocumentsInView(
    itemId!,
    itemName!,
    minimizedDataType
  );

  const handleMenuItemClick = () => {
    onClose();
    addOrRemoveDoc();
  };

  return (
    <>
      {!isDocInCompare ? (
        <Tooltip
          title={
            isDisableAddToCompare && !disabled
              ? getDisableDocumentCompareMessage(minimizedDataType)
              : ''
          }
          placement='right'
        >
          <div>
            <MenuItem onClick={handleMenuItemClick} disabled={isDisableAddToCompare || disabled}>
              <DifferenceOutlinedIcon />
              Add to compare
            </MenuItem>
          </div>
        </Tooltip>
      ) : (
        <MenuItem onClick={handleMenuItemClick} disabled={disabled}>
          <CloseIcon />
          Remove from compare
        </MenuItem>
      )}
    </>
  );
};
