import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { DocumentContextMenuItemProps } from './DocumentContextMenu.types';
import { openBookmarkModal } from '../../../store/files/bookmarks/bookmarks.slice';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useDispatch } from 'react-redux';

export const DocumentContextMenuBookmark = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const dispatch = useDispatch();
  const handleBookmarkClick = () => {
    if (document) {
      dispatch(openBookmarkModal({ bookmark: document }));
    }
    onClose();
  };
  return (
    <MenuItem disabled={disabled} onClick={handleBookmarkClick}>
      <BookmarkBorderIcon />
      Bookmark
    </MenuItem>
  );
};
