import { SyntheticEvent } from 'react';
import { MenuItem } from '@mui/material';
import { GridColumnMenuContainer } from '@mui/x-data-grid-pro';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';
import { SearchInputField } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { GridColumnMenuAndColumnHandlerProps } from './DocumentsGridColumnMenu.types';
import { useSearchInputDict } from '../../StaticComponents/SearchInput/SearchInput.hooks';

const DocumentsGridColumnMenu = ({
  handleColumnChange,
  ...props
}: GridColumnMenuAndColumnHandlerProps) => {
  const { filteredValues, onChangeSearch } = useSearchInputDict(
    props.currentColumn.valueOptions as MetadataDictionaryValue[]
  );

  const handleClick = (e: SyntheticEvent, value: string) => {
    props.hideMenu(e);
    handleColumnChange(props.currentColumn.field, value);
  };

  return (
    <GridColumnMenuContainer {...props}>
      <SearchInputField
        label={props.currentColumn?.headerName?.toLowerCase() || ''}
        onChangeHandle={onChangeSearch}
      />
      {filteredValues.map(({ label, value }) => (
        <MenuItem key={value} onClick={(e) => handleClick(e, value)}>
          {label}
        </MenuItem>
      ))}
    </GridColumnMenuContainer>
  );
};

export default DocumentsGridColumnMenu;
