import React from 'react';
import { ButtonWithIcon } from '../StaticComponents/ButtonWithIcon';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import './Bookmarks.scss';
import { Paper } from '@mui/material';
import { BookmarksHeaderProps } from './BookmarksHeader.types';

const BookmarksHeader = ({
  handleOpen,
  handleToggleSearchbar,
  showSearchbar,
}: BookmarksHeaderProps) => {
  return (
    <>
      <Paper elevation={0} className='bookmarks-header'>
        <span className='bookmarks-title'>My bookmarks</span>
        <div>
          <ButtonWithIcon
            startIcon={<SearchIcon fontSize='large' />}
            onClick={handleToggleSearchbar}
          >
            {showSearchbar ? 'Hide search' : 'Search bookmarks'}
          </ButtonWithIcon>
          <ButtonWithIcon onClick={handleOpen} startIcon={<AddIcon fontSize='large' />}>
            Create new
          </ButtonWithIcon>
        </div>
      </Paper>
    </>
  );
};

export default BookmarksHeader;
