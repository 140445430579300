import { useUpdateMetadataMutation } from '../../store/files/upload/list.service';
import { UPDATE_METADATA_CACHE_KEY } from '../../store/files/upload/list.service.types';
import { useAppSelector } from '../../store/hooks';
import { uploadSelectors } from '../../store/files/upload/upload.selectors';
import { getDocsByStatus } from './UploadedFileList.helper';
import { isDocumentEditable } from '../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { UploadedFileStatus } from './UploadedFileList.types';
import useCustomGetPreLandingListQuery from './UploadFiles.hooks';

type useEditingDisabledOpt = { forDeleteSelected?: boolean };

export function useEditingDisabled({ forDeleteSelected }: useEditingDisabledOpt = {}) {
  const { data, isFetching } = useCustomGetPreLandingListQuery();
  const [, { isLoading }] = useUpdateMetadataMutation({
    fixedCacheKey: UPDATE_METADATA_CACHE_KEY,
  });

  const uploading = !!useAppSelector(uploadSelectors.selectFilesUploading).length;
  const selectedDocs = useAppSelector(uploadSelectors.selectedDocs);
  const isSelectedNotEditable =
    !forDeleteSelected &&
    !!data?.filter(
      ({ DocumentId, Status }) => selectedDocs.includes(DocumentId) && !isDocumentEditable(Status)
    ).length;
  const scanning = !!getDocsByStatus(UploadedFileStatus.SCANNING, data).length;

  return isFetching || isLoading || uploading || scanning || isSelectedNotEditable;
}
