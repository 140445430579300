import {
  useGetMetadataQuery,
  useGetUsersQuery,
} from '../../../store/adminPanel/adminPanel.service';
import React, { useEffect, useState } from 'react';
import { CircularProgress, Paper } from '@mui/material';
import './ManageUsersGroups.scss';
import { ManageUsersGroupsFilters } from './ManageUsersGroupsFilters';
import { ManageUsersGroupsBulkOperations } from './ManageUsersGroupsBulkOperations';
import { ManageUsersGroupsList } from './ManageUsersGroupsList';

export const ManageUsersGroups = () => {
  const { data: users, isLoading, isFetching } = useGetUsersQuery();
  const { data: metadata } = useGetMetadataQuery();

  const [filteredUsers, setFilteredUsers] = useState(users);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);

  useEffect(() => {
    setFilteredUsers(users);
  }, [users]);

  if (isLoading || isFetching || !metadata) {
    return (
      <Paper
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '30px',
          borderRadius: 4,
          marginTop: '35px',
          minWidth: 600,
          height: '30vh',
        }}
      >
        <CircularProgress size={50} />
      </Paper>
    );
  }

  return (
    <>
      <Paper elevation={0} sx={{ minWidth: 600, borderRadius: 4, marginBottom: '30px' }}>
        <div className='admin-panel__manage-users-groups'>
          <div className='admin-panel__manage-users-groups-filters'>
            <ManageUsersGroupsFilters users={users ?? []} setFilteredUsers={setFilteredUsers} />
          </div>
          <div className='manage-users-groups-bulk-operations'>
            <ManageUsersGroupsBulkOperations
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
            />
          </div>
        </div>
      </Paper>
      <ManageUsersGroupsList filteredUsers={filteredUsers} setSelectedUsers={setSelectedUsers} />
    </>
  );
};
