import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useState } from 'react';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import '../../StaticComponents/ButtonWithIcon.scss';
import './MetadataMenu.scss';
import { useAppDispatch } from '../../../store/hooks';
import { downloadMetadataCsv, uploadMetadataCsv } from '../../../store/files/upload/upload.slice';

export const MetadataMenu = () => {
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const onDownloadCsv = () => {
    dispatch(downloadMetadataCsv());
    handleClose();
  };

  const clearInputValueToAllowUploadSameFile = (
    input: React.FormEvent<HTMLInputElement>['currentTarget']
  ) => {
    input.value = '';
  };

  const onUploadMetadataFile = (event: React.FormEvent<HTMLInputElement>) => {
    dispatch(uploadMetadataCsv(event?.currentTarget?.files?.[0] as File));
    clearInputValueToAllowUploadSameFile(event.currentTarget);
  };

  return (
    <div>
      <Button
        variant='outlined'
        disableElevation
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        className='button-with-icon'
      >
        Metadata upload
      </Button>
      <input
        id='metadata-csv-upload-input'
        type='file'
        style={{ display: 'none' }}
        accept='.text/csv, .csv'
        onChange={onUploadMetadataFile}
      />
      <Menu
        className='metadata-csv-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onDownloadCsv} disableRipple>
          <FileDownloadOutlinedIcon />
          Generate metadata file
        </MenuItem>
        <label htmlFor='metadata-csv-upload-input'>
          <MenuItem onClick={handleClose} disableRipple>
            <FileUploadOutlinedIcon />
            Upload metadata file
          </MenuItem>
        </label>
      </Menu>
    </div>
  );
};
