import {
  getDictionaryLabelByValue,
  getFlagByCountryName,
} from '../../../../store/files/upload/list.helpers';
import React from 'react';
import { BOOLEAN_VALUES } from '../../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { ElementCheckedIcon } from '../../../Documents/DocumentViewModal/MetadataPanel.helper';
import { FilterPanelItemProps } from './FiltersPanel.types';
import { Tooltip } from '@mui/material';

const getIconOrSingleValueField = (value: string) => {
  const isStringBoolean = value === BOOLEAN_VALUES.TRUE || value === BOOLEAN_VALUES.FALSE;
  return isStringBoolean ? (
    <ElementCheckedIcon type='tiny' checked={value === BOOLEAN_VALUES.TRUE} />
  ) : (
    <Tooltip title={value} placement='top-start'>
      <span className='filter-panel-item-field-value'>{value}</span>
    </Tooltip>
  );
};

export const FilterPanelItem = ({
  filter,
  label,
  multipleFilters,
  dictionary,
  hasFlag,
}: FilterPanelItemProps) => {
  return (
    <>
      {filter?.length ? (
        <div className='filter-panel-item'>
          <span className='filter-panel-item-field'>{label}: </span>
          {multipleFilters
            ? (filter as string[]).map((value, index) => (
                <span key={value} className='filter-panel-item-field-value'>
                  {hasFlag ? getFlagByCountryName(value) + ' ' : ''}
                  {dictionary ? getDictionaryLabelByValue(dictionary, value) : filter}
                  {!!filter[index + 1] ? ', ' : ''}
                </span>
              ))
            : getIconOrSingleValueField(filter as string)}
        </div>
      ) : null}
    </>
  );
};
