import React, { useState } from 'react';
import { ButtonWithIcon } from '../ButtonWithIcon';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { BulkDeleteModalProps } from './BulkDeleteModal.types';
import { DeleteConfirmModal } from '../Modals/ConfirmationModal/DeleteConfirmModal';
import { TEST_ID } from '../../../config/test-fields-ids.config';

export const BulkDeleteModal: React.FC<BulkDeleteModalProps> = ({
  handler,
  disabled: disabledProp,
}) => {
  const { title, message, handleBulkDelete, disabled } = handler();
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonWithIcon
        data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_BULK_DELETE}
        startIcon={<DeleteOutlineOutlinedIcon fontSize='large' />}
        onClick={() => setOpen(true)}
        disabled={disabledProp || disabled}
        className='red'
      >
        Delete selected
      </ButtonWithIcon>
      <DeleteConfirmModal
        open={open}
        onClose={handleClose}
        onConfirm={handleBulkDelete}
        title={title}
        message={message}
      />
    </>
  );
};
