import { Input, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { SearchDocumentGridProps } from './SearchDocumentGrid.types';
import './SearchDocumentGrid.scss';

export const SearchDocumentGrid = ({ value, setSearchText }: SearchDocumentGridProps) => {
  return (
    <Input
      value={value}
      onChange={(event) => setSearchText(event.target.value)}
      disableUnderline
      className='search-grid'
      placeholder='Search'
      startAdornment={
        <InputAdornment position='start'>
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};
