import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar } from 'notistack';
import { DocumentContextMenuItemProps } from './DocumentContextMenu.types';
import { useAppDispatch } from '../../../store/hooks';
import {
  documentsListApi,
  useDeleteDocumentMutation,
} from '../../../store/files/documents/documents.list.service';
import {
  DOCUMENTS_LIST_API_TAGS,
  DocumentResponse,
} from '../../../store/files/documents/documents.list.types';
import { ErrorMessages } from '../../../services/errors.service.types';
import { GridContextMenuDelete } from '../../StaticComponents/GridContextMenuDelete/GridContextMenuDelete';
import { showDeletingStatusACH, showDeletingStatusClausesACH } from './DocumentContextMenu.helpers';
import { showLoader } from '../../../store/ui/ui.slice';
import {
  CLAUSE,
  DELETE_MODAL_MESSAGE,
  DELETE_MODAL_TITLE,
  DOCUMENT,
  DELETE_MESSAGE_PARENT_RELATED_DOCUMENT,
} from '../../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import { useIsDocumentResponseData } from '../../../store/files/documentsAndClauses/list.hooks.helpers';
import { ITEM_TYPE } from '../../../store/ui/ui.types';
import {
  clausesListApi,
  useDeleteClauseMutation,
} from '../../../store/files/clauses/clauses.list.service';
import { CLAUSES_LIST_API_TAGS } from '../../../store/files/clauses/clauses.list.types';
import { REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION } from '../../../config/config';
import { timeoutPromise } from '../../../helpers/app.helpers';

import { useCheckIfDocumentIsParent } from './useCheckIfDocumentIsParent';
import { useEffect, useState } from 'react';
import { useGetRelatedClauses } from '../DocumentViewModal/DocumentViewModal.hooks';
import { isDocumentResponse } from '../../../store/files/documentsAndClauses/list.helpers';

export const DocumentContextMenuDelete = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const { documentIsParent, isLoading } = useCheckIfDocumentIsParent(
    (document as DocumentResponse)?.DocumentId ?? ''
  );
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { itemId, dataType, hasRelatedClauses } = useIsDocumentResponseData(document);
  const [deleteDocument] = useDeleteDocumentMutation();
  const [deleteClause] = useDeleteClauseMutation();
  const [deleteDisabled, setDeleteDisabled] = useState(!!isDocumentResponse(document));
  const title =
    dataType === ITEM_TYPE.DOCUMENT ? DELETE_MODAL_TITLE(DOCUMENT) : DELETE_MODAL_TITLE(CLAUSE);
  let description =
    dataType === ITEM_TYPE.DOCUMENT ? DELETE_MODAL_MESSAGE(DOCUMENT) : DELETE_MODAL_MESSAGE(CLAUSE);
  const [descriptionExt, setDescriptionExt] = useState(
    documentIsParent
      ? `${DELETE_MESSAGE_PARENT_RELATED_DOCUMENT} <br />` + description
      : description
  );

  const clauses = useGetRelatedClauses(
    isDocumentResponse(document) ? (document as DocumentResponse) : undefined,
    !hasRelatedClauses
  );

  useEffect(() => {
    const hasRelatedClausesWithDraft = clauses.some(
      (clause) => clause.IsDraft === document?.IsDraft
    );
    if (hasRelatedClausesWithDraft) {
      setDescriptionExt(`${DELETE_MESSAGE_PARENT_RELATED_DOCUMENT} <br />` + description);
    }
    setDeleteDisabled(false);
  }, [clauses, description, document?.IsDraft]);

  const deleteItem = dataType === ITEM_TYPE.DOCUMENT ? deleteDocument : deleteClause;
  const invalidateCache =
    dataType === ITEM_TYPE.DOCUMENT
      ? documentsListApi.util.invalidateTags([DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST])
      : clausesListApi.util.invalidateTags([CLAUSES_LIST_API_TAGS.CLAUSES_LIST]);

  const handleDeleteConfirm = async () => {
    if (itemId) {
      try {
        dispatch(showLoader(true));
        dispatch(
          dataType === ITEM_TYPE.DOCUMENT
            ? showDeletingStatusACH(itemId)
            : showDeletingStatusClausesACH(itemId)
        );
        await deleteItem(itemId).unwrap();
        await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);
        dispatch(invalidateCache);
      } catch (error) {
        enqueueSnackbar(ErrorMessages.FileDeleteError, { variant: 'error' });
      } finally {
        dispatch(showLoader(false));
      }
    }
  };

  return (
    <GridContextMenuDelete
      icon={<DeleteOutlineIcon />}
      onClose={onClose}
      title={title}
      description={descriptionExt}
      handleDeleteConfirm={handleDeleteConfirm}
      disabled={deleteDisabled || disabled || isLoading}
    />
  );
};
