import React, { useEffect, useRef, useState } from 'react';
import { FileViewerProps } from './FileViewer.types';
import { FileLoader } from './FileLoader';
import { useZoomSwitch } from './FileViewer.helpers';
import { ViewerToolbar } from './ViewerToolbar/ViewerToolbar';
import { copyEventListener, useTextSelectionDispatch } from './FileViewer.hook';
import { useDocumentDownload } from './useDocumentDownload';
import { useTransformDocumentDataToDiplayableUrl } from './useTransformDocumentDataToDiplayableUrl';
import { useHighlight } from './HtmlViewer.highlight';
import { prettifyHtml } from './HtmlViewer.prettify';
import { useClauseHighlight } from './HtmlViewer.clause-highlight';
import { ScrollSyncPane } from 'react-scroll-sync';

export const HtmlViewer = ({
  loading,
  error,
  url,
  keyword,
  method,
  order,
  searchOption,
  debug,
  clauses,
}: FileViewerProps) => {
  const ref = useRef<HTMLIFrameElement>(null);
  const { scale, handleZoom } = useZoomSwitch(url);
  const { handleHighlight, handleSwitch } = useHighlight(ref, keyword, method);

  const { documentData, isDownloading, isError } = useDocumentDownload(loading, error, url, order);
  const { documentUrl, isLoading } = useTransformDocumentDataToDiplayableUrl(
    documentData,
    loading,
    error,
    order
  );

  const [contentRendered, setContentRendered] = useState(false);
  useClauseHighlight(ref, contentRendered, clauses, scale);

  useEffect(() => {
    setContentRendered(false);
  }, [documentUrl]);

  const { handleTextSelectionDispatch } = useTextSelectionDispatch();

  const handleTextSelection = () => {
    const iframeWindow = ref.current?.contentWindow;
    if (!iframeWindow) return;
    const selectionObj = iframeWindow.getSelection();
    handleTextSelectionDispatch(selectionObj);
  };

  const iframeWindow = ref.current?.contentWindow;
  useEffect(() => {
    if (!iframeWindow) return;
    const copyFunction = copyEventListener(iframeWindow);
    iframeWindow.document.addEventListener('copy', copyFunction);

    return () => {
      iframeWindow.document.removeEventListener('copy', copyFunction);
    };
  }, [iframeWindow]);

  const handleIframeLoad = () => {
    const iframeDoc = ref.current?.contentDocument || ref.current?.contentWindow?.document;
    if (iframeDoc) {
      iframeDoc.onmouseup = handleTextSelection;
    }
    prettifyHtml(ref.current?.contentDocument?.body, debug);
    setContentRendered(true);
    handleHighlight();
  };

  useEffect(() => {
    ref.current?.contentDocument?.body.style.setProperty('scale', scale.toString());
  }, [scale]);

  useEffect(() => {
    const currentIframe = ref.current;

    return () => {
      if (currentIframe && currentIframe.contentDocument) {
        currentIframe.contentDocument.onmouseup = null;
      }
    };
  }, []);

  return (
    <div className='file-viewer file-viewer--html'>
      {isDownloading || isLoading || isError ? (
        <FileLoader loading={isDownloading || isLoading} error={isError} />
      ) : (
        <>
          <ScrollSyncPane attachTo={{ current: ref.current?.contentDocument?.body }}>
            <iframe title='Document view' src={documentUrl} ref={ref} onLoad={handleIframeLoad} />
          </ScrollSyncPane>

          <ViewerToolbar
            handleZoom={handleZoom}
            handleSwitch={handleSwitch}
            searchOption={searchOption}
            method={method}
          />
        </>
      )}
    </div>
  );
};
