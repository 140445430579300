import React from 'react';
import { Button, Icon, Tooltip } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { BarButtonProps } from './BarButton.types';
import { CounterInCompare } from '../CompareButton';
import { FileTypeIcon } from '../../StaticComponents/FileTypeIcon';
import { mapUploadedFileType } from '../../../store/files/upload/upload.helpers';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import './BarButton.scss';

export const BarButton = React.forwardRef<HTMLButtonElement, BarButtonProps>(
  ({ handleClose, handleClick, name, docIndexOfCompare, className }, ref) => {
    return (
      <Tooltip title={name} key={name}>
        <Button
          ref={ref}
          className={`document-bar-list__file ${
            docIndexOfCompare ? 'file-to-compare' : ''
          } ${className}`}
          startIcon={
            docIndexOfCompare ? (
              <CounterInCompare numberInCompare={docIndexOfCompare} />
            ) : (
              <Icon>
                <FileTypeIcon fileType={mapUploadedFileType(name)} />
              </Icon>
            )
          }
          endIcon={
            <CloseIcon
              className='close'
              onClick={handleClose}
              data-test-id={TEST_ID.DOC_BAR.CLOSE_DOC}
            />
          }
          onClick={handleClick}
          data-test-id={TEST_ID.DOC_BAR.OPEN_DOC}
        >
          <span>{name}</span>
        </Button>
      </Tooltip>
    );
  }
);
