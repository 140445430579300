import { ClauseMetadata } from '../../UploadClause/UploadClause.types';
import { ClauseMetadataUpdateRequest } from '../../../store/files/clauses/clauses.list.types';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';

export const removeNonEditableFields = (
  data: ClauseMetadata,
  nonEditableEntities: MetadataDictionaryValue[]
): ClauseMetadata => {
  const nonEditableEntityValues = new Set(nonEditableEntities.map((item) => item.value));

  const editableEntities = data.Entities.filter((entity) => !nonEditableEntityValues.has(entity));

  return {
    ...data,
    Entities: editableEntities,
  };
};

export const mapToClauseMetadataUpdateRequestDto = (
  data: ClauseMetadata
): ClauseMetadataUpdateRequest => {
  return {
    ClauseName: data.ClauseName || '',
    Entities: data.Entities || [],
    Lobs: data.Lobs || [],
    Lops: data.Lops || [],
    ClauseSources: data.ClauseSources || [],
    IndividualizationReason: data.IndividualizationReason || '',
    Countries: data.Countries || [],
    BusinessTypes: data.BusinessTypes || [],
    Classification: data.Classification || '',
    ClauseTypes: data.ClauseTypes || [],
    Tag: data.Tag || '',
    ClauseStatus: data.ClauseStatus || '',
    Remarks: data.Remarks || '',
    Content: data.Content || '',
  };
};
