import { ClauseMetadata } from './UploadClause.types';
import {
  CreateClauseRequest,
  RecreateClauseRequest,
} from '../../store/files/clauses/clauses.list.types';
import { MIN_CLAUSE_WORD_COUNT } from '../../config/config';

export const mapCreate = (
  DocumentId: string | undefined,
  clause: ClauseMetadata
): CreateClauseRequest => {
  return {
    ...clause,
    ExtractedFrom: DocumentId,
  };
};

export const mapRecreate = (
  ClauseId: string,
  {
    ClauseName,
    ClauseNumber,
    ClauseSources,
    ClauseStatus,
    ClauseTypes,
    Content,
    IndividualizationReason,
    Remarks,
  }: ClauseMetadata
): RecreateClauseRequest => {
  return {
    ClauseId,
    ClauseName,
    ClauseNumber,
    ClauseSources,
    ClauseStatus,
    ClauseTypes,
    Content,
    IndividualizationReason,
    Remarks,
  };
};

export const countWords = (value: string) => {
  const segmenter = new Intl.Segmenter(undefined, { localeMatcher: 'lookup', granularity: 'word' });
  const matches = Array.from(segmenter.segment(value));
  return matches.filter(({ isWordLike, segment }) => isWordLike && !segment.match(/\d+/g)).length;
};

export const hasMinimumWords = (value: string) => {
  return countWords(value) >= MIN_CLAUSE_WORD_COUNT;
};
