import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import React, { useEffect, useState } from 'react';
import { SearchFieldProps } from './SearchField.types';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import './SearchField.scss';
import { DeleteOutline } from '@mui/icons-material';

export const SearchField: React.FC<SearchFieldProps> = ({
  searchQuery,
  setSearchQuery,
  selected,
  onApply = () => {},
  onDelete,
  onClick = () => {},
}) => {
  const [searchQueryInput, setSearchQueryInput] = useState<string>(searchQuery);

  useEffect(() => {
    setSearchQueryInput(searchQuery);
  }, [searchQuery]);

  return (
    <div className='search-field-wrapper'>
      <TextField
        className={`search-field ${selected ? 'search-field--selected' : ''}`}
        data-test-id={TEST_ID.SEARCH_QUERY.FIELD}
        placeholder='Type to search'
        size='small'
        value={searchQueryInput}
        onClick={onClick}
        onChange={(e) => setSearchQueryInput(e.target.value)}
        onBlur={() => {
          setSearchQuery(searchQueryInput);
        }}
        InputProps={{
          endAdornment: (
            <>
              {searchQueryInput && (
                <IconButton
                  data-test-id={TEST_ID.SEARCH_QUERY.FIELD_CLEAN}
                  className='search-field__close-end-icon--visible'
                  onClick={() => {
                    setSearchQueryInput('');
                    setSearchQuery('');
                    onApply();
                  }}
                >
                  <CloseIcon />
                </IconButton>
              )}
            </>
          ),
        }}
      />

      <div className='search-field__after-icon'>
        {onDelete && selected ? (
          <IconButton
            data-test-id={TEST_ID.SEARCH_QUERY.FIELD_CLEAN}
            className='search-field__close-end-icon--visible'
            onClick={onDelete}
          >
            <DeleteOutline />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};
