import React, { useMemo } from 'react';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import DatePicker from '@mui/lab/DatePicker';
import DateTimePicker from '@mui/lab/DateTimePicker';
import InputBase from '@mui/material/InputBase';
import { getDatePickerProps } from './GridEditDateCell.helpers';

export function GridEditDateCell({
  id,
  field,
  value,
  colDef,
  row,
  ...props
}: GridRenderEditCellParams<Date | string | null>) {
  const apiRef = useGridApiContext();

  const Component = colDef.type === 'dateTime' ? DateTimePicker : DatePicker;

  const handleChange = async (value: Date | null, keyboardInput?: string) => {
    await apiRef.current.setEditCellValue({ id, field, value });
    if (keyboardInput) return;
    apiRef.current.stopCellEditMode({ id, field });
  };

  const customProps = useMemo(() => getDatePickerProps(field, row), [field, row]);

  return (
    <Component
      {...props}
      {...customProps}
      value={value}
      renderInput={({ inputRef, inputProps, InputProps, disabled, error }) => (
        <InputBase
          className='date-input'
          fullWidth
          autoFocus
          ref={inputRef}
          {...InputProps}
          disabled={disabled}
          error={error || props.error}
          inputProps={inputProps}
        />
      )}
      onChange={handleChange}
    />
  );
}
