import './Reports.scss';
import MyReportsTab from './MyReportsTab';
import { useBulkDeleteReports } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal.hooks';
import { BulkDeleteModal } from '../StaticComponents/BulkDeleteModal/BulkDeleteModal';
import { Tabs } from '../StaticComponents/Tabs/Tabs';
import { TAB_NAMES } from '../../config/config';
import { getRoutePath, RoutePath } from '../../services/route.service';
import { ReportsTab } from './ReportsTab';
import { useReadAccess, useUserAccessContext } from '../../contexts/UserAccess';
import { useLocation } from 'react-router-dom';

export const Reports = () => {
  useReadAccess();

  const location = useLocation();
  const isMyReportsTab =
    location.pathname === getRoutePath(RoutePath.REPORTS, RoutePath.MY_REPORTS);
  const { hasReportsTabAccess } = useUserAccessContext();

  return (
    <div className='reports'>
      <div className='reports-title'>
        <span>Reports</span>
        <div>{isMyReportsTab && <BulkDeleteModal handler={useBulkDeleteReports} />}</div>
      </div>
      <Tabs
        base={RoutePath.REPORTS}
        components={[
          {
            label: TAB_NAMES.REPORTS,
            url: RoutePath.REPORTS_REPORTS,
            component: <ReportsTab />,
            disabled: !hasReportsTabAccess,
          },
          {
            label: TAB_NAMES.MY_REPORTS,
            url: RoutePath.MY_REPORTS,
            component: <MyReportsTab />,
          },
        ]}
      />
    </div>
  );
};
