import React from 'react';
import './PanelCard.scss';
import { PanelCardProps } from './PanelCard.types';

export const PanelCard: React.FC<PanelCardProps> = ({
  icon,
  title,
  handleClick,
  selected,
  disabled,
}) => {
  return (
    <div
      className={`panel-card ${disabled ? 'disabled' : ''} ${
        selected && !disabled ? 'selected' : ''
      }`}
      onClick={handleClick}
    >
      {icon}
      <div>{title}</div>
    </div>
  );
};
