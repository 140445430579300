import { DOCUMENT_LABELS } from '../../../../config/config';
import React from 'react';
import { FilterPanelItem } from './FilterPanelItem';
import { DocumentFiltersPanel } from './DocumentFiltersPanel';
import { FiltersPanelProps } from './FiltersPanel.types';
import { ClauseFiltersPanel } from './ClauseFiltersPanel';
import { getExcluded, getLang, getMethod } from '../../../Documents/DocumentsCount.helpers';

const SEQUENTIAL_SEARCH_SEPARATOR = ' | ';

export const FiltersPanel = ({
  filters,
  dictionaries,
  dictionariesClauses,
  clauseMode,
}: FiltersPanelProps) => {
  if (!dictionaries) return null;
  const searchQueries = filters.Queries?.map(({ SearchQuery }) => SearchQuery).join(
    SEQUENTIAL_SEARCH_SEPARATOR
  );
  const searchMethods = filters.Queries?.map(({ SearchMethod }) => SearchMethod)
    .map(getMethod)
    .join(SEQUENTIAL_SEARCH_SEPARATOR);
  const originalLanguages = filters.Queries?.map(({ OriginalLanguage }) => OriginalLanguage)
    .map(getLang)
    .join(SEQUENTIAL_SEARCH_SEPARATOR);
  const excludedPhrases = filters.Queries?.map(({ ExcludePhrases }) => ExcludePhrases)
    .map(getExcluded)
    .join(SEQUENTIAL_SEARCH_SEPARATOR);

  return (
    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
      <FilterPanelItem filter={searchQueries} label={DOCUMENT_LABELS.SEARCH_QUERY} />

      <FilterPanelItem filter={searchMethods} label={DOCUMENT_LABELS.SEARCH_METHOD} />

      <FilterPanelItem filter={originalLanguages} label={DOCUMENT_LABELS.ORIGINAL_LANGUAGE} />

      <FilterPanelItem filter={excludedPhrases} label={DOCUMENT_LABELS.EXCLUDED_WORDS} />

      {clauseMode ? (
        <ClauseFiltersPanel filters={filters} dictionaries={dictionariesClauses} />
      ) : (
        <DocumentFiltersPanel filters={filters} dictionaries={dictionaries} />
      )}
    </div>
  );
};
