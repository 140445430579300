import {
  documentLabelMapping,
  DocumentsListParams,
  DocumentsListParamsNames,
  DocumentStatus,
} from './documents.list.types';
import { omit } from 'lodash';
import { getFilteredParams } from '../documentsAndClauses/list.helpers';
import { ClausesListParams, ClausesListParamsNames } from '../clauses/clauses.list.types';
import { defaultSequentialSearch } from '../upload/list.helpers';
import { SEQUENTIAL_SEARCH } from '../documentsAndClauses/list.types';
import { DocumentMetadataFields } from '../../../components/UploadFiles/MetadataForm/MetadataForm.types';

const filterFields: DocumentsListParamsNames[] = [
  'Entity',
  'Country',
  'Lob',
  'Lop',
  'BusinessType',
  'DocumentType',
  'Classification',
  'Language',
  'Active',
  'LatestVersion',
];

const filterClausesFields: ClausesListParamsNames[] = [
  'Entities',
  'Countries',
  'Lobs',
  'Lops',
  'ClauseSources',
  'BusinessTypes',
  'ClauseTypes',
  'Classifications',
  'Language',
  'Provider',
  'Tag',
  'ClauseStatus',
];

export const getOnlyFilteredParams = (
  values: DocumentsListParams | ClausesListParams,
  clauseMode = false
) => {
  const fields = clauseMode ? filterClausesFields : filterFields;
  return getFilteredParams(values).filter(([key]) =>
    fields.includes(key as keyof (DocumentsListParams | ClausesListParams))
  );
};

export const cleanupFilters = (values: DocumentsListParams, clauseMode = false) => {
  return {
    ...omit(values, clauseMode ? filterClausesFields : filterFields),
    [SEQUENTIAL_SEARCH]: [defaultSequentialSearch()],
  };
};

export const getDocumentStatusByValue = (searchValue: string) =>
  Object.entries(DocumentStatus).find(([, value]) => value === searchValue)?.[0] || '';

export const getDocumentLabel = (key: DocumentMetadataFields): string | undefined => {
  return documentLabelMapping[key];
};
