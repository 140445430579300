import { Button, Tooltip } from '@mui/material';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { documentsSelectors } from '../../../store/files/documents/documents.selectors';
import { RoutePath } from '../../../services/route.service';
import { closeClauseViewModal } from '../../../store/files/clauses/clauses.slice';
import { useNavigate } from 'react-router-dom';
import { ClauseResponse } from '../../../store/files/clauses/clauses.list.types';
import { LanguageToggleSwitchTypes } from '../../StaticComponents/LanguageToggleSwitch/LanguageToggleSwitch.types';
import { useGetClauseDictionariesQueryDefaults } from '../../../store/files/upload/list.service.hooks';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { getTooltipMessage, isButtonVisible } from './AdjustClauseButton.helpers';
import { useUserAccessContext } from '../../../contexts/UserAccess';

export const AdjustClauseButton = ({
  clause,
  language,
}: {
  clause: ClauseResponse;
  language: LanguageToggleSwitchTypes;
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { hasReportsTabAccess } = useUserAccessContext();

  const { data: writeAccessDictionaries } = useGetClauseDictionariesQueryDefaults(
    { AccessMode: AccessMode.WRITE },
    { skip: !clause }
  );

  const selectedText: string = useAppSelector(documentsSelectors.selectSelectedText);

  const buttonVisible = isButtonVisible(
    clause,
    language,
    writeAccessDictionaries,
    hasReportsTabAccess
  );
  if (!buttonVisible) return null;

  const tooltipMessage = getTooltipMessage(clause, selectedText);

  const handleAdjustClauseClick = () => {
    navigate(RoutePath.UPLOAD_CLAUSE, { state: { clause } });
    dispatch(closeClauseViewModal());
  };

  return (
    <Tooltip title={tooltipMessage}>
      <span>
        <Button
          disabled={!!tooltipMessage}
          onClick={handleAdjustClauseClick}
          variant='text'
          startIcon={<PlaylistAddCheckIcon className='document-view-icons' />}
        >
          Adjust clause
        </Button>
      </span>
    </Tooltip>
  );
};
