import { UploadedDocuments } from './UploadedDocuments';
import { useUserAccessContext } from '../../contexts/UserAccess';
import { REPORTED_DOCS_CONFIG, REPORTED_READ_DOCS_CONFIG } from './UploadedDocuments.helpers';

export const ReportedUploadedDocuments = () => {
  const { hasDocumentReadReportedAccess } = useUserAccessContext();

  return (
    <UploadedDocuments
      config={hasDocumentReadReportedAccess ? REPORTED_READ_DOCS_CONFIG : REPORTED_DOCS_CONFIG}
    />
  );
};
