export const timeoutPromise = async (timeout: number) =>
  new Promise((resolve) => setTimeout(resolve, timeout));

export const toStrArray = (param: string | string[] | undefined): string[] => {
  if (param) {
    if (Array.isArray(param)) {
      return param;
    } else {
      return [param];
    }
  }
  return [];
};
