import React from 'react';
import './ReportsTab.scss';
import { ReportsTabSection } from './ReportsTabSection';
import { ReportSectionProps } from './ReportsTabSection.types';
import { Paper } from '@mui/material';
import { REPORTS_LIST_REFRESH_INTERVAL_FOR_BLOCKING } from '../../config/config';
import { useGetReportsQuery } from '../../store/files/reports/reports.service';
import { REPORT_STATUS, ReportType } from '../../store/files/reports/reports.types';

const reportSectionsData: ReportSectionProps[] = [
  {
    title: 'Clauses summary per company',
    type: 'ClauseSummary',
    description: (
      <div>
        The report presents a list of clause categories with the number of specific clauses from
        each company.
        <br />
        The report contains clauses uploaded within the selected period.
      </div>
    ),
  },
  {
    title: 'Clause list',
    type: 'ClauseList',
    description: (
      <div>
        The report presents a list of clauses with additional metadata (Tag, Company, Line of
        business) and the number of linked documents for each clause.
        <br />
        The report contains clauses uploaded within the selected period.
      </div>
    ),
  },
  {
    title: 'Reported clauses',
    type: 'ReportedClauses',
    description: (
      <div>
        The report presents a list of reported clauses with all the clause's metadata and the number
        of linked documents to each clause.
        <br />
        The report contains clauses reported within the selected period.
      </div>
    ),
  },
  {
    title: 'Related documents',
    type: 'RelatedDocuments',
    description: (
      <div>
        The report presents a list of clauses with their relation to specific documents.
        <br />
        The report contains clauses uploaded within the selected period.
      </div>
    ),
  },
];

export const ReportsTab = () => {
  const { data } = useGetReportsQuery(undefined, {
    pollingInterval: REPORTS_LIST_REFRESH_INTERVAL_FOR_BLOCKING,
    refetchOnMountOrArgChange: true,
  });

  const checkIfReportOfTypeInProgress = (type: ReportType) => {
    return !!data?.filter(
      (report) =>
        (report?.Status === REPORT_STATUS.REQUESTED ||
          report.Status === REPORT_STATUS.IN_PROCESSING) &&
        report?.ReportType === type
    ).length;
  };

  return (
    <div className='reports-container'>
      {reportSectionsData.map(({ title, type, description }, index) => (
        <Paper key={index} className='reports-section-paper'>
          <ReportsTabSection
            title={title}
            type={type}
            description={description}
            loading={checkIfReportOfTypeInProgress(type)}
          />
        </Paper>
      ))}
    </div>
  );
};
