import Grid from '@mui/material/Grid';
import { CLAUSE_LABELS, DOCUMENT_LABELS } from '../../../config/config';
import { CLAUSE_SOURCE_ERGO_INDIVIDUAL } from '../../../store/files/upload/list.helpers';
import { renderItemOrListOfItems } from '../../Documents/DocumentViewModal/MetadataPanel.helper';
import { ClauseMetadataPanelProps } from './ClauseMetadataPanel.types';
import { formatUiDateTimeFromString } from '../../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { ReportedField } from '../../SharedComponents/ReportedField/ReportedField';

export const ClauseMetadataPanel = ({ dictionaries, clause }: ClauseMetadataPanelProps) => (
  <div className='metadata-view'>
    <div className='metadata-view-block'>
      <ReportedField item={clause} dictionaries={dictionaries} />
      <Grid container rowSpacing={2}>
        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.COMPANY_NAME}:</div>
        </Grid>
        <Grid item xs={8}>
          {renderItemOrListOfItems(dictionaries?.Entities, clause?.Entities)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.COUNTRY}:</div>
        </Grid>
        <Grid item xs={8}>
          {renderItemOrListOfItems(dictionaries?.Countries, clause?.Countries, true)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.BUSINESS_LINE}:</div>
        </Grid>
        <Grid item xs={8}>
          {renderItemOrListOfItems(dictionaries?.Lobs, clause?.Lobs)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.PRODUCT_LINE}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{renderItemOrListOfItems(dictionaries?.Lops, clause?.Lops)}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.CLASSIFICATION}:</div>
        </Grid>
        <Grid item xs={8}>
          {renderItemOrListOfItems(dictionaries?.Classification, clause?.Classification)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.ORIGINAL_LANGUAGE}:</div>
        </Grid>
        <Grid item xs={8}>
          {renderItemOrListOfItems(dictionaries?.Language, clause?.Language)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{CLAUSE_LABELS.CLAUSE_SOURCES}:</div>
        </Grid>
        <Grid item xs={8}>
          {renderItemOrListOfItems(dictionaries?.ClauseSources, clause?.ClauseSources)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.BUSINESS_TYPE}:</div>
        </Grid>
        <Grid item xs={8}>
          {renderItemOrListOfItems(dictionaries?.BusinessTypes, clause?.BusinessTypes)}
        </Grid>
        {clause?.ClauseSources.includes(CLAUSE_SOURCE_ERGO_INDIVIDUAL) && (
          <>
            <Grid item xs={4}>
              <div className='bold'>{CLAUSE_LABELS.REASONS_FOR_INDIVIDUALIZATION}:</div>
            </Grid>
            <Grid item xs={8}>
              {clause?.IndividualizationReason}
            </Grid>
          </>
        )}

        <Grid item xs={4}>
          <div className='bold'>{CLAUSE_LABELS.TYPE_OF_CLAUSE}:</div>
        </Grid>
        <Grid item xs={8}>
          {renderItemOrListOfItems(dictionaries?.ClauseTypes, clause?.ClauseTypes)}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{CLAUSE_LABELS.CLAUSE_NUMBER}:</div>
        </Grid>
        <Grid item xs={8}>
          {clause?.ClauseNumber}
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.STATUS}:</div>
        </Grid>
        <Grid item xs={8} className='metadata-view-block-icon-container'>
          {clause.ClauseStatus}
        </Grid>

        {clause.Remarks && (
          <>
            <Grid item xs={4}>
              <div className='bold'>{DOCUMENT_LABELS.REMARKS}:</div>
            </Grid>
            <Grid item xs={8} className='metadata-view-block-icon-container'>
              {clause.Remarks}
            </Grid>
          </>
        )}

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.DOCUMENT_ID}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{clause?.ClauseId}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.UPDATED_BY}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{clause?.EditedBy}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.UPDATED_AT}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{formatUiDateTimeFromString(clause?.EditedAt)}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.CREATED_BY}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{clause?.UploadedBy}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.CREATED_AT}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{formatUiDateTimeFromString(clause?.UploadedAt)}</div>
        </Grid>

        <Grid item xs={4}>
          <div className='bold'>{DOCUMENT_LABELS.TAG}:</div>
        </Grid>
        <Grid item xs={8}>
          <div>{clause?.Tag}</div>
        </Grid>
      </Grid>
    </div>
  </div>
);
