import { RegisterOptions } from 'react-hook-form';

type KnownKeys = 'Email' | 'UserGroups';

type ValidationRules = {
  [key in KnownKeys]?: RegisterOptions;
};

export const CreateUserPanelRules: ValidationRules = {
  Email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Invalid email address',
    },
  },
  UserGroups: {
    required: 'Select user group to create user',
  },
};
