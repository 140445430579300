import { Controller } from 'react-hook-form';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { VALIDATION_RULES } from './NameInput.helpers';
import { NameInputProps } from './NameInput.types';
import { FILE_EXTENSIONS } from '../../../store/ui/ui.types';
import { FileNameInput } from '../../StaticComponents/FileNameInput/FileNameInput';

export const NameInput: React.FC<NameInputProps> = ({
  control,
  isLoading,
  name = '',
  itemType,
  clearApiError,
  reset,
  sanitizeExtenstion = false,
}) => {
  useEffect(() => {
    return () => {
      if (reset) {
        reset({ Name: '' });
      }
    };
  }, [reset]);
  const handleKeyDown = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    clearApiError();
  };

  const inputProps = sanitizeExtenstion
    ? {
        inputComponent: FileNameInput,
        inputProps: { fileExtension: FILE_EXTENSIONS.CSV },
      }
    : {};

  return (
    <Controller
      control={control}
      name='Name'
      defaultValue={name}
      rules={VALIDATION_RULES[itemType]}
      render={({ field, fieldState: { error } }) => {
        return (
          <TextField
            {...field}
            disabled={isLoading}
            className='single-input-modal-name'
            placeholder={'Type ' + itemType.toLowerCase() + ' name'}
            fullWidth
            size='small'
            variant='outlined'
            onKeyDown={handleKeyDown}
            helperText={
              error && (
                <span className='single-input-modal-helper-text'>
                  <WarningAmberOutlinedIcon /> <span>{error.message}</span>
                </span>
              )
            }
            InputProps={inputProps}
          />
        );
      }}
    />
  );
};
