import { ButtonWithIcon } from '../StaticComponents/ButtonWithIcon';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import React from 'react';
import { SearchButtonProps } from './SearchButton.types';

export const SearchButton = ({
  fullscreen,
  showSearch,
  setShowSearch,
  isPendingTab,
}: SearchButtonProps) => {
  return fullscreen ? null : (
    <ButtonWithIcon
      startIcon={showSearch ? <CloseIcon fontSize='large' /> : <SearchIcon fontSize='large' />}
      onClick={() => setShowSearch(!showSearch)}
      disabled={isPendingTab}
    >
      {showSearch ? 'Hide search' : 'Search'}
    </ButtonWithIcon>
  );
};
