import { Navigate } from 'react-router-dom';
import { useUserAccessContext } from '../../contexts/UserAccess';
import { initialPage } from '../Auth/auth.helpers';
import { RoutePath } from '../../services/route.service';

export const MainPageRedirect = () => {
  const { hasDocumentReadAccess, hasClauseReadAccess, hasAdminPanelAccess } =
    useUserAccessContext();
  const mainPage = hasDocumentReadAccess
    ? RoutePath.DOCUMENTS
    : hasClauseReadAccess
    ? RoutePath.CLAUSES
    : hasAdminPanelAccess
    ? RoutePath.ADMIN_PANEL
    : RoutePath.NO_ACCESS;
  return <Navigate to={initialPage || mainPage} />;
};
