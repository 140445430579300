import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { SnackbarProps } from '@mui/material/Snackbar/Snackbar';
import './MarsSnackbar.scss';

export const MarsSnackbar = (props: SnackbarProps) => {
  return (
    <Snackbar
      {...props}
      className='mars-snackbar'
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    />
  );
};
