import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

const ATTENTION_REASON_ENTITY =
  'Attention! Changing the entity will remove previous privileges. Would You like to continue?';
const ATTENTION_REASON_STATUS =
  "Attention! Changing the status will remove user's privileges. Would You like to continue?";

export enum EDIT_REASON {
  STATUS = 'STATUS',
  ENTITY = 'ENTITY',
}

export const EditAttention: React.FC<{ reason: EDIT_REASON }> = ({ reason }) => {
  return (
    <div className='edit-users-group-form__attention'>
      <WarningAmberOutlinedIcon className='warning' />
      <p>
        {reason === EDIT_REASON.ENTITY
          ? ATTENTION_REASON_ENTITY
          : reason === EDIT_REASON.STATUS
          ? ATTENTION_REASON_STATUS
          : ''}
      </p>
    </div>
  );
};
