import React, { useCallback, useEffect, useRef, useState } from 'react';
import UploadService from '../../../store/files/upload/upload.service';
import { FileViewerTxtProps } from './FileViewer.types';
import { FileLoader } from './FileLoader';
import { useFileLoader } from './FileLoader.hook';
import './FileViewer.scss';
import { searchHighlight } from './TxtViewer.highlight';
import { ViewerToolbar } from './ViewerToolbar/ViewerToolbar';
import { useMarkSwitchForTxtViewer, useZoomSwitch } from './FileViewer.helpers';
import { useCompareClauseText } from './TxtViewer.helpers';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { setCompareClauseReferences } from '../../../store/ui/ui.slice';
import { useSearch } from './ViewerToolbar/SearchContext';
import { SearchMethod } from '../../../store/files/documentsAndClauses/list.types';
import { useTextSelectionDispatch } from './FileViewer.hook';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { ScrollSyncPane } from 'react-scroll-sync';

export const TxtViewer = ({
  loading,
  error,
  url,
  keyword,
  method,
  order,
  searchOption,
}: FileViewerTxtProps) => {
  const dispatch = useAppDispatch();

  const { reference1, reference2 } =
    useAppSelector(uiSelectors.selectCompareClauseReferences) ?? {};

  const compareMode = useAppSelector(uiSelectors.selectCompareMode);

  const { isLoading, isError, setLoading, setError } = useFileLoader(loading, error);
  const [text, setText] = useState('');
  const { searchTerm } = useSearch();
  const exact = keyword ? method === SearchMethod.EXACT : true;

  const content = text ? searchHighlight(text, searchTerm, exact) : '';
  const { diff, compareClauseText, multipleComparison } = useCompareClauseText(order);
  const isDiffStringifiedHtml = typeof diff === 'string';
  const shouldRenderStringifiedHtml = isDiffStringifiedHtml || !diff?.length || order === undefined;

  const innerHtmlContent = isDiffStringifiedHtml && order !== undefined ? diff : content;

  const ref = useRef<HTMLDivElement>(null);
  const { scale, handleZoom } = useZoomSwitch(url, true);
  const { initialize, handleSwitch } = useMarkSwitchForTxtViewer();

  const handleCompare = useCallback(() => {
    if (reference1 && text && multipleComparison) {
      compareClauseText(reference1?.clauseText, text);
    } else if (reference1 && reference2) {
      compareClauseText(reference1?.clauseText, reference2?.clauseText);
    }
  }, [compareClauseText, multipleComparison, reference1, reference2, text]);

  const handleAddClauseReference = useCallback(
    (text: string) => {
      if (order === 0 || order === 1) {
        dispatch(
          setCompareClauseReferences({
            clauseText: text,
            referenceNumber: order,
          })
        );
      }
    },
    [dispatch, order]
  );

  useEffect(() => {
    handleCompare();
  }, [handleCompare, compareMode]);

  useEffect(() => {
    if (url === '') {
      setError(true);
      handleAddClauseReference('');
      setText('');
    } else if (url) {
      setLoading(true);
      setError(false);
      UploadService.documentDownload(url)
        .catch(() => setError(true))
        .then((response) => {
          setLoading(false);
          handleAddClauseReference(response?.data);

          setText(response?.data);
          initialize(ref.current);
        });
    }
  }, [
    compareClauseText,
    diff,
    dispatch,
    handleAddClauseReference,
    initialize,
    method,
    order,
    reference1,
    reference2,
    setError,
    setLoading,
    url,
  ]);

  const { handleTextSelectionDispatch } = useTextSelectionDispatch();

  const handleTextSelection = async () => {
    await timeoutPromise(0);
    const selectionObj = window.getSelection();
    handleTextSelectionDispatch(selectionObj);
  };

  return (
    <div className='file-viewer file-viewer--txt'>
      {isLoading || isError ? (
        <FileLoader loading={isLoading} error={isError} />
      ) : (
        <>
          <ScrollSyncPane>
            <div ref={ref} className='file-viewer--txt__content'>
              <div style={{ scale: scale.toString() }}>
                {shouldRenderStringifiedHtml ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: innerHtmlContent }}
                    onMouseUp={handleTextSelection}
                  />
                ) : (
                  <div>
                    {diff?.map(({ added, removed, value }, index) => {
                      const suffix = `${added ? '--added' : ''}${removed ? '--removed' : ''}`;
                      return (
                        <span key={index} className={`file-viewer--txt__content__text${suffix}`}>
                          {value}
                        </span>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </ScrollSyncPane>

          <ViewerToolbar
            handleZoom={handleZoom}
            handleSwitch={handleSwitch}
            searchOption={searchOption}
            method={method}
          />
        </>
      )}
    </div>
  );
};
