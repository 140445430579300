import React, { useCallback, useEffect } from 'react';
import _, { cloneDeep, isEmpty } from 'lodash';
import {
  filterBusinessTypeValues,
  filterCountryValues,
  filterEntityValuesBasedOnFields,
  filterLobValues,
  filterLopValues,
} from './DocumentsSearch.helpers';
import { DocumentsListParams } from '../../../store/files/documents/documents.list.types';
import { DocumentSearchFieldsProps } from './SearchFilters.types';
import {
  DictionariesResponse,
  MetadataDictionaryValue,
} from '../../../store/files/upload/list.service.types';

export const NARROW_FIELDS = ['Entity', 'Country', 'Lop', 'Lob', 'BusinessType'];

interface UseNarrowDownFilters {
  filtersApplied: DocumentSearchFieldsProps['filtersApplied'];
  lastSelected?: string;
  entityValues?: MetadataDictionaryValue[];
  isDropDownOpen: boolean;
  dictionaries?: DictionariesResponse;
  setCountryValues: React.Dispatch<MetadataDictionaryValue[] | undefined>;
  setLobValues: React.Dispatch<MetadataDictionaryValue[] | undefined>;
  setLopValues: React.Dispatch<MetadataDictionaryValue[] | undefined>;
  setBusinessType: React.Dispatch<MetadataDictionaryValue[] | undefined>;
  setEntityValues: React.Dispatch<MetadataDictionaryValue[] | undefined>;
}

export const isItTheOnlyFilterSelected = (
  field: keyof DocumentsListParams,
  filtersApplied: DocumentSearchFieldsProps['filtersApplied']
) => {
  const filtersSelected = _.omitBy(_.pick(filtersApplied, NARROW_FIELDS), isEmpty);
  return Object.keys(filtersSelected)?.length === 1 && filtersSelected?.[field]?.length;
};

export const getEntitiesFilters = (
  filtersApplied: DocumentSearchFieldsProps['filtersApplied'],
  entityValues?: MetadataDictionaryValue[]
) => {
  if (filtersApplied.Entity?.length) return filtersApplied.Entity;
  else return entityValues?.map(({ value }) => value);
};

export const useNarrowDownFilters = ({
  filtersApplied,
  lastSelected,
  entityValues,
  isDropDownOpen,
  dictionaries,
  setCountryValues,
  setLobValues,
  setLopValues,
  setBusinessType,
  setEntityValues,
}: UseNarrowDownFilters) => {
  const isThereOnlyOneFilterSelected =
    Object.keys(_.omitBy(_.pick(filtersApplied, NARROW_FIELDS), isEmpty))?.length === 1;

  const isNotLastSelected = useCallback(
    (field: keyof DocumentsListParams) => {
      return lastSelected !== field;
    },
    [lastSelected]
  );

  const getFiltersValues = useCallback(
    (
      field: keyof DocumentsListParams,
      filtersSelected: Partial<DocumentsListParams>,
      setLobDicts: boolean = false
    ) => {
      const filtersSelectedTmp = cloneDeep(filtersSelected);
      if (isItTheOnlyFilterSelected(field, filtersApplied)) {
        filtersSelectedTmp['Entity'] = dictionaries?.Entity.values.map(({ value }) => value);
        if (setLobDicts)
          filtersSelectedTmp['Lob'] = dictionaries?.Lob.values.map(({ value }) => value);
      } else {
        filtersSelectedTmp['Entity'] = getEntitiesFilters(filtersApplied, entityValues);
      }
      return filtersSelectedTmp;
    },
    [dictionaries, entityValues, filtersApplied]
  );

  useEffect(() => {
    if (!isDropDownOpen || isThereOnlyOneFilterSelected) {
      let filtersSelected: Partial<DocumentsListParams> = _.pick(filtersApplied, NARROW_FIELDS);
      filtersSelected['Entity'] = getEntitiesFilters(filtersApplied, entityValues);
      let newLobs = undefined;
      if (isNotLastSelected('Lob')) {
        filtersSelected = getFiltersValues('Lob', filtersSelected);
        newLobs = filterLobValues(dictionaries, filtersSelected);
        setLobValues(newLobs);
      }
      if (isNotLastSelected('Lop')) {
        let filtersSelectedForLop = cloneDeep(filtersSelected);
        filtersSelectedForLop['Lob'] = filtersApplied.Lob?.length
          ? filtersApplied.Lob
          : newLobs?.map(({ value }) => value);
        filtersSelectedForLop = getFiltersValues('Lop', filtersSelectedForLop, true);
        setLopValues(filterLopValues(dictionaries, filtersSelectedForLop));
      }
      if (isNotLastSelected('BusinessType')) {
        filtersSelected = getFiltersValues('BusinessType', filtersSelected);
        setBusinessType(filterBusinessTypeValues(dictionaries, filtersSelected));
      }
      if (isNotLastSelected('Country')) {
        filtersSelected = getFiltersValues('Country', filtersSelected);
        setCountryValues(filterCountryValues(dictionaries, filtersSelected));
      }
    }
  }, [
    dictionaries,
    entityValues,
    filtersApplied,
    getFiltersValues,
    isDropDownOpen,
    isNotLastSelected,
    isThereOnlyOneFilterSelected,
    setBusinessType,
    setCountryValues,
    setLobValues,
    setLopValues,
  ]);

  useEffect(() => {
    if (!isDropDownOpen && lastSelected !== 'Entity') {
      const filtersSelected = _.pick(filtersApplied, NARROW_FIELDS);
      if (Object.keys(filtersSelected).length) {
        const entities = filterEntityValuesBasedOnFields(filtersSelected, dictionaries?.Entity);
        setEntityValues(entities);
      }
    }
  }, [dictionaries, filtersApplied, isDropDownOpen, lastSelected, setEntityValues]);
};
