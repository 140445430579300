import { DictionariesParams } from './list.service.types';
import { BOOLEAN_VALUES } from '../../../components/UploadFiles/MetadataForm/MetadataFormHelpers';
import { useGetClauseDictionariesQuery, useGetDictionariesQuery } from './list.service';

const defaultDraftParam = (arg: DictionariesParams) => {
  return { ...arg, Draft: arg.Draft ?? BOOLEAN_VALUES.FALSE };
};

export const useGetDictionariesQueryDefaults: typeof useGetDictionariesQuery = (arg, options) => {
  return useGetDictionariesQuery(typeof arg === 'object' ? defaultDraftParam(arg) : arg, options);
};

export const useGetClauseDictionariesQueryDefaults: typeof useGetClauseDictionariesQuery = (
  arg,
  options
) => {
  return useGetClauseDictionariesQuery(
    typeof arg === 'object' ? defaultDraftParam(arg) : arg,
    options
  );
};
