import React from 'react';
import './ItemNameText.scss';
import { ItemNameTextProps } from './ItemNameText.types';
import { Tooltip } from '@mui/material';

export const ItemNameText = ({ fileName, strong }: ItemNameTextProps) => {
  return (
    <Tooltip title={fileName} placement='top-start'>
      <div className={strong ? 'file-name-text file-name-strong' : 'file-name-text'}>
        {fileName}
      </div>
    </Tooltip>
  );
};
