import { LicenseInfo } from '@mui/x-license-pro';
import { IdentityProvider, MimeType } from './config.types';
import { DOCUMENTS_GRID_COLUMNS } from '../components/SpreadSheet/DocumentsGrid/DocumentsGrid.types';
import { DocumentMetadataFields } from '../components/UploadFiles/MetadataForm/MetadataForm.types';
import { SearchMethod } from '../store/files/documentsAndClauses/list.types';
import { GridSortDirection } from '@mui/x-data-grid/models/gridSortModel';

export const MAX_PAGE_SIZE = 1000;
export const MAX_FILES_SIZE = 10000000;
export const MAX_WORDING_LENGTH = 9999;
export const MAX_REPORTING_REASON_COMMENT_LENGTH = 200;

export const MIME_TYPES: Record<string, MimeType> = {
  PDF: {
    mimeType: 'application/pdf',
    name: 'PDF',
  },
  HTML: {
    mimeType: 'text/html',
    name: 'HTML',
  },
};

export const IS_LANGUAGE_FILTERING_ENABLED = false;

export const DEFAULT_SEARCH_METHOD = SearchMethod.PHRASE;
export const INIT_NUMBER_OF_DOCUMENTS = 20;
export const NUMBER_OF_ITEMS_ALLOWED = [20, 50, 100];
export const PRE_LANDING_PAGE_SIZE = 1000;
export const SAVED_SEARCH_PAGE_SIZE = 50;
export const MIN_NUMBER_OF_ITEMS_TO_COMPARE = 2;
export const MAX_NUMBER_OF_DOCS_TO_COMPARE = 10;
export const MAX_NUMBER_OF_CLAUSES_TO_COMPARE = 10;
export const MAX_NUMBER_OF_MINIMIZE_DATA = 10;
export const MAX_NUMBER_OF_CLAUSES_TO_MINIMIZE = 10;

export const SEARCH_NAME_AND_BOOKMARK_MIN_LENGTH = 3;
export const SEARCH_NAME_AND_BOOKMARK_MAX_LENGTH = 100;

export const REPORT_MIN_LENGTH = 3;
export const REPORT_MAX_LENGTH = 100;

export const PENDING_LIST_REFRESH_INTERVAL = 30000;
export const REPORTS_LIST_REFRESH_INTERVAL = 5000;
export const REPORTS_LIST_REFRESH_INTERVAL_FOR_BLOCKING = 15000;

export const REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION = 1500;

export const MAX_REMARKS_LENGTH = 200;
export const MAX_PRODUCT_TYPE_LENGTH = 50;
export const MIN_PRODUCT_TYPE_LENGTH = 1;
export const MAX_INSURED_INDUSTRY_LENGTH = 50;
export const MIN_INSURED_INDUSTRY_LENGTH = 1;

export const TAB_ITEMS_PAGE_SIZE = 1000;

export const MAX_COMMENT_LENGTH = 999;
export const MIN_COMMENT_LENGTH = 1;

const AUTH_PROVIDERS_ENV: IdentityProvider[] = JSON.parse(
  process.env.REACT_APP_AUTH_PROVIDERS || '{}'
);

//@TODO ADD UT HERE
export const AUTH_PROVIDERS = AUTH_PROVIDERS_ENV.length ? AUTH_PROVIDERS_ENV : [];

const initMUIXLicense = () => {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_KEY || '');
};
initMUIXLicense();

export const TRAININGS_URL =
  'https://myergo.sharepoint.com/sites/GWR_Global/SitePages/Help-Center.aspx';

export const MY_FILES_BLOCKED_COLUMNS = {
  FORM: [DocumentMetadataFields.Entity, DocumentMetadataFields.Language],
  SPREADSHEET: [DOCUMENTS_GRID_COLUMNS.COMPANY_NAME, DOCUMENTS_GRID_COLUMNS.LANGUAGE],
};

export const UPLOAD_FILES_BLOCKED_COLUMNS = {
  FORM: [DocumentMetadataFields.Entity],
  SPREADSHEET: [DOCUMENTS_GRID_COLUMNS.COMPANY_NAME],
};

export enum DOCUMENT_TYPES {
  STANDARD_GENERAL_CONDITION = 'SGC',
  STANDARD_SPECIAL_CONDITION = 'SSC',
  MODIFICATION_TO_GENERAL_SPECIAL_CONDITIONS = 'MGSC',
  ENDORSEMENT_RELATED_TO_SOCPE_OF_COVERAGE = 'ERSC',
}

export enum CLAUSE_LABELS {
  CLAUSE_NAME = 'Clause Name',
  CLAUSE_SOURCES = 'Clause sources',
  REASONS_FOR_INDIVIDUALIZATION = 'Reasons for individualization',
  TYPE_OF_CLAUSE = 'Type of clause',
  CLAUSE_NUMBER = 'Clause number',
  CLAUSE_TYPE = 'Clause type',
  CLAUSE_SOURCE_BEST_PRACTICE = 'Mark as best practice',
  CLAUSE_SOURCE_INVALID = 'Mark as invalid',
}

export enum DOCUMENT_LABELS {
  FILE_NAME = 'File Name',
  COMPANY_NAME = 'Company',
  COUNTRY = 'Country',
  BUSINESS_LINE = 'Line of Business',
  PRODUCT_LINE = 'Product Line',
  BUSINESS_TYPE = 'Business Type',
  DOCUMENT_TYPE = 'Document Type',
  PARENT_DOCUMENTS = 'Related Documents',
  VERSION_KEY = 'Related Versions',
  CLASSIFICATION = 'Classification',
  LANGUAGE = 'Language',
  VALID_FROM = 'Valid from',
  VALID_TO = 'Policy period [until]',
  EXPIRE_AT = 'Retention date',
  VERSION = 'Version',
  ACTIVE = 'Active',
  LATEST_VERSION = 'Latest Version',
  DOCUMENT_NAME = 'Document Name',
  REMARKS = 'Remarks',
  DOCUMENT_ID = 'UUID',
  UPDATED_BY = 'Last Edited by',
  UPDATED_AT = 'Last Edited time',
  CREATED_BY = 'Submitted by',
  CREATED_AT = 'Submitted time',
  STATUS = 'Status',
  TAG = 'Tag',
  SEARCH_QUERY = 'Search query',
  SEARCH_METHOD = 'Search method',
  ORIGINAL_LANGUAGE = 'Original Language',
  PRODUCT_TYPE = 'Product Type',
  INSURED_INDUSTRY = 'Insured Industry',
  EXCLUDED_WORDS = 'Excluded words',
}

export enum DOCUMENT_METADATA_TOOLTIPS {
  FILE_NAME = 'The original title of the document.',
  COMPANY_NAME = 'Name of the company the file originates from.',
  COUNTRY = 'Country where the company is registered and performs business.',
  BUSINESS_LINE = 'Line of Business to which given product belongs to.',
  PRODUCT_LINE = 'Line of Product to which given product belongs to; generic values.',
  BUSINESS_TYPE = 'Type of Business to which given product belongs to.',
  DOCUMENT_TYPE = 'Type of the document.',
  PARENT_DOCUMENTS = 'Related documents',
  RELATED_VERSIONS = 'Related versions',
  CLASSIFICATION = 'Official Information Classification (C1-C4).',
  LANGUAGE = 'The original language of the file.',
  VALID_FROM = 'For standard wordings – the date from which given product is in offer. \n For individual wordings – the date of signature.',
  VALID_TO = 'For standard wordings – the date when product was withdrawn from the offer. \n For individual wordings – the last day of the validity \n of the wording.',
  EXPIRE_AT = 'Date at which the file should be deleted from the system.',
  VERSION = 'Local and valid version of the file.',
  ACTIVE = 'Check-mark indicating that the file is an active product where the “not active” is reserved for archived files.',
  LATEST_VERSION = 'Check-mark indicating that the file is the latest version \n of the product.',
  DOCUMENT_NAME = 'Name of the file generated automatically. \n It can be modified after submission.',
  REMARKS = 'Text field for additional comments/remarks to the file.',
  DOCUMENT_ID = 'A unique ID of file generated automatically. \n It can not be modified.',
  UPDATED_AT = 'Time when the document was last updated.',
  UPDATED_BY = 'The last user who updated the document.',
  CREATED_AT = 'Time when the document was submitted.',
  CREATED_BY = 'The user who submitted the document',
  PRODUCT_TYPE = 'Product type to which given product belongs to.',
  INSURED_INDUSTRY = 'Industry to which given product belongs to.',
}

export enum CLAUSE_METADATA_TOOLTIPS {
  CLAUSE_NAME = 'Name of the clause.',
  COMPANY_NAME = 'Name of the company that uses the clause.',
  COUNTRY = 'Country in that the clause is used.',
  BUSINESS_LINE = 'Line of Business in that the clause is used. You can select multiple values.',
  PRODUCT_LINE = 'Product Line that uses the clause. You can select multiple values.',
  BUSINESS_TYPE = 'Clause used for commercial or retail business. You can select multiple values.',
  SOURCE = 'The origin of the clause or clause owner.',
  CLASSIFICATION = 'Official Information Classification (C1-C4). You can select multiple values.',
  LANGUAGE = 'The original language of the clause.',
  REASON = 'Please describe the reason for the individualization of the clause (without sensitive or personal data).',
  TYPE = 'Applicable for Primary insurance or Reinsurance business. You can select multiple values.',
  NUMBER = 'Optional parameter, the clause number attached to the metric in which the clause is defined.',
  TAG = 'Tag for clause',
}

export const DOWNLOAD_LABEL = 'Download original PDF';

export const MAXIMUM_NO_OF_UPLOADING_DOCS = 30;
export const MAXIMUM_NO_OF_UPLOADING_NEW_VERSION_OF_DOC = 1;

export const API_DATE_FORMAT = 'yyyy-MM-dd';
export const UI_DATE_FORMAT = 'dd.MM.yyyy';
export const UI_DATE_TIME_FORMAT = 'dd.MM.yyyy HH:mm:ss';
export const UI_DATE_TIME_SHORTEN_FORMAT = 'dd.MM.yyyy - HH:mm';

export const DATE_FIELD_CONFIG = {
  inputFormat: UI_DATE_FORMAT,
  mask: '__.__.____',
};

export const DATE_RANGE_PICKER_LABELS = {
  FROM: 'From',
  TO: 'To',
};

export enum TAB_NAMES {
  LIST_VIEW = 'List View',
  SPREADSHEET_VIEW = 'Spreadsheet View',
  PENDING = 'Pending',
  MY_REPORTS = 'My Reports',
  REPORTS = 'Reports',
  DRAFT_DOCUMENTS = 'Draft Documents',
  DRAFT_CLAUSES = 'Draft Clauses',
}

export enum BOOKMARKS_TAB_NAMES {
  DOCUMENTS = 'Documents',
  CLAUSES = 'Clauses',
}

export const TOOLTIP_DELAY = 1000;

export const ACH_METADATA_EDIT_DELAY = 3500;

export const SORTING_ORDER: GridSortDirection[] = ['asc', 'desc', null];

export const EXPERIMENTAL_ENABLED = process.env.REACT_APP_EXPERIMENTAL_ENABLED === 'true';

export enum NAME_INPUT_LABELS {
  FOLDER = 'folder',
  BOOKMARK_NAME = 'report name',
}

export enum REPORTING_FORM_LABELS {
  REASON = 'Reason of reporting',
  COMMENT = 'Comment (optional)',
}

export const SERVICE_EMAIL = 'my.it.support@ergo.de';

export const DE = 'DE';

export const CLAUSE_STATUS_TOOLTIPS = {
  BEST_PRACTICE_VIEW:
    'Clause is recommended in a guideline, passed by legislature, approved by a board or checked by a group function such as compliance, legal, Global P&C etc. See remarks for specifications.',
  BEST_PRACTICE_EDIT:
    'Clause is recommended in a guideline, passed by legislature, approved by a board or checked by a group function such as compliance, legal, Global P&C etc. Please specify in remarks.',
  INVALID_VIEW:
    'Clause no longer corresponds to current developments or legal situation, is incomplete, exposes ERGO to risks outside of risk appetite, is invalid or ineffective (by court ruling) or is incorrect. See remarks for specifications.',
  INVALID_EDIT:
    'Clause no longer corresponds to current developments or legal situation, is incomplete, exposes ERGO to risks outside of risk appetite, is invalid or ineffective (by court ruling) or is incorrect. Please specify in remarks.',
};

export const ZOOM_INITIAL = 1;
export const ZOOM_UNLOCKED = -1;

export const MIN_CLAUSE_WORD_COUNT = 8;
export const MIN_CLAUSE_WORD_COUNT_MSG = `Clause should have at least ${MIN_CLAUSE_WORD_COUNT} words.`;
