import React, { useEffect } from 'react';
import { Amplify, Hub } from 'aws-amplify';
import { awsAuthConfig } from '../../config/aws-auth.config';
import { useAppDispatch } from '../../store/hooks';
import { setAuthError } from '../../store/ui/ui.slice';
import { getErrorDescription } from '../Login/AuthError.helpers';
import {
  cleanupLastUsedProvider,
  setInitialPathToSessionStorage,
  showReloadModal,
  storeLastUsedProvider,
} from './auth.helpers';
import { store } from '../../store/store';

export const AuthProvider: React.FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setAuthError(getErrorDescription()));
    setInitialPathToSessionStorage();
    /*
    To turn on Amplify logs just overwrite LOG_LEVEL in Amplify.Logger:
     Amplify.Logger.LOG_LEVEL = 'DEBUG';
     */
    Amplify.configure(awsAuthConfig);
  }, [dispatch]);

  return <></>;
};

Hub.listen('auth', (data) => {
  switch (data.payload.event) {
    case 'signIn':
      storeLastUsedProvider(data);
      break;
    case 'signIn_failure':
      cleanupLastUsedProvider();
      break;
    case 'tokenRefresh_failure':
      store.dispatch(showReloadModal());
      break;
  }
});
