import { MarsModal } from '../MarsModal';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import DangerousIcon from '@mui/icons-material/Dangerous';
import WarningIcon from '@mui/icons-material/Warning';
import React from 'react';
import {
  UploadConfirmationModalProps,
  uploadConfirmModalDescriptions,
} from './UploadConfirmationModal.types';
import './UploadConfirmationModal.scss';

export const UploadConfirmationModal: React.FC<UploadConfirmationModalProps> = ({
  open,
  onClose,
  result,
  renderDuplicateItems,
  renderErrorItems,
  mode,
}) => {
  const [singleItemDescription, multipleItemsDescription, pendingItemLocation, itemRepository] =
    uploadConfirmModalDescriptions[mode];

  const renderBeginOfMessage = (numberOfResults: number) => {
    return numberOfResults > 1
      ? numberOfResults.toString() + multipleItemsDescription
      : singleItemDescription;
  };

  return (
    <MarsModal
      open={open}
      onClose={onClose}
      buttonLabel='Close'
      modalClassName='submit-modal-wrapper'
    >
      <div className='submit-modal'>
        <div className='modal-info'>
          {result?.success ? (
            <div>
              <TaskAltIcon className='success-icon' />
              <div>
                {renderBeginOfMessage(result?.success)}
                uploaded successfully.
              </div>
              <div>
                You can find status of processing files
                <br /> in the {pendingItemLocation}.
                {mode === 'clause'
                  ? ' Please remember that the classification may change to the lowest available of all linked documents to that clause.'
                  : null}
              </div>
            </div>
          ) : null}
        </div>
        <div className='modal-info'>
          {result?.error ? (
            <div>
              <DangerousIcon className='error-icon' />
              <div>
                {renderBeginOfMessage(result?.error)}
                failed to upload. Try again.
              </div>
              <div className='error-list'>{renderErrorItems}</div>
            </div>
          ) : null}
        </div>
        <div className='modal-info'>
          {result?.duplicates ? (
            <div>
              <WarningIcon className='warning-icon' />
              <div>
                {renderBeginOfMessage(result?.duplicates)}
                you were trying to upload already exists.
                <br /> Go to "{itemRepository}".
              </div>
              <div className='duplicate-list'>{renderDuplicateItems}</div>
            </div>
          ) : null}
        </div>
      </div>
    </MarsModal>
  );
};
