import './DocumentBar.scss';
import React, { useEffect, useState } from 'react';
import { DocumentBarList } from './DocumentBarList';
import { useLocation } from 'react-router-dom';
import { BarMenu } from './BarMenu';
import { shouldShowSelectedBar } from './DocumentBar.helpers';

import { MINIMIZED_DATA } from '../../store/ui/ui.types';
import useMinimizedData from './ItemsBar.hooks';

export const DocumentBar = () => {
  const { pathname } = useLocation();
  const [isDocumentBarVisible, setIsDocumentBarVisible] = useState(false);

  const { minimizedData, itemsToCompare, handleClearCompare, handleClearMinimized } =
    useMinimizedData(MINIMIZED_DATA.MINIMIZED_DOCS);

  useEffect(() => {
    setIsDocumentBarVisible(shouldShowSelectedBar(pathname, minimizedData?.length));
  }, [pathname, minimizedData]);

  return isDocumentBarVisible ? (
    <div className='document-bar'>
      <DocumentBarList minimizedData={minimizedData} itemsToCompare={itemsToCompare} />
      <BarMenu
        itemsToCompare={itemsToCompare}
        handleClearCompare={handleClearCompare}
        handleClearMinimized={handleClearMinimized}
      />
    </div>
  ) : null;
};
