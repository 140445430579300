import {
  NAME_INPUT_LABELS,
  REPORT_MAX_LENGTH,
  REPORT_MIN_LENGTH,
  SEARCH_NAME_AND_BOOKMARK_MAX_LENGTH,
  SEARCH_NAME_AND_BOOKMARK_MIN_LENGTH,
} from '../../../config/config';
import { FILE_EXTENSIONS, ITEM_TYPE } from '../../../store/ui/ui.types';

export const VALIDATION_RULES = {
  [ITEM_TYPE.BOOKMARK]: {
    required: {
      value: true,
      message: `Enter name to save the ${NAME_INPUT_LABELS.FOLDER}`,
    },
    minLength: {
      value: SEARCH_NAME_AND_BOOKMARK_MIN_LENGTH,
      message: `Name must be more than ${SEARCH_NAME_AND_BOOKMARK_MIN_LENGTH} characters`,
    },
    maxLength: {
      value: SEARCH_NAME_AND_BOOKMARK_MAX_LENGTH,
      message: `Name must be less than ${SEARCH_NAME_AND_BOOKMARK_MAX_LENGTH} characters`,
    },
  },
  [ITEM_TYPE.REPORT]: {
    required: {
      value: true,
      message: `Enter name to save the ${NAME_INPUT_LABELS.BOOKMARK_NAME}`,
    },
    validate: (value: string) => {
      if (
        (value.endsWith(FILE_EXTENSIONS.CSV) && value.length < 7) ||
        (!value.endsWith(FILE_EXTENSIONS.CSV) && value.length < REPORT_MIN_LENGTH)
      ) {
        return `Name must be more than ${REPORT_MIN_LENGTH} characters`;
      }
      return true;
    },
    maxLength: {
      value: REPORT_MAX_LENGTH,
      message: `Name must be less than ${REPORT_MAX_LENGTH} characters`,
    },
  },
  [ITEM_TYPE.CLAUSE]: {},
  [ITEM_TYPE.DOCUMENT]: {},
};
