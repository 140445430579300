import React, { useCallback, useEffect, useState } from 'react';
import { DocumentGrid } from './DocumentsGrid';
import {
  AchDocumentGridWrapperProps,
  DocumentGridProps,
  SpreadSheetDocument,
} from './DocumentsGrid.types';
import { StatusProgress } from '../StatusProgress';
import { mapToSpreadSheetDocuments } from '../SpreadSheet.helpers';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useLoadingWithTimeout } from '../../../store/files/documents/documents.hooks';
import { useUpdateDocumentsMetadataMutation } from '../../../store/files/documents/documents.list.service';
import { DOCS_UPDATE_METADATA_CACHE_KEY } from '../../../store/files/documents/documents.list.types';
import { useAchEditingDisabled } from '../AchEditingDisabled.hook';
import {
  getDocumentIds,
  mapChangesToUpdateStatuses,
  mapToDocumentMetadataUpdateRequestDto,
  missingFields,
} from './AchDocumentsGrid.helpers';
import { showModal, updateStatuses } from '../../../store/ui/ui.slice';
import { ERROR_TITLES } from '../../../store/ui/ui.error.types';
import { useStatusProgress } from '../StatusProgress.hook';
import { SavedWithErrors } from '../SavedWithErrors';
import { ALL_CHANGES_SAVED } from '../StatusProgress.types';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { useClearSelectedAndErrorDocs } from '../../Documents/DocumentsListGrid.hooks';
import { MY_FILES_BLOCKED_COLUMNS } from '../../../config/config';
import { mapErrorResponseToUpdateStatuses } from '../../../store/files/documents/documents.update.error.helpers';
import {
  isAchResponseWithErrors,
  mapSuccessResponseToUpdateStatuses,
} from '../../../store/files/documents/documents.update.success.helpers';
import { handleApiError } from '../../../store/error.helpers';
import { useDispatchUnsavedSpreadsheetChanges } from './DispatchUnsavedSpreadsheetChanges.hook';
import { useDispatchSelectDocs, useSelectionChangeHandler } from './AchDocumentsGrid.hooks';

export const AchDocumentsGrid: React.FC<AchDocumentGridWrapperProps> = ({
  useGetDataListWithParams,
  search,
  isSnackbarEnabled,
}) => {
  const selected = useAppSelector(uiSelectors.selectSelectedItem);
  const {
    data: docs,
    isLoading: isLoadingDocs,
    isFetching: isFetchingDocs,
    rowCount,
    page,
    pageSize,
    setPage,
    setPageSize,
  } = useGetDataListWithParams();

  const achDocuments = docs?.Documents ?? [];
  const [updateMetadata] = useUpdateDocumentsMetadataMutation({
    fixedCacheKey: DOCS_UPDATE_METADATA_CACHE_KEY,
  });
  const dispatch = useAppDispatch();
  const isEditingDisabled = useAchEditingDisabled(useGetDataListWithParams);
  const [documents, setDocuments] = useState<DocumentGridProps['docs']>([]);
  const [isParsing, setIsParsing] = useState<boolean>(true);
  const [dataChanged, setDataChanged] = useState<DocumentGridProps['docs']>([]);

  const { isLoading, startLoading, stopLoading, loadWithTimeout } = useLoadingWithTimeout();

  const { saved, errorMessage, setSaved, setErrorMessage } = useStatusProgress(dataChanged.length);
  const bulkUpdateStatuses = useAppSelector(uiSelectors.selectUpdateStatuses);

  useDispatchUnsavedSpreadsheetChanges(dataChanged);
  useClearSelectedAndErrorDocs();
  const onSelectionModelChange = useSelectionChangeHandler(achDocuments);

  const getDocuments = useCallback(
    () => [...mapToSpreadSheetDocuments(docs?.Documents ? docs.Documents : [])],
    [docs]
  );

  const handleDocumentChange = (changedDocs: SpreadSheetDocument[]) => {
    setDataChanged(changedDocs);
    dispatch(updateStatuses(mapChangesToUpdateStatuses(getDocumentIds(changedDocs))));
  };

  useEffect(() => {
    setDocuments(getDocuments());
    setIsParsing(false);
  }, [docs, getDocuments]);

  const dispatchSelectDocs = useDispatchSelectDocs();

  const handleSave = () => {
    setSaved('');
    setErrorMessage('');
    const changedDocuments = mapToDocumentMetadataUpdateRequestDto(dataChanged);
    startLoading();
    updateMetadata(changedDocuments)
      .unwrap()
      .then((response) => {
        setDataChanged([]);
        dispatch(updateStatuses(mapSuccessResponseToUpdateStatuses(response, changedDocuments)));
        if (isAchResponseWithErrors(response)) {
          stopLoading();
          setSaved(
            <SavedWithErrors
              success={response?.Added?.length!}
              errors={response?.Failed?.length!}
            />
          );
        } else {
          loadWithTimeout(() => {
            dispatchSelectDocs([], false);
            setSaved(ALL_CHANGES_SAVED);
          });
        }
      })
      .catch((response) => {
        stopLoading();
        dispatch(updateStatuses(mapErrorResponseToUpdateStatuses(response, changedDocuments)));
        setErrorMessage(ERROR_TITLES.ERROR_WHILE_SAVING_DATA);
        dispatch(
          showModal({
            title: ERROR_TITLES.ERROR_WHILE_SAVING_DATA,
            reason: handleApiError(response),
          })
        );
      });
  };

  return (
    <>
      <DocumentGrid
        search={search}
        setDataChanged={handleDocumentChange}
        docs={documents}
        isLoading={isLoadingDocs || isFetchingDocs || isParsing}
        isEditingDisabled={isEditingDisabled}
        disabledColumns={MY_FILES_BLOCKED_COLUMNS.SPREADSHEET}
        validate={true}
        updateStatuses={bulkUpdateStatuses}
        rowCount={rowCount}
        page={page}
        pageSize={pageSize}
        setPage={setPage}
        setPageSize={setPageSize}
        selectionModel={selected}
        onSelectionModelChange={onSelectionModelChange}
      />
      <StatusProgress
        filesExist={!!docs?.Documents.length}
        isLoading={isLoading}
        dataChangedCount={dataChanged.length}
        errorMessage={errorMessage}
        saved={saved}
        onSave={handleSave}
        missingFields={missingFields(dataChanged)}
        isSnackbarEnabled={isSnackbarEnabled}
      />
    </>
  );
};
