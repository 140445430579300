import { useState } from 'react';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';
import { SavedSearch } from '../../../store/user/savedsearch.types';
import { GroupMetadata } from '../../../store/adminPanel/adminPanel.types';

export const useSearchInputBase = <T>(options: T[], fn: (el: T) => string) => {
  const [inputText, setInputText] = useState<string>('');
  const onChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(e.target.value.toLowerCase());
  };
  const filteredValues = options?.filter((el) => fn(el).toLowerCase().includes(inputText));
  return { inputText, setInputText, filteredValues, onChangeSearch };
};
export const useSearchInputDict = (options: MetadataDictionaryValue[]) =>
  useSearchInputBase<MetadataDictionaryValue>(options, (el) => el.label);
export const useSearchInput = (options: SavedSearch[]) =>
  useSearchInputBase<SavedSearch>(options, (el) => el.Name!);

export const useSearchGroupNameInput = (options: GroupMetadata[]) =>
  useSearchInputBase<GroupMetadata>(options, (el) => el.GroupName!);
