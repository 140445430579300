import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import React from 'react';
import { Modal } from '@mui/material';
import { useAppSelector } from '../../../store/hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';

export const Loader = () => {
  const showLoader = useAppSelector(uiSelectors.selectShowLoader);

  return (
    <Modal open={showLoader}>
      <Box sx={{ textAlign: 'center', position: 'absolute', top: '50%', left: '50%' }}>
        <CircularProgress sx={{ color: 'white' }} />
      </Box>
    </Modal>
  );
};
