import { Checkbox, FormControlLabel } from '@mui/material';
import React from 'react';
import { ComponentWithLabelProps as CheckboxWithLabelProps } from './ComponentWithLabel.types';

export const CheckboxWithLabel: React.FC<CheckboxWithLabelProps> = React.forwardRef(
  ({ checked, onChange, label, labelPlacement, ...props }, ref) => (
    <FormControlLabel
      ref={ref}
      labelPlacement={labelPlacement}
      control={<Checkbox {...props} checked={checked} size='small' onChange={onChange} />}
      label={label}
    />
  )
);
