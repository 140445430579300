import { DocumentListDataFetchingHook } from '../Documents/Documents.types';

export interface SpreadSheetViewProps {
  useGetDataListWithParams?: DocumentListDataFetchingHook;
  editMode?: DOCUMENTS_EDIT_SOURCE;
  fullscreen: boolean;
  toggleFullscreen: () => void;
  isSnackbarEnabled?: boolean;
}

export enum DOCUMENTS_EDIT_SOURCE {
  PRE_LANDING = 'PRE_LANDING',
  DOCUMENTS = 'DOCUMENTS',
  SAVED_SEARCHES = 'SAVED_SEARCHES',
}
