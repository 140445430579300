import { Button } from '@mui/material';
import React from 'react';
import './ButtonWithIcon.scss';
import { ButtonWithIconProps } from './ButtonWithIcon.types';

export const ButtonWithIcon: React.FC<ButtonWithIconProps> = ({
  startIcon,
  style,
  children,
  onClick,
  disabled,
  className = '',
  ...props
}) => {
  return (
    <Button
      className={'button-with-icon ' + className}
      variant='outlined'
      startIcon={startIcon}
      onClick={onClick}
      style={{ ...style }}
      disabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};
