import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { DocumentContextMenuItemProps } from './DocumentContextMenu.types';
import { openReportedReasonModal } from '../../../store/ui/ui.slice';
import ReportGmailerrorred from '@mui/icons-material/ReportGmailerrorred';
import { useDispatch } from 'react-redux';

export const DocumentContextMenuReport = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const dispatch = useDispatch();
  const handleReportClick = () => {
    if (document) {
      dispatch(openReportedReasonModal(document));
    }
    onClose();
  };
  return (
    <MenuItem disabled={disabled} onClick={handleReportClick}>
      <ReportGmailerrorred />
      Report
    </MenuItem>
  );
};
