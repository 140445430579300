import React from 'react';
import { DocumentsSearch } from './DocumentsSearch/DocumentsSearch';
import { DocumentsList } from './DocumentsList';
import { DocumentsCount } from './DocumentsCount';
import { useGetDocumentsListWithParams } from '../../store/files/documents/documents.hooks';
import { MenuItemsDocuments } from './DocumentContextMenu/DocumentContextMenu.helpers';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import { useDocumentReadAccess } from '../../contexts/UserAccess';
import { useFetchAndDispatchDocument } from './Documents.hooks';

const useGetDataListWithParams = useGetDocumentsListWithParams;

export const Documents = () => {
  useDocumentReadAccess();
  useFetchAndDispatchDocument();
  const { filters, setFilters } = useGetDataListWithParams();

  return (
    <>
      <DocumentsSearch filters={filters} onApply={setFilters} accessMode={AccessMode.READ} />
      <DocumentsCount useGetDataListWithParams={useGetDataListWithParams} />
      <DocumentsList
        menuOptions={MenuItemsDocuments}
        useGetDataListWithParams={useGetDataListWithParams}
        exportButtonEnabled
      />
    </>
  );
};
