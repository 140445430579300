import { Box, CircularProgress, Paper } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { NUMBER_OF_ITEMS_ALLOWED, REPORTS_LIST_REFRESH_INTERVAL } from '../../config/config';
import { useGetReportsQuery } from '../../store/files/reports/reports.service';
import { paginationOptions } from '../StaticComponents/CustomPagination/CustomPagination';
import { NoItemsFound } from '../StaticComponents/NoItemsFound/NoItemsFound';
import { getColumns, isReportRowSelectable } from './ReportsList.helper';
import { useAppSelector } from '../../store/hooks';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { useContextMenu } from '../Documents/DocumentContextMenu/DocumentContextMenu.hooks';
import { Report } from '../../store/files/reports/reports.types';
import { ReportsContextMenu } from './ReportsContextMenu';
import { useDispatchSelectDocs } from '../SpreadSheet/DocumentsGrid/AchDocumentsGrid.hooks';

const MyReportsTab = () => {
  const [pageSize, setPageSize] = useState<number>(20);
  const selected = useAppSelector(uiSelectors.selectSelectedItem);
  const { control, handleContextMenu } = useContextMenu<Report>();

  const { data, isLoading } = useGetReportsQuery(undefined, {
    pollingInterval: REPORTS_LIST_REFRESH_INTERVAL,
    refetchOnMountOrArgChange: true,
  });

  const dispatchSelectDocs = useDispatchSelectDocs();

  return (
    <Paper elevation={0} className='documents-list'>
      {isLoading ? (
        <Box sx={{ textAlign: 'center', padding: '30px' }}>
          <CircularProgress />
        </Box>
      ) : data?.length ? (
        <DataGridPro
          sortModel={[{ field: 'Status', sort: 'asc' }]}
          className='documents-list-grid bookmarks-list-grid'
          headerHeight={32}
          autoHeight={true}
          getRowId={(row) => row.ReportId}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
          rows={data ?? []}
          rowsPerPageOptions={NUMBER_OF_ITEMS_ALLOWED}
          columns={getColumns(handleContextMenu)}
          checkboxSelection
          disableSelectionOnClick
          selectionModel={selected}
          onSelectionModelChange={(ids) => {
            dispatchSelectDocs(ids as string[]);
          }}
          isRowSelectable={isReportRowSelectable}
          disableColumnMenu
          disableDensitySelector
          pagination
          componentsProps={{
            pagination: paginationOptions(),
          }}
        />
      ) : (
        <NoItemsFound icon='folderIconReports' subtitle='Your reports will be stored here.' />
      )}

      <ReportsContextMenu control={control} />
    </Paper>
  );
};

export default MyReportsTab;
