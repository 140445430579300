import { SavedSearch } from './savedsearch.types';
import { isNil, omitBy } from 'lodash';

export const mapSavedSearchRequest = (value: SavedSearch): SavedSearch => {
  const Active =
    value?.Filters && 'Active' in value?.Filters ? value.Filters?.Active || undefined : undefined;
  const LatestVersion =
    value?.Filters && 'LatestVersion' in value?.Filters
      ? value.Filters?.LatestVersion || undefined
      : undefined;
  const Provider =
    value?.Filters && 'Provider' in value?.Filters
      ? value.Filters?.Provider || undefined
      : undefined;
  return {
    ...value,
    CreatedAt: undefined,
    UpdatedAt: undefined,
    Filters: {
      ...value.Filters,
      Active,
      LatestVersion,
      Provider,
    },
  };
};

export const mapSavedSearchResponse = (response?: SavedSearch[]): SavedSearch[] => {
  return response
    ? response.map((value) => {
        const Filters = omitBy(value.Filters, isNil);
        return { ...value, Filters };
      })
    : [];
};
