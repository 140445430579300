import { documentsListApi } from '../../../store/files/documents/documents.list.service';
import { store } from '../../../store/store';
import {
  DOCUMENTS_LIST_API_ENDPOINTS,
  DOCUMENTS_LIST_API_TAGS,
} from '../../../store/files/documents/documents.list.types';
import { clausesListApi } from '../../../store/files/clauses/clauses.list.service';
import {
  CLAUSES_LIST_API_ENDPOINTS,
  CLAUSES_LIST_API_TAGS,
} from '../../../store/files/clauses/clauses.list.types';

export const getOriginalArgs = (
  tag: DOCUMENTS_LIST_API_TAGS,
  endpoint: DOCUMENTS_LIST_API_ENDPOINTS
) => {
  const { originalArgs } =
    documentsListApi.util
      .selectInvalidatedBy(store.getState(), [{ type: tag }])
      .reverse()
      .find(({ endpointName }) => endpointName === endpoint) || {};
  return originalArgs;
};

export const getOriginalArgsClause = (
  tag: CLAUSES_LIST_API_TAGS,
  endpoint: CLAUSES_LIST_API_ENDPOINTS
) => {
  const { originalArgs } =
    clausesListApi.util
      .selectInvalidatedBy(store.getState(), [{ type: tag }])
      .reverse()
      .find(({ endpointName }) => endpointName === endpoint) || {};
  return originalArgs;
};
