import { useGetReportQuery } from '../../store/files/reports/reports.service';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { downloadReport } from '../../store/files/reports/reports.slice';
import { Report } from '../../store/files/reports/reports.types';

export const useReportDownload = (report?: Report, onClose?: () => void) => {
  const [fetchPreSignedUrl, setFetchPreSignedUrl] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { data, isSuccess } = useGetReportQuery(report?.ReportId ?? '', {
    skip: !fetchPreSignedUrl,
  });
  const inProgress = fetchPreSignedUrl && !isSuccess;

  const handleDownloadReport = useCallback(() => {
    if (isSuccess) {
      dispatch(
        downloadReport({
          fileName: report?.ReportName ?? '',
          PreSignedUrl: data?.presignedUrl ?? '',
        })
      );
      onClose && onClose();
    }
  }, [isSuccess, dispatch, report, data, onClose]);

  const handleClick = useCallback(() => {
    setFetchPreSignedUrl(true);
    handleDownloadReport();
  }, [handleDownloadReport]);

  useEffect(() => {
    handleDownloadReport();
  }, [handleDownloadReport]);

  return { handleClick, inProgress };
};
