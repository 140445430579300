export enum DOCUMENTS_LIST_COLUMNS {
  FILE_NAME = 'FileName',
  COMPANY_NAME = 'Entity',
  COUNTRY = 'Country',
  BUSINESS_LINE = 'Lob',
  PRODUCT_LINE = 'Lop',
  BUSINESS_TYPE = 'BusinessType',
  DOCUMENT_TYPE = 'DocumentType',
  CLASSIFICATION = 'Classification',
  LANGUAGE = 'Language',
  ACTIVE = 'Active',
  VALID_FROM = 'ValidFrom',
  VALID_TO = 'ValidTo',
  EXPIRE_AT = 'ExpireAt',
  VERSION = 'Version',
  LATEST_VERSION = 'LatestVersion',
  DOCUMENT_NAME = 'DocumentName',
  DOCUMENT_ID = 'DocumentId',
  UPDATED_BY = 'UpdatedBy',
  UPDATED_AT = 'UpdatedAt',
  CREATED_BY = 'CreatedBy',
  CREATED_AT = 'CreatedAt',
  REMARKS = 'Remarks',
  STATUS = 'Status',
  FAILURE_REASON = 'FailureReason',
  PRODUCT_TYPE = 'ProductType',
  INSURED_INDUSTRY = 'InsurerIndustry',
}
