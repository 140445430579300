import React from 'react';
import { UploadedFile } from '../UploadedFileList.types';

export const renderItems = (docs: UploadedFile[], ids: string[] | undefined) => {
  return ids?.length
    ? docs
        .filter((d) => ids.indexOf(d.DocumentId) > -1)
        .map((d) => <li key={d.DocumentId}> {d.FileName} </li>)
    : [];
};
