import React from 'react';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { Box, CircularProgress, Paper } from '@mui/material';
import { TAB_ITEMS_PAGE_SIZE } from '../../../config/config';
import { NoItemsFound } from '../../StaticComponents/NoItemsFound/NoItemsFound';
import { ManageTagsListColumns } from './ManageTagsList.helpers';
import { useGetMetadataQuery } from '../../../store/adminPanel/adminPanel.service';
import { useGetTagsQuery } from '../../../store/files/tags/tags.service';
import { Tag } from '../../../store/files/tags/tags.types';
import './ManageTagsList.scss';

export const ManageTagsList = ({ setSelected }: { setSelected: (user: Tag) => void }) => {
  const { data: tags, isLoading: isLoadingTags, isFetching: isFetchingTags } = useGetTagsQuery();
  const {
    data: metadata,
    isLoading: isLoadingMetadata,
    isFetching: isFetchingMetadata,
  } = useGetMetadataQuery();

  return (
    <Paper
      elevation={0}
      sx={{ minWidth: 600, borderRadius: 4, minHeight: '50vh', marginBottom: '30px' }}
    >
      {isLoadingTags || isFetchingTags || isLoadingMetadata || isFetchingMetadata ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            padding: 10,
          }}
        >
          <CircularProgress size={50} />
        </Box>
      ) : metadata && tags?.length ? (
        <DataGridPro
          autoHeight={true}
          className='documents-list-grid'
          columns={ManageTagsListColumns(metadata, setSelected)}
          disableColumnMenu
          disableSelectionOnClick
          getRowId={(row: Tag) => row.Name}
          hideFooterRowCount
          pageSize={TAB_ITEMS_PAGE_SIZE}
          rows={tags}
        />
      ) : (
        <NoItemsFound icon='tagsIcon' subtitle='' />
      )}
    </Paper>
  );
};
