import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { useUserAccessContext } from '../../../contexts/UserAccess';
import { useEffect } from 'react';
import { draftMode } from '../../../store/ui/ui.slice';

const useUploadButtonLogic = () => {
  const { hasWriteAccess, hasSandboxAccess } = useUserAccessContext();
  const draftModeSelected = useAppSelector(uiSelectors.selectDraftMode);
  const dispatch = useAppDispatch();

  let isDraftModeSelected = false;
  let showDropDownButton = false;

  if (hasWriteAccess && !hasSandboxAccess) {
    isDraftModeSelected = false;
    showDropDownButton = false;
  } else if (hasWriteAccess && hasSandboxAccess) {
    isDraftModeSelected = draftModeSelected;
    showDropDownButton = true;
  } else if (!hasWriteAccess && hasSandboxAccess) {
    isDraftModeSelected = true;
    showDropDownButton = false;
  }

  useEffect(() => {
    if (!hasWriteAccess && hasSandboxAccess) {
      dispatch(draftMode(true));
    }
  }, [hasWriteAccess, hasSandboxAccess, dispatch]);

  return { isDraftModeSelected, showDropDownButton };
};

export default useUploadButtonLogic;
