import { useSnackbar } from 'notistack';
import { DocumentContextMenuItemProps } from '../Documents/DocumentContextMenu/DocumentContextMenu.types';
import { useAppDispatch } from '../../store/hooks';
import { documentsListApi } from '../../store/files/documents/documents.list.service';
import { DOCUMENTS_LIST_API_TAGS } from '../../store/files/documents/documents.list.types';
import { ErrorMessages } from '../../services/errors.service.types';
import { GridContextMenuDelete } from '../StaticComponents/GridContextMenuDelete/GridContextMenuDelete';
import { showLoader } from '../../store/ui/ui.slice';
import {
  REMOVE_FROM_BOOKMARK_MODAL_MESSAGE,
  REMOVE_FROM_BOOKMARK_MODAL_SUBMIT_BUTTON_LABEL,
  REMOVE_FROM_BOOKMARK_MODAL_TITLE,
} from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import { useIsDocumentResponseData } from '../../store/files/documentsAndClauses/list.hooks.helpers';
import BookmarkRemoveOutlinedIcon from '@mui/icons-material/BookmarkRemoveOutlined';
import { useDeleteBookmarkItemMutation } from '../../store/files/bookmarks/bookmarks.service';
import { useParams } from 'react-router-dom';
import { clausesListApi } from '../../store/files/clauses/clauses.list.service';
import { CLAUSES_LIST_API_TAGS } from '../../store/files/clauses/clauses.list.types';
import { Messages } from '../../services/messages.service.types';
import { isDocumentResponse } from '../../store/files/documentsAndClauses/list.helpers';
import { BookmarksPathParams } from './BookmarkContextMenuDeleteItem.types';

export const BookmarkContextMenuDeleteItem = ({
  document,
  onClose,
  disabled,
}: DocumentContextMenuItemProps) => {
  const dispatch = useAppDispatch();
  const [deleteBookmarkItem] = useDeleteBookmarkItemMutation();
  const { enqueueSnackbar } = useSnackbar();
  const { itemId } = useIsDocumentResponseData(document);
  const { bookmark_id } = useParams<BookmarksPathParams>();

  const handleDeleteConfirm = () => {
    if (bookmark_id && itemId) {
      dispatch(showLoader(true));
      deleteBookmarkItem({ BookmarkId: bookmark_id, ItemsToDelete: [itemId] })
        .unwrap()
        .then(() => {
          enqueueSnackbar(Messages.BookmarkItemRemovedSuccess);
          dispatch(
            isDocumentResponse(document)
              ? documentsListApi.util.invalidateTags([DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST])
              : clausesListApi.util.invalidateTags([CLAUSES_LIST_API_TAGS.CLAUSES_LIST])
          );
        })
        .catch(() => enqueueSnackbar(ErrorMessages.BookmarkItemRemoveError, { variant: 'error' }))
        .finally(() => dispatch(showLoader(false)));
    }
  };

  return (
    <GridContextMenuDelete
      icon={<BookmarkRemoveOutlinedIcon />}
      label='Remove from bookmark'
      onClose={onClose}
      title={REMOVE_FROM_BOOKMARK_MODAL_TITLE}
      description={REMOVE_FROM_BOOKMARK_MODAL_MESSAGE}
      successButtonLabel={REMOVE_FROM_BOOKMARK_MODAL_SUBMIT_BUTTON_LABEL}
      handleDeleteConfirm={handleDeleteConfirm}
      disabled={disabled}
    />
  );
};
