import { TEST_ID } from '../../../config/test-fields-ids.config';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import React, { Dispatch, FormEvent, useCallback, useEffect, useState } from 'react';
import { Modal } from '@mui/material';
import './GenerateReport.scss';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import { ButtonWithIcon } from '../../StaticComponents/ButtonWithIcon';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ADMIN_PANEL_USERS_REPORT_ENDPOINT } from '../../../services/api.service.config';
import { downloadReport } from '../../../store/adminPanel/adminPanel.slice';
import { adminPanelSelectors } from '../../../store/adminPanel/adminPanel.selectors';

export const GenerateReport: React.FC<{ onClose: Dispatch<boolean> }> = ({ onClose }) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [pivoting, setPivoting] = useState<boolean | undefined>(undefined);
  const selectReport = useAppSelector(adminPanelSelectors.selectReport);

  const dispatch = useAppDispatch();

  const handleSubmit = (pivoting: boolean) => (e?: FormEvent) => {
    e?.preventDefault();
    setPivoting(pivoting);
    setIsDownloading(true);
    dispatch(
      downloadReport({
        fileName: !pivoting ? 'users_report' : 'users_groups_report',
        PreSignedUrl: `${ADMIN_PANEL_USERS_REPORT_ENDPOINT}?Pivoting=${pivoting}`,
      })
    );
  };
  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  useEffect(() => {
    if (!selectReport.downloading && isDownloading) {
      setIsDownloading(false);
      handleClose();
    }
  }, [selectReport, isDownloading, setIsDownloading, handleClose]);

  return (
    <Modal open={true}>
      <form className='edit-users-group-form__edit-user__form' onSubmit={() => {}}>
        <div
          className='edit-users-groups-modal generate-confirmation__modal'
          data-test-id={TEST_ID.ADMIN_PANEL_PAGE.ADMIN_PANEL_EDIT_USER_GROUPS_MODAL}
        >
          <ModalClose onClose={handleClose} />
          <div className='confirmation-modal-header '>
            <div className='confirmation-modal-title'>Generate report</div>
            <div className='generate-confirmation__modal-message'>
              <TaskAltIcon className='success-icon' />
              Your report was successfully created and ready to be downloaded on your computer.
            </div>
            <div className='generate-confirmation__modal-buttons'>
              <LoadingButton
                className='confirm-button'
                type='submit'
                onClick={handleSubmit(false)}
                loading={isDownloading && !pivoting}
                disabled={isDownloading && pivoting}
              >
                Download Users Report
              </LoadingButton>
              <LoadingButton
                className='confirm-button'
                type='submit'
                onClick={handleSubmit(true)}
                loading={isDownloading && pivoting}
                disabled={isDownloading && !pivoting}
              >
                Download Users' Groups Report
              </LoadingButton>
              <ButtonWithIcon className='cancel-button' onClick={handleClose} disabled={false}>
                Cancel
              </ButtonWithIcon>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
};
