import { useLocation, useNavigate } from 'react-router-dom';
import { RoutePath } from '../../services/route.service';
import { useEffect, useState } from 'react';
import {
  defaultListQueryParams,
  updateListQueryParams,
} from '../../store/files/documents/documents.slice';
import { useAppDispatch } from '../../store/hooks';

type LocationState = {
  relatedDocuments?: string[];
  parentDocumentId?: string;
  fileName?: string;
};

export const useGetRelatedDocumentsFromLocationState = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const relatedDocuments = (state as LocationState)?.relatedDocuments;
  const parentDocumentId = (state as LocationState)?.parentDocumentId;
  const fileName = (state as LocationState)?.fileName;

  const [related, setRelated] = useState(false);
  useEffect(() => {
    if (!related && relatedDocuments) {
      setRelated(true);
      dispatch(
        updateListQueryParams({ ...defaultListQueryParams(), DocumentId: relatedDocuments })
      );
    }
    return () => {
      if (related && relatedDocuments) {
        dispatch(updateListQueryParams(defaultListQueryParams()));
      }
    };
  }, [dispatch, related, relatedDocuments]);

  const clearLocationState = () => {
    if (parentDocumentId) {
      navigate(RoutePath.DOCUMENTS, {
        state: null,
      });
    }
  };

  return {
    parentDocumentId,
    relatedDocuments,
    fileName,
    clearLocationState,
  };
};
