import { MarsModal } from '../Modals/MarsModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import './ErrorModal.scss';
import { ERRORS } from '../../../store/error.helpers';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { closeModal } from '../../../store/ui/ui.slice';
import { ERROR_DOCUMENT_SEPARATOR } from '../../../store/files/documents/documents.update.error.helpers';
import { ERROR_MODAL_ACTIONS } from './ErrorModal.helpers';

const NoInternetConnection = () => (
  <>
    <p>Try:</p>
    <ul>
      <li>Checking the network cables, modem and router</li>
      <li>Reconnecting to Wi-Fi</li>
    </ul>
    <br />
    <b>ERR_INTERNET_DISCONNECTED</b>
  </>
);

export const ErrorModal = () => {
  const dispatch = useAppDispatch();

  let {
    show,
    title,
    reason,
    label = 'Close',
    closeAction,
  } = useAppSelector(uiSelectors.selectErrorModal);

  const onCloseModal = () => {
    dispatch(closeModal());
    closeAction && ERROR_MODAL_ACTIONS[closeAction]();
  };

  const renderReason = () => {
    if (reason === ERRORS.NO_INTERNET_CONNECTION) {
      title = 'No internet connection';
      return <NoInternetConnection />;
    }
    if (reason?.includes(ERROR_DOCUMENT_SEPARATOR)) {
      return (
        <ul>
          {reason.split(ERROR_DOCUMENT_SEPARATOR).map((v, i) => (
            <li key={i + v}>{v}</li>
          ))}
        </ul>
      );
    }
    return reason;
  };

  return show ? (
    <MarsModal
      title={title}
      buttonLabel={label}
      open={show}
      onClose={onCloseModal}
      modalClassName='error-modal-wrapper'
    >
      <div className='error-modal'>
        <div className='description'>{renderReason()}</div>
      </div>
    </MarsModal>
  ) : null;
};
