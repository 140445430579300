import React from 'react';
import './ReportDetailsSummary.scss';
import { ReportDetailsSummaryProps } from './ReportDetailsSummary.types';

export const ReportDetailsSummary: React.FC<ReportDetailsSummaryProps> = ({
  reportedReason,
  comment,
}) => {
  return (
    <div className='report-details-summary'>
      <table>
        <tbody>
          <tr>
            <td className='label'>Reason:</td>
            <td className='value'>{reportedReason}</td>
          </tr>
          <tr>
            <td className='label'>Comment:</td>
            <td className='value'>{comment}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
