import { Divider, ListItemText, Menu, MenuItem } from '@mui/material';
import React from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import './DocumentBarMenu.scss';
import { TEST_ID } from '../../config/test-fields-ids.config';
import { BarMenuProps } from './BarMenu.types';

export const BarMenu: React.FC<BarMenuProps> = ({
  itemsToCompare,
  handleClearCompare,
  handleClearMinimized,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton className='document-bar-list__button-menu' onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        className='document-bar-list__menu'
        data-test-id={TEST_ID.DOC_BAR.MENU}
      >
        <MenuItem
          disabled={!itemsToCompare?.length}
          onClick={handleClearCompare}
          data-test-id={TEST_ID.DOC_BAR.MENU_CLEAR_COMPARE}
        >
          <ListItemText>Clear compare list</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleClearMinimized} data-test-id={TEST_ID.DOC_BAR.MENU_CLEAR_DOCS}>
          <ListItemText>Clear minimized list</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
