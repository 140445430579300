import React, { useEffect } from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { setAuthError } from '../../store/ui/ui.slice';

export const AuthError = () => {
  const errorDescription = useAppSelector(uiSelectors.selectAuthError);
  const dispatch = useAppDispatch();

  useEffect(() => {
    return () => {
      dispatch(setAuthError(''));
    };
  }, [dispatch]);

  if (!errorDescription) {
    return <></>;
  }

  return (
    <div>
      <Alert severity='error' sx={{ textAlign: 'left', width: 330 }}>
        <AlertTitle>Authentication error</AlertTitle>
        {errorDescription}
      </Alert>
    </div>
  );
};
