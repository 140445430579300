import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Popper from '@mui/material/Popper';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ClickAwayListener, { ClickAwayListenerProps } from '@mui/material/ClickAwayListener';
import './SavedSearches.scss';
import common from '../../../assets/common.module.scss';
import IconButton from '@mui/material/IconButton';
import { InfoButtonProps } from './SavedSearches.types';
import { FiltersPanel } from './FiltersPanel/FiltersPanel';
import { TEST_ID } from '../../../config/test-fields-ids.config';

import './SavedSearchInfoButton.scss';

export const SavedSearchInfoButton: React.FC<InfoButtonProps> = ({
  title,
  filters,
  dictionaries,
  dictionariesClauses,
  clauseMode,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const id = open ? 'saved-search-info-popper' : undefined;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose: ClickAwayListenerProps['onClickAway'] = (event) => {
    if (anchorEl?.contains(event.target as HTMLElement)) {
      return;
    }
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton aria-describedby={id} size='small' onClick={handleClick} disableFocusRipple>
        <InfoOutlinedIcon fontSize='inherit' />
      </IconButton>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_INFO_POPPER}
        placement='bottom-start'
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper sx={{ p: '24px 16px', minWidth: 200, maxWidth: 332 }}>
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 'bold',
                lineHeight: '24px',
                color: common.darkGrayTextColor,
                marginBottom: '5px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {title}
            </Typography>

            <FiltersPanel
              filters={filters}
              dictionaries={dictionaries}
              dictionariesClauses={dictionariesClauses}
              clauseMode={clauseMode}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
