import axios, { AxiosResponse } from 'axios';
import { getAuthorizationHeader } from '../../components/Auth/auth.helpers';

enum FileType {
  PDF = 'application/pdf',
  CSV = 'text/csv',
}

class DownloadService {
  private forceDownload(response: AxiosResponse, fileName: string, fileType: string) {
    const url = window.URL.createObjectURL(new Blob([response.data], { type: fileType }));
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName;
    link.click();
  }

  private async downloadFile(
    PreSignedUrl: string,
    fileName: string,
    fileType: FileType,
    addAuthToken?: boolean
  ) {
    let headers = {};
    if (addAuthToken) {
      const { name, value } = await getAuthorizationHeader();
      headers = {
        [name]: value,
      };
    }

    const response = await axios.get(PreSignedUrl, {
      responseType: 'blob',
      headers,
    });

    this.forceDownload(response, fileName, fileType);
  }

  async downloadDocument(PreSignedUrl: string, fileName: string) {
    await this.downloadFile(PreSignedUrl, fileName, FileType.PDF);
  }

  async downloadReport(PreSignedUrl: string, fileName: string, addAuthToken?: boolean) {
    await this.downloadFile(PreSignedUrl, fileName, FileType.CSV, addAuthToken);
  }
}

const downloadService = new DownloadService();

export default downloadService;
