import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar } from 'notistack';
import { BookmarkContextMenuItemProps } from './BookmarkContextMenu.types';
import { useAppDispatch } from '../../store/hooks';
import { useDeleteBookmarkMutation } from '../../store/files/bookmarks/bookmarks.service';
import { ErrorMessages } from '../../services/errors.service.types';
import { Messages } from '../../services/messages.service.types';
import { showLoader } from '../../store/ui/ui.slice';
import {
  DELETE_MODAL_MESSAGE,
  DELETE_MODAL_TITLE,
} from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import { GridContextMenuDelete } from '../StaticComponents/GridContextMenuDelete/GridContextMenuDelete';
import { useNavigate, useLocation } from 'react-router-dom';
import { RoutePath } from '../../services/route.service';

const BOOKMARK = 'bookmark';
const title = DELETE_MODAL_TITLE(BOOKMARK);
const description = DELETE_MODAL_MESSAGE(BOOKMARK);

export const BookmarkContextMenuDelete = ({ id, onClose }: BookmarkContextMenuItemProps) => {
  const dispatch = useAppDispatch();
  const [deleteBookmark] = useDeleteBookmarkMutation();
  const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleDeleteConfirm = () => {
    if (id) {
      dispatch(showLoader(true));
      deleteBookmark(id)
        .unwrap()
        .then(() => {
          enqueueSnackbar(Messages.BookmarkRemovedSuccess);
          if (pathname !== RoutePath.BOOKMARKS) {
            navigate(RoutePath.BOOKMARKS);
          }
        })
        .catch(() => enqueueSnackbar(ErrorMessages.DeleteBookmarkError, { variant: 'error' }))
        .finally(() => dispatch(showLoader(false)));
    }
  };

  return (
    <GridContextMenuDelete
      icon={<DeleteOutlineIcon />}
      onClose={onClose}
      title={title}
      description={description}
      handleDeleteConfirm={handleDeleteConfirm}
    />
  );
};
