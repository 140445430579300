import { CLAUSE_LABELS, DOCUMENT_LABELS } from '../../../../config/config';
import React from 'react';
import { FilterPanelItem } from './FilterPanelItem';
import { ClauseFiltersPanelProps } from './FiltersPanel.types';

export const ClauseFiltersPanel = ({ filters, dictionaries }: ClauseFiltersPanelProps) => {
  if (!dictionaries) return null;
  return (
    <>
      <FilterPanelItem
        filter={filters.Entities}
        label={DOCUMENT_LABELS.COMPANY_NAME}
        dictionary={dictionaries.Entities}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.Countries}
        label={DOCUMENT_LABELS.COUNTRY}
        dictionary={dictionaries.Countries}
        multipleFilters
        hasFlag
      />

      <FilterPanelItem
        filter={filters.Lobs}
        label={DOCUMENT_LABELS.BUSINESS_LINE}
        dictionary={dictionaries.Lobs}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.Lops}
        label={DOCUMENT_LABELS.PRODUCT_LINE}
        dictionary={dictionaries.Lops}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.BusinessTypes}
        label={DOCUMENT_LABELS.BUSINESS_TYPE}
        dictionary={dictionaries.BusinessTypes}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.ClauseTypes}
        label={CLAUSE_LABELS.CLAUSE_TYPE}
        dictionary={dictionaries.ClauseTypes}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.Classifications}
        label={DOCUMENT_LABELS.CLASSIFICATION}
        dictionary={dictionaries.Classification}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.Language}
        label={DOCUMENT_LABELS.LANGUAGE}
        dictionary={dictionaries.Language}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.ClauseStatus}
        label={DOCUMENT_LABELS.STATUS}
        dictionary={dictionaries.ClauseStatus}
      />
    </>
  );
};
