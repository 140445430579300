import { countryCodeEmoji } from 'country-code-emoji';
import {
  ClauseDictionariesResponse,
  DictionariesResponse,
  MetadataDictionary,
  MetadataDictionaryValue,
  ReportedReason,
} from './list.service.types';
import { DE } from '../../../config/config';
import { OriginalLanguage } from '../documents/documents.list.types';
import { DEFAULT_SEARCH_METHOD } from '../../../config/config';

export const getFlagByCountryName = (countryCode: string) => {
  return countryCode ? countryCodeEmoji(countryCode) : '';
};

export const getDictionaryLabelByValue = (dictionary: MetadataDictionary, entityCode: string) => {
  return dictionary?.values.find(({ value }) => value === entityCode)?.label;
};

export const getDictionaryLabelsByValues = (
  dictionary: MetadataDictionary,
  entityCodes: string[]
) => {
  return entityCodes.map((code: string) => getDictionaryLabelByValue(dictionary, code));
};

export const getReportedReasonLabelByValue = (data: ReportedReason, value: string) => {
  const item = data?.values.find((item) => item.value === value);
  return item ? item.label : null;
};
export const CLAUSE_SOURCE_ERGO_INDIVIDUAL = 'EI';

export const getAllGermanCompaniesFromDictionary = (
  entitiesDictionary: MetadataDictionary | undefined
) => {
  if (!entitiesDictionary) return [];
  return entitiesDictionary.values
    .filter((dictValue) => dictValue.related?.Country === DE)
    .map((dictValue) => dictValue.value);
};

export const defaultSequentialSearch = () => ({
  OriginalLanguage: OriginalLanguage.ORIGINAL,
  SearchMethod: DEFAULT_SEARCH_METHOD,
});

export enum MetadataDictKeys {
  BusinessType = 'BusinessType',
  Lob = 'Lob',
  Lop = 'Lop',
}

/**
 * Extend entities with missing related values. If there is no BusinessType or Lob add all those values according to dictionary
 *
 * @param metadataDictionary
 */

export const getExtendedEntitiesRelatedValuesWith = (
  metadataDictionary: DictionariesResponse
): MetadataDictionaryValue[] => {
  const bt = metadataDictionary.BusinessType.values.map(({ value }) => value);
  const lob = metadataDictionary.Lob.values.map(({ value }) => value);
  const lopPerLob = metadataDictionary.Lob.values.reduce(
    (lopPerLob: Record<string, string[]>, { value, related }) => {
      if (related?.Lop) lopPerLob[value] = related?.Lop;
      return lopPerLob;
    },
    {}
  );

  return metadataDictionary.Entity.values.reduce(
    (metadata_extended: MetadataDictionaryValue[], metadata_tmp) => {
      if (metadata_tmp.related && !metadata_tmp.related?.[MetadataDictKeys.BusinessType]) {
        metadata_tmp.related[MetadataDictKeys.BusinessType] = bt;
      }
      if (metadata_tmp.related && !metadata_tmp?.related?.[MetadataDictKeys.Lob]) {
        metadata_tmp.related[MetadataDictKeys.Lob] = lob;
      }
      if (metadata_tmp.related && !metadata_tmp?.related?.[MetadataDictKeys.Lop]) {
        metadata_tmp.related.Lob?.forEach((singleLob) => {
          if (metadata_tmp.related) {
            metadata_tmp.related[MetadataDictKeys.Lop] = [
              // @ts-ignore
              ...(metadata_tmp.related[MetadataDictKeys.Lop]?.length
                ? metadata_tmp.related[MetadataDictKeys.Lop]
                : []),
              ...lopPerLob[singleLob],
            ];
          }
        });
      }
      metadata_extended.push(metadata_tmp);
      return metadata_extended;
    },
    []
  );
};

export const CLAUSE_SOURCE_ERGO_AUTOMATED = 'EA';

export const disableAutomatedUpload = (response: ClauseDictionariesResponse) => {
  return response.ClauseSources.values.map((clauseSource) => {
    if (clauseSource.value === CLAUSE_SOURCE_ERGO_AUTOMATED) {
      return {
        ...clauseSource,
        disabled: true,
      };
    }
    return clauseSource;
  });
};

/**
 * Extend entities with missing related values. If there is no BusinessType or Lob add all those values according to dictionary
 *
 * @param metadataDictionary
 */

export const getExtendedClauseEntitiesRelatedValuesWith = (
  metadataDictionary: ClauseDictionariesResponse
): MetadataDictionaryValue[] => {
  const bt = metadataDictionary.BusinessTypes.values.map(({ value }) => value);
  const lob = metadataDictionary.Lobs.values.map(({ value }) => value);
  const lopPerLob = metadataDictionary.Lobs.values.reduce(
    (lopPerLob: Record<string, string[]>, { value, related }) => {
      if (related?.Lop) lopPerLob[value] = related?.Lop;
      return lopPerLob;
    },
    {}
  );

  return metadataDictionary.Entities.values.reduce(
    (metadata_extended: MetadataDictionaryValue[], metadata_tmp) => {
      if (metadata_tmp.related && !metadata_tmp.related?.[MetadataDictKeys.BusinessType]) {
        metadata_tmp.related[MetadataDictKeys.BusinessType] = bt;
      }
      if (metadata_tmp.related && !metadata_tmp?.related?.[MetadataDictKeys.Lob]) {
        metadata_tmp.related[MetadataDictKeys.Lob] = lob;
      }
      if (metadata_tmp.related && !metadata_tmp?.related?.[MetadataDictKeys.Lop]) {
        metadata_tmp.related.Lob?.forEach((singleLob) => {
          if (metadata_tmp.related) {
            metadata_tmp.related[MetadataDictKeys.Lop] = [
              // @ts-ignore
              ...(metadata_tmp.related[MetadataDictKeys.Lop]?.length
                ? metadata_tmp.related[MetadataDictKeys.Lop]
                : []),
              ...lopPerLob[singleLob],
            ];
          }
        });
      }
      metadata_extended.push(metadata_tmp);
      return metadata_extended;
    },
    []
  );
};
