import { Button } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreen from '@mui/icons-material/CloseFullscreen';
import { FullScreenDocumentGridProps } from './FullScreenDocumentGrid.types';
import './FullScreenDocumentGrid.scss';

export const FullScreenDocumentGrid = ({
  fullscreen,
  toggleFullscreen,
}: FullScreenDocumentGridProps) => {
  return (
    <Button
      variant='text'
      startIcon={fullscreen ? <CloseFullscreen /> : <OpenInFullIcon />}
      className='full-screen-grid'
      onClick={toggleFullscreen}
    >
      {fullscreen ? 'Close' : 'Full screen'}
    </Button>
  );
};
