import '../DocumentBar/DocumentBar.scss';
import React, { useEffect, useState } from 'react';
import { ClauseBarList } from './ClauseBarList';
import { useLocation } from 'react-router-dom';
import { BarMenu } from '../DocumentBar/BarMenu';

import { MINIMIZED_DATA } from '../../store/ui/ui.types';
import { shouldShowSelectedBar } from './ClausesListGrid.helpers';
import useMinimizedData from '../DocumentBar/ItemsBar.hooks';

export const ClauseBar = () => {
  const { pathname } = useLocation();
  const [isClauseBarVisible, setIsClauseBarVisible] = useState(false);

  const { minimizedData, itemsToCompare, handleClearCompare, handleClearMinimized } =
    useMinimizedData(MINIMIZED_DATA.MINIMIZED_CLAUSES);

  useEffect(() => {
    setIsClauseBarVisible(shouldShowSelectedBar(pathname, minimizedData?.length));
  }, [pathname, minimizedData]);

  return isClauseBarVisible ? (
    <div className='document-bar'>
      <ClauseBarList minimizedData={minimizedData} itemsToCompare={itemsToCompare} />
      <BarMenu
        itemsToCompare={itemsToCompare}
        handleClearCompare={handleClearCompare}
        handleClearMinimized={handleClearMinimized}
      />
    </div>
  ) : null;
};
