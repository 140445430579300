import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useSnackbar } from 'notistack';
import { ReportsContextMenuItemProps } from './ReportsContextMenu.types';
import { useAppDispatch } from '../../store/hooks';
import { useDeleteReportMutation } from '../../store/files/reports/reports.service';
import { ErrorMessages } from '../../services/errors.service.types';
import { Messages } from '../../services/messages.service.types';
import { showLoader } from '../../store/ui/ui.slice';
import {
  DELETE_MODAL_MESSAGE,
  DELETE_MODAL_TITLE,
  REPORT,
} from '../StaticComponents/Modals/ConfirmationModal/ConfirmationModal.messages';
import { GridContextMenuDelete } from '../StaticComponents/GridContextMenuDelete/GridContextMenuDelete';

const title = DELETE_MODAL_TITLE(REPORT);
const description = DELETE_MODAL_MESSAGE(REPORT);

export const ReportsContextMenuDelete = ({
  report,
  handleClose,
  disabled,
}: ReportsContextMenuItemProps) => {
  const dispatch = useAppDispatch();
  const [deleteBookmark] = useDeleteReportMutation();

  const { enqueueSnackbar } = useSnackbar();
  const id = report?.ReportId;

  const handleDeleteConfirm = () => {
    if (id) {
      dispatch(showLoader(true));
      deleteBookmark(id)
        .unwrap()
        .then(() => {
          enqueueSnackbar(Messages.ReportRemovedSuccess);
        })
        .catch(() => enqueueSnackbar(ErrorMessages.ReportDeleteError, { variant: 'error' }))
        .finally(() => {
          dispatch(showLoader(false));
        });
    }
  };

  return (
    <GridContextMenuDelete
      disabled={disabled}
      icon={<DeleteOutlineIcon />}
      onClose={handleClose}
      title={title}
      description={description}
      handleDeleteConfirm={handleDeleteConfirm}
    />
  );
};
