import {
  ClauseDictionariesResponse,
  DictionariesResponse,
} from '../../../store/files/upload/list.service.types';
import { DocumentsListParams } from '../../../store/files/documents/documents.list.types';
import { GridRenderCellParams } from '@mui/x-data-grid-pro';
import { SavedSearch } from '../../../store/user/savedsearch.types';

export interface InfoButtonProps {
  title: string;
  filters: DocumentsListParams;
  dictionaries?: DictionariesResponse;
  dictionariesClauses?: ClauseDictionariesResponse;
  clauseMode?: boolean;
}

export type SavedSearchCellParams = GridRenderCellParams<string, Required<SavedSearch>>;

export const FILTERS_PREFIX: Extract<keyof SavedSearch, 'Filters'> = 'Filters';
