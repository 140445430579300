import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import React from 'react';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import './SearchInput.scss';

interface SearchInputProps {
  searchQuery: string;
  setSearchQuery: (x: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({ searchQuery, setSearchQuery }) => {
  const handleOnChange = ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };

  return (
    <TextField
      className='search-input'
      data-test-id={TEST_ID.SEARCH_QUERY.FIELD}
      placeholder='Type to search'
      size='small'
      value={searchQuery}
      onChange={handleOnChange}
      InputProps={{
        startAdornment: <SearchIcon />,
      }}
    />
  );
};
