export enum Messages {
  MetadataUpdateSuccess = 'Metadata changed successfully',
  SavedSearchUpdateSuccess = 'Saved search changed successfully',
  SavedSearchRemovedSuccess = 'Saved search removed successfully',
  SavedSearchRemovedSuccessMulti = 'Saved searches removed successfully',
  BookmarkRemovedSuccess = 'Bookmark deleted successfully',
  BookmarkItemRemovedSuccess = 'File removed successfully',
  ReportRemovedSuccess = 'Report removed successfully',
  ReportRemovedSuccessMulti = 'Reports removed successfully',
  SuccessUpdate = 'Successfully updated',

  CommentRemovedSuccess = 'Comment has been deleted successfully.',
  CommentAddedSuccess = 'Comment has been added successfully.',
  AdminPanelBulkEditGroupsModal = 'Users have been updated successfully',
}
