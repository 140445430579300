import React from 'react';
import Box from '@mui/material/Box';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { FileLoaderProps } from './FileViewer.types';

export const FileLoader = ({ loading, error }: FileLoaderProps) => {
  if (loading || error) {
    return (
      <Box sx={{ textAlign: 'center', padding: '30px' }}>
        {error ? <ErrorOutlineIcon /> : <CircularProgress />}
      </Box>
    );
  }

  return <></>;
};
