import { useState, useCallback } from 'react';
import { ContextMenuHandler, ContextMenuHookReturn } from './DocumentContextMenu.types';

export function useContextMenu<T>(): ContextMenuHookReturn<T> {
  const [selectedItem, setSelectedItem] = useState<T>();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleContextMenu: ContextMenuHandler<T> = (event, item) => {
    event.preventDefault();
    setSelectedItem(item);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const onClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return { control: { onClose, anchorEl, selectedItem }, handleContextMenu };
}
