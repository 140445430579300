import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {
  ADMIN_PANEL_TAGS_CREATE_ENDPOINT,
  ADMIN_PANEL_TAGS_LIST_ENDPOINT,
} from '../../../services/api.service.config';
import { appendAuthorizationHeader } from '../../../components/Auth/auth.helpers';
import { Tag } from './tags.types';

export enum TAGS_API_TAGS {
  LIST = 'List',
}

export const tagsApi = createApi({
  reducerPath: 'tagsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: ADMIN_PANEL_TAGS_LIST_ENDPOINT,
    prepareHeaders: appendAuthorizationHeader,
  }),
  tagTypes: [TAGS_API_TAGS.LIST],
  endpoints: (builder) => ({
    getTags: builder.query<Tag[], void>({
      query: () => '',
      providesTags: [TAGS_API_TAGS.LIST],
    }),
    addTag: builder.mutation<void, Tag>({
      query: (body) => ({
        url: ADMIN_PANEL_TAGS_CREATE_ENDPOINT,
        method: 'POST',
        body,
      }),
      invalidatesTags: (res, err) => (err ? [] : [TAGS_API_TAGS.LIST]),
    }),
    updateTag: builder.mutation<void, Tag>({
      query: ({ Name, Synonyms }) => ({
        url: '',
        method: 'PATCH',
        body: [{ Name, Synonyms }],
      }),
      invalidatesTags: (res, err) => (err ? [] : [TAGS_API_TAGS.LIST]),
    }),
    deleteTag: builder.mutation<void, string>({
      query: (name) => ({
        url: '',
        method: 'DELETE',
        body: { Names: [name] },
      }),
      invalidatesTags: [TAGS_API_TAGS.LIST],
    }),
  }),
});

export const { useGetTagsQuery, useAddTagMutation, useUpdateTagMutation, useDeleteTagMutation } =
  tagsApi;
