import '../Documents/DocumentContextMenu/DocumentContextMenu.scss';
import { Menu } from '@mui/material';
import { ReportsContextMenuProps } from './ReportsContextMenu.types';
import { ReportsContextMenuDelete } from './ReportsContextMenuDelete';
import { ReportsContextMenuDownload } from './ReportsContextMenuDownload';
import { DOCUMENT_CONTEXT_MENU_ITEM_NAME } from '../Documents/DocumentContextMenu/DocumentContextMenu.types';
import {
  menuItemDisabled,
  menuItemVisible,
} from '../Documents/DocumentContextMenu/DocumentContextMenu.helpers';
import { REPORT_STATUS } from '../../store/files/reports/reports.types';
import {
  MenuItemsStatusDone,
  MenuItemsStatusFailed,
  MenuItemsStatusPending,
} from './ReportsContextMenu.helpers';
import React from 'react';
import { ContextMenuEditName } from '../SharedComponents/ContextMenu/ContextMenuEditName';
import { useUpdateReportMutation } from '../../store/files/reports/reports.service';
import { ITEM_TYPE } from '../../store/ui/ui.types';

export const ReportsContextMenu: React.FC<ReportsContextMenuProps> = ({
  control: { selectedItem, anchorEl, onClose },
}) => {
  const menuItems =
    selectedItem?.Status === REPORT_STATUS.PROCESSED
      ? MenuItemsStatusDone
      : selectedItem?.Status === REPORT_STATUS.FAILED
      ? MenuItemsStatusFailed
      : MenuItemsStatusPending;
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      className='documents-list-menu'
    >
      {menuItemVisible(menuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE) ? (
        <ReportsContextMenuDelete
          disabled={menuItemDisabled(menuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.DELETE)}
          report={selectedItem}
          handleClose={onClose}
        />
      ) : null}

      {menuItemVisible(menuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT) ? (
        <ContextMenuEditName
          id={selectedItem?.ReportId}
          onClose={onClose}
          name={selectedItem?.ReportName}
          updateMutation={useUpdateReportMutation}
          itemType={ITEM_TYPE.REPORT}
          disabled={menuItemDisabled(menuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.EDIT)}
          sanitizeExtenstion
        />
      ) : null}

      {menuItemVisible(menuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD) ? (
        <ReportsContextMenuDownload
          disabled={menuItemDisabled(menuItems, DOCUMENT_CONTEXT_MENU_ITEM_NAME.DOWNLOAD)}
          report={selectedItem}
          handleClose={onClose}
        />
      ) : null}
    </Menu>
  );
};
