import { Button, Divider, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import '../../StaticComponents/ButtonWithIcon.scss';
import './MarsMenu.scss';
import { MarsMenuProps } from './MarsMenu.types';
import { isCurrentLink } from '../../../helpers/routing.helpers';
import { useLocation, useNavigate } from 'react-router-dom';

export const MarsMenu: React.FC<MarsMenuProps> = ({
  className,
  icon,
  endIcon,
  name,
  menuItems,
  anchorOrigin = {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin = {
    vertical: 'top',
    horizontal: 'left',
  },
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const isCurrent = () => {
    const menuHrefs = menuItems.filter(Boolean).map(({ href }) => href);
    return isCurrentLink(menuHrefs, pathname) || open;
  };

  return (
    <div className={className}>
      <Button
        className={`nav-button ${isCurrent() ? 'active' : ''}`}
        sx={{ my: 1, mx: 1.5 }}
        key={name}
        onClick={handleClick}
        disableRipple
      >
        {icon}
        {name}
        {endIcon}
      </Button>
      <Menu
        className='mars-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {menuItems.filter(Boolean).map(({ href, label, onClick, divider, disabled }, i) => [
          <MenuItem
            disableRipple
            disabled={disabled}
            className={disabled ? `mars-menu__link--disabled` : ''}
            onClick={() => {
              href && navigate(href);
              handleClose();
              onClick?.();
            }}
            key={`${i}${href}`}
          >
            {label}
          </MenuItem>,
          divider ? <Divider /> : null,
        ])}
      </Menu>
    </div>
  );
};
