import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { SAVED_SEARCH_API_TAGS, SavedSearch, SavedSearchListParams } from './savedsearch.types';
import {
  SAVED_SEARCHES_DELETE_ENDPOINT,
  SAVED_SEARCHES_LIST_ENDPOINT,
} from '../../services/api.service.config';
import { appendAuthorizationHeader } from '../../components/Auth/auth.helpers';
import { mapSavedSearchRequest, mapSavedSearchResponse } from './savedsearch.helpers';
import { prepareQueryString } from '../files/documentsAndClauses/list.helpers';

export const savedSearchApi = createApi({
  reducerPath: 'savedSearchApi',
  baseQuery: fetchBaseQuery({
    baseUrl: SAVED_SEARCHES_LIST_ENDPOINT,
    prepareHeaders: appendAuthorizationHeader,
  }),
  tagTypes: [SAVED_SEARCH_API_TAGS.LIST],
  endpoints: (builder) => ({
    getSavedSearches: builder.query<SavedSearch[], SavedSearchListParams>({
      query: (values) => `${SAVED_SEARCHES_LIST_ENDPOINT}?${prepareQueryString(values)}`,
      providesTags: [SAVED_SEARCH_API_TAGS.LIST],
      transformResponse: mapSavedSearchResponse,
    }),
    addSavedSearch: builder.mutation<void, SavedSearch>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body: mapSavedSearchRequest(body),
      }),
      invalidatesTags: [SAVED_SEARCH_API_TAGS.LIST],
    }),
    updateSavedSearch: builder.mutation<void, SavedSearch>({
      query: (body) => ({
        url: '',
        method: 'PUT',
        body: mapSavedSearchRequest(body),
      }),
      invalidatesTags: [SAVED_SEARCH_API_TAGS.LIST],
    }),
    deleteSavedSearch: builder.mutation<void, string>({
      query: (id) => ({
        url: SAVED_SEARCHES_DELETE_ENDPOINT.replace('{id}', id),
        method: 'DELETE',
      }),
      invalidatesTags: [SAVED_SEARCH_API_TAGS.LIST],
    }),
  }),
});

export const {
  useGetSavedSearchesQuery,
  useAddSavedSearchMutation,
  useUpdateSavedSearchMutation,
  useDeleteSavedSearchMutation,
} = savedSearchApi;
