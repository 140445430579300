import React from 'react';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { ConfigStrings } from './ClauseNomenclaturePopover.types';

const NomenclatureExampleTable = () => {
  return (
    <Table>
      <TableBody>
        <TableRow>
          <TableCell className='tableCell'>{ConfigStrings.REQUIRED}</TableCell>
          <TableCell className='tableCell'>{ConfigStrings.TOPIC}</TableCell>
          <TableCell className='tableCell italic'>{ConfigStrings.SANCTION_CLAUSE}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell className='tableCell'>{ConfigStrings.ADDITIONAL}</TableCell>
          <TableCell className='tableCell'>{ConfigStrings.INCL_EXCL_WRITEBACK}</TableCell>
          <TableCell className='tableCell italic'>
            {ConfigStrings.SANCTION_LIMITATION_EXCLUSION_CLAUSE}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className='tableCell'></TableCell>
          <TableCell className='tableCell'>{ConfigStrings.SOURCE}</TableCell>
          <TableCell className='tableCell italic'>
            {ConfigStrings.SANCTION_CLAUSE_AON_BASED}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className='tableCell'></TableCell>
          <TableCell className='tableCell'>{ConfigStrings.YEAR}</TableCell>
          <TableCell className='tableCell italic'>
            {ConfigStrings.SANCTION_CLAUSE_GDV_2020}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className='tableCell'></TableCell>
          <TableCell className='tableCell'>
            {ConfigStrings.GEOGRAPHIC_SCOPE_OF_APPLICATION}
          </TableCell>
          <TableCell className='tableCell italic'>
            {ConfigStrings.SANCTION_CLAUSE_US_SANCTIONS_ONLY}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell className='tableCell'></TableCell>
          <TableCell className='tableCell'>LoB</TableCell>
          <TableCell className='tableCell italic'>
            {ConfigStrings.WAR_RISKS_INCLUSION_CLAUSE_MARINE}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default NomenclatureExampleTable;
