import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import DownloadService from '../files/download.service';
import { handleApiError } from '../error.helpers';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

export interface AdminPanelState {
  report: AdminPanelReport;
}

export interface AdminPanelReport {
  downloading: boolean;
  error: string | null;
}

export const downloadReport = createAsyncThunk<void, { fileName: string; PreSignedUrl: string }>(
  'adminPanel/download/csv',
  async ({ fileName, PreSignedUrl }) => {
    await DownloadService.downloadReport(PreSignedUrl, fileName, true).catch(async (error) => {
      const serializedError: FetchBaseQueryError = {
        //workaround due to blob response type
        data: JSON.parse(await error.response.data.text()),
        status: error.response.status,
      };
      throw handleApiError(serializedError, true);
    });
  }
);

const initialState: AdminPanelState = {
  report: {
    downloading: false,
    error: null,
  },
};

const adminPanelSlice = createSlice({
  name: 'adminPanel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadReport.pending, (state) => {
        state.report.downloading = true;
        state.report.error = null;
      })
      .addCase(downloadReport.fulfilled, (state) => {
        state.report.downloading = false;
        state.report.error = null;
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.report.downloading = false;
        state.report.error = action.error.message ?? null;
      });
  },
});

export default adminPanelSlice.reducer;
