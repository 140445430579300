export enum MetadataEditModalModes {
  UPLOAD = 'UPLOAD',
  PROCESSING = 'PROCESSING',
  VIEWER = 'VIEWER',
}

export const SCANNING_RESULT_CLEAN = 'Clean';

export enum MetadataEditModalTitles {
  UPLOAD_NEW_VERSION = 'Upload new version',
  EDIT_METADATA = 'Edit metadata',
}
