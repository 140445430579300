import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import DatePicker from '@mui/lab/DatePicker';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { Controller, useForm } from 'react-hook-form';
import {
  BOOLEAN_VALUES,
  CustomTextField,
  formatApiDate,
  formatBoolean,
  minMaxFieldLengthRule,
  modifyPlaceholder,
  NOT_SELECTED,
  parseDate,
  requiredRule,
  useFilterParentDocumentsValues,
  useFilterRelatedVersionValue,
  useFormFiltering,
} from './MetadataFormHelpers';
import { DocumentMetadata, DocumentMetadataFields, MetadataFormProps } from './MetadataForm.types';
import { listApi } from '../../../store/files/upload/list.service';
import { useGetDictionariesQueryDefaults } from '../../../store/files/upload/list.service.hooks';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import {
  DATE_FIELD_CONFIG,
  DOCUMENT_LABELS,
  DOCUMENT_METADATA_TOOLTIPS,
  MAX_INSURED_INDUSTRY_LENGTH,
  MAX_PRODUCT_TYPE_LENGTH,
  MAX_REMARKS_LENGTH,
  MIN_INSURED_INDUSTRY_LENGTH,
  MIN_PRODUCT_TYPE_LENGTH,
  REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION,
} from '../../../config/config';
import { RadioGroupSwitch } from '../../StaticComponents/RadioGroupSwitch';
import { getDocumentName } from '../../SpreadSheet/DocumentsGrid/DocumentsGrid.helpers';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { FileNameInput } from '../../StaticComponents/FileNameInput/FileNameInput';
import { getDatePickerProps } from '../../SpreadSheet/DocumentsGrid/GridEditDateCell.helpers';
import { ApiError } from './ApiError';
import { ReportedField } from '../../SharedComponents/ReportedField/ReportedField';
import { useSnackbar } from 'notistack';
import {
  documentsListApi,
  useDeleteDocumentReportMutation,
} from '../../../store/files/documents/documents.list.service';
import { Messages } from '../../../services/messages.service.types';

import './MetadataForm.scss';
import { closeMetadataEditModal } from '../../../store/files/documents/documents.slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { DOCUMENTS_LIST_API_TAGS } from '../../../store/files/documents/documents.list.types';
import { timeoutPromise } from '../../../helpers/app.helpers';
import { useRelatedDocumentsValues } from './useParentDocumentsValues.hook';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';
import SubmitUploadButton from '../UploadSubmitDocuments/SubmitUploadButton';
import { UploadConfirmationModal } from '../../StaticComponents/Modals/UploadConfirmationModal/UploadConfirmationModal';
import { SubmitResult } from '../../../store/files/upload/submit.service.types';
import { useUpdateFormValues, useCheckFieldChanges } from './MetadataForm.hooks';
import { renderItems } from '../UploadSubmitDocuments/UploadSubmitDocuments.helpers';
import { closeModal } from '../../../store/files/upload/upload.slice';
import { LIST_API_TAGS } from '../../../store/files/upload/list.service.types';
import useCustomGetPreLandingListQuery from '../UploadFiles.hooks';
import { uiSelectors } from '../../../store/ui/ui.selectors';
import { useLocation } from 'react-router-dom';
import { getRoutePath, RoutePath } from '../../../services/route.service';

export const MetadataForm: React.FC<MetadataFormProps> = ({
  document,
  onSubmit,
  isLoading,
  apiError,
  disabledColumns = [],
  validate = false,
  disableSaveOnMissingData = false,
  bulkMetadataEdit = false,
  addNewVersionMode = false,
  previousVersionDocument,
  preLandingMetadataEdit = false,
}) => {
  const { pathname } = useLocation();
  const draftMode = useAppSelector(uiSelectors.selectDraftMode) && preLandingMetadataEdit;
  const { data: dictionaries } = useGetDictionariesQueryDefaults({
    AccessMode: AccessMode.WRITE,
    Draft: formatBoolean(
      draftMode ||
        pathname ===
          getRoutePath(RoutePath.SANDBOX_DOCUMENTS, RoutePath.UPLOADED_DOCUMENTS_LIST_VIEW)
    ),
  });
  const isPdfRendered = useAppSelector(uploadSelectors.selectIsPdfRendered);
  const { data: docs } = useCustomGetPreLandingListQuery();
  const [result, setResult] = useState<SubmitResult>();
  const renderDuplicateItems = docs ? renderItems(docs, result?.duplicatesId) : [<></>];
  const renderErrorItems = docs ? renderItems(docs, result?.errorsId) : [<></>];

  const [isReportedChecked, setIsReportedChecked] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [dataSaved, setDataSaved] = useState(false);
  const [disableBySubmit, setDisableBySubmit] = useState(false);
  const [initialValues, setInitialValues] = useState<DocumentMetadata>();
  const {
    control,
    getValues,
    watch,
    trigger,
    formState: { isDirty, isValid },
    setValue,
  } = useForm<DocumentMetadata>({
    mode: 'onChange',
    defaultValues: document,
  });

  const watchedFields = watch();
  useUpdateFormValues(
    previousVersionDocument,
    document,
    addNewVersionMode,
    setValue,
    setInitialValues
  );

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const defaultEntity = dictionaries?.Entity?.values?.[0]?.value;
    const defaultCountry = dictionaries?.Country?.values?.[0]?.value;
    const { Entity, Country } = getValues();
    if (bulkMetadataEdit && defaultEntity && defaultCountry && !Entity && !Country) {
      setValue(DocumentMetadataFields.Entity, defaultEntity);
      setValue(DocumentMetadataFields.Country, defaultCountry);
    }
  }, [
    bulkMetadataEdit,
    dictionaries?.Country?.values,
    dictionaries?.Entity?.values,
    getValues,
    setValue,
  ]);

  const { ParentDocuments, VersionKey, ...restFormData } = getValues();
  const missingLopInBulkMode = bulkMetadataEdit && !!restFormData.Lob && !restFormData.Lop;

  useEffect(() => {
    trigger();
  }, [trigger, dictionaries, missingLopInBulkMode, watchedFields]);
  const [cancelDocumentReport, { isLoading: isCancelDocLoading }] =
    useDeleteDocumentReportMutation();

  const parentDocumentsValues = useRelatedDocumentsValues(
    restFormData,
    DocumentMetadataFields.ParentDocuments
  );

  const delayedParentDocumentsOptions = useFilterParentDocumentsValues(
    setValue,
    ParentDocuments,
    parentDocumentsValues
  );

  const relatedVersionsDocumentsValues = useRelatedDocumentsValues(
    restFormData,
    DocumentMetadataFields.VersionKey
  );

  const delayedRelatedVersionsDocumentsOptions = useFilterRelatedVersionValue(
    setValue,
    VersionKey,
    relatedVersionsDocumentsValues
  );

  const documentNameValue = getDocumentName(watch());

  const disableSaveFromNewVersionMode = addNewVersionMode && (!dataSaved || disableBySubmit);

  const saveDisabled =
    (disableSaveOnMissingData && (!isDirty || !isValid) && !isReportedChecked) ||
    disableSaveFromNewVersionMode;

  const changedFields = useCheckFieldChanges(initialValues, getValues());

  const handleSubmit = useCallback(
    (e?: FormEvent) => {
      return new Promise<void>(async (resolve, reject) => {
        try {
          if (e) e.preventDefault();
          if (isReportedChecked && document?.DocumentId) {
            await cancelDocumentReport(document.DocumentId).unwrap();
            dispatch(closeMetadataEditModal());
            await timeoutPromise(REFRESH_DELAY_AFTER_OPEN_SEARCH_ACTION);
            dispatch(
              documentsListApi.util.invalidateTags([DOCUMENTS_LIST_API_TAGS.DOCUMENTS_LIST])
            );
            enqueueSnackbar(Messages.SuccessUpdate);
          }
          const formValues = getValues();
          formValues.ParentDocuments = formValues?.ParentDocuments?.filter(
            (doc) => doc !== NOT_SELECTED
          );

          if (isDirty) {
            await onSubmit(formValues);
          }
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    [
      isReportedChecked,
      document,
      getValues,
      isDirty,
      onSubmit,
      cancelDocumentReport,
      dispatch,
      enqueueSnackbar,
    ]
  );

  const { noEntitySelected, lobValues, businessTypesValues, lopValues } = useFormFiltering(
    dictionaries,
    getValues(),
    setValue,
    draftMode
  );

  useEffect(() => {
    // must wait until PDF rendered to avoid interfering with PDF rendering
    // (randomly throws errors regarding worker)
    if (addNewVersionMode && isPdfRendered && !dataSaved) {
      setDataSaved(true);
      handleSubmit();
    }
  }, [handleSubmit, isPdfRendered, dataSaved, addNewVersionMode]);

  if (!dictionaries)
    return (
      <Box sx={{ textAlign: 'center', padding: '30px' }}>
        <CircularProgress />
      </Box>
    );

  const isCountryGermany = getValues(DocumentMetadataFields.Country) === 'DE';

  if (addNewVersionMode && !document) {
    return (
      <div className='render-no-metadata '>
        <div>You can provide metadata after uploading the file.</div>
      </div>
    );
  }
  return (
    <form className='metadata-form' onSubmit={handleSubmit}>
      <div className='metadata-form-fields'>
        <div className='metadata-form-block'>
          <ReportedField
            item={document}
            isReportedChecked={isReportedChecked}
            setIsReportedChecked={setIsReportedChecked}
            dictionaries={dictionaries}
            editMode
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.FileName}
            defaultValue=''
            rules={requiredRule(validate, true)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.FILE_NAME}
                {...field}
                InputProps={{ inputComponent: FileNameInput }}
                disabled={
                  disabledColumns.includes(DocumentMetadataFields.FileName) ||
                  !validate ||
                  addNewVersionMode
                }
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.FILE_NAME}
                label={DOCUMENT_LABELS.FILE_NAME}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.Entity}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Entity.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.ENTITY}
                {...field}
                disabled={
                  disabledColumns.includes(DocumentMetadataFields.Entity) || addNewVersionMode
                }
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.COMPANY_NAME}
                select
                label={DOCUMENT_LABELS.COMPANY_NAME}
                values={dictionaries?.Entity.values}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.Country}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Country.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.COUNTRY}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.COUNTRY}
                select
                disabled
                label={DOCUMENT_LABELS.COUNTRY}
                values={dictionaries?.Country.values}
                isCountry
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.Lob}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Lob.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.LOB}
                {...field}
                disabled={noEntitySelected}
                onChange={(e) => {
                  field.onChange(e);
                  setValue(DocumentMetadataFields.Lop, '', { shouldValidate: true });
                }}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.BUSINESS_LINE}
                select
                label={DOCUMENT_LABELS.BUSINESS_LINE}
                values={lobValues}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.Lop}
            defaultValue=''
            rules={requiredRule(validate || missingLopInBulkMode, dictionaries?.Lop?.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.LOP}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.PRODUCT_LINE}
                select
                label={DOCUMENT_LABELS.PRODUCT_LINE}
                disabled={!restFormData.Lob}
                values={lopValues}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.ProductType}
            defaultValue=''
            rules={minMaxFieldLengthRule(
              validate,
              dictionaries?.ProductType?.required || isCountryGermany,
              MIN_PRODUCT_TYPE_LENGTH,
              MAX_PRODUCT_TYPE_LENGTH
            )}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.PRODUCT_TYPE}
                inputProps={{ maxLength: MAX_PRODUCT_TYPE_LENGTH }}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.PRODUCT_TYPE}
                label={DOCUMENT_LABELS.PRODUCT_TYPE}
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.InsurerIndustry}
            defaultValue=''
            rules={minMaxFieldLengthRule(
              validate,
              dictionaries?.InsurerIndustry?.required || isCountryGermany,
              MIN_INSURED_INDUSTRY_LENGTH,
              MAX_INSURED_INDUSTRY_LENGTH
            )}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.INSURED_INDUSTRY}
                inputProps={{ maxLength: MAX_INSURED_INDUSTRY_LENGTH }}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.INSURED_INDUSTRY}
                label={DOCUMENT_LABELS.INSURED_INDUSTRY}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.BusinessType}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.BusinessType?.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.BUSINESS_TYPE}
                {...field}
                disabled={noEntitySelected}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.BUSINESS_TYPE}
                select
                label={DOCUMENT_LABELS.BUSINESS_TYPE}
                values={businessTypesValues}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.DocumentType}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.DocumentType.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.DOCUMENT_TYPE}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.DOCUMENT_TYPE}
                select
                label={DOCUMENT_LABELS.DOCUMENT_TYPE}
                values={dictionaries?.DocumentType.values}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.ParentDocuments}
            defaultValue={[NOT_SELECTED]}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.PARENT_DOCUMENTS}
                {...field}
                helperWarning={error}
                disabled={parentDocumentsValues === undefined || parentDocumentsValues.length < 2}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.PARENT_DOCUMENTS}
                select
                isParentDocuments
                multiselect
                label={DOCUMENT_LABELS.PARENT_DOCUMENTS}
                values={delayedParentDocumentsOptions}
              />
            )}
          />
          {preLandingMetadataEdit && !addNewVersionMode && (
            <Controller
              control={control}
              name={DocumentMetadataFields.VersionKey}
              defaultValue=''
              render={({ field, fieldState: { error } }) => (
                <CustomTextField
                  data-test-id={TEST_ID.BULK_METADATA.RELATED_VERSIONS}
                  {...field}
                  helperWarning={error}
                  disabled={
                    relatedVersionsDocumentsValues === undefined ||
                    relatedVersionsDocumentsValues.length < 2
                  }
                  tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.RELATED_VERSIONS}
                  select
                  isParentDocuments
                  label={DOCUMENT_LABELS.VERSION_KEY}
                  values={delayedRelatedVersionsDocumentsOptions}
                />
              )}
            />
          )}
        </div>
        <div className='metadata-form-block'>
          <Controller
            control={control}
            name={DocumentMetadataFields.Classification}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Classification.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                {...field}
                data-test-id={TEST_ID.BULK_METADATA.CLASSIFICATION}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.CLASSIFICATION}
                select
                label={DOCUMENT_LABELS.CLASSIFICATION}
                values={dictionaries?.Classification.values}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.Language}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Language.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.LANGUAGE}
                {...field}
                select
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.LANGUAGE}
                label={DOCUMENT_LABELS.LANGUAGE}
                disabled={disabledColumns.includes(DocumentMetadataFields.Language)}
                values={dictionaries?.Language.values}
              />
            )}
          />
          <Controller
            control={control}
            name={DocumentMetadataFields.ValidFrom}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.ValidFrom.required)}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                {...DATE_FIELD_CONFIG}
                {...getDatePickerProps(DocumentMetadataFields.ValidFrom, watch())}
                value={parseDate(field.value) || null}
                onChange={(date: Date | null) => field.onChange(formatApiDate(date))}
                label={DOCUMENT_LABELS.VALID_FROM}
                renderInput={(params) => {
                  const modifiedParams = previousVersionDocument
                    ? modifyPlaceholder(params, previousVersionDocument.ValidFrom)
                    : params;
                  return (
                    <CustomTextField
                      data-test-id={TEST_ID.BULK_METADATA.VALID_FROM}
                      {...modifiedParams}
                      helperWarning={error}
                      tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.VALID_FROM}
                    />
                  );
                }}
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.ValidTo}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.ValidTo.required)}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                {...DATE_FIELD_CONFIG}
                {...getDatePickerProps(DocumentMetadataFields.ValidTo, watch())}
                value={parseDate(field.value) || null}
                onChange={(date: Date | null) => field.onChange(formatApiDate(date))}
                label={DOCUMENT_LABELS.VALID_TO}
                renderInput={(params) => {
                  const modifiedParams = previousVersionDocument
                    ? modifyPlaceholder(params, previousVersionDocument.ValidTo)
                    : params;
                  return (
                    <CustomTextField
                      data-test-id={TEST_ID.BULK_METADATA.VALID_TO}
                      {...modifiedParams}
                      helperWarning={error}
                      tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.VALID_TO}
                    />
                  );
                }}
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.ExpireAt}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.ExpireAt?.required)}
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                {...field}
                {...DATE_FIELD_CONFIG}
                {...getDatePickerProps(DocumentMetadataFields.ExpireAt, watch())}
                value={parseDate(field.value) || null}
                onChange={(date: Date | null) => field.onChange(formatApiDate(date))}
                label={DOCUMENT_LABELS.EXPIRE_AT}
                renderInput={(params) => {
                  const modifiedParams = previousVersionDocument
                    ? modifyPlaceholder(params, previousVersionDocument.ExpireAt)
                    : params;
                  return (
                    <CustomTextField
                      data-test-id={TEST_ID.BULK_METADATA.EXPIRE_AT}
                      {...modifiedParams}
                      helperWarning={error}
                      tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.EXPIRE_AT}
                    />
                  );
                }}
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.Version}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Version.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.DOCUMENT_VERSION}
                {...field}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.VERSION}
                label={DOCUMENT_LABELS.VERSION}
                placeholder={
                  previousVersionDocument
                    ? `previous version - ${previousVersionDocument.Version}`
                    : ''
                }
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.LatestVersion}
            rules={requiredRule(validate, dictionaries?.LatestVersion.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.LATEST_VERSION}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.LATEST_VERSION}
                label={DOCUMENT_LABELS.LATEST_VERSION}
                InputProps={{
                  readOnly: true,
                  inputComponent: () => (
                    <div className='custom-text-field-input-component'>
                      <RadioGroupSwitch {...field} />
                    </div>
                  ),
                }}
              />
            )}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.Active}
            defaultValue={BOOLEAN_VALUES.TRUE}
            rules={requiredRule(validate, dictionaries?.Active.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.ACTIVE}
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.ACTIVE}
                label={DOCUMENT_LABELS.ACTIVE}
                InputProps={{
                  readOnly: true,
                  inputComponent: () => (
                    <div className='custom-text-field-input-component'>
                      <RadioGroupSwitch {...field} />
                    </div>
                  ),
                }}
              />
            )}
          />

          <CustomTextField
            data-test-id={TEST_ID.BULK_METADATA.DOCUMENT_NAME}
            value={documentNameValue}
            disabled
            label={DOCUMENT_LABELS.DOCUMENT_NAME}
            tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.DOCUMENT_NAME}
          />

          <Controller
            control={control}
            name={DocumentMetadataFields.Remarks}
            defaultValue=''
            rules={requiredRule(validate, dictionaries?.Remarks?.required)}
            render={({ field, fieldState: { error } }) => (
              <CustomTextField
                data-test-id={TEST_ID.BULK_METADATA.REMARKS}
                inputProps={{ maxLength: MAX_REMARKS_LENGTH }}
                {...field}
                value={field.value ?? ''}
                multiline
                helperWarning={error}
                tooltipMessage={DOCUMENT_METADATA_TOOLTIPS.REMARKS}
                label={DOCUMENT_LABELS.REMARKS}
              />
            )}
          />
        </div>
      </div>

      <ApiError apiError={apiError} />

      <div className='metadata-form-submit'>
        <LoadingButton
          data-test-id={TEST_ID.BULK_METADATA.SAVE_BUTTON}
          loading={isLoading || isCancelDocLoading}
          disabled={saveDisabled || missingLopInBulkMode}
          fullWidth
          variant='contained'
          size='large'
          type='submit'
          className={!addNewVersionMode ? 'single-button' : ''}
        >
          Save
        </LoadingButton>
        {addNewVersionMode && (
          <SubmitUploadButton
            disabled={!isValid || !isPdfRendered}
            toSubmit={[document?.DocumentId!]}
            setResultForFinalConfirmModal={setResult}
            setOpenFinalConfirmModal={setOpenConfirmModal}
            beforeSubmit={handleSubmit}
            onLoadingChange={setDisableBySubmit}
            changedFields={changedFields}
          />
        )}
      </div>
      <UploadConfirmationModal
        open={openConfirmModal}
        onClose={() => {
          setOpenConfirmModal(false);
          dispatch(closeModal());
          dispatch(listApi.util.invalidateTags([LIST_API_TAGS.LIST]));
        }}
        result={result}
        renderDuplicateItems={renderDuplicateItems}
        renderErrorItems={renderErrorItems}
        mode='document'
      />
    </form>
  );
};
