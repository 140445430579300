import { DataGridPro } from '@mui/x-data-grid-pro';
import { TAB_ITEMS_PAGE_SIZE } from '../../../../config/config';
import { DocumentTabItemsProps } from './DocumentTabItems.types';

import './DocumentTabItems.scss';

export const DocumentTabItems: React.FC<DocumentTabItemsProps> = ({
  items,
  dictionaries,
  documentHook: useDocumentHook,
  columnFn,
  height = 100,
}) => {
  const {
    openNewDocumentViewModal,
    checkIfItemMinimized,
    checkIfItemCompared,
    checkIfMinimizeDisabled,
  } = useDocumentHook();

  return (
    <div className='tab-items-container'>
      <DataGridPro
        pageSize={TAB_ITEMS_PAGE_SIZE}
        getRowHeight={() => height}
        sortModel={[{ field: 'UpdatedAt', sort: 'desc' }]}
        className='tab-items-list-grid'
        autoHeight
        getRowId={(row) => row.DocumentId}
        rows={items ?? []}
        columns={columnFn(dictionaries)}
        onRowClick={({ row: { DocumentId } }) => {
          openNewDocumentViewModal && openNewDocumentViewModal(DocumentId);
        }}
        disableSelectionOnClick
        disableColumnMenu
        headerHeight={0}
        hideFooterRowCount
        getRowClassName={({ row: { DocumentId } }) => {
          if (checkIfItemCompared && checkIfItemCompared(DocumentId)) {
            return 'row-compared';
          }
          if (checkIfItemMinimized && checkIfItemMinimized(DocumentId)) {
            return 'row-minimized';
          }
          if (checkIfMinimizeDisabled && checkIfMinimizeDisabled(DocumentId)) {
            return 'row-minimized-disabled';
          }
          return '';
        }}
      />
    </div>
  );
};
