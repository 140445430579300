import { useParams } from 'react-router-dom';
import { useGetDocumentsListQuery } from '../../store/files/documents/documents.list.service';
import {
  DocumentsListParams,
  DocumentStatus,
} from '../../store/files/documents/documents.list.types';
import { openDocumentViewModal } from '../../store/files/documents/documents.slice';
import { useAppDispatch } from '../../store/hooks';

export const useFetchAndDispatchDocument = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const queryParameters: DocumentsListParams = {
    DocumentId: [id ?? ''],
    Status: [DocumentStatus.READY],
  };
  const { data: documents } = useGetDocumentsListQuery(queryParameters, { skip: !id });
  if (id) {
    const document = documents?.Documents?.[0];
    dispatch(openDocumentViewModal({ document: document! }));
  }
};
