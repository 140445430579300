import React from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';

import './ReadMoreControl.scss';
import { SERVICE_EMAIL } from '../../config/config';

const READ_MORE_TOOLTIP_TITLE =
  'Global Wording Repository offers functionality of automatic upload of documents from local repositories. In order to enable this option for your entity/business unit, please contact ';

export const ReadMoreControl = () => {
  return (
    <Tooltip
      title={
        <>
          {READ_MORE_TOOLTIP_TITLE}
          <a
            className='automatic-upload-info-link'
            href={`mailto:${SERVICE_EMAIL}`}
            target='_blank'
            rel='noreferrer'
          >
            {SERVICE_EMAIL}
          </a>
        </>
      }
    >
      <div className='automatic-upload-info'>
        <InfoOutlinedIcon className='automatic-upload-info-icon' />
        <div>Automatic Upload - Read more</div>
      </div>
    </Tooltip>
  );
};
