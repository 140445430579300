import { ModalClose } from '../ModalClose';
import { Box, Button, Modal } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './WarningModal.scss';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import CircularProgress from '@mui/material/CircularProgress';

export const WarningModal: React.FC<{
  open: boolean;
  onClose?: () => void;
  buttonLabel: string;
  title: string;
  children: React.ReactNode;
  isLoading?: boolean;
}> = ({ open, onClose, title, buttonLabel, children, isLoading = false }) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  if (!onClose) {
    onClose = () => setOpenModal(false);
  }

  useEffect(() => {
    setOpenModal(open);
  }, [open]);
  return (
    <>
      <Modal open={openModal} onClose={onClose}>
        {isLoading ? (
          <Box sx={{ textAlign: 'center', position: 'absolute', top: '50%', left: '50%' }}>
            <CircularProgress sx={{ color: 'white' }} />
          </Box>
        ) : (
          <div className='mars-modal warning-modal'>
            <ModalClose onClose={onClose} />
            {title ? (
              <Box className='modal-title'>
                <div>{title}</div>
                <Box className='rectangle' />
              </Box>
            ) : null}
            <Box className='modal-description'>
              <div>
                <WarningAmberOutlinedIcon className='warning' />
              </div>
              {children}
            </Box>
            <Box>
              <Button className='dismiss' variant='contained' onClick={onClose}>
                {buttonLabel}
              </Button>
            </Box>
          </div>
        )}
      </Modal>
    </>
  );
};
