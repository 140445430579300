import React, { Dispatch, FormEvent, useEffect, useState } from 'react';
import { FormControl, Modal } from '@mui/material';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { ButtonWithIcon } from '../../StaticComponents/ButtonWithIcon';
import {
  AdminPanelMetadata,
  AdminPanelUser,
  GroupMetadata,
} from '../../../store/adminPanel/adminPanel.types';
import './EditUsersGroupsModal.scss';
import { EDIT_REASON, EditAttention } from './EditAttention';
import LoadingButton from '@mui/lab/LoadingButton';
import { Controller, useForm } from 'react-hook-form';
import { useUpdateUserMutation } from '../../../store/adminPanel/adminPanel.service';
import TextField from '@mui/material/TextField';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import { DocumentFilter } from '../../Documents/DocumentsSearch/DocumentFilter';
import { GroupNameFilter } from '../GroupNameFilter/GroupNameFilter';
import { SwitchWithLabel } from '../../StaticComponents/SwitchWithLabel';
import { filterUserGroupsValues } from '../AdminPanel.helpers';
import { useSnackbar } from 'notistack';
import { handleApiError } from '../../../store/error.helpers';
import { useApiError } from '../../../store/ui/ui.helpers';
import { ApiError } from '../../UploadFiles/MetadataForm/ApiError';
import { FirstParam } from '../../Documents/DocumentsSearch/DocumentsSearch.types';

export const EditUsersGroupsModal: React.FC<{
  open: boolean;
  onClose: Dispatch<void>;
  selectedUser: AdminPanelUser;
  metadata?: AdminPanelMetadata;
}> = ({ open, onClose, selectedUser, metadata }) => {
  const { apiError, setApiError, clearApiError } = useApiError();
  const [showAttention, setShowAttention] = useState<boolean>(false);
  const [editReason, setEditReason] = useState<EDIT_REASON | undefined>(undefined);
  const [filteredUserGroupsValues, setFilteredUserGroupsValues] = useState<
    GroupMetadata[] | undefined
  >(undefined);
  const { enqueueSnackbar } = useSnackbar();

  const [update, { isLoading: isUpdatingUser }] = useUpdateUserMutation();

  const { control, watch, setValue, getValues, reset } = useForm<AdminPanelUser>();
  const selectedEntity = watch('Entity');
  const selectedActive = watch('Active');

  useEffect(() => {
    if (selectedUser)
      reset({
        Entity: selectedUser.Entity || '',
        UserGroups: selectedUser.UserGroups,
        Email: selectedUser?.Email,
        Approver: selectedUser?.Approver,
        Active: selectedUser?.Active,
      });
  }, [selectedUser, reset]);

  useEffect(() => {
    if (selectedUser) setValue('UserGroups', selectedUser.UserGroups);
  }, [selectedEntity, selectedUser, setValue]);

  useEffect(() => {
    setFilteredUserGroupsValues(
      filterUserGroupsValues(selectedEntity, selectedUser?.UserGroups, metadata?.Groups, setValue)
    );
  }, [selectedEntity, selectedUser, metadata, setValue]);

  const shouldShowAttentionMessageStep = editReason && !showAttention;

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    if (shouldShowAttentionMessageStep) {
      setShowAttention(true);
    } else {
      setShowAttention(false);
      update(getValues())
        .unwrap()
        .then(() => {
          enqueueSnackbar('User has been updated successfully');
        })
        .catch((response) => setApiError(handleApiError(response, true)));
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal open={open}>
      <form className='edit-users-group-form__edit-user__form' onSubmit={handleSubmit}>
        <div
          className={`edit-users-groups-modal ${showAttention ? 'attention-modal' : ''}`}
          data-test-id={TEST_ID.ADMIN_PANEL_PAGE.ADMIN_PANEL_EDIT_USER_GROUPS_MODAL}
        >
          <ModalClose onClose={handleClose} />
          <div className='confirmation-modal-header'>
            <div className='confirmation-modal-title'>Edit user</div>
            <div className='confirmation-modal-message'>
              {editReason && showAttention ? (
                <EditAttention reason={editReason} />
              ) : (
                <div className='edit-users-group-form__edit-user'>
                  <div className='edit-users-group-form__edit-user__filters'>
                    <Controller
                      control={control}
                      name='Email'
                      render={({ field, fieldState: { error } }) => (
                        <TextField
                          {...field}
                          className='single-input-modal-name'
                          placeholder='Email'
                          fullWidth
                          size='small'
                          variant='outlined'
                          helperText={<ErrorMessage error={error} />}
                          disabled={true}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='Approver'
                      render={({ field }) => (
                        <TextField
                          {...field}
                          className='single-input-modal-name'
                          label='Approver'
                          variant='outlined'
                          fullWidth
                          size='small'
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='Entity'
                      render={({ field }) => (
                        <DocumentFilter
                          {...field}
                          label='Entity'
                          values={metadata?.Entities}
                          firstParam={FirstParam.NONE}
                          onChange={(value) => {
                            field.onChange(value);
                            clearApiError();
                            setEditReason(EDIT_REASON.ENTITY);
                          }}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='UserGroups'
                      render={({ field, fieldState: { error } }) => (
                        <GroupNameFilter
                          {...field}
                          disabled={!selectedActive}
                          error={error}
                          multiple
                          label='User group(s)'
                          values={filteredUserGroupsValues as unknown as GroupMetadata[]}
                        />
                      )}
                    />

                    <Controller
                      control={control}
                      name='Active'
                      render={({ field }) => (
                        <FormControl className='switch-form-control'>
                          <SwitchWithLabel
                            {...field}
                            checked={field.value}
                            onChange={(_, checked) => {
                              field.onChange(checked);
                              clearApiError();
                              if (!checked) {
                                setEditReason(EDIT_REASON.STATUS);
                                setValue('UserGroups', []);
                              }
                            }}
                            label='Active'
                          />
                        </FormControl>
                      )}
                    />
                  </div>
                  <div className='edit-users-group-form__edit-user__form__error'>
                    <ApiError apiError={apiError} />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className='modal-buttons'>
            <ButtonWithIcon
              className='cancel-button'
              onClick={handleClose}
              disabled={isUpdatingUser}
            >
              Cancel
            </ButtonWithIcon>
            <LoadingButton
              className='confirm-button'
              loading={isUpdatingUser}
              type='submit'
              onClick={handleSubmit}
            >
              Save
            </LoadingButton>
          </div>
        </div>
      </form>
    </Modal>
  );
};
