import { DocumentResponse } from '../../../store/files/documents/documents.list.types';

export interface MetadataFormProps {
  document?: DocumentMetadata;
  onSubmit: (data: DocumentMetadata) => void;
  isLoading?: boolean;
  apiError?: string;
  validate?: boolean;
  disableSaveOnMissingData?: boolean;
  editMode?: boolean;
  disabledColumns?: DocumentMetadataFields[];
  bulkMetadataEdit?: boolean;
  addNewVersionMode?: boolean;
  previousVersionDocument?: DocumentResponse | undefined;
  preLandingMetadataEdit?: boolean;
}

export enum DocumentMetadataFields {
  DocumentId = 'DocumentId',
  FileName = 'FileName',
  Entity = 'Entity',
  Version = 'Version',
  LatestVersion = 'LatestVersion',
  DocumentType = 'DocumentType',
  ParentDocuments = 'ParentDocuments',
  Language = 'Language',
  Lob = 'Lob',
  Country = 'Country',
  Classification = 'Classification',
  ValidFrom = 'ValidFrom',
  ValidTo = 'ValidTo',
  Lop = 'Lop',
  BusinessType = 'BusinessType',
  Active = 'Active',
  ExpireAt = 'ExpireAt',
  Remarks = 'Remarks',
  ProductType = 'ProductType',
  InsurerIndustry = 'InsurerIndustry',
  VersionKey = 'VersionKey',
}

export type DocumentMetadata = {
  [DocumentMetadataFields.DocumentId]?: string;
  [DocumentMetadataFields.FileName]?: string;
  [DocumentMetadataFields.Entity]?: string;
  [DocumentMetadataFields.Version]?: string;
  [DocumentMetadataFields.LatestVersion]?: string;
  [DocumentMetadataFields.DocumentType]?: string;
  [DocumentMetadataFields.ParentDocuments]?: string[];
  [DocumentMetadataFields.Language]?: string;
  [DocumentMetadataFields.Lob]?: string;
  [DocumentMetadataFields.Country]?: string;
  [DocumentMetadataFields.Classification]?: string;
  [DocumentMetadataFields.ValidFrom]?: string;
  [DocumentMetadataFields.ValidTo]?: string;
  [DocumentMetadataFields.Lop]?: string;
  [DocumentMetadataFields.BusinessType]?: string;
  [DocumentMetadataFields.Active]?: string;
  [DocumentMetadataFields.ExpireAt]?: string;
  [DocumentMetadataFields.Remarks]?: string;
  [DocumentMetadataFields.ProductType]?: string;
  [DocumentMetadataFields.InsurerIndustry]?: string;
  [DocumentMetadataFields.VersionKey]?: string;
};
