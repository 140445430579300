import { Box, Modal } from '@mui/material';
import React from 'react';
import './MarsModal.scss';
import { ModalClose } from './ModalClose';
import { ModalProps } from './Modal.types';
import LoadingButton from '@mui/lab/LoadingButton';

export const MarsModal: React.FC<ModalProps> = ({
  children,
  title,
  buttonLabel,
  open,
  onConfirm,
  onClose = () => {},
  className = '',
  modalClassName = '',
  disabled = false,
  loading = false,
  ...props
}) => {
  const onClick = onConfirm || onClose;
  return (
    <Modal {...props} open={open} onClose={onClose} className={modalClassName}>
      <div className={`mars-modal ${className}`}>
        <ModalClose onClose={onClose} />
        {title ? (
          <Box className='modal-title'>
            <div>{title}</div>
            <Box className='rectangle' />
          </Box>
        ) : null}
        <Box className='modal-description'>{children}</Box>
        <Box>
          <LoadingButton
            className='dismiss'
            variant='contained'
            onClick={onClick}
            disabled={disabled}
            loading={loading}
          >
            {buttonLabel}
          </LoadingButton>
        </Box>
      </div>
    </Modal>
  );
};
