import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import './BulkMetadataModal.scss';
import { ButtonWithIcon } from '../../StaticComponents/ButtonWithIcon';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { MetadataForm } from '../MetadataForm/MetadataForm';
import { useEditingDisabled } from '../EditingDisabled.hook';
import { useUpdateMetadataMutation } from '../../../store/files/upload/list.service';
import { UPDATE_METADATA_CACHE_KEY } from '../../../store/files/upload/list.service.types';
import { DocumentMetadata } from '../MetadataForm/MetadataForm.types';
import { cleanupLocalFields, prepareBulkRequest } from '../MetadataForm/MetadataFormHelpers';
import { useAppDispatch } from '../../../store/hooks';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import { UPLOAD_FILES_BLOCKED_COLUMNS } from '../../../config/config';
import { handleApiError } from '../../../store/error.helpers';
import { removeSelectedDocs } from '../../../store/files/upload/upload.slice';
import { BulkMetadataModalProps } from './BulkMetadataModal.types';
import useCustomGetPreLandingListQuery from '../UploadFiles.hooks';

export const BulkMetadataModal: React.FC<BulkMetadataModalProps> = ({
  selectedDocsIds,
  bulkEditOpen,
  setBulkEditOpen,
}) => {
  const dispatch = useAppDispatch();
  const isEditingDisabled = useEditingDisabled();
  const [apiError, setApiError] = useState<string>();
  const { data: allPreLandingDocs } = useCustomGetPreLandingListQuery();
  const selectedDocs = allPreLandingDocs?.filter((doc) => selectedDocsIds.includes(doc.DocumentId));

  const [update, { isLoading }] = useUpdateMetadataMutation({
    fixedCacheKey: UPDATE_METADATA_CACHE_KEY,
  });

  const handleClose = () => {
    setBulkEditOpen(false);
    setApiError(undefined);
  };
  const handleSubmit = (data: DocumentMetadata) => {
    setApiError(undefined);
    update(cleanupLocalFields(prepareBulkRequest(data, selectedDocs)))
      .unwrap()
      .then(() => {
        dispatch(removeSelectedDocs());
        handleClose();
      })
      .catch((response) => setApiError(handleApiError(response)));
  };

  return (
    <>
      <ButtonWithIcon
        startIcon={
          <NoteAltOutlinedIcon
            data-test-id={TEST_ID.UPLOAD_FILES.BULK_METADATA_EDIT}
            fontSize='large'
          />
        }
        onClick={() => setBulkEditOpen(true)}
        disabled={isEditingDisabled || !selectedDocsIds.length}
      >
        Bulk metadata edit
      </ButtonWithIcon>

      <Modal open={bulkEditOpen}>
        <div className='bulk-metadata-modal'>
          <ModalClose onClose={handleClose} />

          <div className='bulk-metadata-modal-header'>
            <div className='bulk-metadata-modal-title'>Bulk metadata edit</div>
            <div>Enter the default values to fill the entire columns.</div>
          </div>

          <MetadataForm
            onSubmit={handleSubmit}
            isLoading={isLoading}
            apiError={apiError}
            disabledColumns={UPLOAD_FILES_BLOCKED_COLUMNS.FORM}
            bulkMetadataEdit
            preLandingMetadataEdit
          />
        </div>
      </Modal>
    </>
  );
};
