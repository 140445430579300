import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SavedSearchSelectProps } from './SavedSearchSelect.types';
import { SearchInputField } from '../../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { useSearchInput } from '../../../StaticComponents/SearchInput/SearchInput.hooks';
import SaveIcon from '@mui/icons-material/Save';
import './SavedSearchSelect.scss';
import { useGetSavedSearchesQuery } from '../../../../store/user/savedsearch.service';
import { SavedSearchTypeValues } from '../../../../store/user/savedsearch.types';

export const SavedSearchSelect = React.forwardRef(
  ({ onChange, disabled, clauseMode }: SavedSearchSelectProps, ref) => {
    const { data } = useGetSavedSearchesQuery(
      {
        SavedSearchTypes: [
          clauseMode ? SavedSearchTypeValues.CLAUSE : SavedSearchTypeValues.DOCUMENT,
        ],
      },
      { skip: disabled }
    );
    const { setInputText, filteredValues, onChangeSearch } = useSearchInput(data || []);

    return (
      <div className='save-search-select'>
        <Select
          ref={ref}
          size='small'
          displayEmpty
          value=''
          onChange={({ target: { value } }: SelectChangeEvent) =>
            onChange(data?.find(({ SavedSearchId }) => SavedSearchId === value)?.Filters)
          }
          onClose={() => setInputText('')}
          input={<OutlinedInput />}
          disabled={disabled}
          renderValue={() => (
            <div className='save-search-select-label'>
              <span className='save-search-select-icon'>
                <SaveIcon />
              </span>
              Saved searches
            </div>
          )}
        >
          {data?.length && (
            <SearchInputField label='saved searches' onChangeHandle={onChangeSearch} />
          )}
          {data?.length ? (
            filteredValues.map(({ Name, SavedSearchId }, index) => (
              <MenuItem key={index} value={SavedSearchId}>
                {Name}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>You have not saved any searches yet</MenuItem>
          )}
        </Select>
      </div>
    );
  }
);
