import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { DocumentsListParams } from '../../../../store/files/documents/documents.list.types';
import { getColumnsVisibility } from '../../DocumentsListGrid.helpers';
import { ExportConfirmationModal } from '../ExportConfirmationModal/ExportConfirmationModal';
import { ObjectType } from '../../../../store/files/reports/reports.types';
import { useExportReportMutation } from '../../../../store/files/reports/reports.service';
import { ClausesListParams } from '../../../../store/files/clauses/clauses.list.types';
import './ExportButton.scss';

export const ExportButton = ({
  params,
  type,
}: {
  params: DocumentsListParams | ClausesListParams;
  type?: ObjectType.DOCUMENT | ObjectType.CLAUSE;
}) => {
  const [open, setOpen] = useState(false);
  const [exportReportMutation, { isLoading, isError }] = useExportReportMutation();

  const visibleColumn = Object.entries(getColumnsVisibility())
    .filter(([, value]) => value)
    .map(([key]) => key);

  const handleClick = async () => {
    await exportReportMutation({
      Filters: params,
      Fields: type === ObjectType.CLAUSE ? [] : visibleColumn,
      ObjectType: type === ObjectType.CLAUSE ? ObjectType.CLAUSE : ObjectType.DOCUMENT,
    });

    setOpen(true);
  };

  return (
    <>
      {isLoading ? (
        <Box sx={{ width: '65px', height: '24px', textAlign: 'center', marginLeft: '15px' }}>
          <CircularProgress size={25} />
        </Box>
      ) : (
        <span className='export-button' onClick={handleClick}>
          <FileUploadOutlinedIcon />
          Export
        </span>
      )}
      <ExportConfirmationModal isError={isError} open={open} handleClose={() => setOpen(false)} />
    </>
  );
};
