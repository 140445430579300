import {
  BULK_EDIT_MODAL_MESSAGE,
  BULK_EDIT_MODAL_SUBMIT_BUTTON_LABEL,
  BULK_EDIT_MODAL_TITLE,
} from './ConfirmationModal.messages';
import { ConfirmationModal } from './ConfirmationModal';
import React from 'react';
import { BulkEditConfirmModalProps } from './ConfirmationModal.types';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';

export const BulkEditConfirmModal: React.FC<BulkEditConfirmModalProps> = ({
  open,
  onConfirm,
  onClose,
}) => {
  return (
    <ConfirmationModal
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      message={BULK_EDIT_MODAL_MESSAGE}
      title={BULK_EDIT_MODAL_TITLE}
      successButtonIcon={<NoteAltOutlinedIcon fontSize='large' />}
      successButtonLabel={BULK_EDIT_MODAL_SUBMIT_BUTTON_LABEL}
    />
  );
};
