import { Controller } from 'react-hook-form';
import { DocumentFilter } from './DocumentFilter';
import React, { useState } from 'react';
import { DocumentSearchFieldsProps } from './SearchFilters.types';
import {
  filterClassificationValues,
  filterLanguageValues,
  getFilterPath,
} from './DocumentsSearch.helpers';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';
import { useNarrowDownFilters } from './DocumentSearchFields.hooks';
import { onChange, onClose, onOpen } from './DocumentSearchFields.helpers';

export const DocumentSearchFields = ({
  dictionaries,
  filtersApplied,
  control,
  setValue,
  withPathPrefix,
}: DocumentSearchFieldsProps) => {
  const filteredLanguageValues = filterLanguageValues(dictionaries, filtersApplied, setValue);
  const filteredClassificationValues = filterClassificationValues(
    dictionaries,
    filtersApplied,
    setValue
  );

  const [entityValues, setEntityValues] = useState(dictionaries?.Entity.values);
  const [countryValues, setCountryValues] = useState<MetadataDictionaryValue[] | undefined>(
    () => undefined
  );
  const [lobValues, setLobValues] = useState<MetadataDictionaryValue[] | undefined>(undefined);
  const [lopValues, setLopValues] = useState<MetadataDictionaryValue[] | undefined>(undefined);
  const [businessType, setBusinessType] = useState<MetadataDictionaryValue[] | undefined>(
    undefined
  );
  const [isDropDownOpen, setIsDropDownOpen] = useState<boolean>(false);
  const [lastSelected, setLastSelected] = useState<string | undefined>(undefined);

  useNarrowDownFilters({
    dictionaries,
    entityValues,
    filtersApplied,
    isDropDownOpen,
    lastSelected,
    setBusinessType,
    setCountryValues,
    setEntityValues,
    setLobValues,
    setLopValues,
  });

  const onCloseField = onClose(setLastSelected, setIsDropDownOpen, lastSelected);
  const onOpenField = onOpen(setIsDropDownOpen);
  const onChangeField = onChange(setLastSelected);

  return (
    <>
      <Controller
        control={control}
        name={getFilterPath('Entity', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Company'
            values={entityValues}
            entityDictionary={dictionaries?.Entity}
            onClose={onCloseField(field)}
            onOpen={onOpenField}
            onChange={onChangeField(field)}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Country', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Country'
            values={countryValues}
            isCountry
            onClose={onCloseField(field)}
            onOpen={onOpenField}
            onChange={onChangeField(field)}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Lob', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='LoB'
            values={lobValues}
            onClose={onCloseField(field)}
            onOpen={onOpenField}
            onChange={onChangeField(field)}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Lop', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='LoP'
            values={lopValues}
            onClose={onCloseField(field)}
            onOpen={onOpenField}
            onChange={onChangeField(field)}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('BusinessType', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Business Type'
            values={businessType}
            onClose={onCloseField(field)}
            onOpen={onOpenField}
            onChange={onChangeField(field)}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('DocumentType', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Document type'
            values={dictionaries?.DocumentType.values}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Classification', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter
            {...field}
            multiple
            label='Classification'
            values={filteredClassificationValues}
          />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Language', withPathPrefix)}
        defaultValue={[]}
        render={({ field }) => (
          <DocumentFilter {...field} multiple label='Language' values={filteredLanguageValues} />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('Active', withPathPrefix)}
        defaultValue=''
        render={({ field }) => (
          <DocumentFilter {...field} label='Active' values={dictionaries?.Active.values} />
        )}
      />

      <Controller
        control={control}
        name={getFilterPath('LatestVersion', withPathPrefix)}
        defaultValue=''
        render={({ field }) => (
          <DocumentFilter
            {...field}
            label='Latest Version'
            values={dictionaries?.LatestVersion.values}
          />
        )}
      />
    </>
  );
};
