import React from 'react';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { ModalClose } from '../../StaticComponents/Modals/ModalClose';
import { FileTypeIcon } from '../../StaticComponents/FileTypeIcon';
import { MetadataForm } from '../MetadataForm/MetadataForm';
import './MetadataEditModal.scss';
import { DocumentViewer } from './DocumentViewer';
import {
  MAX_FILES_SIZE,
  MAXIMUM_NO_OF_UPLOADING_NEW_VERSION_OF_DOC,
  MIME_TYPES,
  UPLOAD_FILES_BLOCKED_COLUMNS,
} from '../../../config/config';
import { ItemNameText } from '../../DocumentsAndClauses/ItemNameText/ItemNameText';
import { DragAndDrop } from '../DragAndDrop/DragAndDrop';
import UploadedFileListStatusRenderer from '../UploadedFileListStatusRenderer';
import { useMetadataEditModal } from './MetadataEditModal.hooks';
import { MetadataEditModalModes, MetadataEditModalTitles } from './MetadataEditModal.types';
import { BOOLEAN_VALUES } from '../MetadataForm/MetadataFormHelpers';

export const MetadataEditModal = ({ addNewVersionMode = false }) => {
  const {
    documentId,
    uploadingDoc,
    apiError,
    pdfViewerId,
    step,
    document,
    isLoading,
    handleClose,
    handleSubmit,
    previousVersionDocument,
  } = useMetadataEditModal(addNewVersionMode);

  return (
    <Modal open={!!documentId} onClose={handleClose}>
      <div className='metadata-edit-modal'>
        <ModalClose onClose={handleClose} />

        <div className='back-button' onClick={handleClose}>
          <ChevronLeftIcon />
          {addNewVersionMode
            ? MetadataEditModalTitles.UPLOAD_NEW_VERSION
            : MetadataEditModalTitles.EDIT_METADATA}
        </div>

        <Grid container>
          <Grid item xs className='document-view'>
            {step === MetadataEditModalModes.UPLOAD && (
              <DragAndDrop
                maxFilesSize={MAX_FILES_SIZE}
                filesTypes={[MIME_TYPES.PDF]}
                maxFiles={MAXIMUM_NO_OF_UPLOADING_NEW_VERSION_OF_DOC}
                isDraft={BOOLEAN_VALUES.FALSE}
                versionKey={previousVersionDocument?.VersionKey}
              />
            )}
            {step === MetadataEditModalModes.PROCESSING && uploadingDoc && (
              <UploadedFileListStatusRenderer
                status={uploadingDoc.Status}
                progress={uploadingDoc.progress}
              />
            )}
            {step === MetadataEditModalModes.VIEWER && <DocumentViewer documentId={pdfViewerId} />}
          </Grid>

          <Grid item className='form-view'>
            <div className='metadata-edit-modal-header'></div>
            <div className='metadata-edit-modal-metadata'>
              {document && (
                <div className='modal-title'>
                  <FileTypeIcon fileType={document?.fileType} />
                  <ItemNameText fileName={document?.FileName!} strong />
                </div>
              )}
              <MetadataForm
                disabledColumns={UPLOAD_FILES_BLOCKED_COLUMNS.FORM}
                document={document}
                onSubmit={handleSubmit}
                isLoading={isLoading}
                apiError={apiError}
                validate={true}
                addNewVersionMode={addNewVersionMode}
                previousVersionDocument={previousVersionDocument}
                preLandingMetadataEdit
              />
            </div>
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};
