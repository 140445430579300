export const swapItems: <T>(arr: T[], from: number, to: number) => T[] = (arr, from, to) => {
  const newArr = [...arr];
  const temp = newArr[from];
  newArr[from] = newArr[to];
  newArr[to] = temp;
  return newArr;
};

export const moveItems: <T>(arr: T[], dir: -1 | 1) => T[] = (arr, dir) => {
  const [reference, ...compared] = arr;
  let newArr: typeof arr = [];
  if (dir === -1) {
    const [first, ...other] = compared;
    newArr = [...other, first];
  } else {
    const [last, ...other] = compared.reverse();
    newArr = [last, ...other.reverse()];
  }
  return [reference, ...newArr];
};
