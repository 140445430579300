import React, { useEffect, useState } from 'react';
import { Tabs } from '../StaticComponents/Tabs/Tabs';
import { getRoutePath, RoutePath } from '../../services/route.service';
import { resetBookmarkDetailsListQueryParams } from '../../store/files/bookmarks/bookmarks.slice';
import { BOOKMARKS_TAB_NAMES } from '../../config/config';
import { useAppDispatch } from '../../store/hooks';

import { useGetDocumentsForBookmarkListWithParams } from '../../store/files/documents/documents.hooks';
import { useGetClausesForBookmarkListWithParams } from '../../store/files/clauses/clauses.hooks';
import { useGetBookmarksQuery } from '../../store/files/bookmarks/bookmarks.service';

import { DocumentsList } from '../Documents/DocumentsList';
import { ClausesList } from '../Clauses/ClausesList';
import {
  MenuItemsBookmark,
  MenuItemsBookmarkDocuments,
} from '../Documents/DocumentContextMenu/DocumentContextMenu.helpers';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useReadAccess, useUserAccessContext } from '../../contexts/UserAccess';
import { BookmarkDetailsHeader } from './BookmarkDetailsHeader';

import './BookmarkDetails.scss';
import { Bookmark } from '../../store/files/bookmarks/bookmarks.types';

export const BookmarkDetails = () => {
  useReadAccess();
  const { hasDocumentReadAccess, hasClauseReadAccess } = useUserAccessContext();
  const [selectedBookmark, setSelectedBookmark] = useState<Bookmark | undefined>();
  const { data, isLoading, isFetching } = useGetBookmarksQuery();
  const dispatch = useAppDispatch();

  const { pathname } = useLocation();

  const pathnameSegments = pathname.split('/');
  const BookmarkId = pathnameSegments[2];
  const isDocumentsTab = pathnameSegments[3] === RoutePath.BOOKMARKS_DOCUMENTS;
  const useGetDocumentsForBookmarkListWithParamsFn = () =>
    useGetDocumentsForBookmarkListWithParams(BookmarkId);
  const useGetClausesForBookmarkListWithParamsFn = () =>
    useGetClausesForBookmarkListWithParams(BookmarkId);

  useEffect(() => {
    dispatch(resetBookmarkDetailsListQueryParams());
  }, [dispatch]);

  useEffect(() => {
    if (!isLoading && !isFetching && data?.length) {
      const selectedBookmark = data?.filter((bookmark) => bookmark.BookmarkId === BookmarkId)[0];
      setSelectedBookmark(selectedBookmark);
    }
  }, [BookmarkId, data, isFetching, isLoading]);

  return (
    <div>
      <Routes>
        <Route
          path={RoutePath.BOOKMARKS_DOCUMENTS}
          element={
            <BookmarkDetailsHeader
              selectedBookmark={selectedBookmark}
              isDocumentsTab={isDocumentsTab}
              useGetDataListWithParams={useGetDocumentsForBookmarkListWithParamsFn}
            />
          }
        />
        <Route
          path={RoutePath.BOOKMARKS_CLAUSES}
          element={
            <BookmarkDetailsHeader
              selectedBookmark={selectedBookmark}
              isDocumentsTab={isDocumentsTab}
              useGetDataListWithParams={useGetClausesForBookmarkListWithParamsFn}
            />
          }
        />
      </Routes>

      <Tabs
        base={getRoutePath(RoutePath.BOOKMARKS, BookmarkId as RoutePath) as RoutePath}
        components={[
          {
            label: BOOKMARKS_TAB_NAMES.DOCUMENTS,
            url: RoutePath.BOOKMARKS_DOCUMENTS,
            disabled: !hasDocumentReadAccess,
            component: (
              <DocumentsList
                menuOptions={MenuItemsBookmarkDocuments}
                useGetDataListWithParams={useGetDocumentsForBookmarkListWithParamsFn}
              />
            ),
          },
          {
            label: BOOKMARKS_TAB_NAMES.CLAUSES,
            url: RoutePath.BOOKMARKS_CLAUSES,
            disabled: !hasClauseReadAccess,
            component: (
              <ClausesList
                menuOptions={MenuItemsBookmark}
                useGetDataListWithParams={useGetClausesForBookmarkListWithParamsFn}
              />
            ),
          },
        ]}
      />
    </div>
  );
};
