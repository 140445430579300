export type SubmitResult = {
  success: number;
  error: number;
  errorsId: string[];
  duplicates: number;
  duplicatesId: string[];
};

export enum SUBMIT_STATUS {
  SUCCESS = 'success',
  DUPLICATE = 'duplicate',
  ERROR = 'error',
}

export type SubmitStatus = SUBMIT_STATUS.SUCCESS | SUBMIT_STATUS.DUPLICATE | SUBMIT_STATUS.ERROR;

export type SubmitResults = Record<string, SubmitStatus>[];
