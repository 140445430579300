import React from 'react';
import { CircularProgress, Tooltip } from '@mui/material';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CoronavirusOutlineOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import { UploadedFileStatus } from './UploadedFileList.types';
import { mapErrorResponseToErrorMessageDetails } from '../../store/files/documents/documents.update.error.helpers';
import { StatusRendererProps } from './UploadedFileListStatusRenderer.types';

const UploadedFileListStatusRenderer: React.FC<StatusRendererProps> = ({
  status,
  progress,
  errors,
}) => {
  switch (status) {
    case UploadedFileStatus.MISSING_DATA:
      return (
        <div className='uploading-status warning'>
          <WarningAmberOutlinedIcon />
          <p className='uploading-status-text'>{status}</p>
        </div>
      );
    case UploadedFileStatus.UPLOAD_COMPLETE:
      return (
        <div className='uploading-status'>
          <DoneOutlinedIcon className='done' />
          <p className='uploading-status-text'>{status}</p>
        </div>
      );
    case UploadedFileStatus.INFECTED:
      return (
        <div className='uploading-status error'>
          <CoronavirusOutlineOutlinedIcon />
          <p className='uploading-status-text'>{status}</p>
        </div>
      );
    case UploadedFileStatus.UPLOAD_FAILED:
    case UploadedFileStatus.ERROR:
      return (
        <Tooltip
          title={errors ? <div>{mapErrorResponseToErrorMessageDetails(errors)}</div> : ''}
          placement='top-start'
        >
          <div className='uploading-status error'>
            <ErrorOutlineOutlinedIcon />
            <p className='uploading-status-text'>{status}</p>
          </div>
        </Tooltip>
      );
    case UploadedFileStatus.UPLOADING:
      return (
        <div className='uploading-status'>
          <CircularProgress className='uploading' size={20} />
          <p className='uploading-status-text'>
            {status}... {progress ? progress + '%' : null}
          </p>
        </div>
      );
    case UploadedFileStatus.SCANNING:
    case UploadedFileStatus.DELETING:
    case UploadedFileStatus.SUBMITTING:
      return (
        <div className='uploading-status'>
          <CircularProgress className='uploading' size={20} />
          <p className='uploading-status-text'>{status}</p>
        </div>
      );
    default:
      return null;
  }
};

export default UploadedFileListStatusRenderer;
