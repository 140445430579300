import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Controller, useFormContext } from 'react-hook-form';
import './UploadButtonDraftMode.scss';
import './DropDownButton.scss';
import { DropDownButtonProps, UPLOAD_BUTTON } from './UploadButton.types';

export const DropDownButton: React.FC<DropDownButtonProps> = ({ onChange, draftModeSelected }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { control } = useFormContext();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value?: string) => {
    setAnchorEl(null);
    if (value) {
      onChange(value);
    }
  };

  return (
    <>
      <Button
        className={'upload-files drop-down ' + (draftModeSelected ? 'draft-mode-selected' : '')}
        variant='contained'
        startIcon={<KeyboardArrowDownIcon />}
        onClick={handleClick}
      />
      <Controller
        name='uploadMode'
        control={control}
        defaultValue={UPLOAD_BUTTON.UPLOAD_FILES}
        render={({ field }) => (
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => handleClose()}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              className: 'dropdown-menu',
            }}
          >
            <MenuItem
              onClick={() => {
                field.onChange(UPLOAD_BUTTON.UPLOAD_FILES);
                handleClose(UPLOAD_BUTTON.UPLOAD_FILES);
              }}
              selected={field.value === UPLOAD_BUTTON.UPLOAD_FILES}
              className='upload-file-item'
            >
              Upload Files
            </MenuItem>
            <MenuItem
              onClick={() => {
                field.onChange(UPLOAD_BUTTON.UPLOAD_DRAFTS);
                handleClose(UPLOAD_BUTTON.UPLOAD_DRAFTS);
              }}
              selected={field.value === UPLOAD_BUTTON.UPLOAD_DRAFTS}
              className='upload-draft-item'
            >
              Upload Draft Files
            </MenuItem>
          </Menu>
        )}
      />
    </>
  );
};
