import { useCallback, useMemo } from 'react';
import { MAX_WORDING_LENGTH } from '../../config/config';
import SpecialCharacterTextField from '../UploadFiles/MetadataEditModal/SpecialCharactersTextField';
import { CustomTextField, formatBoolean } from '../UploadFiles/MetadataForm/MetadataFormHelpers';
import { ClauseMetadata, FieldProps } from './UploadClause.types';
import { useAppSelector } from '../../store/hooks';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { AccessMode } from '../../store/files/documentsAndClauses/list.types';
import { useGetClauseDictionariesQueryDefaults } from '../../store/files/upload/list.service.hooks';
import { getDraftPrefilledClause } from '../UploadFiles/MetadataForm/getDraftPrefilledClause';
import { UseFormReset } from 'react-hook-form';

const useCustomTextFieldRenderer = (shouldPrefillForm: boolean, adjustClauseMode: boolean) => {
  return useMemo(() => {
    if (shouldPrefillForm && adjustClauseMode) {
      return ({ field }: FieldProps) => (
        <SpecialCharacterTextField {...field} label='' fullWidth multiline rows={10} />
      );
    } else {
      return ({ field, fieldState }: FieldProps) => (
        <CustomTextField
          inputProps={{ maxLength: MAX_WORDING_LENGTH }}
          {...field}
          multiline
          helperWarning={fieldState?.error}
          label=''
          hasCounter
          InputLabelProps={{ shrink: true }}
          placeholder='Input text here'
          disabled={shouldPrefillForm}
        />
      );
    }
  }, [shouldPrefillForm, adjustClauseMode]);
};

export default useCustomTextFieldRenderer;

export const useDraftClausePrefill = (enabled: boolean, reset: UseFormReset<ClauseMetadata>) => {
  const draftMode = useAppSelector(uiSelectors.selectDraftMode) && enabled;

  const { data: dictionaries } = useGetClauseDictionariesQueryDefaults({
    AccessMode: AccessMode.WRITE,
    Draft: formatBoolean(draftMode),
  });

  const handleDraft = useCallback(() => {
    if (draftMode && dictionaries) {
      reset(getDraftPrefilledClause(dictionaries));
    }
  }, [dictionaries, draftMode, reset]);

  return { draftMode, handleDraft };
};
