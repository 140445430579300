import React, { useMemo } from 'react';
import { DEFAULT_SEARCH_METHOD } from '../../../config/config';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import {
  SearchMethod,
  SEQUENTIAL_SEARCH,
} from '../../../store/files/documentsAndClauses/list.types';
import { LabelWithInfo } from './LabelWithInfo';
import { Controller } from 'react-hook-form';
import Typography from '@mui/material/Typography';
import { SearchOptionsProps } from './SearchOptions.types';
import { getPrefixed } from './SearchOptions.helpers';
import { TEST_ID } from '../../../config/test-fields-ids.config';
import './SearchOptions.scss';

export const SearchOptions: React.FC<SearchOptionsProps> = ({ control, prefix, index }) => {
  const prefixed = useMemo(() => getPrefixed(prefix), [prefix]);
  const searchMethodName = `${prefixed}${SEQUENTIAL_SEARCH}.${index}.SearchMethod`;
  return (
    <div className='search-options' data-test-id={TEST_ID.SEARCH_OPTIONS.SEARCH_OPTIONS_LIST}>
      <Typography variant='overline'>Search options</Typography>

      <Controller
        control={control}
        name={searchMethodName}
        defaultValue={DEFAULT_SEARCH_METHOD}
        render={({ field }) => (
          <RadioGroup {...field}>
            <FormControlLabel
              control={<Radio size='small' />}
              data-test-id={TEST_ID.SEARCH_OPTIONS.SEARCH_PHRASE}
              value={SearchMethod.PHRASE}
              label={
                <LabelWithInfo
                  title='Phrase search'
                  description='Documents and/or clauses containing any words from the searched phrase'
                />
              }
            />

            <FormControlLabel
              control={<Radio size='small' />}
              data-test-id={TEST_ID.SEARCH_OPTIONS.SEARCH_BROAD}
              value={SearchMethod.BROAD}
              label={
                <LabelWithInfo
                  title='Broad search'
                  description='Documents and/or clauses containing all words from the searched phrase'
                />
              }
            />

            <FormControlLabel
              control={<Radio size='small' />}
              data-test-id={TEST_ID.SEARCH_OPTIONS.SEARCH_EXACT}
              value={SearchMethod.EXACT}
              label={
                <LabelWithInfo
                  title='Exact search'
                  description='Documents and/or clauses with exactly the same phrase in exactly the same order'
                />
              }
            />
          </RadioGroup>
        )}
      />
    </div>
  );
};
