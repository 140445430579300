import { parseDate } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { DOCUMENTS_GRID_COLUMNS, SpreadSheetDocument } from './DocumentsGrid.types';
import { set } from 'date-fns';

export const TODAY = set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });

export const getDatePickerProps = (
  field: string,
  row: Pick<SpreadSheetDocument, 'ValidFrom' | 'ValidTo'>
) => {
  switch (field) {
    case DOCUMENTS_GRID_COLUMNS.VALID_FROM:
      return { maxDate: parseDate(row.ValidTo) };
    case DOCUMENTS_GRID_COLUMNS.VALID_TO:
      return { minDate: parseDate(row.ValidFrom) };
    case DOCUMENTS_GRID_COLUMNS.EXPIRE_AT:
      return { minDate: TODAY };
  }
};
