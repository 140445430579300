import { DocumentsListParams } from '../files/documents/documents.list.types';
import { ClausesListParams } from '../files/clauses/clauses.list.types';

export enum SAVED_SEARCH_API_TAGS {
  LIST = 'List',
}

export enum SavedSearchTypeValues {
  DOCUMENT = 'document',
  CLAUSE = 'clause',
}

export interface SavedSearchListParams {
  SavedSearchTypes: SavedSearchTypeValues[];
}

export interface SavedSearch {
  Name?: string;
  Filters?: DocumentsListParams | ClausesListParams;
  SavedSearchId?: string;
  CreatedAt?: string;
  UpdatedAt?: string;
  SavedSearchType?: SavedSearchTypeValues;
}
