import React, { FormEvent } from 'react';
import { useSnackbar } from 'notistack';
import Typography from '@mui/material/Typography';
import { MarsModal } from '../StaticComponents/Modals/MarsModal';
import '../Documents/SingleInputModal.scss';
import { handleApiError } from '../../store/error.helpers';
import { useAddBookmarkMutation } from '../../store/files/bookmarks/bookmarks.service';
import { useForm } from 'react-hook-form';
import { ApiError } from '../UploadFiles/MetadataForm/ApiError';
import { BookmarksCreate } from './BookmarksCreate.types';
import { ITEM_TYPE } from '../../store/ui/ui.types';
import { NameInput } from '../SharedComponents/NameInput/NameInput';
import { Name } from '../SharedComponents/NameInput/NameInput.types';
import { useApiError } from '../../store/ui/ui.helpers';

export const BookmarkCreate: React.FC<BookmarksCreate> = ({ open, handleClose }) => {
  const { apiError, setApiError, clearApiError } = useApiError();
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    getValues,
    formState: { isValid },
    reset,
  } = useForm<Name>({
    mode: 'onChange',
  });
  const [update, { isLoading }] = useAddBookmarkMutation();

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    setApiError(undefined);
    const { Name } = getValues();
    update({ Name })
      .unwrap()
      .then(() => {
        handleClose();
        reset();
        enqueueSnackbar('Bookmark created successfully');
      })
      .catch((response) => setApiError(handleApiError(response, true)));
  };

  const onClose = () => {
    handleClose();
    setApiError('');
    reset();
  };

  return (
    <>
      <MarsModal
        className='single-input-modal'
        title='Create new bookmark'
        buttonLabel='Save'
        open={open}
        onConfirm={handleSubmit}
        onClose={onClose}
        disabled={!isValid}
        loading={isLoading}
      >
        <Typography className='single-input-modal-description' variant='body2'>
          Bookmark will be saved in created folder. You can always access all your folders in the
          bookmarks page.
        </Typography>

        <form className='single-input-modal-form' onSubmit={handleSubmit}>
          <NameInput
            control={control}
            isLoading={isLoading}
            itemType={ITEM_TYPE.BOOKMARK}
            clearApiError={clearApiError}
          />
          <button
            className='single-input-modal-helper-button'
            type='submit'
            disabled={isLoading || !isValid}
          />
        </form>

        <ApiError apiError={apiError} />
      </MarsModal>
    </>
  );
};
