import { useEffect, useMemo, useState } from 'react';
import './SavedSearches.scss';
import { SearchInput } from './SearchInput';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { SAVED_SEARCH_PAGE_SIZE } from '../../../config/config';
import { NoItemsFound } from '../../StaticComponents/NoItemsFound/NoItemsFound';
import { useGetSavedSearchesQuery } from '../../../store/user/savedsearch.service';
import { SavedSearch } from '../../../store/user/savedsearch.types';
import { filterSavedSearches, getColumns, getSavedSearchTypes } from './SavedSearches.helpers';
import {
  useGetClauseDictionariesQueryDefaults,
  useGetDictionariesQueryDefaults,
} from '../../../store/files/upload/list.service.hooks';
import { AccessMode } from '../../../store/files/documentsAndClauses/list.types';
import { updateSelection } from '../../../store/files/upload/upload.slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';
import EditSearch from './EditSearch/EditSearch';
import { useUserAccessContext } from '../../../contexts/UserAccess';
import { TEST_ID } from '../../../config/test-fields-ids.config';

const SavedSearches = () => {
  const { hasClauseReadAccess, hasDocumentReadAccess } = useUserAccessContext();
  const SavedSearchTypes = useMemo(
    () => getSavedSearchTypes(hasDocumentReadAccess, hasClauseReadAccess),
    [hasClauseReadAccess, hasDocumentReadAccess]
  );
  const dispatch = useAppDispatch();
  const [phrase, setSearchPhrase] = useState('');
  const { data, isLoading, isFetching } = useGetSavedSearchesQuery({
    SavedSearchTypes,
  });
  const { data: dictionaries } = useGetDictionariesQueryDefaults(
    { AccessMode: AccessMode.READ },
    { skip: !hasDocumentReadAccess }
  );
  const { data: dictionariesClauses } = useGetClauseDictionariesQueryDefaults(
    { AccessMode: AccessMode.READ },
    { skip: !hasClauseReadAccess }
  );
  const [rows, setRows] = useState<SavedSearch[]>([]);
  const selectionModel = useAppSelector(uploadSelectors.selectedDocs);

  useEffect(() => {
    setRows(filterSavedSearches(data, phrase));
  }, [data, phrase]);

  return (
    <>
      {isLoading || isFetching ? (
        <Box sx={{ textAlign: 'center', padding: '30px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <div data-test-id={TEST_ID.SAVED_SEARCHES.SAVED_SEARCHES_LIST} className='saved-searches'>
          <div className='saved-searches-input'>
            <SearchInput searchQuery={phrase} setSearchQuery={setSearchPhrase} />
          </div>

          {data?.length ? (
            <DataGridPro
              hideFooterRowCount
              autoHeight={true}
              getRowId={(row) => row.SavedSearchId}
              rows={rows}
              columns={getColumns(dictionaries, dictionariesClauses)}
              checkboxSelection
              onSelectionModelChange={(selectionModel) => {
                dispatch(updateSelection({ selected: selectionModel as string[] }));
              }}
              selectionModel={selectionModel}
              disableSelectionOnClick
              hideFooterPagination
              disableColumnMenu
              className='upload-file-data-grid'
              pageSize={SAVED_SEARCH_PAGE_SIZE}
            />
          ) : (
            <NoItemsFound icon='searchIcon' subtitle='Your saved searches will be stored here.' />
          )}
        </div>
      )}

      <EditSearch dictionaries={dictionaries} dictionariesClauses={dictionariesClauses} />
    </>
  );
};

export default SavedSearches;
