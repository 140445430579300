import { Chip } from '@mui/material';
import { DOCUMENT_LABELS } from '../../../config/config';
import { ClauseResponse } from '../../../store/files/clauses/clauses.list.types';
import { getDictionaryLabelsByValues } from '../../../store/files/upload/list.helpers';
import {
  ClauseDictionariesResponse,
  PROVIDER,
} from '../../../store/files/upload/list.service.types';

import './ClauseItem.scss';

export const ProviderChip = ({
  provider,
  classname,
}: {
  provider: string | undefined;
  classname?: string;
}) => {
  switch (provider?.toLowerCase()) {
    case PROVIDER.GPC.toLowerCase():
      return <Chip label='General clause added by Global P&C' className={classname} />;
    case PROVIDER.COMPLIANCE.toLowerCase():
      return <Chip label='General clause added by Compliance' className={classname} />;
    default:
      return null;
  }
};

export const mixedMetadata = (dictionaries: ClauseDictionariesResponse, clause: ClauseResponse) => {
  const { Entities, Countries, Lobs, ClauseSources, Tag } = clause;
  return [
    {
      dictValues: [...getDictionaryLabelsByValues(dictionaries?.Entities!, Entities)],
      label: DOCUMENT_LABELS.COMPANY_NAME,
      values: Entities,
    },
    {
      dictValues: [...getDictionaryLabelsByValues(dictionaries?.Countries!, Countries)],
      label: DOCUMENT_LABELS.COUNTRY,
      values: Countries,
    },
    {
      dictValues: [...getDictionaryLabelsByValues(dictionaries?.Lobs!, Lobs)],
      label: DOCUMENT_LABELS.BUSINESS_LINE,
      values: Lobs,
    },
    { dictValues: [...getDictionaryLabelsByValues(dictionaries?.ClauseSources!, ClauseSources)] },
    {
      dictValues: [''],
      label: DOCUMENT_LABELS.TAG,
      values: [Tag],
    },
  ];
};
