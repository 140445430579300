import React, { useRef } from 'react';
import { TextFieldProps } from '@mui/material/TextField';
import { CustomTextField } from '../MetadataForm/MetadataFormHelpers';
import { useCursorHandler, useInputRestrictions } from './SpecialCharactersTextField.hooks';
import { MAX_WORDING_LENGTH } from '../../../config/config';

const SpecialCharacterTextField: React.FC<TextFieldProps> = ({ ...props }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  useCursorHandler(inputRef);
  useInputRestrictions(inputRef);

  return (
    <CustomTextField
      {...props}
      inputRef={inputRef}
      hasCounter
      inputProps={{ maxLength: MAX_WORDING_LENGTH }}
    />
  );
};

export default SpecialCharacterTextField;
