import React, { FormEvent, useState } from 'react';
import { useSnackbar } from 'notistack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import SaveIcon from '@mui/icons-material/Save';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { MarsModal } from '../StaticComponents/Modals/MarsModal';
import { SaveSearchInputProps, SaveSearchProps } from './SaveSearch.types';
import './SaveSearch.scss';
import './SingleInputModal.scss';
import { handleApiError } from '../../store/error.helpers';
import { useAddSavedSearchMutation } from '../../store/user/savedsearch.service';
import { Controller, useForm } from 'react-hook-form';
import { SavedSearch, SavedSearchTypeValues } from '../../store/user/savedsearch.types';
import { ApiError } from '../UploadFiles/MetadataForm/ApiError';
import { SearchFiltersControl } from './DocumentsSearch/SearchFilters.types';
import {
  SEARCH_NAME_AND_BOOKMARK_MAX_LENGTH,
  SEARCH_NAME_AND_BOOKMARK_MIN_LENGTH,
} from '../../config/config';
import { TEST_ID } from '../../config/test-fields-ids.config';
import { useApiError } from '../../store/ui/ui.helpers';

export const SaveSearchInput: React.FC<SaveSearchInputProps> = ({
  control,
  isLoading,
  onClearError,
}) => (
  <Controller
    control={control}
    name='Name'
    defaultValue=''
    rules={{
      required: {
        value: true,
        message: 'Enter name to save the search query',
      },
      minLength: {
        value: SEARCH_NAME_AND_BOOKMARK_MIN_LENGTH,
        message: `Name must be more than ${SEARCH_NAME_AND_BOOKMARK_MIN_LENGTH} characters`,
      },
      maxLength: {
        value: SEARCH_NAME_AND_BOOKMARK_MAX_LENGTH,
        message: `Name must be less than ${SEARCH_NAME_AND_BOOKMARK_MAX_LENGTH} characters`,
      },
    }}
    render={({ field, fieldState: { error } }) => (
      <TextField
        data-test-id={TEST_ID.DOCUMENTS_PAGE.DOCUMENTS_PAGE_SAVE_SEARCH_INPUT}
        {...field}
        disabled={isLoading}
        className='single-input-modal-name'
        placeholder='Type saved search name'
        fullWidth
        size='small'
        variant='outlined'
        helperText={
          error && (
            <span className='single-input-modal-helper-text'>
              <WarningAmberOutlinedIcon /> <span>{error.message}</span>
            </span>
          )
        }
        onChange={(e) => {
          field.onChange(e);
          onClearError();
        }}
      />
    )}
  />
);

export const SaveSearch = ({ filters, clauseMode }: SaveSearchProps) => {
  const { apiError, setApiError, clearApiError } = useApiError();
  const [open, setOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    control,
    getValues,
    formState: { isValid },
    reset,
  } = useForm<SavedSearch>({
    mode: 'onChange',
  });
  const [update, { isLoading }] = useAddSavedSearchMutation();

  const handleSubmit = (e?: FormEvent) => {
    e?.preventDefault();
    setApiError(undefined);
    const { Name } = getValues();
    update({
      Name,
      Filters: filters,
      SavedSearchType: clauseMode ? SavedSearchTypeValues.CLAUSE : SavedSearchTypeValues.DOCUMENT,
    })
      .unwrap()
      .then(() => {
        handleClose();
        enqueueSnackbar('Your search query was saved successfully');
      })
      .catch((response) => setApiError(handleApiError(response, true)));
  };

  const handleClose = () => {
    setOpen(false);
    setApiError('');
    reset();
  };

  return (
    <>
      <Button
        data-test-id={TEST_ID.DOCUMENTS_PAGE.DOCUMENTS_PAGE_SAVE_SEARCH_BUTTON}
        className='save-search-button'
        startIcon={<SaveIcon />}
        size='small'
        variant='text'
        onClick={() => setOpen(true)}
      >
        Save search and filters
      </Button>

      <MarsModal
        data-test-id={TEST_ID.DOCUMENTS_PAGE.DOCUMENTS_PAGE_SAVE_SEARCH_MODAL}
        className='single-input-modal'
        title='Save search query with selected filters'
        buttonLabel='Save'
        open={open}
        onConfirm={handleSubmit}
        onClose={handleClose}
        disabled={!isValid}
        loading={isLoading}
      >
        <Typography className='single-input-modal-description' variant='body2'>
          Save your search query and selected filters to access them later on from documents and
          clauses search.
        </Typography>

        <form className='single-input-modal-form' onSubmit={handleSubmit}>
          <SaveSearchInput
            control={control as SearchFiltersControl}
            isLoading={isLoading}
            onClearError={clearApiError}
          />
          <button
            className='single-input-modal-helper-button'
            type='submit'
            disabled={isLoading || !isValid}
          />
        </form>

        <ApiError apiError={apiError} />
      </MarsModal>
    </>
  );
};
