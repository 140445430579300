import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Clause } from './clauses.list.types';

const selectClauses = (state: RootState) => state.files.clauses;

const selectModalClause = createSelector(
  selectClauses,
  (items): Clause | undefined => items.clauseMetadataEditModal
);

const selectModalClauseView = createSelector(
  selectClauses,
  (items): Clause | undefined => items.clauseViewModal
);

const selectListQueryParams = createSelector(selectClauses, (items) => items.listQueryParams);

const selectPendingListQueryParams = createSelector(
  selectClauses,
  (items) => items.pendingListQueryParams
);

const selectUploadedListQueryParams = createSelector(
  selectClauses,
  (items) => items.uploadedListQueryParams
);

const selectReportedListQueryParams = createSelector(
  selectClauses,
  (items) => items.reportedListQueryParams
);

const selectDraftListQueryParams = createSelector(
  selectClauses,
  (items) => items.draftListQueryParams
);

export const clauseSelector = {
  selectModalClause,
  selectModalClauseView,
  selectListQueryParams,
  selectPendingListQueryParams,
  selectUploadedListQueryParams,
  selectReportedListQueryParams,
  selectDraftListQueryParams,
};
