import React from 'react';
import { mapUploadedFileType } from '../../../store/files/upload/upload.helpers';
import { StatusLabel } from '../../Documents/StatusLabel';
import { DocumentInfoHeaderProps } from './DocumentInfoHeader.types';
import { FileTypeIcon } from '../FileTypeIcon';
import { ItemNameText } from '../../DocumentsAndClauses/ItemNameText/ItemNameText';

import './DocumentInfoHeader.scss';
import { ProviderChip } from '../../Clauses/ClauseItem/ClauseItem.helper';
import { ClauseStatusIcon } from '../../Clauses/ClauseStatusIcon/ClauseStatusIcon';
import { DraftLabel } from '../DraftLabel';

export const DocumentInfoHeader = ({
  document,
  clause,
  showProvider = true,
}: DocumentInfoHeaderProps) => {
  return (
    <div className='document-info-header'>
      <FileTypeIcon fileType={mapUploadedFileType(document?.FileName! || clause?.ClauseName!)} />
      <div className='label-wrapper'>
        {document?.Status && <StatusLabel status={document.Status} />}
        <DraftLabel draft={document?.IsDraft || clause?.IsDraft} />
      </div>
      {clause?.ClauseStatus && <ClauseStatusIcon clauseStatus={clause.ClauseStatus} />}
      <ItemNameText fileName={document?.FileName! || clause?.ClauseName!} strong />
      <small>{document?.Version ? `version ${document.Version}` : ''}</small>
      {showProvider ? <ProviderChip provider={clause?.Provider} /> : null}
    </div>
  );
};
