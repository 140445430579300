import { FieldError } from 'react-hook-form';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import './ErrorMessage.scss';

export const ErrorMessage = ({ error }: { error?: FieldError }) => {
  return error ? (
    <span className='admin-panel__error-message single-input-modal-helper-text'>
      <WarningAmberOutlinedIcon /> <span>{error.message}</span>
    </span>
  ) : null;
};
