import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AddItemToBookmarkRequest, Bookmark, BookmarkDeleteRequest } from './bookmarks.types';
import {
  BOOKMARKS_DELETE_ENDPOINT,
  BOOKMARKS_ITEMS_ENDPOINT,
  BOOKMARKS_LIST_ENDPOINT,
} from '../../../services/api.service.config';
import { appendAuthorizationHeader } from '../../../components/Auth/auth.helpers';
import { UpdateNameProps } from '../../../components/SharedComponents/ContextMenu/ContextMenuEditName.types';

export enum BOOKMARKS_API_TAGS {
  LIST = 'List',
}

export const bookmarksApi = createApi({
  reducerPath: 'bookmarksApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BOOKMARKS_LIST_ENDPOINT,
    prepareHeaders: appendAuthorizationHeader,
  }),
  tagTypes: [BOOKMARKS_API_TAGS.LIST],
  endpoints: (builder) => ({
    getBookmarks: builder.query<Bookmark[], void>({
      query: () => '',
      providesTags: [BOOKMARKS_API_TAGS.LIST],
    }),
    addBookmark: builder.mutation<void, Bookmark>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: (res, err) => (err ? [] : [BOOKMARKS_API_TAGS.LIST]),
    }),
    updateBookmark: builder.mutation<void, UpdateNameProps>({
      query: ({ id, name }) => ({
        url: '',
        method: 'PUT',
        body: {
          Name: name,
          BookmarkId: id,
        },
      }),
      invalidatesTags: (res, err) => (err ? [] : [BOOKMARKS_API_TAGS.LIST]),
    }),
    addItemToBookmark: builder.mutation<void, AddItemToBookmarkRequest>({
      query: (body) => ({
        url: BOOKMARKS_ITEMS_ENDPOINT,
        method: 'POST',
        body,
      }),
    }),
    deleteBookmark: builder.mutation<void, string>({
      query: (id) => ({
        url: BOOKMARKS_DELETE_ENDPOINT.replace('{id}', id),
        method: 'DELETE',
      }),
      invalidatesTags: [BOOKMARKS_API_TAGS.LIST],
    }),
    deleteBookmarkItem: builder.mutation<void, BookmarkDeleteRequest>({
      query: (body) => ({
        url: BOOKMARKS_ITEMS_ENDPOINT,
        method: 'DELETE',
        body,
      }),
    }),
  }),
});

export const {
  useGetBookmarksQuery,
  useAddBookmarkMutation,
  useUpdateBookmarkMutation,
  useAddItemToBookmarkMutation,
  useDeleteBookmarkMutation,
  useDeleteBookmarkItemMutation,
} = bookmarksApi;
