import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { AdminPanelUser, UsersGroupsOperation } from '../../../store/adminPanel/adminPanel.types';
import React from 'react';
import { UsersListChip } from './UsersListChip';
import './BulkEditAttention.scss';

export const BulkEditAttention: React.FC<{
  selectedUsers?: AdminPanelUser[];
  userGroups: string[];
  operation: UsersGroupsOperation;
}> = ({ selectedUsers, userGroups, operation }) => {
  return (
    <div className='bulk-edit-users-group-form__attention'>
      <WarningAmberOutlinedIcon className='warning' />
      <div>
        To selected users:
        <UsersListChip selectedUsers={selectedUsers} />
      </div>
      {userGroups && (
        <div>
          {operation === UsersGroupsOperation.ADD
            ? 'Will be applied the following privileges:'
            : 'The following privileges will be revoked:'}
          <ul className={operation}>
            {userGroups.map((userGroup) => (
              <li key={userGroup}>{userGroup}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
