import React, { useState, useEffect } from 'react';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { UploadedFileStatus } from '../UploadedFileList.types';
import { listApi } from '../../../store/files/upload/list.service';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';
import { updateSelection } from '../../../store/files/upload/upload.slice';
import { MarsSnackbar } from '../../StaticComponents/MarsSnackbar/MarsSnackbar';
import { SubmitResult } from '../../../store/files/upload/submit.service.types';
import { LIST_API_TAGS } from '../../../store/files/upload/list.service.types';
import { UploadSubmitDocumentsProps } from './UploadSubmitDocuments.types';
import { UploadConfirmationModal } from '../../StaticComponents/Modals/UploadConfirmationModal/UploadConfirmationModal';
import SubmitUploadButton from './SubmitUploadButton';
import { renderItems } from './UploadSubmitDocuments.helpers';
import useCustomGetPreLandingListQuery from '../UploadFiles.hooks';

export const UploadSubmitDocuments: React.FC<UploadSubmitDocumentsProps> = ({
  isSnackbarEnabled,
  draftMode,
}) => {
  const dispatch = useAppDispatch();
  const { data: docs } = useCustomGetPreLandingListQuery();
  const selected = useAppSelector(uploadSelectors.selectedDocs);
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState<SubmitResult>();
  const documentId = useAppSelector(uploadSelectors.selectModalDocument);

  useEffect(() => {
    dispatch(updateSelection({ selected: [] }));
  }, [dispatch]);

  const onClose = () => {
    setOpen(false);
    dispatch(listApi.util.invalidateTags([LIST_API_TAGS.LIST]));
    dispatch(updateSelection({ selected: [] }));
  };

  if (!docs) return null;

  const ready = docs
    .filter(({ Status }) => Status === UploadedFileStatus.UPLOAD_COMPLETE)
    .map((doc) => doc.DocumentId);
  const toSubmit = ready.filter((doc) => selected.indexOf(doc) > -1);

  const renderDuplicateItems = renderItems(docs, result?.duplicatesId);
  const renderErrorItems = renderItems(docs, result?.errorsId);

  return (
    <>
      <MarsSnackbar
        open={!!ready.length && isSnackbarEnabled && !documentId}
        message={
          <>
            <DoneOutlinedIcon className='done-icon' />
            <strong>Ready to submit</strong>
            <small>
              ({toSubmit.length}/{ready.length})
            </small>
          </>
        }
        action={
          <SubmitUploadButton
            disabled={!toSubmit.length}
            toSubmit={toSubmit}
            setResultForFinalConfirmModal={setResult}
            setOpenFinalConfirmModal={setOpen}
          />
        }
      />
      <UploadConfirmationModal
        open={open}
        onClose={onClose}
        result={result}
        renderDuplicateItems={renderDuplicateItems}
        renderErrorItems={renderErrorItems}
        mode={draftMode ? 'draft_document' : 'document'}
      />
    </>
  );
};
