import Chip from '@mui/material/Chip';
import React, { useState } from 'react';
import { processWord, removeSpecialCharacters } from './TagInput.helpers';
import { TagInputProps } from './TagInput.types';
import './TagInput.scss';

export const TagInput = ({
  onChange,
  placeholder,
  value = [],
  small = false,
  noSpecialCharacters = false,
}: TagInputProps) => {
  const [inputValue, setInputValue] = useState('');

  const handleDelete = (index: number) => {
    onChange(value.filter((_, i) => index !== i));
  };

  const handleKeyDown = (key: string) => {
    const word = processWord(inputValue, value);
    if (key === 'Enter' && word) {
      onChange([...value, word]);
      setInputValue('');
    }
  };

  return (
    <div className={`tag-input ${small ? 'tag-input--small' : ''}`}>
      {value.map((v, i) => (
        <Chip key={i} size='small' label={v} onDelete={() => handleDelete(i)} />
      ))}

      <input
        placeholder={placeholder}
        value={inputValue}
        onChange={({ target: { value } }) =>
          setInputValue(noSpecialCharacters ? removeSpecialCharacters(value) : value)
        }
        onKeyDown={(e) => {
          e.stopPropagation();
          handleKeyDown(e.key);
        }}
      />
    </div>
  );
};
