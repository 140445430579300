import { DOCUMENT_LABELS } from '../../../../config/config';
import React from 'react';
import { FilterPanelItem } from './FilterPanelItem';
import { DocumentFiltersPanelProps } from './FiltersPanel.types';

export const DocumentFiltersPanel = ({ filters, dictionaries }: DocumentFiltersPanelProps) => {
  if (!dictionaries) return null;
  return (
    <>
      <FilterPanelItem
        filter={filters.Entity}
        label={DOCUMENT_LABELS.COMPANY_NAME}
        dictionary={dictionaries.Entity}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.Country}
        label={DOCUMENT_LABELS.COUNTRY}
        dictionary={dictionaries.Country}
        multipleFilters
        hasFlag
      />

      <FilterPanelItem
        filter={filters.Lob}
        label={DOCUMENT_LABELS.BUSINESS_LINE}
        dictionary={dictionaries.Lob}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.Lop}
        label={DOCUMENT_LABELS.PRODUCT_LINE}
        dictionary={dictionaries.Lop}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.BusinessType}
        label={DOCUMENT_LABELS.BUSINESS_TYPE}
        dictionary={dictionaries.BusinessType}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.DocumentType}
        label={DOCUMENT_LABELS.DOCUMENT_TYPE}
        dictionary={dictionaries.DocumentType}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.Classification}
        label={DOCUMENT_LABELS.CLASSIFICATION}
        dictionary={dictionaries.Classification}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.Language}
        label={DOCUMENT_LABELS.LANGUAGE}
        dictionary={dictionaries.Language}
        multipleFilters
      />

      <FilterPanelItem
        filter={filters.Active}
        label={DOCUMENT_LABELS.ACTIVE}
        dictionary={dictionaries.Active}
      />

      <FilterPanelItem
        filter={filters.LatestVersion}
        label={DOCUMENT_LABELS.LATEST_VERSION}
        dictionary={dictionaries.LatestVersion}
      />
    </>
  );
};
