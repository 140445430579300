import {
  UploadedFileAction,
  UploadedFileStatus,
} from '../../../components/UploadFiles/UploadedFileList.types';

export const mapUploadedFileEdit = (status: UploadedFileStatus) =>
  status === UploadedFileStatus.MISSING_DATA || status === UploadedFileStatus.UPLOAD_COMPLETE;

export const mapUploadedFileAction = (status: UploadedFileStatus) =>
  status === UploadedFileStatus.UPLOADING ? UploadedFileAction.STOP : UploadedFileAction.DELETE;

export const mapUploadedFileType = (name?: string) => name?.split('.')?.pop()?.toLowerCase() || '';
