import { DocumentsListParams } from '../documents/documents.list.types';
import { ClausesListParams } from '../clauses/clauses.list.types';
import { SequentialSearch } from '../documentsAndClauses/list.types';

export enum ObjectType {
  CLAUSE = 'clause',
  DOCUMENT = 'document',
}

export type ExportReportResponse = {
  reportId: string;
  status: string;
};

export type GetReportResponse = {
  presignedUrl: string;
};

export type ReportFilters = DocumentsListParams | ClausesListParams | undefined;

export type ExportReportRequest = {
  Filters: ReportFilters;
  Fields: string[];
  ObjectType: ObjectType;
};

export enum REPORT_STATUS {
  REQUESTED = 'Requested from ACH',
  IN_PROCESSING = 'In processing',
  PROCESSED = 'Processed',
  FAILED = 'Failed',
}

export type Report = {
  ReportId: string;
  UserId: string;
  ReportName?: string;
  Status: REPORT_STATUS;
  CreatedAt: string;
  UpdatedAt: string;
  ReportType: ReportType;
  ObjectType: 'document' | 'clause' | 'not applicable';
};

export interface ReportSearchFilters {
  Status?: string[];
  Entity?: string[];
  ExternalId?: string[];
  BusinessType?: string[];
  DocumentId?: string[];
  DocumentType?: string[];
  ParentDocuments?: string[];
  Lop?: string[];
  Active?: string;
  Language?: string[];
  RelatedClauses?: string[];
  Reported?: string[];
  LatestVersion?: string;
  Classification?: string[];
  Country?: string[];
  VersionKey?: string[];
  ExternalVersion?: string[];
  Lob?: string[];
  ObjectType?: string;
}

export interface CreateReportDTO {
  ObjectType?: ObjectType;
  Fields: string[];
  BookmarkId?: string;
  IncludeFilters?: ReportSearchFilters;
  AccessMode?: string;
  ExcludeFilters?: ReportSearchFilters;
  Queries?: SequentialSearch[];
}

export type ReportType = GenerateReportType | 'StandardDoc' | 'StandardClause';

export type GenerateReportType =
  | 'ClauseSummary'
  | 'ClauseList'
  | 'ReportedClauses'
  | 'RelatedDocuments';

export interface GenerateReportRequest {
  ReportType: GenerateReportType;
  DateFrom?: string;
  DateTo?: string;
}
