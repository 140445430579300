import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { Document } from './documents.list.types';

const selectDocuments = (state: RootState) => state.files.documents;

const selectModalDocument = createSelector(
  selectDocuments,
  (items): Document | undefined => items.metadataEditModal
);

const selectModalDocumentView = createSelector(
  selectDocuments,
  (items): Document | undefined => items.documentViewModal
);

const selectModalDocumentFilesCompare = createSelector(
  selectDocuments,
  (items): Document | undefined => items.documentFilesCompareModal
);

const selectListQueryParams = createSelector(selectDocuments, (items) => items.listQueryParams);
const selectVersionsParams = createSelector(selectDocuments, (items) => items.versionsQueryParams);

const selectPendingListQueryParams = createSelector(
  selectDocuments,
  (items) => items.pendingListQueryParams
);

const selectUploadedDocumentsListQueryParams = createSelector(
  selectDocuments,
  (items) => items.uploadedDocumentsListQueryParams
);

const selectReportedDocumentsListQueryParams = createSelector(
  selectDocuments,
  (items) => items.reportedDocumentsListQueryParams
);

const selectDraftDocumentsListQueryParams = createSelector(
  selectDocuments,
  (items) => items.draftDocumentsListQueryParams
);

const selectSelectedText = createSelector(selectDocuments, (items) => items.selectedText);

export const documentsSelectors = {
  selectVersionsParams,
  selectListQueryParams,
  selectUploadedDocumentsListQueryParams,
  selectReportedDocumentsListQueryParams,
  selectDraftDocumentsListQueryParams,
  selectPendingListQueryParams,
  selectModalDocument,
  selectModalDocumentView,
  selectModalDocumentFilesCompare,
  selectSelectedText,
};
