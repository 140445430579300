import { ClauseStatuses } from '../../../store/files/clauses/clauses.list.types';
import { Tooltip } from '@mui/material';
import { CLAUSE_STATUS_TOOLTIPS } from '../../../config/config';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import './ClauseStatusIcon.scss';

export const ClauseStatusIcon = ({ clauseStatus }: { clauseStatus: ClauseStatuses }) => {
  return clauseStatus === ClauseStatuses.BEST_PRACTICE ? (
    <Tooltip title={CLAUSE_STATUS_TOOLTIPS.BEST_PRACTICE_VIEW} placement='top'>
      <CheckCircleIcon className='clause-status-icon clause-status-icon--green' />
    </Tooltip>
  ) : clauseStatus === ClauseStatuses.INVALID ? (
    <Tooltip title={CLAUSE_STATUS_TOOLTIPS.INVALID_VIEW} placement='top'>
      <ErrorIcon className='clause-status-icon clause-status-icon--red' />
    </Tooltip>
  ) : null;
};
