import axios from 'axios';
import { set } from 'lodash';
import { getAuthorizationHeader } from './auth.helpers';

const axiosAuth = () => {
  const instance = axios.create();

  instance.interceptors.request.use(async function (config) {
    const { name, value } = await getAuthorizationHeader();
    set(config, ['headers', name], value);
    return config;
  });

  return instance;
};

export default axiosAuth();
