const onClose =
  (
    setLastSelected: React.Dispatch<string | undefined>,
    setIsDropDownOpen: React.Dispatch<boolean>,
    lastSelected: string | undefined
  ) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (field: any) =>
  (values: string | string[] | undefined) => {
    if (field.name === lastSelected && Array.isArray(values) && !values.length) {
      setLastSelected(undefined);
    }
    setIsDropDownOpen(false);
  };

const onOpen = (setIsDropDownOpen: React.Dispatch<boolean>) => () => {
  setIsDropDownOpen(true);
};

const onChange =
  (setLastSelected: React.Dispatch<string | undefined>) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (field: any) =>
  (inputValue: string | string[] | undefined) => {
    setLastSelected(field.name);
    field.onChange(inputValue);
  };

export { onClose, onOpen, onChange };
