import { getBusinessTypes, getRelatedLobs } from './ClauseMetadataFormHelpers';
import { ClauseMetadata, ClauseMetadataFields } from '../../UploadClause/UploadClause.types';
import {
  ClauseDictionariesResponse,
  DictionariesResponse,
} from '../../../store/files/upload/list.service.types';
import { getValidationRules } from './MetadataFormHelpers';
import { getLopOptions } from '../../SpreadSheet/DocumentsGrid/EditAutocomplete.helpers';
import { DocumentMetadataFields } from './MetadataForm.types';

export const CLAUSE_TYPE_PRIMARY = 'Primary';
export const CLASSIFICATION_C2 = 'C2';

const getLobsByEntity = (dictionaries: ClauseDictionariesResponse, entity: string) => {
  const lobs = getRelatedLobs(dictionaries.Entities.values, [entity]);
  return lobs.length
    ? dictionaries?.Lobs.values.filter(({ value }) => lobs.includes(value))
    : dictionaries?.Lobs.values;
};

const getLopsByEntityAndLob = (
  dictionaries: ClauseDictionariesResponse,
  entity: string,
  lob: string
) => {
  const dicts = {
    Entity: dictionaries.Entities,
    Lob: dictionaries.Lobs,
    Lop: dictionaries.Lops,
  } as DictionariesResponse;
  return getLopOptions(dicts, lob, entity);
};

const getBusinessTypesByEntityAndLob = (
  dictionaries: ClauseDictionariesResponse,
  entity: string,
  lob: string
) => {
  let businessTypes = getValidationRules(
    dictionaries.Entities,
    entity,
    DocumentMetadataFields.Lob,
    lob,
    DocumentMetadataFields.BusinessType
  );
  if (!businessTypes.length) {
    businessTypes = getBusinessTypes(dictionaries.Entities.values, [entity]);
  }
  return businessTypes.length
    ? dictionaries.BusinessTypes.values.filter(({ value }) => businessTypes.includes(value))
    : dictionaries.BusinessTypes.values;
};

export const getDraftPrefilledClause = (dictionaries: ClauseDictionariesResponse) => {
  let clause: Partial<ClauseMetadata> = {};

  const { value: entity, related } = dictionaries.Entities.values[0] ?? {};

  if (!entity) {
    return clause;
  }

  const lob = getLobsByEntity(dictionaries, entity)[0].value;
  const lop = getLopsByEntityAndLob(dictionaries, entity, lob)[0].value;
  const businessType = getBusinessTypesByEntityAndLob(dictionaries, entity, lob)[0].value;

  clause[ClauseMetadataFields.Entities] = [entity];
  clause[ClauseMetadataFields.Lobs] = [lob];
  clause[ClauseMetadataFields.Lops] = [lop];
  clause[ClauseMetadataFields.BusinessTypes] = [businessType];
  clause[ClauseMetadataFields.ClauseTypes] = [CLAUSE_TYPE_PRIMARY];
  clause[ClauseMetadataFields.IsDraft] = true;

  if (related?.Classification?.includes(CLASSIFICATION_C2)) {
    clause[ClauseMetadataFields.Classification] = CLASSIFICATION_C2;
  }

  if (related?.Country) {
    clause[ClauseMetadataFields.Countries] = [related.Country];
  }

  if (related?.Language) {
    clause[ClauseMetadataFields.Language] = related?.Language;
  }

  return clause;
};
