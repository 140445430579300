import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CLOSE_DOC_FROM_MINIMIZED_REJECT_MSG,
  MINIMIZED_DATA,
  MinimizedData,
  REMOVE_DOC_FROM_COMPARE_REJECT_MSG,
  REMOVE_DOCS_FROM_COMPARE_REJECT_MSG,
} from './ui.types';
import { RootState } from '../store';
import { uiSelectors } from './ui.selectors';
import { setComparableFlagToExistingOne } from './ui.helpers';

export const saveMinimizedData = (type: MINIMIZED_DATA) =>
  createAsyncThunk<
    MinimizedData[],
    MinimizedData,
    {
      state: RootState;
    }
  >(`ui/saveMinimizedData${type}`, async (minimizedData: MinimizedData, { getState }) => {
    let currentMinimizedData =
      type === MINIMIZED_DATA.MINIMIZED_DOCS
        ? uiSelectors.selectMinimizedDocs(getState())
        : uiSelectors.selectMinimizedClauses(getState());
    const dataAlreadyExists = currentMinimizedData.some(
      ({ dataId }) => dataId === minimizedData.dataId
    );
    if (dataAlreadyExists) {
      return [];
    }
    const tmpMinimizedData = [...currentMinimizedData, minimizedData];
    localStorage.setItem(MINIMIZED_DATA[type], JSON.stringify(tmpMinimizedData));
    return tmpMinimizedData;
  });

export const saveMinimizedDocs = saveMinimizedData(MINIMIZED_DATA.MINIMIZED_DOCS);

export const saveMinimizedClauses = saveMinimizedData(MINIMIZED_DATA.MINIMIZED_CLAUSES);

export const closeMinimizedData = (type: MINIMIZED_DATA) =>
  createAsyncThunk<
    MinimizedData[],
    MinimizedData['dataId'][],
    {
      state: RootState;
    }
  >(
    `ui/closeMinimizedData${type}`,
    async (closingDataIds: MinimizedData['dataId'][], { getState, rejectWithValue }) => {
      let currentMinimizedData =
        type === MINIMIZED_DATA.MINIMIZED_DOCS
          ? uiSelectors.selectMinimizedDocs(getState())
          : uiSelectors.selectMinimizedClauses(getState());
      const someDataAlreadyExists = currentMinimizedData.some(({ dataId }) =>
        closingDataIds.includes(dataId)
      );
      if (someDataAlreadyExists) {
        const newMinimizedData = currentMinimizedData.filter(
          ({ dataId }) => !closingDataIds.includes(dataId)
        );
        localStorage.setItem(MINIMIZED_DATA[type], JSON.stringify(newMinimizedData));
        return newMinimizedData;
      }

      return rejectWithValue(CLOSE_DOC_FROM_MINIMIZED_REJECT_MSG);
    }
  );

export const closeMinimizedDoc = closeMinimizedData(MINIMIZED_DATA.MINIMIZED_DOCS);

export const closeMinimizedClause = closeMinimizedData(MINIMIZED_DATA.MINIMIZED_CLAUSES);

function createAddDocToCompare(
  actionType: string,
  localStorageType: MINIMIZED_DATA,
  selectMinimizedDocs: (state: RootState) => MinimizedData[]
) {
  return createAsyncThunk<MinimizedData[], MinimizedData, { state: RootState }>(
    actionType,
    async (docToCompare: MinimizedData, { getState }) => {
      let currentMinimizedDocs = selectMinimizedDocs(getState());
      const minimizedDocToCompare = currentMinimizedDocs.find(
        ({ dataId }) => dataId === docToCompare.dataId
      );
      const newDocToCompare = () => {
        if (!minimizedDocToCompare?.dataId) {
          return [
            ...currentMinimizedDocs,
            {
              ...docToCompare,
              comparable: true,
            },
          ];
        }
        return setComparableFlagToExistingOne(currentMinimizedDocs, minimizedDocToCompare, true);
      };
      const newDocsToCompare = newDocToCompare();
      localStorage.setItem(localStorageType, JSON.stringify(newDocsToCompare));
      return newDocsToCompare;
    }
  );
}

export const addDocToCompare = createAddDocToCompare(
  'ui/addDocToCompare',
  MINIMIZED_DATA.MINIMIZED_DOCS,
  uiSelectors.selectMinimizedDocs
);

export const addClauseToCompare = createAddDocToCompare(
  'ui/addClauseToCompare',
  MINIMIZED_DATA.MINIMIZED_CLAUSES,
  uiSelectors.selectMinimizedClauses
);

function createRemoveDocFromCompare(
  actionType: string,
  localStorageType: MINIMIZED_DATA,
  selectMinimizedDocs: (state: RootState) => MinimizedData[]
) {
  return createAsyncThunk<MinimizedData[], MinimizedData['dataId'], { state: RootState }>(
    actionType,
    async (docIdToRemoveFromCompare: MinimizedData['dataId'], { getState, rejectWithValue }) => {
      let currentMinimizedDocs = selectMinimizedDocs(getState());
      const minimizedDocToRemoveCompare = currentMinimizedDocs.find(
        ({ dataId }) => dataId === docIdToRemoveFromCompare
      );
      if (minimizedDocToRemoveCompare?.dataId) {
        const newMinimizedDocs = setComparableFlagToExistingOne(
          currentMinimizedDocs,
          minimizedDocToRemoveCompare,
          false
        );
        localStorage.setItem(localStorageType, JSON.stringify(newMinimizedDocs));
        return newMinimizedDocs;
      }

      return rejectWithValue(REMOVE_DOC_FROM_COMPARE_REJECT_MSG);
    }
  );
}

export const removeDocFromCompare = createRemoveDocFromCompare(
  'ui/removeDocFromCompare',
  MINIMIZED_DATA.MINIMIZED_DOCS,
  uiSelectors.selectMinimizedDocs
);

export const removeClauseFromCompare = createRemoveDocFromCompare(
  'ui/removeClauseFromCompare',
  MINIMIZED_DATA.MINIMIZED_CLAUSES,
  uiSelectors.selectMinimizedClauses
);

function createClearCompare(
  actionType: string,
  localStorageType: MINIMIZED_DATA,
  selectMinimizedDocs: (state: RootState) => MinimizedData[]
) {
  return createAsyncThunk<MinimizedData[], void, { state: RootState }>(
    actionType,
    async (_void, { getState, rejectWithValue }) => {
      let currentMinimizedDocs = selectMinimizedDocs(getState());
      if (currentMinimizedDocs?.length) {
        const newMinimizedDocs = currentMinimizedDocs.map((minimizedDoc) => {
          return {
            ...minimizedDoc,
            comparable: false,
          };
        });
        localStorage.setItem(localStorageType, JSON.stringify(newMinimizedDocs));
        return newMinimizedDocs;
      }

      return rejectWithValue(REMOVE_DOCS_FROM_COMPARE_REJECT_MSG);
    }
  );
}

export const clearCompare = createClearCompare(
  'ui/clearCompare',
  MINIMIZED_DATA.MINIMIZED_DOCS,
  uiSelectors.selectMinimizedDocs
);

export const clearClauseCompare = createClearCompare(
  'ui/clearClauseCompare',
  MINIMIZED_DATA.MINIMIZED_CLAUSES,
  uiSelectors.selectMinimizedClauses
);

export const clearMinimized = (type: MINIMIZED_DATA) =>
  createAsyncThunk<
    MinimizedData[],
    void,
    {
      state: RootState;
    }
  >(`ui/clearMinimized_${type}`, async () => {
    localStorage.setItem(MINIMIZED_DATA[type], JSON.stringify([]));
    return [];
  });

export const clearMinimizedDocs = clearMinimized(MINIMIZED_DATA.MINIMIZED_DOCS);

export const clearMinimizedClauses = clearMinimized(MINIMIZED_DATA.MINIMIZED_CLAUSES);
