import { useDrag } from 'react-dnd';
import React from 'react';
import { BarButton } from './BarButton';
import { DraggableBarButtonProps } from './BarButton.types';

export const DraggableBarButtonItemType = 'DraggableBarButton';

export const DraggableBarButton = ({
  id,
  index,
  name,
  handleClose,
  className,
}: DraggableBarButtonProps) => {
  const [{ isDragging }, ref] = useDrag(
    {
      type: DraggableBarButtonItemType,
      item: { id, index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    },
    [index]
  );

  return (
    <BarButton
      ref={ref}
      className={`${className} ${isDragging ? 'dragging' : ''}`}
      handleClose={handleClose}
      name={name}
    />
  );
};
