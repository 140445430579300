import { useDispatch } from 'react-redux';
import { useCallback } from 'react';
import { selectDocs } from '../../../store/ui/ui.slice';
import { DocumentResponse } from '../../../store/files/documents/documents.list.types';
import { GridInputSelectionModel } from '@mui/x-data-grid-pro';

export const useDispatchSelectDocs = () => {
  const dispatch = useDispatch();

  return useCallback(
    (documentIds, documentsRelatedClausesIds = [], isDraftDocuments = false) => {
      dispatch(
        selectDocs({
          documentIds,
          documentsRelatedClausesIds: documentsRelatedClausesIds,
          isDraftDocuments: isDraftDocuments,
        })
      );
    },
    [dispatch]
  );
};
export const useSelectionChangeHandler = (documents: DocumentResponse[]) => {
  const dispatchSelectDocs = useDispatchSelectDocs();

  return (documentIds: GridInputSelectionModel) => {
    const selectedDocumentIds = Array.isArray(documentIds) ? documentIds : [];
    const selectedDocuments = documents.filter((doc) =>
      selectedDocumentIds.includes(doc.DocumentId)
    );
    const documentsRelatedClausesIds = selectedDocuments.flatMap(
      ({ RelatedClauses }) => RelatedClauses
    );
    const isDraftDocuments = selectedDocuments.some((document) => document.IsDraft);

    dispatchSelectDocs(
      selectedDocumentIds as string[],
      documentsRelatedClausesIds,
      isDraftDocuments
    );
  };
};
