import React from 'react';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { SearchInputField } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';

import { getValueOrEmptyArray, prepareLabel } from './GroupNameFilter.helpers';
import { GroupMetadata } from '../../../store/adminPanel/adminPanel.types';
import { useSearchGroupNameInput } from '../../StaticComponents/SearchInput/SearchInput.hooks';
import { FieldError } from 'react-hook-form';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';

export interface GroupNameFilterProps {
  error?: FieldError;
  multiple?: boolean;
  label: string;
  value?: string | string[];
  onChange: (value?: string | string[]) => void;
  values?: GroupMetadata[];
  disabled?: boolean;
}

export const GroupNameFilter = React.forwardRef(
  (
    { multiple, label, value, onChange, values = [], error, disabled }: GroupNameFilterProps,
    ref
  ) => {
    const { setInputText, filteredValues, onChangeSearch } = useSearchGroupNameInput(values);
    const filteredGroupNames = filteredValues.map(({ GroupName }) => GroupName);

    return (
      <>
        <Select
          ref={ref}
          size='small'
          disabled={disabled}
          className={value?.length ? 'filter-values-selected' : ''}
          multiple={multiple}
          displayEmpty
          required
          value={value}
          onChange={({ target: { value: newValue } }: SelectChangeEvent<typeof value>) =>
            onChange(multiple ? getValueOrEmptyArray(newValue) : newValue)
          }
          onClose={() => setInputText('')}
          input={<OutlinedInput />}
          renderValue={(selected) => (
            <>
              {label}: <strong>{prepareLabel(multiple, values, selected)}</strong>
            </>
          )}
        >
          <SearchInputField label={label.toLowerCase()} onChangeHandle={onChangeSearch} />
          {values.map(({ GroupName }) => (
            <MenuItem
              key={GroupName}
              value={GroupName}
              hidden={!filteredGroupNames.includes(GroupName)}
            >
              {GroupName}
            </MenuItem>
          ))}
        </Select>
        <ErrorMessage error={error} />
      </>
    );
  }
);
