import IconButton from '@mui/material/IconButton';
import { NavigateBefore, NavigateNext, ZoomIn, ZoomOut } from '@mui/icons-material';
import React from 'react';
import { ViewerToolbarProps } from './ViewerToolbar.types';
import { SearchInput } from '../../../Settings/SavedSearches/SearchInput';
import { useSearch } from './SearchContext';
import { SearchMethod } from '../../../../store/files/documentsAndClauses/list.types';
import { useSelector } from 'react-redux';
import { uiSelectors } from '../../../../store/ui/ui.selectors';

export const ViewerToolbar = ({
  handleZoom,
  handleSwitch,
  searchOption,
  method,
}: ViewerToolbarProps) => {
  const { totalHighlights, currentHighlight } = useSelector(
    uiSelectors.selectTextSearchHighlightDetails
  );
  const { searchTerm, setSearchTerm } = useSearch();
  return (
    <div className='file-viewer--navigation'>
      {handleSwitch && searchOption ? (
        <div className='search-input-doc-view'>
          <SearchInput searchQuery={searchTerm} setSearchQuery={setSearchTerm} />
          {!(method === SearchMethod.BROAD || method === SearchMethod.PHRASE) &&
            totalHighlights > 1 && (
              <div className='file-viewer--highlight-info'>
                {currentHighlight} / {totalHighlights}
              </div>
            )}
          <>
            <IconButton onClick={() => handleSwitch(-1)}>
              <NavigateBefore />
            </IconButton>
            <IconButton onClick={() => handleSwitch()}>
              <NavigateNext />
            </IconButton>
          </>
        </div>
      ) : null}
      <div>
        {handleZoom ? (
          <>
            <IconButton onClick={() => handleZoom(-1)}>
              <ZoomOut />
            </IconButton>
            <IconButton onClick={() => handleZoom()}>
              <ZoomIn />
            </IconButton>
          </>
        ) : null}
      </div>
    </div>
  );
};
