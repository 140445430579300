import React from 'react';
import { Paper } from '@mui/material';
import { ReactComponent as InformationIcon } from '../../../assets/icons/information.svg';
import { InfoPageProps } from './InfoPage.types';
import './InfoPage.scss';

const InfoPage = ({ header, children }: InfoPageProps) => (
  <Paper className='info-page'>
    <div className='info-page-icon-circle'>
      <InformationIcon />
    </div>
    <h1 className='info-page-header'>{header}</h1>
    <div className='info-page-text'>{children}</div>
  </Paper>
);

export default InfoPage;
