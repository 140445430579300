import { DocumentGridProps, METADATA_KEYS, SpreadSheetDocument } from './DocumentsGrid.types';
import {
  DictionariesResponse,
  DictionariesResponseKeys,
  MetadataDictionaryValue,
} from '../../../store/files/upload/list.service.types';
import { UploadedFileStatus } from '../../UploadFiles/UploadedFileList.types';
import { GridInputSelectionModel, GridRowId, GridValueFormatterParams } from '@mui/x-data-grid-pro';
import { differenceWith, invert, isEqual, isUndefined, omitBy } from 'lodash';
import { parseDate } from '../../UploadFiles/MetadataForm/MetadataFormHelpers';
import { format, isAfter, isBefore, isValid } from 'date-fns';
import { Document, DocumentStatus } from '../../../store/files/documents/documents.list.types';
import { DocumentMetadataFields } from '../../UploadFiles/MetadataForm/MetadataForm.types';
import { TODAY } from './GridEditDateCell.helpers';
import { UI_DATE_FORMAT, UI_DATE_TIME_FORMAT } from '../../../config/config';

const METADATA_KEYS_INVERTED = invert(METADATA_KEYS);

const getMetadataKeyByColumnName = (key: string) => {
  // @ts-ignore - workaround to get enum key by value
  return METADATA_KEYS[METADATA_KEYS_INVERTED[key]];
};

export const getMetadataByColumnAndValue = (
  dictionaries: DictionariesResponse,
  key: string,
  value: string
) => {
  return getMetadataValues(dictionaries, getMetadataKeyByColumnName(key))?.find(
    ({ value: metadataValue }) => metadataValue === value
  );
};

export const getMetadataValues = (dictionaries: DictionariesResponse, key: string) => {
  return dictionaries[key as DictionariesResponseKeys]?.values;
};

export const getMetadataValuesByColumnName = (
  dictionaries: DictionariesResponse,
  columnName: string
) => {
  return dictionaries[getMetadataKeyByColumnName(columnName) as DictionariesResponseKeys]?.values;
};

export const isDocumentEditable = (status: UploadedFileStatus) => {
  return (
    [
      UploadedFileStatus.MISSING_DATA,
      UploadedFileStatus.UPLOAD_COMPLETE,
      DocumentStatus.READY,
    ].indexOf(status) > -1
  );
};

export const formatDate = (date: Date | undefined | null, dateFormat: string) =>
  date && isValid(date) ? format(date, dateFormat) : '';

export const formatUiDate = (date?: Date) => formatDate(date, UI_DATE_FORMAT);

export const formatUiDateFromString = (value: string) => {
  const date = parseDate(value);
  return formatUiDate(date);
};

export const formatUiDateTimeFromString = (value: string) => {
  const date = parseDate(value);
  return formatDate(date, UI_DATE_TIME_FORMAT);
};

export const valueFormatter =
  (dictionaries: DictionariesResponse, key: string) => (params: GridValueFormatterParams) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const cellValue = (params as any)?.value;
    const metadata = getMetadataByColumnAndValue(dictionaries, key, String(cellValue));
    return metadata?.label || cellValue || '';
  };

export const sortComparator = (v1: MetadataDictionaryValue, v2: MetadataDictionaryValue) => {
  return String(v1).localeCompare(String(v2));
};

export const getOrParseDate = (value: string | Date) =>
  isValid(value) ? (value as Date) : parseDate(value as string);

const validateRequired = (validate: boolean, required: boolean, value?: Date) =>
  validate && required && !value;

const validateBefore = (validFrom?: Date, validTo?: Date) =>
  !!validFrom && !!validTo && isBefore(validTo, validFrom);

export const validateValidFromField = (
  validate: boolean,
  required: boolean,
  validToString: string,
  validFrom?: Date
) => {
  const validTo = getOrParseDate(validToString);
  return validateRequired(validate, required, validFrom) || validateBefore(validFrom, validTo);
};

export const validateValidToField = (
  validate: boolean,
  required: boolean,
  validFromString: string,
  validTo?: Date
) => {
  const validFrom = getOrParseDate(validFromString);
  return validateRequired(validate, required, validTo) || validateBefore(validFrom, validTo);
};

export const validateExpireAtField = (validate: boolean, required: boolean, expireAt?: Date) => {
  return validateRequired(validate, required, expireAt) || (!!expireAt && isAfter(TODAY, expireAt));
};

const cleanupUndefined = (values: SpreadSheetDocument): SpreadSheetDocument => {
  return omitBy(values, isUndefined) as SpreadSheetDocument;
};

export const getChanges = (
  docs: SpreadSheetDocument[],
  rows: SpreadSheetDocument[]
): DocumentGridProps['docs'] => {
  return differenceWith(rows.map(cleanupUndefined), docs, isEqual);
};

export const updateDoc = (docs: SpreadSheetDocument[], newRow: SpreadSheetDocument) => {
  return docs.map((doc) => (doc.DocumentId === newRow.DocumentId ? newRow : doc));
};

export const getIdsAndSelectedFlag = (
  selectionModel?: GridInputSelectionModel
): [GridRowId[], boolean] => {
  const selectedIds = Array.isArray(selectionModel) ? selectionModel : [];
  const rowsSelected = selectedIds.length > 0;
  return [selectedIds, rowsSelected];
};

export const updateAllDocs = (
  docs: SpreadSheetDocument[],
  selectedIds: GridRowId[],
  field: string,
  value: string
) => {
  return docs.map((doc) =>
    selectedIds.includes(doc.DocumentId)
      ? {
          ...doc,
          [field]: value,
        }
      : doc
  );
};

export const getDocumentName = (document: Partial<Document>) =>
  [
    document?.[DocumentMetadataFields.Entity],
    document?.[DocumentMetadataFields.Lob],
    document?.[DocumentMetadataFields.DocumentType],
    document?.[DocumentMetadataFields.Lop],
    document?.[DocumentMetadataFields.Version],
  ]
    .filter(Boolean)
    .join('_');
