import { DataGridPro, GridSlotsComponentsProps, GridRowParams } from '@mui/x-data-grid-pro';
import React from 'react';
import './PendingItemsListGrid.scss';
import { NoItemsFound } from '../../StaticComponents/NoItemsFound/NoItemsFound';
import { NUMBER_OF_ITEMS_ALLOWED } from '../../../config/config';
import { paginationOptions } from '../../StaticComponents/CustomPagination/CustomPagination';
import { Box, CircularProgress } from '@mui/material';
import { PendingItemsListGridProps } from './PendingItemsListGrid.types';
import {
  DocumentsListResponse,
  DocumentStatus,
} from '../../../store/files/documents/documents.list.types';
import { ClausesListResponse } from '../../../store/files/clauses/clauses.list.types';
import { updateSelection } from '../../../store/files/upload/upload.slice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { uploadSelectors } from '../../../store/files/upload/upload.selectors';

const componentsCustomProps: GridSlotsComponentsProps = {
  pagination: paginationOptions(true),
};

export const PendingItemsListGrid = (options: PendingItemsListGridProps) => {
  const { data, isLoading, isFetching, rowCount, page, pageSize, setPage, setPageSize } =
    options.dataHook();

  const selected = useAppSelector(uploadSelectors.selectedDocs);
  const dispatch = useAppDispatch();

  const pendingItemListData =
    options.dataType === 'Documents'
      ? (data as DocumentsListResponse)?.[options.dataType] ?? []
      : (data as ClausesListResponse)?.[options.dataType] ?? [];

  if (isLoading || isFetching) {
    return (
      <Box sx={{ textAlign: 'center', padding: '30px' }}>
        <CircularProgress />
      </Box>
    );
  }

  const isSelectable = (params: GridRowParams) => params.row.Status !== DocumentStatus.PROCESSING;

  return pendingItemListData.length ? (
    <div style={{ minHeight: '100vh', width: '100%' }}>
      <DataGridPro
        className='documents-list-grid pending-documents-list-grid'
        autoHeight={true}
        columns={options.columnsDefinition()}
        rows={pendingItemListData}
        rowCount={rowCount}
        rowsPerPageOptions={NUMBER_OF_ITEMS_ALLOWED}
        pagination
        paginationMode='server'
        page={page}
        pageSize={pageSize}
        onPageChange={setPage}
        onPageSizeChange={setPageSize}
        disableSelectionOnClick
        getRowId={(row) => row[options.rowIdKey]}
        rowHeight={64}
        disableColumnMenu
        scrollbarSize={15}
        componentsProps={componentsCustomProps}
        checkboxSelection
        selectionModel={selected}
        onSelectionModelChange={(selectionModel) => {
          dispatch(updateSelection({ selected: selectionModel as string[] }));
        }}
        isRowSelectable={isSelectable}
      />
    </div>
  ) : (
    <NoItemsFound subtitle='' icon={options.dataType === 'Documents' ? 'default' : 'clauseIcon'} />
  );
};
