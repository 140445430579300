import React, { useState } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import './ClauseNomenclaturePopover.scss';
import { Divider } from '@mui/material';
import { useGetClauseTagsQuery } from '../../../../store/files/clauses/clauses.list.service';
import { ADDITION_CATEGORIES, ConfigStrings } from './ClauseNomenclaturePopover.types';
import CircularProgress from '@mui/material/CircularProgress';
import NomenclatureExampleTable from './ClauseNomenclatureTable';

export const ClauseNomenclaturePopover: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { data: clauseTags = [] } = useGetClauseTagsQuery(undefined);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span className='link-notice' onClick={handleClick}>
        {ConfigStrings.CHECK_NOMENCLATURE_LINK_TEXT}
      </span>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={1}
      >
        <Box className='popoverBox'>
          <Typography className='heading'>
            {ConfigStrings.REQUIRED_IN_CLAUSE_NOMENCLATURE}
          </Typography>
          {clauseTags.length === 0 ? (
            <div className='spinner'>
              <CircularProgress />
            </div>
          ) : (
            <>
              <ul className='list'>
                <li key='topic' className='listItem'>
                  {ConfigStrings.TOPIC}
                </li>
              </ul>
              <Typography className='example'>
                {ConfigStrings.EXAMPLE_INTRO}
                {clauseTags
                  .filter((tag) => tag.label !== ConfigStrings.OTHER)
                  .map((tag) => tag.label)
                  .join(', ')}
              </Typography>
            </>
          )}

          <Typography className='heading'>{ConfigStrings.ADDITIONS_HEADER}</Typography>
          <ul className='list'>
            {ADDITION_CATEGORIES.map((item, index) => (
              <li key={index} className='listItem'>
                {item}
              </li>
            ))}
          </ul>
          <Divider className='divider' />
          <Typography className='heading'>{ConfigStrings.EXAMPLE_OF_CLAUSE_NAME}</Typography>
          <Box>
            <NomenclatureExampleTable />
          </Box>
        </Box>
      </Popover>
    </>
  );
};
