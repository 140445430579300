import DOMPurify from 'dompurify';
import { MIME_TYPES } from '../../../config/config';
import viewer from '../FileViewer/FileViewer.module.scss';
import { Order } from './useDocumentCompare';
import { markJsStyles } from './HtmlViewer.highlight';
import { customStyles } from './HtmlViewer.prettify';
import { clauseStyles } from './HtmlViewer.clause-highlight';

const defaultStyles =
  '<style>span {z-index: -1;} div:empty {z-index: -1;} body { transform-origin: 0 0; }</style>';

enum MARK_STYLE_TYPE {
  ADDED = 'added',
  REMOVED = 'removed',
}

const getMarkStyles = (type: MARK_STYLE_TYPE) => {
  const markBackgroundColor =
    type === MARK_STYLE_TYPE.ADDED
      ? viewer.markBackgroundAddedChange
      : viewer.markBackgroundRemovedChange;

  return `<style>mark {background: ${markBackgroundColor};}</style>`;
};

const EMPTY_CLASS = 'empty';

const addEmptyClassToEmptyElements = (node: Element) => {
  if (!node) return;

  if (['DIV', 'SPAN'].includes(node.tagName) && !node.textContent!.trim()) {
    node.classList.add(EMPTY_CLASS);
  } else {
    const children = node.children;
    for (let i = 0; i < children.length; i++) {
      addEmptyClassToEmptyElements(children[i]);
    }
  }
};

export const cleanupHtml = (input: string) => {
  const parser = new DOMParser();
  const doc = parser.parseFromString(input, 'text/html');

  addEmptyClassToEmptyElements(doc.documentElement);
  doc.documentElement.querySelectorAll(`.${EMPTY_CLASS}`).forEach((e) => e.remove());

  const serializer = new XMLSerializer();
  return serializer.serializeToString(doc);
};

export const fixHtmlForHighlight = (input: string) => {
  return input
    .replaceAll(/-<br \/> +/g, '-<br />')
    .replaceAll(/-<br \/><\/span><span ([^>]*)> +/g, '-<br /></span><span $1>');
};

export const getSanitizedBlob = (
  html: string,
  applyDifferencesOnHtml?: (html: string, order?: Order) => string,
  order?: Order,
  invertedCompare?: boolean
) => {
  const sanitized = DOMPurify.sanitize(html);
  const cleaned = fixHtmlForHighlight(cleanupHtml(sanitized));

  const sanitizedPreviewWithHighlights =
    applyDifferencesOnHtml && order !== undefined
      ? applyDifferencesOnHtml(cleaned, order)
      : cleaned;

  const typeOfMarkStyles =
    order === 0 && !invertedCompare ? MARK_STYLE_TYPE.REMOVED : MARK_STYLE_TYPE.ADDED;

  const out =
    sanitizedPreviewWithHighlights +
    defaultStyles +
    markJsStyles +
    clauseStyles +
    getMarkStyles(typeOfMarkStyles) +
    customStyles;

  return new Blob([out], { type: MIME_TYPES.HTML.mimeType });
};
