import React from 'react';
import '../Documents/DocumentContextMenu/DocumentContextMenu.scss';
import { Menu, MenuItem } from '@mui/material';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import { BookmarkContextMenuDelete } from './BookmarkContextMenuDelete';
import { BookmarkContextMenuProps } from './BookmarkContextMenu.types';
import { ContextMenuEditName } from '../SharedComponents/ContextMenu/ContextMenuEditName';
import { ITEM_TYPE } from '../../store/ui/ui.types';
import { useUpdateBookmarkMutation } from '../../store/files/bookmarks/bookmarks.service';

export const BookmarksContextMenu = ({
  control: { selectedItem, anchorEl, onClose },
  anchorOrigin,
  transformOrigin,
}: BookmarkContextMenuProps) => {
  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
      className='documents-list-menu'
    >
      <ContextMenuEditName
        id={selectedItem?.BookmarkId}
        onClose={onClose}
        name={selectedItem?.Name}
        updateMutation={useUpdateBookmarkMutation}
        itemType={ITEM_TYPE.BOOKMARK}
      />
      <MenuItem disabled>
        <ShareOutlinedIcon />
        Share
      </MenuItem>

      <BookmarkContextMenuDelete onClose={onClose} id={selectedItem?.BookmarkId} />
    </Menu>
  );
};
