import { useState } from 'react';

export const useConfirmationModal = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = (onClose?: () => void) => () => {
    setOpen(false);
    onClose?.();
  };

  return { open, handleOpen, handleClose };
};
