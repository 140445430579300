import axiosAuth from '../../../components/Auth/axiosAuth';
import { SUBMIT_ENDPOINT } from '../../../services/api.service.config';
import { SUBMIT_STATUS, SubmitResult, SubmitStatus, SubmitResults } from './submit.service.types';
import { ERROR_MARS_CODE, ERROR_RESPONSE_STATUS } from '../../error.types';

class SubmitService {
  async submitDocuments(ids: string[]): Promise<SubmitResult> {
    const results = await Promise.all(
      ids.map((id) =>
        this.submitDocument(id)
          .then(() => ({
            [id]: SUBMIT_STATUS.SUCCESS,
          }))
          .catch((error) => {
            if (
              error?.response?.status === ERROR_RESPONSE_STATUS.DUPLICATED_DOCUMENT &&
              error?.response?.data.errors.filter(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (e: any) => e.errorCode === ERROR_MARS_CODE.DUPLICATED_DOCUMENT
              ).length
            ) {
              return { [id]: SUBMIT_STATUS.DUPLICATE };
            } else return { [id]: SUBMIT_STATUS.ERROR };
          })
      )
    );

    const getIdsForStatus = (submitResults: SubmitResults, status: SubmitStatus) => {
      const duplicates = submitResults.filter((result) => Object.values(result).includes(status));
      return duplicates.map((result) => Object.keys(result)?.[0]);
    };
    const successId = getIdsForStatus(results as SubmitResults, SUBMIT_STATUS.SUCCESS);
    const errorsId = getIdsForStatus(results as SubmitResults, SUBMIT_STATUS.ERROR);
    const duplicatesId = getIdsForStatus(results as SubmitResults, SUBMIT_STATUS.DUPLICATE);

    return {
      success: successId.length,
      error: errorsId.length,
      errorsId,
      duplicates: duplicatesId.length,
      duplicatesId,
    };
  }

  submitDocument(id: string) {
    return axiosAuth.post(SUBMIT_ENDPOINT.replace('{id}', id));
  }
}

const submitService = new SubmitService();

export default submitService;
