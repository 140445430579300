import React from 'react';
import './TermsAndConditions.scss';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';

export const TermsAndConditions = () => {
  return (
    <div className='terms-and-conditions'>
      <div>
        <Typography variant='body1'>
          Pursuant to Article 13 of Regulation (EU) 2016/679 of the European Parliament and of the
          Council of 27 April 2016 on the protection of natural persons with regard to the
          processing of personal data and on the free movement of such data, and repealing Directive
          95/46/EC (hereinafter: “GDPR”), I hereby wish to provide you, as User of ERGO Group Global
          Wording Repository (GWR), with the following information:
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>Details of the Controller</Typography>
        <Typography variant='body1'>
          Controller for the data processing is:
          <br />
          <br />
          ERGO Group AG
          <br />
          ERGO-Platz 1
          <br />
          40198 Düsseldorf
          <br />
          <Link href='mailto:info@ergo.de'>info@ergo.de</Link>
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>Contact details of the Data Protection Officer</Typography>
        <Typography variant='body1'>
          You can contact our Data Protection Officer by writing to the above address and adding
          "For the attention of the Data Protection Officer" or at the email address:&nbsp;
          <Link href='mailto:datenschutz@ergo.de'>datenschutz@ergo.de</Link>
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>
          What categories of data do we use and where do they come from?
        </Typography>
        <Typography variant='body1'>
          When you register in our repository, we collect your personal data (name, surname, e-mail,
          position). We may also collect other personal data that you have provided in your
          communication with ERGO Group.
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>
          What are the purposes for which data is processed?
        </Typography>
        <Typography variant='body1'>
          If you provide us with your personal data during registration, we will process this data
          for the purpose of managing access and authorization in the GWR system. We may also use
          your data to contact you. We will inform you in advance if we want to process your
          personal data for a purpose which is not indicated there.
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>
          On what legal basis is your personal data processed?
        </Typography>
        <Typography variant='body1'>
          We process your personal data in compliance with the provisions of the European General
          Data Protection Regulation (GDPR), the NEW German Federal Data Protection Act
          (Bundesdatenschutzgesetz) and all other relevant legislation on the processing of personal
          data.
          <br />
          <br />
          The legal basis of processing your data will be legitimate interests of the controller,
          according to Art. 6 para. 1 lit f) GDPR.
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>Who will obtain your data?</Typography>
        <Typography variant='body1'>
          Within the controller (the ERGO Group AG), the only persons and departments to receive the
          relevant data are the persons and departments responsible for the process in question; in
          this respect there is a clear allocation of duties and a clear authorization concept. The
          data may also be passed to service-providers for the purposes specified above. The
          involvement of service providers is necessary, for example, as part of the administration
          and maintenance of IT systems.
          <br />
          <br /> Personal data can also be sent to other recipients in so far as this is necessary
          for the performance of contractual or statutory obligations (e.g. sent to supervisory
          authorities).
          <br />
          <br /> This data can also be forwarded to Group companies e.g. as part of Group
          communications or Group management. Other GWR users (employees of ERGO Group companies)
          also will be able to communicate with you.
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>Is your data sent to a third country?</Typography>
        <Typography variant='body1'>
          If personal data is sent to a service provider or Group company outside the European
          Economic Area (EEA), it is only sent if the EU Commission has confirmed that the country
          has an adequate level of data protection or if other adequate data protection guarantees
          (e.g. the agreement of EU standard contractual clauses) are in place. You can also request
          the information from the places or persons specified as contacts at the beginning of this
          section.
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>What measures do we take to protect your data?</Typography>
        <Typography variant='body1'>
          We take appropriate and state of the art technical and organizational security precautions
          to protect data against accidental or intentional manipulation, loss, destruction or
          against access by unauthorized persons. In the case of the GWR we use encryption (at rest
          and in motion), Access Right Management and monitoring, user’s tokens, IP restricted
          access and more.
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>
          What data protection rights do you have as a Data Subject?
        </Typography>
        <Typography variant='body1'>
          You can request information from the above address about your personal data which has been
          saved. Under certain conditions you can also require that your data is corrected or
          deleted. You also have a right to restrict the processing of your data and a right to the
          disclosure in a structured, customary and machine-readable format of the data you
          supplied.
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>Right to object</Typography>
        <Typography variant='body1'>
          If we process your data to protect our legitimate interests, you may object to this
          processing for reasons which arise from your particular situation. We will then cease
          processing your personal data unless we can demonstrate compelling reasons worthy of
          protection for the processing which override your interests, rights, and freedoms or if
          the processing is for the assertion, exercise or defence of legal claims.
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>Where can you file complaints?</Typography>
        <Typography variant='body1'>
          You can file complaints either with the Data Protection Officer detailed above or with a
          data protection supervisory authority. The data protection supervisory authority
          responsible for us is: <br />
          <br />
          The North Rhine-Westphalia Commissioner for Data Protection and Freedom of Information
          (LDI NRW): Kavalleriestrasse 2-4 40213 Düsseldorf
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>For how long is your data saved?</Typography>
        <Typography variant='body1'>
          We delete your personal data as soon as it is no longer required for the purposes stated
          above. This is done regularly as a result of statutory obligations to provide proof and
          also retention obligations which are defined, amongst other places, in the German
          Commercial Code, the Tax Code and other tax legislation. Under the above legislation the
          retention periods are normally up to ten years. In addition, it does sometimes happen that
          personal data is retained for the period in which claims may be asserted against us (the
          legal period of prescription varies between 3 and 30 years). You can find additional
          information, in so far as it is relevant, in the sections on the various data processing
          operations.
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>Are you obliged to provide your data?</Typography>
        <Typography variant='body1'>
          If you want use our repository you are obliged to provide personal data. We cannot provide
          the services you request without this information. In each case we only collect the data
          we actually need.
        </Typography>
      </div>
      <div>
        <Typography variant='subtitle1'>
          To what extent are decisions on particular cases or profiling activities automated?
        </Typography>
        <Typography variant='body1'>
          If we use purely automatic processing method, including profiling, in a specific case to
          arrive at a decision, we will inform you as part of the procedure.
        </Typography>
      </div>
    </div>
  );
};
