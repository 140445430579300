import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClauseResponse } from '../clauses/clauses.list.types';
import { DocumentResponse } from '../documents/documents.list.types';
import { Bookmark } from './bookmarks.types';
import { defaultListQueryParams as defaultDocumentsListQueryParams } from '../documents/documents.slice';
import { defaultListQueryParams as defaultClausesListQueryParams } from '../clauses/clauses.slice';
import { DocumentsListParams } from '../documents/documents.list.types';
import { ClausesListParams } from '../clauses/clauses.list.types';

export interface BookmarksState {
  bookmarkModal?: Bookmark;
  listQueryParams: { documents: DocumentsListParams; clauses: ClausesListParams };
  currentBookmarkId?: string;
}

const initialState: BookmarksState = {
  bookmarkModal: undefined,
  listQueryParams: {
    documents: defaultDocumentsListQueryParams(),
    clauses: defaultClausesListQueryParams(),
  },
};

export const bookmarksSlice = createSlice({
  name: 'bookmarks',
  initialState,
  reducers: {
    openBookmarkModal: (
      state: BookmarksState,
      action: PayloadAction<{
        bookmark: Bookmark | DocumentResponse | ClauseResponse;
        currentBookmarkId?: string;
      }>
    ) => {
      const { bookmark, currentBookmarkId } = action.payload;
      state.bookmarkModal = bookmark;
      state.currentBookmarkId = currentBookmarkId;
    },
    resetBookmarkDetailsListQueryParams: (state) => {
      state.listQueryParams = {
        documents: {
          ...initialState.listQueryParams.documents,
        },
        clauses: {
          ...initialState.listQueryParams.clauses,
        },
      };
    },
    closeBookmarkModal: (state: BookmarksState) => {
      state.bookmarkModal = undefined;
    },
    updateDocumentsForBookmarkListQueryParams: (
      state: BookmarksState,
      action: PayloadAction<DocumentsListParams>
    ) => {
      state.listQueryParams.documents = action.payload;
    },
    updateClausesForBookmarkListQueryParams: (
      state: BookmarksState,
      action: PayloadAction<ClausesListParams>
    ) => {
      state.listQueryParams.clauses = action.payload;
    },
  },
});

export const {
  resetBookmarkDetailsListQueryParams,
  openBookmarkModal,
  closeBookmarkModal,
  updateDocumentsForBookmarkListQueryParams,
  updateClausesForBookmarkListQueryParams,
} = bookmarksSlice.actions;

export default bookmarksSlice.reducer;
