import {
  AdminPanelCreatedUser,
  AdminPanelUser,
  GroupMetadata,
} from '../../../store/adminPanel/adminPanel.types';
import { MetadataDictionaryValue } from '../../../store/files/upload/list.service.types';

export const mapGroupsToDictValues = (groups?: GroupMetadata[]): MetadataDictionaryValue[] =>
  groups
    ? groups
        .map(({ GroupName }) => GroupName)
        .sort()
        .map((GroupName) => ({ value: GroupName, label: GroupName }))
    : [];

export const filterUsers = (
  users: AdminPanelUser[],
  user: AdminPanelCreatedUser
): AdminPanelUser[] =>
  users.filter(({ Email, Entity, UserGroups }) => {
    return (
      (user.Email ? Email.toLowerCase().includes(user.Email.toLowerCase()) : true) &&
      (user.Entity ? Entity === user.Entity : true) &&
      (user.UserGroups
        ? UserGroups.filter((group) => user.UserGroups.includes(group)).length ===
          user.UserGroups.length
        : true)
    );
  });
