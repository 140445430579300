import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { MINIMIZED_DATA } from '../../store/ui/ui.types';
import { uiSelectors } from '../../store/ui/ui.selectors';
import {
  clearClauseCompare,
  clearCompare,
  clearMinimizedClauses,
  clearMinimizedDocs,
} from '../../store/ui/ui.actions';

const useMinimizedData = (dataType: MINIMIZED_DATA) => {
  const dispatch = useAppDispatch();
  const minimizedDataSelector =
    dataType === MINIMIZED_DATA.MINIMIZED_DOCS
      ? uiSelectors.selectMinimizedDocs
      : uiSelectors.selectMinimizedClauses;
  const itemsToCompareSelector =
    dataType === MINIMIZED_DATA.MINIMIZED_DOCS
      ? uiSelectors.selectDocsToCompare
      : uiSelectors.selectClauseToCompare;

  const minimizedData = useAppSelector(minimizedDataSelector);
  const itemsToCompare = useAppSelector(itemsToCompareSelector);

  const handleClearCompare =
    dataType === MINIMIZED_DATA.MINIMIZED_DOCS
      ? () => dispatch(clearCompare())
      : () => dispatch(clearClauseCompare());

  const handleClearMinimized =
    dataType === MINIMIZED_DATA.MINIMIZED_DOCS
      ? () => dispatch(clearMinimizedDocs())
      : () => dispatch(clearMinimizedClauses());

  return { minimizedData, itemsToCompare, handleClearCompare, handleClearMinimized };
};

export default useMinimizedData;
