import { useEffect, useState } from 'react';
import { useFileLoader } from './FileLoader.hook';
import { getSanitizedBlob } from './HtmlViewer.helpers';
import { Order, useDocumentCompare } from './useDocumentCompare';

export const useTransformDocumentDataToDiplayableUrl = (
  data: string,
  loading: boolean,
  error: boolean,
  order?: number
) => {
  const { applyDifferencesOnStringifiedHtml, invertedCompare } = useDocumentCompare(order as Order);
  const { isLoading, setLoading } = useFileLoader(loading, error);
  const [documentUrl, setDocumentUrl] = useState('');

  useEffect(() => {
    let documentUrl = '';
    if (data) {
      setLoading(true);

      documentUrl = URL.createObjectURL(
        getSanitizedBlob(data, applyDifferencesOnStringifiedHtml, order as Order, invertedCompare)
      );
      setDocumentUrl(documentUrl);
      setLoading(false);
    }
    return () => {
      URL.revokeObjectURL(documentUrl);
    };
  }, [setLoading, data, applyDifferencesOnStringifiedHtml, order, invertedCompare]);

  return { documentUrl, isLoading };
};
