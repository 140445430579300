import { Box, CircularProgress } from '@mui/material';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ClauseResponse } from '../../../../store/files/clauses/clauses.list.types';
import { COLUMNS_DEFINITION } from './DocumentRelatedClauses.helpers';
import { useAppSelector } from '../../../../store/hooks';
import { uiSelectors } from '../../../../store/ui/ui.selectors';

export const DocumentRelatedClauses = ({ clauses }: { clauses: ClauseResponse[] }) => {
  const scrollToClauseInDoc = useAppSelector(uiSelectors.selectScrollToClauseInDoc);

  return (
    <>
      {!clauses.length ? (
        <Box sx={{ textAlign: 'center', padding: '30px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <DataGridPro
          className='tab-items-list-grid document-related-clauses'
          autoHeight={true}
          disableColumnFilter
          disableColumnSelector
          columns={COLUMNS_DEFINITION()}
          rows={clauses}
          getRowClassName={({ row: { ClauseId } }) =>
            ClauseId === scrollToClauseInDoc ? 'row-current' : ''
          }
          getRowId={(row) => row.ClauseId}
          disableSelectionOnClick
          rowHeight={150}
          disableColumnMenu
          scrollbarSize={15}
          headerHeight={0}
          hideFooterRowCount
        />
      )}
    </>
  );
};
