import React from 'react';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Backdrop, CircularProgress } from '@mui/material';
import common from '../../assets/common.module.scss';
import { useReportDownload } from './ReportDownload.hooks';
import { MenuItem } from '@mui/material';
import { ReportsContextMenuItemProps } from './ReportsContextMenu.types';

export const ReportsContextMenuDownload: React.FC<ReportsContextMenuItemProps> = ({
  report,
  disabled,
  handleClose,
}) => {
  const { handleClick, inProgress } = useReportDownload(report, handleClose);

  return (
    <>
      <MenuItem onClick={handleClick} disabled={inProgress || disabled}>
        <FileDownloadOutlinedIcon className='document-view-icons' />
        Download
      </MenuItem>
      <Backdrop sx={{ color: common.white }} open={inProgress}>
        <CircularProgress color='inherit' />
      </Backdrop>
    </>
  );
};
