import { useEffect } from 'react';
import { SpreadSheetDocument } from './DocumentsGrid.types';
import { useAppDispatch } from '../../../store/hooks';
import { unsavedChanges } from '../../../store/ui/ui.slice';

export function useDispatchUnsavedSpreadsheetChanges(dataChanged: SpreadSheetDocument[]) {
  const dispatch = useAppDispatch();
  const isDataUnsaved = !!dataChanged?.length;

  useEffect(() => {
    dispatch(unsavedChanges(isDataUnsaved));
    return () => {
      dispatch(unsavedChanges(false));
    };
  }, [dispatch, isDataUnsaved]);
}
