import { useGetDocumentFileQuery } from '../../../store/files/documents/documents.list.service';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import { downloadDocument } from '../../../store/files/documents/documents.slice';
import {
  DocumentFileResponseFields,
  DocumentResponse,
} from '../../../store/files/documents/documents.list.types';
import { ClauseResponse } from '../../../store/files/clauses/clauses.list.types';
import { useIsDocumentResponseData } from '../../../store/files/documentsAndClauses/list.hooks.helpers';

export const useDocumentDownload = (
  document?: DocumentResponse | ClauseResponse,
  onClose?: () => void
) => {
  const { itemName, itemId } = useIsDocumentResponseData(document);

  const [fetchPreSignedUrl, setFetchPreSignedUrl] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { data: documentFile, isSuccess } = useGetDocumentFileQuery(itemId ?? '', {
    skip: !fetchPreSignedUrl,
  });
  const inProgress = fetchPreSignedUrl && !isSuccess;

  const handleDownloadDocument = useCallback(() => {
    if (isSuccess && document) {
      dispatch(
        downloadDocument({
          fileName: itemName!,
          PreSignedUrl: documentFile?.[DocumentFileResponseFields.OriginalPdfUrl] ?? '',
        })
      );
      onClose && onClose();
    }
  }, [dispatch, document, itemName, documentFile, isSuccess, onClose]);

  const handleClick = useCallback(() => {
    setFetchPreSignedUrl(true);
    handleDownloadDocument();
  }, [handleDownloadDocument]);

  useEffect(() => {
    handleDownloadDocument();
  }, [handleDownloadDocument]);

  return { handleClick, inProgress };
};
