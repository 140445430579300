import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GetCommentResponse, AddCommentData, GetCommentParams } from './comments.types';
import {
  COMMENTS_ENDPOINT,
  COMMENTS_LIST_ENDPOINT,
  COMMENTS_DELETE_ENDPOINT,
} from '../../../services/api.service.config';
import { appendAuthorizationHeader } from '../../../components/Auth/auth.helpers';

export enum COMMENTS_API_TAGS {
  LIST = 'CommentsList',
}

export const commentsApi = createApi({
  reducerPath: 'commentsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: COMMENTS_ENDPOINT,
    prepareHeaders: appendAuthorizationHeader,
  }),
  tagTypes: [COMMENTS_API_TAGS.LIST],
  endpoints: (builder) => ({
    getComments: builder.query<GetCommentResponse, GetCommentParams>({
      query: ({ itemType, id }) => ({
        url: COMMENTS_LIST_ENDPOINT.replace('{itemType}', itemType).replace('{id}', id),
      }),
      providesTags: [COMMENTS_API_TAGS.LIST],
    }),
    addComment: builder.mutation<void, AddCommentData>({
      query: (body) => ({
        url: '',
        method: 'POST',
        body,
      }),
      invalidatesTags: [COMMENTS_API_TAGS.LIST],
    }),
    deleteComment: builder.mutation<void, string>({
      query: (id) => ({
        url: COMMENTS_DELETE_ENDPOINT.replace('{id}', id),
        method: 'DELETE',
      }),
      invalidatesTags: [COMMENTS_API_TAGS.LIST],
    }),
  }),
});

export const { useGetCommentsQuery, useAddCommentMutation, useDeleteCommentMutation } = commentsApi;
