import React from 'react';
import { FormWarning } from './MetadataFormHelpers';
import './ApiError.scss';

export const ApiError: React.FC<{ apiError?: string }> = ({ apiError }) => {
  return apiError ? (
    <div className='api-error'>
      <FormWarning error={{ type: '', message: apiError }} />
    </div>
  ) : null;
};
