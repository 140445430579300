import { DeleteFunction } from './BulkDeleteModal.types';
import {
  DELETE_MODAL_MESSAGE,
  DELETE_MODAL_MESSAGE_MULTI,
  DELETE_MODAL_TITLE,
} from '../Modals/ConfirmationModal/ConfirmationModal.messages';

export async function processDelete(
  selectedIds: string[],
  deleteDocument: (arg: string) => DeleteFunction
) {
  const results = await Promise.all(
    selectedIds.map((id) => {
      return deleteDocument(id)
        .unwrap()
        .then(() => true)
        .catch(() => false);
    })
  );
  return results.filter((f) => !f).length;
}

export function getTitleAndMessage(
  itemName: string,
  itemPluralName: string,
  selectedIds: string[]
) {
  const [title, message] =
    selectedIds.length > 1
      ? [
          DELETE_MODAL_TITLE(itemPluralName),
          DELETE_MODAL_MESSAGE_MULTI(selectedIds.length, itemPluralName),
        ]
      : [DELETE_MODAL_TITLE(itemName), DELETE_MODAL_MESSAGE(itemName)];

  return { title, message };
}
