import { useEffect, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { uiSelectors } from '../../store/ui/ui.selectors';
import { useGetListQuery } from '../../store/files/upload/list.service';
import { showLoader } from '../../store/ui/ui.slice';
import { PreLandingListQueryParams } from './UploadFiles.types';

const useCustomGetPreLandingListQuery = (params?: PreLandingListQueryParams) => {
  const dispatch = useAppDispatch();
  const draftModeSelected = useAppSelector(uiSelectors.selectDraftMode);

  const { pollingInterval, skipQuery, overrideIsDraftByUploadButton, overrideValue } = params || {};

  const queryParameters = {
    isDraft: overrideIsDraftByUploadButton ? overrideValue : draftModeSelected,
  };
  const queryOptions: { pollingInterval?: number; skip?: boolean } = {
    pollingInterval,
    skip: skipQuery,
  };

  const { data, isFetching, isLoading } = useGetListQuery(queryParameters, queryOptions);

  const prevIsDraftRef = useRef(draftModeSelected);

  useEffect(() => {
    if (
      (!overrideIsDraftByUploadButton && draftModeSelected !== prevIsDraftRef.current) ||
      !isFetching
    ) {
      dispatch(showLoader(isFetching));
    }
    prevIsDraftRef.current = draftModeSelected;
  }, [isFetching, draftModeSelected, dispatch, overrideIsDraftByUploadButton]);

  return { data, isFetching, isLoading };
};

export default useCustomGetPreLandingListQuery;
