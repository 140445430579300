import { useEffect, useState, useCallback } from 'react';
import UploadService from '../../../store/files/upload/upload.service';
import { useFileLoader } from './FileLoader.hook';
import { useAppDispatch } from '../../../store/hooks';

import { setCompareDocumentReferences } from '../../../store/ui/ui.slice';
import { extractTextFromHTML, Order } from './useDocumentCompare';

export const useDocumentDownload = (
  loading: boolean,
  error: boolean,
  url?: string,
  order?: Order
) => {
  const { isLoading, isError, setLoading, setError } = useFileLoader(loading, error);
  const dispatch = useAppDispatch();

  const [documentData, setDocumentData] = useState('');

  const handleAddDocumentReference = useCallback(
    (text: string, order?: Order) => {
      if (order !== undefined && (order === 0 || order === 1 || order === 2)) {
        dispatch(
          setCompareDocumentReferences({
            text: extractTextFromHTML(text),
            order,
          })
        );
      }
    },
    [dispatch]
  );

  useEffect(() => {
    if (url === '') {
      setError(true);
    } else if (url) {
      setLoading(true);
      setError(false);
      UploadService.documentDownload(url)
        .catch(() => setError(true))
        .then((response) => {
          setLoading(false);

          handleAddDocumentReference(response?.data, order);
          setDocumentData(response?.data);
        });
    }
  }, [setError, setLoading, order, url, handleAddDocumentReference]);

  return {
    documentData,
    isDownloading: isLoading,
    isError,
  };
};
